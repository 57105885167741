import React from 'react';
import './official-edit.scss';
import EditOfficialForm from './edit-official-form/edit-official-form.container';
import { Link } from 'react-router-dom';
import withAuth from '../../../AuthHOC/withAuth';

class EditOfficial extends React.Component {
	render() {
		return (
			<div className="official-edit">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Edit Officials</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/officials">Officials</Link></li>
								<li className="breadcrumb-item current">Edit Official</li>
							</ol>
						</nav>
					</div>
				</div>
				<div>
					<EditOfficialForm id={this.props.match.params.id} {...this.props} />
				</div>
			</div>
		);
	}
}

export default withAuth(EditOfficial, ['administrator', 'allocator']);