import {FORGOT_SUCCESS, FORGOT_ERROR, FORGOT_LOADER_SHOW, RESET_SUCCESS, RESET_ERROR} from '../actions/forgot';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
    errors: {},
    is_loading: false,
	form_success: false,
	reset_errors: {},
	reset_form_success: false
}

export default (state, actions) => {
	if(state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FORGOT_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				errors: {}
            };
        case FORGOT_ERROR:
			return {
				...state,
				errors: actions.errors,
			};
		case RESET_SUCCESS:
			return {
				...state,
				reset_form_success: actions.status,
				reset_errors: {}
            };
        case RESET_ERROR:
			return {
				...state,
				reset_errors: actions.errors,
			};
		case FORGOT_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case LOCATION_CHANGE:
			return {
				...state,
				errors: {},
                is_loading: false,
                form_success: false
			}
		default: 
			return state;
	}
}