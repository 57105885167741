import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';

export default class TeamResults extends Component {
    constructor(props) {
        super(props);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillMount() {
        this.props.fetchTeams();
    }

    handleEdit = (id) => {
        this.props.onOpenEditModal(id);
    }

    handleDelete = (id) => {
        swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if(result.value)
            {
                this.props.removeTeam(id);
            }
          })
    }
    render () {
        const data = this.props.teams;
        const columns = [{
            Header: 'Name',
            accessor: 'team_name', // String-based value accessors!
            sortable:true,
            sortFunction: ($a,$b) => $a >$b ? 1: -1
          }, {
            Header: 'League',
            accessor: 'league.league_name',
          }, {
            Header: 'Venue',
            accessor: 'venue.venue_name',
          }, {
            Header: 'Actions',
            Cell: row => (
                <div className="actions">
                    <ul className="list-inline">
                        <li><button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button></li>
                        <li><span className="seprator">|</span></li>
                        <li><button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button></li>
                    </ul>
                </div>
            )
         }];
        return (
            <div className="table-area">
                {this.props.loading && <Loader />}
                <ReactTable
                    PaginationComponent={Pagination}
                    data={data}
                    minRows={5}
                    columns={columns}
                    noDataText="No Teams Found."
                    defaultPageSize= {10}
                    resizable={true}
                />
            </div>
        );
    }
}

TeamResults.propTypes = {
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            team_name: PropTypes.string,
            sortable:true,
            sortFunction: ($a,$b) => $a >$b ? 1: -1,
            league: PropTypes.string,
            home_venue: PropTypes.string,
        }).isRequired
    ),
    fetchTeams: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    removeTeam: PropTypes.func.isRequired
}