import {
	GET_ALL_ACKNOWLEDGEMENTS_SUCCESS,
	GET_ACKNOWLEDGEMENT_SUCCESS,
	SHOW_ACKNOWLEDGEMENT_MODAL,
	SET_ACKNOWDLEDGEMENT_MODAL_DATA,
	RESET_ACKNOWLEDGEMENT_MODAL_STATE
} from '../actions/acknowledgements';

const initialState = {
	data: [],
	acknowledgement: {},
	showModal: false,
	modalData: {}
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case GET_ALL_ACKNOWLEDGEMENTS_SUCCESS:
			return {
				...state,
				data: actions.results,
			};
		case GET_ACKNOWLEDGEMENT_SUCCESS:
			return {
				...state,
				acknowledgement: actions.results,
			};
		case SHOW_ACKNOWLEDGEMENT_MODAL:
			return {
				...state,
				showModal: actions.value,
			};
		case SET_ACKNOWDLEDGEMENT_MODAL_DATA:
			return {
				...state,
				modalData: actions.value,
			};
		case RESET_ACKNOWLEDGEMENT_MODAL_STATE:
			return {
				...state,
				showModal: false,
				modalData: {}
			};
		default:
			return state;
	}
}