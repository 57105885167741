import {
    FETCH_GAMEFEES_SUCCESS,
    GAMEFEE_LOADER_SHOW,
    GAMEFEE_EDIT_ERROR,
    GAMEFEE_EDIT_SUCCESS
} from '../actions/gamefee';

const initialState = {
    results: [],
    is_loading: false,
    edit_errors: {},
    edit_success: false
}

export default (state, actions) => {
    if (state === undefined) {
        return initialState;
    }

    switch (actions.type) {
        case FETCH_GAMEFEES_SUCCESS:
            return {
                ...state,
                results: actions.results
            };
        case GAMEFEE_LOADER_SHOW:
            return {
                ...state,
                is_loading: actions.status
            };
        case GAMEFEE_EDIT_ERROR:
            return {
                ...state,
                edit_errors: actions.errors
            }
        case GAMEFEE_EDIT_SUCCESS:
            return {
                ...state,
                edit_success: actions.status
            }
        default:
            return state;
    }
}
