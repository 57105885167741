import { 
	SET_FEATURES_LOADER,
	FETCH_FEATURES_DATA_SUCCESS,
	SET_TOGGLE_LOADER
} from '../actions/features';

const initialState = {
	loader: false,
	toggle_loader: false,
	data: []
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case SET_FEATURES_LOADER:
			return {
				...state,
				loader: actions.status
			};
		case FETCH_FEATURES_DATA_SUCCESS:
			return {
				...state,
				data: actions.results
			};
		case SET_TOGGLE_LOADER:
			return {
				...state,
				toggle_loader: actions.status
			};
		default:
			return state;
	}
}