import { LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_ALERT_ERROR, LOGIN_LOADER_SHOW } from '../actions/login';
import { LOCATION_CHANGE } from 'react-router-redux';
const initialState = {
	results: [],
	errors: {},
	alert_error_msg: '',
	is_loading: false,
	is_alert_error: false
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				results: actions.results
            };
        case LOGIN_ERROR:
			return {
				...state,
				errors: actions.errors,
			};
		case LOGIN_ALERT_ERROR:
			return {
				...state,
				alert_error_msg: actions.error,
				is_alert_error: actions.status,
				errors: {}
			};
		case LOGIN_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case LOCATION_CHANGE:
			return {
				...state,
				results: [],
				errors: {},
				alert_error_msg: '',
				is_loading: false,
				is_alert_error: false
			}
		default: 
			return state;
	}
}