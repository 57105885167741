import {
	CLEAR_BILLING_DATA,
	TOGGLE_BILLING_LOADER,
	TOGGLE_MODAL_BILLING_LOADER,
	FETCH_SUBSCRIPTION_INFO_SUCCESS,
	FETCH_INVOICES_DATA_SUCCESS,
	FETCH_CARD_DATA_SUCCESS,
	SHOW_TRIAL_BANNER,
	CLOSE_TRIAL_BANNER
} from '../actions/billing';

const initialState = {
	loader: false,
	modalLoader: false,
	subcriptionData: {},
	invoicesList: [],
	cardData: {},
	showTrialBanner: false,
	trialStatus: '',
	trialDays: ''
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case CLEAR_BILLING_DATA:
			return initialState;
		case TOGGLE_BILLING_LOADER:
			return {
				...state,
				loader: actions.status
			};
		case TOGGLE_MODAL_BILLING_LOADER:
			return {
				...state,
				modalLoader: actions.status
			};
		case FETCH_SUBSCRIPTION_INFO_SUCCESS:
			return {
				...state,
				subcriptionData: actions.results
			}
		case FETCH_INVOICES_DATA_SUCCESS:
			return {
				...state,
				invoicesList: actions.results
			}
		case FETCH_CARD_DATA_SUCCESS:
			return {
				...state,
				cardData: actions.results
			}
		case SHOW_TRIAL_BANNER:
			if (actions.results.status === 'ended') {
				return {
					...state,
					showTrialBanner: true,
					trialStatus: actions.results.status
				}
			} else if (actions.results.status === 'trial') {
				return {
					...state,
					showTrialBanner: true,
					trialStatus: actions.results.status,
					trialDays: actions.results.daysend
				}
			} else {
				return {
					...state,
					showTrialBanner: false,

				}
			}
		case CLOSE_TRIAL_BANNER:
			return {
				...state,
				showTrialBanner: false,
				trialStatus: '',
				trialDays: ''
			}
		default:
			return state;
	}
}