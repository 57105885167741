import React from 'react';
import './game-edit.scss';
import {Link} from 'react-router-dom';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import dateIcon from '../../../../../public/svg/date.svg';
import moment from 'moment';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import AssignReferre from './assign-referee/assign_referee.container';
import withAuth from '../../../../AuthHOC/withAuth';
const format = 'YYYY-MM-DD';
const now = moment();
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

class GameEditPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
          showTime: true,
          showDateInput: false,
          disabled: false,
          gameTime: moment().hour(0).minute(0),
          home_team_id: '',
          away_team_id: '',
          league_id: '',
          venue_id: '',
          gender_id: '',
          game_date: now,
          game_time: '',
          agegroup_id: '',
          game_pattern: '',
          game_published: '0',
          fields: []
        };

        this.onTimeChange = this.onTimeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.resetData();
        this.props.fetchGamesdata();
        this.props.fetchGame(this.props.match.params.id);
    }

    onChange = (value) => {
        this.setState({
            game_date: value,
        });
    }

    onTimeChange(value) {
        this.setState({
            game_time: value.format("HH:mm")
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {
            home_team_id,
            away_team_id,
            venue_id,
            league_id,
            gender_id,
            game_date,
            game_time,
            game_pattern,
            game_published
        } = this.state;

        const data = {
            home_team_id: home_team_id,
            league_id: league_id,
            away_team_id: away_team_id,
            venue_id: venue_id,
            gender: gender_id,
            game_date: game_date.format(format),
            game_time: game_time,
            game_pattern: game_pattern,
            game_published: game_published,
            agegroup_id: '1'
        }

        await this.props.onGameSubmitted({id: this.props.match.params.id, data});
        this.props.fetchGame(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                showTime: true,
                showDateInput: false,
                disabled: false,
                gameTime: moment().hour(0).minute(0),
                home_team_id: '',
                away_team_id: '',
                league_id: '',
                venue_id: '',
                gender_id: '',
                game_date: now,
                game_time: '',
                agegroup_id: '',
                game_pattern: '',
                game_published: '0',
            });
        }
        if(Object.keys(nextProps.game).length > 0)
        {
            this.setState({
                home_team_id: nextProps.game.game.home_team_id,
                away_team_id: nextProps.game.game.away_team_id,
                league_id: nextProps.game.game.league_id,
                venue_id: nextProps.game.game.venue_id,
                gender_id: nextProps.game.game.gender,
                game_date: moment(nextProps.game.game.game_date),
                game_time: nextProps.game.game.game_time.slice(0,5),
                agegroup_id: nextProps.game.game.agegroup_id,
                game_pattern: nextProps.game.game.game_pattern,
                game_published: nextProps.game.game.game_published,
                fields: nextProps.game.fields,
                game_id: nextProps.game.game.id
            })
        }
    }

	render() {
        const state = this.state;
        const calendar = (<Calendar
            style={{ zIndex: 1000 }}
            dateInputPlaceholder="Select Date"
            formatter={format}
            disabledTime={null}
            timePicker={null}
            defaultValue={now}
            showDateInput={state.showDateInput}
        />);
        let leagues = this.props.leagues;
        let optionLeagueItems = leagues.map((league) =>
                <option key={league.League_ID} value={league.League_ID}>{league.League_Name}</option>
            );
        let venues = this.props.venues;
        let optionVenueItems = venues.map((venue) =>
                <option key={venue.Venue_ID} value={venue.Venue_ID}>{venue.Venue_Name}</option>
            );
        let teams = this.props.teams;
        let optionTeamItems = teams.map((team) =>
                <option key={team.Team_ID} value={team.Team_ID}>{team.Team_Name}</option>
            );
        let patterns = this.props.patterns;
            let optionPatternItems = patterns.map((pattern) =>
                    <option key={pattern.Pattern_ID} value={pattern.Pattern_ID}>{pattern.Pattern_Name}</option>
            );

        let home_team_error = null;
        let away_team_error = null;
        let league_error= null;
        let venue_error= null;
        let gender_error= null;
        let pattern_error= null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'home_team_id')
                    home_team_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'away_team_id')
                    away_team_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'league_id')
                    league_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'venue_id')
                    venue_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'gender_id')
                    gender_error = <FormError error={this.props.errors[key][0]} />;
                else
                    pattern_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
		return(
			<div className="edit-game-page">
				<div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title">Edit Game</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item current"><Link to="/games">All Games</Link></li>
	                            <li className="breadcrumb-item current">Edit Game</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="box">
                    <div className="edit-game mb_30">
                        { this.props.game_loading && <Loader /> }
    					<div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Team A</label>
                                    <div className="input-effect-1">
                                        <select name="home_team_id" value={this.state.home_team_id} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="">Select home team</option>
                                            {optionTeamItems}
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {home_team_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Team B</label>
                                    <div className="input-effect-1">
                                        <select name="away_team_id" value={this.state.away_team_id} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="">Select away team</option>
                                            {optionTeamItems}
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {away_team_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Venue</label>
                                    <div className="input-effect-1">
                                        <select name="venue_id" value={this.state.venue_id} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="">Select venue</option>
                                            {optionVenueItems}
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {venue_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">League</label>
                                    <div className="input-effect-1">
                                        <select name="league_id" value={this.state.league_id} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="">Select league</option>
                                            {optionLeagueItems}
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {league_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Gender</label>
                                    <div className="input-effect-1">
                                        <select name="gender_id" value={this.state.gender_id} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="0">Select gender</option>
                                            <option value="1">Men</option>
                                            <option value="2">Women</option>
                                            <option value="3">Boys</option>
                                            <option value="4">Girls</option>
                                            <option value="5">Mixed</option>
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {gender_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Game Dates</label>
                                    <div className="input-effect-1">
                                        <div className="datepicker" id="datepicker">
                                            <DatePicker
                                              animation="slide-up"
                                              disabled={state.disabled}
                                              calendar={calendar}
                                              value={state.game_date}
                                              onChange={this.onChange}
                                              getCalendarContainer={()=> document.getElementById('datepicker')}
                                              className="custom-calendar"
                                            >
                                              {
                                                ({ value }) => {
                                                  return (
                                                    <div className="input-group">
                                                        <input
                                                          placeholder="Select Date"
                                                          disabled={state.disabled}
                                                          readOnly
                                                          tabIndex="-1"
                                                          className="ant-calendar-picker-input ant-input form-control"
                                                          value={value.format(format)}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <img src={dateIcon} alt="date" className="imgsvg" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                  );
                                                }
                                              }
                                            </DatePicker>
                                        </div>
                                        <div className="focus-border"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Game Time</label>
                                    <div className="timepicker">
                                        <div className="input-group">
                                            <TimePicker showSecond={false} value={moment(this.state.game_time,'HH:mm')} onChange={this.onTimeChange}/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={dateIcon} alt="date" className="imgsvg" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Game Patterns</label>
                                    <div className="input-effect-1">
                                        <select name="game_pattern" value={this.state.game_pattern} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option>Select pattern</option>
                                            {optionPatternItems}
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                    {pattern_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="label">Game Status</label>
                                    <div className="input-effect-1">
                                        <select name="game_published" value={this.state.game_published} onChange={event => {this.handleInputChange(event)}} className="normal-select">
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                        <div className="focus-border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-orange" onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <AssignReferre fields={this.state.fields} game_id={this.props.match.params.id} />
                </div>
			</div>
		);
	}
}

GameEditPage.propTypes = {
    fetchGamesdata: PropTypes.func.isRequired,
    //onGameSubmitted: PropTypes.func.isRequired,
    leagues: PropTypes.arrayOf(
        PropTypes.shape({
            League_ID: PropTypes.number,
            League_Name: PropTypes.string
        }).isRequired
    ),
    venues: PropTypes.arrayOf(
        PropTypes.shape({
            Venue_ID: PropTypes.number,
            Venue_Name: PropTypes.string
        }).isRequired
    ),
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            Team_ID: PropTypes.number,
            Team_Name: PropTypes.string
        }).isRequired
    ),
    genders: PropTypes.arrayOf(
        PropTypes.shape({
            Gender_ID: PropTypes.number,
            Gender_Name: PropTypes.string
        }).isRequired
    ),
    patterns: PropTypes.arrayOf(
        PropTypes.shape({
            Pattern_ID: PropTypes.number,
            Pattern_Name: PropTypes.string
        }).isRequired
    ),
    game_loading: PropTypes.bool.isRequired,
    form_success: PropTypes.bool.isRequired,
    errors: PropTypes.object
}

export default withAuth(GameEditPage,['administrator','allocator']);