import React, { Component } from 'react';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { getPermission } from '../../constants';

function withAuth(AuthComponent, roles) {
	return class AuthWrapped extends Component {
		constructor(props) {
			super(props);
			this.state = {
				user: null
			}
		}
		componentWillMount() {
			if (localStorage.getItem('user')) {
				let auth_user = JSON.parse(localStorage.getItem('user'));
				this.setState({
					user: auth_user.user
				});
				let permissions = getPermission();
				if (typeof(permissions) === 'string') {
					localStorage.removeItem('user');
					this.props.history.push('/login');
				}
				const found = permissions.some(p => roles.includes(p));
				if (!found) {
					this.props.history.goBack();
					swal(
						'Info',
						"You don't have access to this location.",
						'info'
					);
				}
			}
			else {
				if (this.props.location.pathname !== '/') {
					swal(
						'Error',
						'Please do login to continue.',
						'error'
					);
				}
				this.props.history.push("/login");
			}
		}
		render() {
			if (localStorage.getItem('user')) {
				let permissions = getPermission();
				const found = permissions.some(p => roles.includes(p));
				if (!found) {
					return null;
				} else {
					return (
						<AuthComponent history={this.props.history} user={this.state.user} {...this.props} />
					);
				}
			} else {
				return null;
			}
		}
	}
}

export default withAuth;