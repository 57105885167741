import React, { Component } from 'react';
import Logo from '../logo/logo';
import Logos from '../../public/images/small-logo.png';
import dashboardSvg from '../../public/svg/dashboard.svg';
import clockSvg from '../../public/svg/clock.svg';
import lockSvg from '../../public/svg/lock.svg';
import gamesSvg from '../../public/svg/games.svg';
import recordSvg from '../../public/svg/records.svg';
import officialSvg from '../../public/svg/official.svg';
import settingsSvg from '../../public/svg/settings.svg';
import organisationSvg from '../../public/svg/organisation.svg';
import reportSvg from '../../public/svg/report.svg';
import communicationSvg from '../../public/svg/communication.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import '../../public/styles/leftsidebar.scss';
import $ from 'jquery';
import { getLockedStatus, getPermission, getUserId,getHeaders } from '../../constants';
import ReactResizeDetector from 'react-resize-detector';
import classnames from 'classnames';
import { API_BASE_URL } from '../../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import Loader from '../loader/loader';
import axios from 'axios';
import moment from 'moment';

const now = moment();
console.log("NOW", now);
const before = moment().subtract(7, 'd');
console.log("BEFORE", before);
const format = 'DD-MM-YYYY';
export default class LeftSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			report_start_date: before,
			report_end_date: now,
			loading: false
		};
		this.setLoading = this.setLoading.bind(this);
	}

	setLoading(val) {
		this.setState({ loading: val });
	}


	getlockStatus() {

		return getLockedStatus();
	}
	getSettings() {
		// image tag to svg convert
		$('.imgsvg').each(function () {
			var $img = $(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgURL = $img.attr('src');
			$.get(imgURL, function (data) {
				var $svg = $(data).find('svg');
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg');
				}
				$svg = $svg.removeAttr('xmlns:a');
				if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
					$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
				}
				$img.replaceWith($svg);
			}, 'xml');
		});

		const toggleStateParent = () => {
			this.props.toggle();
		};


		function leftmenu() {
			if ($(window).width() > 767) {
				// desktop
				$('.main').addClass('desktop').removeClass('mobile left-open');
				$('.desktop .header-container .toggle').click(function (e) {
					e.preventDefault();
					$('.main').toggleClass('left-mini');
					toggleStateParent();
				});
			} else {
				// mobile
				$('.main').addClass('mobile').removeClass('desktop left-mini');
				$('.mobile .header-container .toggle').click(function () {
					$('.main').addClass('left-open');
				});
				$('.mobile .leftmenu li a.link').on('click', function () {
					if ($('.mobile .leftmenu li a.link').hasClass('active')) {
						setTimeout(function () {
							$('.main').removeClass('left-open');
						}, 500);
					}
				});
				$(document).on('touchstart click', function (e) {
					var closearea = $(".mobile .leftsidebar, .mobile .header-container .toggle");
					if (closearea.has(e.target).length === 0) {
						$('.main').removeClass('left-open');
					}
				});
			}
		}
		$(document).ready(function () {
			leftmenu();
		});
		$(window).resize(function () {
			leftmenu();
		});
	}
	exportBankRecordToCSV() {
		let start_date = this.report_start_date;
		let end_date = this.report_end_date;
		console.log("Dates:", start_date, end_date, this.report_start_date);
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		this.setLoading(true);
		// axios.get(`${API_BASE_URL}/v1/auth/users/bankrecords/${getUserId()}/organisation/${organisation_id}/reports/bankrecords/csv`, {
		axios.get(`${API_BASE_URL}/v1/auth/users/bankrecord/${organisation_id}/CSV/${getUserId()}`, {
			// params: {
			// 	start_date: "2021-09-22",
			// 	end_date: "2021-09-29"
			// },
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'Access-Control-Allow-Origin': '*',
				'Authorization': 'Bearer ' + user.access_token,
			},
			//headers: getHeaders()
			responseType: 'blob'

		}).then((response) => {
			// this.setLoading(false);
			// swal(
			// 	'Success',
			// 	response.data.message,
			// 	'success'
			// );
			this.setLoading(false);
			const mimeType = format === 'excel'
				? 'application / vnd.openxmlformats - officedocument.spreadsheetml.sheet'
				: format === 'csv'
					? 'text/csv'
					: format === 'pdf'
						? 'application/pdf'
						: '';

			const fileExtension = format === 'excel'
				? 'xlsx'
				: format === 'csv'
					? 'csv'
					: format === 'pdf'
						? 'pdf'
						: '';

			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
			link.download = `Bank-Report-Testing.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			swal(
				'Success',
				'Download Report Success',
				'success'
			);
		}).catch((error) => {
			this.setLoading(true);
			console.log(error);
		})
	}

	componentDidMount() {
		this.getSettings();
		this.getlockStatus();

	}
	render() {
		const permissions = getPermission();
		const isAvailabilityLockedStatus = this.getlockStatus();
		console.log("Lock Status", isAvailabilityLockedStatus);
		return (

			<div className={classnames('leftsidebar', { 'banner-active': this.props.bannerActive })}>
				<div className="wrappers">
					<div className="main-logo">
						<div className="dflex align-items-center h-100">
							<Logo />
							<div className="small-logo">
								<span><img src={Logos} alt="logo" /></span>
							</div>
						</div>
					</div>
					<div className="leftmenu" id="accordion">
						{this.state.loading && <Loader />}
						<ul>
							{permissions.some(p => ['administrator', 'allocator', 'official', 'billing'].includes(p)) && (
								<li className="level_0">
									<NavLink exact={true} to="/" activeClassName='active' className="link">
										<span className="icon icon-dashboard">
											<img src={dashboardSvg} className="imgsvg" alt="dashboard" />
										</span>
										<span className="text">Dashboard</span>
									</NavLink>
								</li>
							)}
							{permissions.some(p => ['official'].includes(p)) && (
								<li className="level_0">
									<NavLink exact={true} to="/availability" activeClassName='active' className="link">
										<span className="icon icon-dashboard">
											<img src={clockSvg} className="imgsvg" alt="availability" />
										</span>
										<span className="text">Availability</span>
									</NavLink>
								</li>
							)}
							<li className="level_0">
								<a href="#/" data-target="#game_collapse_1" className="collapsed" data-toggle="collapse">
									<span className="icon icon-games">
										<img src={gamesSvg} className="imgsvg" alt="games" />
									</span>
									<span className="text">Games</span>
									<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
								</a>
								<div className="collapse sub-menu" id="game_collapse_1" data-parent="#accordion">
									<ul>
										{permissions.some(p => ['administrator', 'allocator'].includes(p)) && <li><NavLink to="/games" activeClassName='active' className="link">All Games</NavLink></li>}
										{permissions.some(p => ['official'].includes(p)) && <li><NavLink to="/mygames" activeClassName='active' className="link">My Games</NavLink></li>}
									</ul>
								</div>
							</li>

							{permissions.some(p => ['administrator'].includes(p)) && (
								<li className="level_0">
									<a href="#/" data-target="#officials_collapse_1" className="collapsed" data-toggle="collapse">
										<span className="icon icon-settings">
											<img src={officialSvg} className="imgsvg" alt="officials" />
										</span>
										<span className="text">Officials</span>
										<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
									</a>
									<div className="collapse sub-menu" id="officials_collapse_1" data-parent="#accordion">
										<ul>
											<li><NavLink to="/officials" activeClassName='active' className="link">Active Users</NavLink></li>
											<li><NavLink to="/official/pending" activeClassName='active' className="link">Pending Users</NavLink></li>
											{/* <li><NavLink to="/official/available" activeClassName='active' className="link">Available</NavLink></li> */}
										</ul>
									</div>
								</li>
							)}

							{permissions.some(p => ['administrator','billing'].includes(p)) && (
								<li className="level_0">
									<a href="#/" data-target="#reports_collapse_1" className="collapsed" data-toggle="collapse">
										<span className="icon icon-settings">
											<img src={reportSvg} className="imgsvg" alt="reports" />
										</span>
										<span className="text">Reports</span>
										<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
									</a>
									<div className="collapse sub-menu" id="reports_collapse_1" data-parent="#accordion">
										<ul>
										{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/travel-claim" activeClassName='active' className="link">Travel Claim</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/performance" activeClassName='active' className="link">Performance Report</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/payment" activeClassName='active' className="link">Payment</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/roster" activeClassName='active' className="link">Roster</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/decline" activeClassName='active' className="link">Declines</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/official/available" activeClassName='active' className="link">Availability</NavLink></li>}
											{permissions.some(p => ['billing'].includes(p)) &&<li><NavLink to="/report/bankrecord" activeClassName='active' className="link">Bank Record</NavLink></li>}
											{permissions.some(p => ['administrator'].includes(p)) &&<li><NavLink to="/report/allusers-record" activeClassName='active' className="link">All Users Record</NavLink></li>}
										</ul>
									</div>
								</li>
							)}

							{permissions.some(p => ['administrator'].includes(p)) && (
								<li className="level_0">
									<a href="#/" data-target="#organisation_collapse_1" className="collapsed" data-toggle="collapse">
										<span className="icon icon-settings">
											<img src={organisationSvg} className="imgsvg" alt="organisation" />
										</span>
										<span className="text">Organisation</span>
										<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
									</a>
									<div className="collapse sub-menu" id="organisation_collapse_1" data-parent="#accordion">
										<ul>
											<li><NavLink to="/organisation/leagues" activeClassName='active' className="link">Leagues</NavLink></li>
											<li><NavLink to="/organisation/venues" activeClassName='active' className="link">Venues</NavLink></li>
											<li><NavLink to="/organisation/patterns" activeClassName='active' className="link">Patterns</NavLink></li>
											<li><NavLink to="/organisation/teams" activeClassName='active' className="link">Teams</NavLink></li>
											<li><NavLink to="/organisation/regions" activeClassName='active' className="link">Regions</NavLink></li>
											<li><NavLink to="/organisation/payscales" activeClassName='active' className="link">Pay Scales</NavLink></li>
											<li><NavLink to="/report/custom" activeClassName='active' className="link">Performance Reports Template</NavLink></li>
											<li><NavLink to="/report/game" activeClassName='active' className="link">Game Reports Template</NavLink></li>
											<li><NavLink to="/acknowledgements" activeClassName='active' className="link">Acknowledgements</NavLink></li>
										</ul>
									</div>
								</li>
							)}

							{permissions.some(p => ['administrator', 'allocator'].includes(p)) && (
								<li className="level_0">
									<a href="#/" data-target="#communication_collapse_1" className="collapsed" data-toggle="collapse">
										<span className="icon icon-settings">
											<img src={communicationSvg} className="imgsvg" alt="organisation" />
										</span>
										<span className="text">Communication</span>
										<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
									</a>
									<div className="collapse sub-menu" id="communication_collapse_1" data-parent="#accordion">
										<ul>
											<li><NavLink to="/comunication/send-bulk-email" activeClassName='active' className="link">Send Bulk Email</NavLink></li>
										</ul>
									</div>
								</li>
							)}

							{permissions.some(p => ['administrator'].includes(p)) && (
								<li className="level_0">
									<a href="#/" data-target="#setting_collapse_1" className="collapsed" data-toggle="collapse">
										<span className="icon icon-settings">
											<img src={settingsSvg} className="imgsvg" alt="setting" />
										</span>
										<span className="text">Settings</span>
										<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
									</a>
									<div className="collapse sub-menu" id="setting_collapse_1" data-parent="#accordion">
										<ul>
											<li><NavLink to="/features" activeClassName='active' className="link">Features</NavLink></li>
											<li><NavLink to="/subscription" activeClassName='active' className="link">Billing</NavLink></li>
										</ul>
									</div>
								</li>
							)}
	
							{permissions.some(p => ['administrator', 'allocator'].includes(p)) && (
								<li className="level_0">
									{isAvailabilityLockedStatus
										?

										<NavLink exact={true} to="/lock-availability" activeClassName='active' className="link">
											<span className="icon icon-dashboard">
												<img src={lockSvg} className="imgsvg" alt="lock-availability" />
											</span>
											<span className="text">Lock Availability</span>
										</NavLink>

										:

										<NavLink exact={true} to="">
											<span className="icon icon-dashboard">
												<img src={lockSvg} className="imgsvg" alt="lock-availability" />
											</span>
											<span className="text">Ask Admin</span>
										</NavLink>
									}
								</li>
							)}
						</ul>
					</div>
				</div>
				<ReactResizeDetector handleWidth refreshMode="debounce" refreshRate={100} onResize={this.props.onResize} />
			</div>
		);
	}
}