import React from 'react';
import withAuth from '../../../AuthHOC/withAuth';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faCalendarAlt, faClock, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import Dropzone from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';
import Loading from '../../../loader/loader';

import './custom-reports.scss';

class CustomReportsPage extends React.Component {
	state = {
		report_name: '',
		isModalOpened: false,
		field_type: 'text',
		field_title: '',
		options: [''],
		field_required: false,
		fields: []
	}

	closeModal = () => {
		this.setState({
			isModalOpened: false
		})
	}

	openModal = () => {
		this.setState({
			isModalOpened: true
		})
	}

	resetModal = () => {
		this.setState({
			field_type: 'text',
			field_title: '',
			field_required: false,
			options: ['']
		});
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	addOption = () => {
		this.setState(state => ({
			options: [...state.options, '']
		}))
	}

	removeOption = index => {
		this.setState({
			options: this.state.options.filter((_, i) => i !== index)
		});
	}

	handleInputOption = (index, event) => {
		let newData = this.state.options.slice();
		newData[index] = event.target.value;
		this.setState({
			options: newData
		})
	}

	addField = () => {
		let data;
		let { field_type, field_title, field_required, options } = this.state;

		if (field_type === 'text' || field_type === 'number' || field_type === 'date' || field_type === 'time' || field_type === 'datetime' || field_type === 'file') {
			data = {
				type: field_type,
				title: field_title,
				required: field_required
			}
		} else if (field_type === 'checkbox' || field_type === 'radio' || field_type === 'dropdown') {
			data = {
				type: field_type,
				title: field_title,
				options: options,
				required: field_required
			}
		}

		this.setState(state => ({
			fields: [...state.fields, data]
		}), () => {
				this.closeModal();
				this.resetModal();
		});
	}

	removeField = index => {
		this.setState({
			fields: this.state.fields.filter((_, i) => i !== index)
		});
	}

	resetFields = () => {
		this.setState({
			report_name: '',
			isModalOpened: false,
			field_type: 'text',
			field_title: '',
			options: [''],
			field_required: false,
			fields: [],
		});
	};

	submitReports = () => {
		let data = {
			report_name: this.state.report_name,
			fields: this.state.fields
		}

		this.props.submitReports(data, this.resetFields);
	}

	render() {
		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD'}
				disabledTime={null}
				timePicker={null}
				defaultValue={moment()}
				showDateInput={false}
			/>
		);

		const calendarAndTime = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD HH:mm:ss'}
				disabledTime={null}
				defaultValue={moment()}
				showDateInput={false}
				timePicker={<TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} />}
			/>
		);
		return (
			<div className="custom-reports">
					{this.props.loader && <Loading />}
					<Modal
						open={this.state.isModalOpened}
						onClose={this.closeModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-lg modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Add a new field</h5>
									<button type="button" className="close" onClick={this.closeModal}>
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="d-flex mb-3 flex-wrap">
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="textRadio" name="field_type" value="text" checked={this.state.field_type === 'text'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="textRadio">Text</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="checkboxRadio" name="field_type" value="checkbox" checked={this.state.field_type === 'checkbox'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="checkboxRadio">Checkbox</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="radioRadio" name="field_type" value="radio" checked={this.state.field_type === 'radio'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="radioRadio">Radio</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="dropdownRadio" name="field_type" value="dropdown" checked={this.state.field_type === 'dropdown'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="dropdownRadio">Dropdown</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="numberRadio" name="field_type" value="number" checked={this.state.field_type === 'number'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="numberRadio">Number</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="dateRadio" name="field_type" value="date" checked={this.state.field_type === 'date'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="dateRadio">Date</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="timeRadio" name="field_type" value="time" checked={this.state.field_type === 'time'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="timeRadio">Time</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="datetimeRadio" name="field_type" value="datetime" checked={this.state.field_type === 'datetime'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="datetimeRadio">Date Time</label>
										</div>
										<div className="custom-control custom-radio mr-3">
											<input type="radio" id="fileRadio" name="field_type" value="file" checked={this.state.field_type === 'file'} onChange={this.handleInputChange} className="custom-control-input" />
											<label className="custom-control-label" htmlFor="fileRadio">File</label>
										</div>
									</div>
									<div>
										<div className="form-group">
											<div className="d-flex justify-content-between align-items-center mb-3">
												<label className="label m-0">Title</label>
												<div className="custom-control custom-checkbox m-0">
													<input type="checkbox" className="custom-control-input" id="requiredCheckbox" name="field_required" checked={this.state.field_required} onChange={this.handleInputChange} />
													<label className="custom-control-label" htmlFor="requiredCheckbox">Required</label>
												</div>
											</div>
											<input type="text" className="form-control" name="field_title" onChange={this.handleInputChange} value={this.state.field_title} placeholder="Title" />
										</div>
										{(this.state.field_type === 'checkbox'
											|| this.state.field_type === 'radio'
											|| this.state.field_type === 'dropdown') && (
											<div className="form-group">
												<div className="d-flex justify-content-between align-items-center mb-3">
													<label className="label mb-0">Options</label>
													<button className="line-btn" onClick={this.addOption}>Add Option</button>
												</div>
												{this.state.options.map((field, index) => {
													return (
														<div key={index} className="d-flex justify-content-between align-items-center mb-2">
															<input type="text" className="form-control mr-2" value={field} onChange={(event) => { this.handleInputOption(index, event) }} placeholder={'Option ' + Number(index + 1)} />
															<FontAwesomeIcon icon={faMinusCircle} onClick={() => { this.removeOption(index) }} className="report-icon" />
														</div>
													);
												})}
											</div>
										)}
										<button className="btn btn-orange mt-3" onClick={this.addField}>Add Field</button>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					<div className="d-flex align-items-center justify-content-between mb-4">
						<h1>Report Details</h1>
						<button className="btn btn-orange" onClick={this.openModal}>Add Field</button>
					</div>
					<div className="form-group">
						<label className="label">Report Name</label>
						<input type="text" className="form-control" value={this.state.report_name} name="report_name" onChange={this.handleInputChange} placeholder="Report Name" />
					</div>
					<hr />
					<div>
						{this.state.fields.map((field, index) => {
							if (field.type === 'text') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}{field.required && <span className="required-text">Required</span>}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<TextareaAutosize
											minRows="3"
											className="form-control no-transition"
											placeholder={field.title}
										/>
									</div>
								)
							} else if (field.type === 'checkbox') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<div className="d-flex flex-wrap">
											{field.options.map((option, optionIndex) => {
												return (
													<div key={optionIndex} className="custom-control custom-checkbox mr-4">
														<input type="checkbox" className="custom-control-input" id={`${option}-${optionIndex}-${index}`} />
														<label className="custom-control-label" htmlFor={`${option}-${optionIndex}-${index}`}>{option}</label>
													</div>
												)
											})}
										</div>
									</div>
								)
							} else if (field.type === 'radio') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<div className="d-flex flex-wrap">
											{field.options.map((option, optionIndex) => {
												return (
													<div key={optionIndex} className="custom-control custom-radio mr-4">
														<input type="radio" className="custom-control-input" id={`${option}-${optionIndex}-${index}`} name={`${field.title}-${index}`} />
														<label className="custom-control-label" htmlFor={`${option}-${optionIndex}-${index}`}>{option}</label>
													</div>
												)
											})}
										</div>
									</div>
								)
							} else if (field.type === 'dropdown') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<select className="form-control custom-select">
											<option>Open this select menu</option>
											{field.options.map((option, optionIndex) => {
												return (
													<option key={optionIndex} value={option}>{option}</option>
												)
											})}
										</select>
									</div>
								);
							} else if (field.type === 'number') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<input type="number" className="form-control" placeholder={field.title} />
									</div>
								);
							} else if (field.type === 'date') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<div className="input-effect-1 p-0 col-sm-4">
											<div className="datepicker" id="datepicker">
												<DatePicker
													animation="slide-up"
													disabled={false}
													calendar={calendar}
													value={moment()}
													getCalendarContainer={() => document.getElementById('datepicker')}
													className="custom-calendar"
												>
													{
														({ value }) => {
															return (
																<div className="input-group">
																	<input
																		placeholder="Select Date"
																		disabled={false}
																		readOnly
																		tabIndex="-1"
																		className="ant-calendar-picker-input ant-input form-control"
																		value={value.format('MM-DD-YYYY')}
																	/>
																	<div className="input-group-append">
																		<span className="input-group-text">
																			<FontAwesomeIcon icon={faCalendarAlt} />
																		</span>
																	</div>
																</div>
															);
														}
													}
												</DatePicker>
											</div>
											<div className="focus-border"></div>
										</div>
									</div>
								);
							} else if (field.type === 'time') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<div className="timepicker input-group p-0 col-sm-4">
											<TimePicker showSecond={false} />
											<div className="input-group-append">
												<span className="input-group-text">
													<FontAwesomeIcon icon={faClock} />
												</span>
											</div>
										</div>
									</div>
								);
							} else if (field.type === 'datetime') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<div className="input-effect-1 p-0 col-sm-4">
											<div className="datepicker" id="datepicker">
												<DatePicker
													animation="slide-up"
													disabled={false}
													calendar={calendarAndTime}
													value={moment()}
													getCalendarContainer={() => document.getElementById('datepicker')}
													className="custom-calendar"
												>
													{
														({ value }) => {
															return (
																<div className="input-group">
																	<input
																		placeholder="Select Date"
																		disabled={false}
																		readOnly
																		tabIndex="-1"
																		className="ant-calendar-picker-input ant-input form-control"
																		value={value.format('MM-DD-YYYY - HH:mm:ss')}
																	/>
																	<div className="input-group-append">
																		<span className="input-group-text">
																			<FontAwesomeIcon icon={faCalendarAlt} />
																		</span>
																	</div>
																</div>
															);
														}
													}
												</DatePicker>
											</div>
											<div className="focus-border"></div>
										</div>
									</div>
								);
							} else if (field.type === 'file') {
								return (
									<div key={index} className="form-group mb-4">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<label className="label mb-0">{field.title}</label>
											<button className="line-btn" onClick={() => { this.removeField(index) }}>Remove Field</button>
										</div>
										<Dropzone
											className="drop-area d-flex align-items-center"
											multiple={false}
											accept=".xls, .csv"
										>
											<div className="text">
												<p>
													<span className="icon icon-upload">
														<FontAwesomeIcon icon={faCloudUploadAlt} className="fa fa-file-excel" />
													</span>
												</p>
												<p>Drop file here to click upload</p>
											</div>
										</Dropzone>
									</div>
								);
							}
							return 0;
						})}
					</div>
					<button className="btn btn-orange" onClick={this.submitReports}>Submit Report</button>
			</div>
		);
	}
}

export default withAuth(CustomReportsPage, ['administrator']);