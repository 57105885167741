import React, { Component } from 'react';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import withAuth from '../../../../AuthHOC/withAuth';

class ResultsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false
		}
		this.handleButton = this.handleButton.bind(this);
		this.toggleRow = this.toggleRow.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.handleThProps = this.handleThProps.bind(this);
	}

	componentWillMount() {
		this.props.getPendingUser();
	}

	componentWillReceiveProps(nextProps) {
		let selected = {}
		nextProps.list.forEach(data => {
			selected[data.id] = false;
		});

		this.setState({
			selected: selected
		});
	}

	handleButton = (id) => {
		swal({
			title: 'Are you sure?',
			text: "Do you want to resend the invitation?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, resend it!'
		}).then((result) => {
			if (result.value) {
				this.props.resendInvite([id]);
			}
		})
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	toggleRow(gameId) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[gameId] = !this.state.selected[gameId];

		const selectedList = Object.keys(newSelected).filter(obj => {
			return newSelected[obj] === true;
		});
		
		this.props.selectedList(selectedList);

		this.setState({
			selected: newSelected,
		})
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
			this.props.list.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			const selectedList = Object.keys(newObj).filter(obj => {
				return newObj[obj] === true;
			});
			this.props.selectedList(selectedList);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: false });
			this.props.list.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			const selectedList = Object.keys(newObj).filter(obj => {
				return newObj[obj] === true;
			});

			this.props.selectedList(selectedList);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage
			});
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.props.games.forEach(data => {
						newSelected[data.id] = false;
					});
					this.props.games.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	render() {
		const data = this.props.list;
		const columns = [{
			Header: (header) => (
				<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
					<input
						type="checkbox"
						className="custom-control-input"
						id="selectAllCheckBox"
						checked={this.state.selectedAll[this.state.currentPage]}
						onChange={() => this.toggleSelectAll()}
						ref={input => {
							if (input) {
								input.indeterminate = this.state.indeterminateSign;
							}
						}}
					/>
					<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
				</div>
			),
			width: 52,
			Cell: row => (
				<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
					<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id]} onChange={() => this.toggleRow(row.original.id)} />
					<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
				</div>
			),
			className: 'header-checkbox-toggle',
			headerStyle: { padding: '1.07143em 0' },
			style: { padding: '0.71429em 0' }
		}, {
			Header: 'Name',
			accessor: 'name'
		}, {
			Header: 'Email Address',
			accessor: 'email_address'
		}, {
			Header: 'Actions',
			width: 190,
			Cell: row => (
				<button type="button" onClick={() => this.handleButton(row.original.id)} className="btn btn-success">Resend Invitation</button>
			)
		}];
		return (
			<div className="table-area">
				{(this.props.loader || this.props.action_loader) && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText="No Games Found."
					defaultPageSize={this.state.pageSize}
					pageSizeOptions={[10, 25, 50, 100]}
					resizable={true}
					onPageChange={(index) => this.handlePageChange(index)}
					onPageSizeChange={(size) => this.handlePageSizeChange(size)}
					getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
				/>
			</div>
		);
	}
}

export default withAuth(ResultsTable, ['administrator']);