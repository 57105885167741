import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { FORGOT_PERFORMED, RESET_PERFORMED, forgotLoaderStatus, forgotSuccess, forgotError, resetSuccess, resetError } from '../actions/forgot';
import {API_BASE_URL} from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* doForgotPerformed({formData}) {
	yield put(forgotLoaderStatus(true));
    try {
        const results = yield call(
            axios.post,
            `${API_BASE_URL}/v1/auth/forgot`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*'
                }
            }
        );
        yield put(forgotLoaderStatus(false));
        swal(
            'Success',
            results.data.Message,
            'success'
        );
        yield put(forgotSuccess(true));
    }catch (error) {
		yield put(forgotLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(forgotError(error.response.data.errors));
        }
    }
}

function* doResetPerformed({formData}) {
	yield put(forgotLoaderStatus(true));
    try {
        const results = yield call(
            axios.post,
            `${API_BASE_URL}/v1/auth/reset`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*'
                }
            }
        );
        yield put(forgotLoaderStatus(false));
        swal(
            'Success',
            results.data.Message,
            'success'
        );
        yield put(resetSuccess(true));
    }catch (error) {
		yield put(forgotLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(resetError(error.response.data.errors));
        }
    }
}

export default function* () {
    yield takeLatest(FORGOT_PERFORMED, doForgotPerformed);
    yield takeLatest(RESET_PERFORMED, doResetPerformed);
}