import {
	LEAGUE_LOADER_SHOW,
	FETCH_LEAGUES_SUCCESS,
	LEAGUE_SUCCESS,
	LEAGUE_ERROR,
	LEAGUE_EDIT_ERROR,
	GET_LEAGUE_DETAIL_SUCCESS,
	LEAGUE_DELETE_SUCCESS,
	GET_LIST_CUSTOM_REPORT_SUCCESS
} from '../actions/league';

const initialState = {
	results: [],
	league_detail: {},
	is_loading: false,
	form_success: false,
	errors: {},
	edit_errors: {},
	is_deleted: false,
	list_custom_report: []
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_LEAGUES_SUCCESS:
			return {
				...state,
				results: actions.results,
				is_deleted: false
			};
		case LEAGUE_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case LEAGUE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				errors: {},
				is_deleted: false
			};
		case GET_LEAGUE_DETAIL_SUCCESS:
			return {
				...state,
				league_detail: actions.league
			};
		case LEAGUE_ERROR:
			return {
				...state,
				errors: actions.errors
			};
		case LEAGUE_EDIT_ERROR:
			return {
				...state,
				edit_errors: actions.errors
			};
		case LEAGUE_DELETE_SUCCESS:
			return {
				...state,
				is_deleted: actions.status
			};
		case GET_LIST_CUSTOM_REPORT_SUCCESS:
			return {
				...state,
				list_custom_report: actions.status
			};
		default:
			return state;
	}
}