import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../../AuthHOC/withAuth';
import ReactTable from "react-table";
import Loader from '../../../loader/loader';
import Pagination from '../../../pagination/pagination';
import Modal from 'react-responsive-modal';
import swal from 'sweetalert2/dist/sweetalert2.js';

class ConflictsPage extends Component {
	state = {
		modal: false,
		modalEdit: false,
		type: 0,
		conflict_id: 0,
		new_conflict_id: 0,
		edit_type: 0,
		current_id: 0
	}

	componentWillMount() {
		this.props.fetchConflictsData(this.props.match.params.id);
	}

	getConflictsType = id => {
		switch (id) {
			case 0: return null
			case 1: return 'Team'
			case 2: return 'Venue'
			case 4: return 'League'
			default: return ''
		}
	}

	getConflictsValue = data => {
		let type = this.getConflictsType(data.conflict_type).toLowerCase();
		let types = type + 's';
		let results = this.props.generalData[types].filter(obj => {
			return obj.id === data.conflict_id
		});

		return results[0][type + '_name'];
	}

	conflictDataRaw = id => {
		switch (id) {
			case 0: return null
			case 1: return 'team'
			case 2: return 'venue'
			case 4: return 'league'
			default: return ''
		}
	}

	openModal = () => {
		this.setState({
			modal: true
		})
	}

	closeModal = () => {
		this.setState({
			modal: false
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit = () => {
		swal({
			title: 'Are you sure?',
			text: "Do you want to submit this conflicts?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				let data = {
					official_id: Number(this.props.match.params.id),
					type: Number(this.state.type),
					conflict_id: Number(this.state.conflict_id)
				}
				this.props.submitConflicts(data, this.closeModal);
			}
		})
	}

	closeEditModal = () => {
		this.setState({
			modalEdit: false
		})
	}

	handleEdit = data => {
		this.setState({
			modalEdit: true,
			edit_type: data.conflict_type,
			new_conflict_id: data.conflict_id,
			current_id: data.id
		});
	}

	handleEditSubmit = () => {
		swal({
			title: 'Are you sure?',
			text: "Do you want to submit this edited conflicts?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				let data = {
					new_conflict_id: this.state.new_conflict_id
				}
				this.props.submitEditConflicts(data, this.state.current_id, this.props.match.params.id, this.closeEditModal);
			}
		})
	}

	handleDelete = id => {
		swal({
			title: 'Are you sure?',
			text: "Do you want to delete conflicts?",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				this.props.deleteConflicts(id, this.props.match.params.id);
			}
		})
	}

	getLeagueName = (leagueId) => {
		let leagueName = ""
		this.props.generalData['leagues'].forEach(league => {
			if (league.id === leagueId) {
				leagueName = league.league_name;
			}
		});
		return leagueName;
	}

	render() {
		const data = this.props.userData;
		const columns = [{
			Header : 'Type',
			Cell: row => (this.getConflictsType(row.original.conflict_type))
		}, {
			Header: 'Value',
			Cell: row => (this.getConflictsValue(row.original))
		}, {
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li>
							<button className="link" onClick={() => this.handleEdit(row.original)}>Edit</button>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button>
						</li>
					</ul>
				</div>
			)
		}]
		return (
			<div className="conflicts">
				{this.props.loader && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Conflicts</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Conflicts</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						<button onClick={this.openModal} className="btn btn-orange">Create New Conflicts</button>
					</div>
				</div>
				<div className="box">
					<ReactTable 
						PaginationComponent={Pagination}
						data={data}
						columns={columns}
						minRows={5}
						noDataText="No Conflicts Found."
					/>
				</div>

				<Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show auto-width-modal"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Create New Conflicts</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Type</label>
											<div className="input-effect-1">
												<select className="normal-select" name="type" value={this.state.type} onChange={event => this.handleInputChange(event)}>
													<option value={0}>Select One</option>
													<option value={1}>Team</option>
													<option value={2}>Venue</option>
													<option value={4}>League</option>
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Value</label>
											<div className="input-effect-1">
												<select className="normal-select" name="conflict_id" value={this.state.conflict_id} onChange={event => this.handleInputChange(event)} disabled={Number(this.state.type) === 0}>
													{Number(this.state.type) === 0 && <option>Please select the type</option>}
													{Number(this.state.type) !== 0 &&
														this.props.generalData.length !== 0 && this.props.generalData[this.conflictDataRaw(Number(this.state.type)) + 's'].map(obj => {
															
															if (Number(this.state.type) === 1) {
																let leagueName = this.getLeagueName(Number(obj['league_id']));
																return leagueName && <option key={obj.id} value={obj.id}>{leagueName + ' - ' + ['Men', 'Women', 'Boys', 'Girls', 'Mixed'][obj['gender']-1] +' - '+ obj['team_name']}</option>
															} else {
																return <option key={obj.id} value={obj.id}>{obj[this.conflictDataRaw(Number(this.state.type)) + '_name']}</option>
															}
														})}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="action text-center">
									<button className="btn btn-orange" onClick={this.handleSubmit}>Submit</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					open={this.state.modalEdit}
					onClose={this.closeEditModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show auto-width-modal"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Conflicts</h5>
								<button type="button" className="close" onClick={this.closeEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col-xl-12 col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<label className="label">Value</label>
											<div className="input-effect-1">
												<select className="normal-select" name="new_conflict_id" value={this.state.new_conflict_id} onChange={event => this.handleInputChange(event)}>
													{Number(this.state.edit_type) !== 0 &&
														this.props.generalData.length !== 0 && this.props.generalData[this.conflictDataRaw(Number(this.state.edit_type)) + 's'].map(obj => {
															return <option key={obj.id} value={obj.id}>{obj[this.conflictDataRaw(Number(this.state.edit_type)) + '_name']}</option>
														})}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="action text-center">
									<button className="btn btn-orange" onClick={this.handleEditSubmit}>Submit</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default withAuth(ConflictsPage, ['administrator']);