import React, { Component } from 'react';
import LeftSidebar from '../shared/left-sidebar';
import Header from '../shared/header.container';
import classNames from 'classnames';
//import Intercom from 'react-intercom';
import 'bootstrap/dist/js/bootstrap.js';
import Routes from '../Routes/Routes';
import '../../public/styles/app.scss';
import { Loader, FullScreenLoader } from '../loader';
import { connect } from 'react-redux';
import { changeOrganisation } from '../../actions/organisation';
import './banner.scss';
import { CSSTransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import CloseIcon from '../../public/svg/close-circle.svg';
import { getTrialEnd, closeTrialBanner } from '../../actions/billing';
import { getCurrentUser, getFormatVersion, getPermission } from '../../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import ModalAcknowledgements from '../acknowledgements';

class App extends Component {
	state = {
		sidebarWidth: 0,
		headerHeight: 0,
		fullMode: true,
		stopRender: true
	};

	promisedSetState = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		})
	}

	async componentDidMount() {
		if (getCurrentUser() != null) {
			let user = {
				// user_id: '',
				email: '',
				firstname: '',
				lastname: ''
			};
	
			let auth_data = getCurrentUser();
			if (auth_data.user) {
				user = {
					// user_id: auth_data.user.intercom_id,
					email: auth_data.user.email,
					firstname: auth_data.user.name ? auth_data.user.name.split(' ')[0] : '',
					lastname: auth_data.user.name ?  (auth_data.user.name.split(' ')[1] ?  auth_data.user.name.split(' ')[1] : '') : ''
				}
				window.addEventListener('load', function () {
					console.log(user)
					window._hsq.push(['revokeCookieConsent']);
					window._hsq = window._hsq || [];
					window._hsq.push([
						"identify",
						user
					]);
					window._hsq.push(["trackPageView"]);
					window.HubSpotConversations.widget.refresh();
				})
				
			}

			if (getCurrentUser().formatVersion !== getFormatVersion()) {
				localStorage.removeItem('user');
				this.props.history.push('/login');
				await this.promisedSetState({ stopRender: false });
				swal(
					'Update Available',
					'Please re-login to continue',
					'info'
				);
			} else {
				this.promisedSetState({ stopRender: false });
			}
		} else {
			this.promisedSetState({ stopRender: false });
		}
		

		const pathName = this.props.location.pathname;
		if (pathName !== '/register' && pathName !== '/login') {
			if (getCurrentUser()) {
				if (getPermission().includes('billing')) {
					this.props.getTrialEnd();
				}
			}
		}
	}

	onResizeSidebar = (width) => {
		if (this.state.fullMode) {
			this.setState({ sidebarWidth: width });
		} else {
			setTimeout(() => {
				if (this.state.fullMode) {
					this.setState({ sidebarWidth: width });
				}
			}, 250);
		}
	};

	onResizeHeader = (_, height) => {
		this.setState({
			headerHeight: height
		});
	};

	toggleFullMode = () => {
		if (this.state.fullMode) {
			setTimeout(() => {
				this.setState({ fullMode: false });
			}, 1000);
		} else {
			this.setState({ fullMode: true });
		};
	};

	render() {
		const { showBanner, trialStatus, trialDays, closeTrialBanner } = this.props;
		const mainPanelClassNames = classNames(
			'main-panel',
			{ auth: !localStorage.getItem('user') }
		);


		if (this.state.stopRender) {
			return null;
		}

		return (
			<div className="wrapper">
				<div className="wrapper-inner">
					<CSSTransitionGroup
						transitionName="banner"
						transitionEnterTimeout={300}
						transitionLeaveTimeout={300}
					>
					{showBanner && (
						<div className="top-banner">
							<p className="banner-text">
								{trialStatus === 'trial'
									? `Your trial ends in ${trialDays} days `
									: 'Your account has been suspended '
								}
								<Link to="/subscription">
									{trialStatus === 'trial'
										? 'Click here to upgrade your account'
										: 'Please upgrade your account'
									}
								</Link>
							</p>
							<button className="btn-close" type="button" onClick={closeTrialBanner}><img alt="close" src={CloseIcon} /></button>
						</div>
					)}
					</CSSTransitionGroup>
					<div className="dashboard-thumb"></div>
					{localStorage.getItem('user') && <LeftSidebar onResize={this.onResizeSidebar} toggle={this.toggleFullMode} bannerActive={showBanner} />}
					<div className={mainPanelClassNames}>
						{localStorage.getItem('user') && <Header onResize={this.onResizeHeader} changeOrganisation={this.props.changeOrganisation} bannerActive={showBanner} />}
						<div className={classNames('main-content', { 'banner-active': showBanner })}>
							<div className="container-fluid h-100">
								{this.props.loader > 0 && (
									<Loader
										sidebarWidth={this.state.sidebarWidth}
										headerHeight={this.state.headerHeight}
									/>
								)}
								{this.props.fullScreenLoader > 0 && (
									<FullScreenLoader />
								)}
								<Routes trialStatus={trialStatus} />
							</div>
						</div>
					</div>
				</div>
				{/* {localStorage.getItem('user') && <Intercom appID="wt440dsi" {...user} />} */}
				{this.props.acknowledgementModal && <ModalAcknowledgements />}
			</div>
		);
	}
}

const mapsDispatchToProps = dispatch => ({
	changeOrganisation: id => dispatch(changeOrganisation(id)),
	getTrialEnd: () => dispatch(getTrialEnd()),
	closeTrialBanner: () => dispatch(closeTrialBanner())
});

const mapsStateToProps = state => ({
	loader: state.loader.showLoader,
	fullScreenLoader: state.loader.showFullSizeLoader,
	showBanner: state.billing.showTrialBanner,
	trialStatus: state.billing.trialStatus,
	trialDays: state.billing.trialDays,
	acknowledgementModal: state.acknowledgements.showModal
});

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(App);