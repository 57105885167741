import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';

export default class EditTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team_name: '',
            league_id: '',
            home_venue_id:'',
            gender: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {
            team_name,
            league_id,
            home_venue_id,
            gender
        } = this.state;

        const data = {
            team_name: team_name,
            league_id: league_id,
            home_venue_id: home_venue_id,
            gender: gender
        }
        this.props.onTeamSubmitted(data, this.props.id);
    }

    componentWillMount() {
        this.props.fetchLeagues();
        this.props.fetchVenues();
        this.props.getTeam(this.props.id);
    }

    componentWillReceiveProps(nextProps) {
        if(Object.keys(nextProps.team).length > 0)
        {
            this.setState({
                team_name: nextProps.team.team_name,
                league_id: nextProps.team.league_id,
                home_venue_id: nextProps.team.home_venue_id,
                gender: nextProps.team.gender
            });
        }
    }

    render () {
        let team_name_error = null;
        let league_error= null;
        let venue_error= null;
        let gender_error= null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'team_name')
                    team_name_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'league_id')
                    league_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'home_venue_id')
                    venue_error = <FormError error={this.props.errors[key][0]} />;
                else
                    gender_error = <FormError error={this.props.errors[key][0]} />;
            }
        }

        let leagues = this.props.leagues;
        let optionLeagueItems = leagues.map((league) =>
                <option key={league.id} value={league.id}>{league.league_name}</option>
            );
        let venues = this.props.venues;
        let optionVenueItems = venues.map((venue) =>
                <option key={venue.id} value={venue.id}>{venue.venue_name}</option>
            );

        return (
            <div className="edit-team">
                { (this.props.league_loading || this.props.venue_loading || this.props.loading) && <Loader /> }
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Team Name</label>
                            <div className="input-effect-1">
                                <input type="text" name="team_name" placeholder="Team" value={this.state.team_name} onChange={event => {this.handleInputChange(event)}} className="form-control" />
                                <div className="focus-border"></div>
                            </div>
                            {team_name_error}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">League</label>
                            <div className="input-effect-1">
                                <select name="league_id" value={this.state.league_id} onChange={event => {this.handleInputChange(event)}} className="form-control">
                                    <option value=''>Select League</option>
                                    {optionLeagueItems}
                                </select>
                                <div className="focus-border"></div>
                            </div>
                            {league_error}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Venue</label>
                            <div className="input-effect-1">
                                <select name="home_venue_id" value={this.state.home_venue_id} onChange={event => {this.handleInputChange(event)}} className="form-control">
                                    <option value=''>Select Venue</option>
                                    {optionVenueItems}
                                </select>
                                <div className="focus-border"></div>
                            </div>
                            {venue_error}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Gender</label>
                            <div className="input-effect-1">
                                <select name="gender" value={this.state.gender} onChange={event => {this.handleInputChange(event)}} className="form-control">
                                    <option value="0">Select gender</option>
                                    <option value="1">Men</option>
                                    <option value="2">Women</option>
                                    <option value="3">Boys</option>
                                    <option value="4">Girls</option>
                                    <option value="5">Mixed</option>
                                </select>
                                <div className="focus-border"></div>
                            </div>
                            {gender_error}
                        </div>
                    </div>
                </div>
                <div className="action text-center">
                    <button type="button" onClick={this.handleSubmit} className="btn btn-orange">Update Team</button>
                </div>
            </div>
        );
    }
}

EditTeam.propTypes = {
    getTeam: PropTypes.func.isRequired,
    team: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    fetchLeagues: PropTypes.func.isRequired,
    fetchVenues: PropTypes.func.isRequired,
    leagues: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            league_name: PropTypes.string
        }).isRequired
    ),
    venues: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            venue_name: PropTypes.string
        }).isRequired
    ), 
    league_loading: PropTypes.bool,
    venue_loading: PropTypes.bool,
    onTeamSubmitted: PropTypes.func.isRequired
}