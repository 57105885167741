import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Pagination from '../../../../pagination/pagination';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getPermission } from '../../../../../constants';

export default class OfficialResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ''
		}
		this.handleDelete = this.handleDelete.bind(this);
	}
	componentWillMount() {
		this.props.fetchOfficials();
	}

	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.removeOfficial(id);
			}
		})
	}
	render() {
		let data = this.props.officials;
		const permissions = getPermission();	
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase());
			})
		}
		const columns = [{
			Header: 'Officials',
			Cell: row => (
				<div>
					<span className="official-name highlight">{row.original.name}</span>
					<div className="action">
						<ul className="list-inline">
							<li><Link to={`/official/edit/${row.original.id}`}>Edit</Link></li>
							{permissions.some(p => ['administrator'].includes(p)) && (
								<li><span className="seprator">|</span><button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button></li>
							)}
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Phone Number',
			accessor: 'mobile_number',
		}, {
			Header: 'Email',
			accessor: 'email_address'
		}, {
			Header: 'Upcoming Games',
			Cell: row => (
				<div className="next-game">
					{row.original.next_game === "No upcoming game" ? row.original.next_game :
						<div>
							<span className="upcoming-game">{row.original.next_game.home_team.team_name} vs {row.original.next_game.away_team.team_name}</span>
							<span className="game-date-time">
								<span className="date">{row.original.next_game.game.game_date}</span>
								<span className="seprator">/</span>
								<span className="time">{row.original.next_game.game.game_time}</span>
							</span>
						</div>
					}
				</div>
			)
		}];
		return (
			<div className="table-area">
				<div className="heading mb_20">
					<div className="dflex justify-content-end">
						<div className="search-table">
							<div className="input-group">
								<input type="text" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} className="form-control" placeholder="Search" />
								<div className="input-group-append">
									<button className="input-group-text"><FontAwesomeIcon icon={faSearch} className="fa fa-search" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.props.loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					columns={columns}
					noDataText="No Officials Found."
					defaultPageSize={10}
					resizable={true}
					minRows={5}
				/>
			</div>
		);
	}
}

OfficialResults.propTypes = {
	fetchOfficials: PropTypes.func.isRequired,
	officials: PropTypes.arrayOf(
		PropTypes.shape({
			email: PropTypes.string,
			name: PropTypes.string,
			mobile: PropTypes.string
		}).isRequired
	),
	loading: PropTypes.bool,
	removeOfficial: PropTypes.func.isRequired
}