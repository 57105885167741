import React, { Component } from 'react';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default class GameResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: {},
			isExpandedAll: false
		}
		this.handleConfirm = this.handleConfirm.bind(this);
		this.handleDecline = this.handleDecline.bind(this);
		this.positionName = this.positionName.bind(this);
		this.statusColor = this.statusColor.bind(this);
		this.handleRowExpanded = this.handleRowExpanded.bind(this);
		this.handleThProps = this.handleThProps.bind(this);
	}

	handleConfirm(id) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, confirm this game!'
		}).then((result) => {
			if (result.value) {
				this.props.confirmGameToOfficial(id);
			}
		})
	}

	handleDecline(id) {
		swal({
			title: 'Are you sure?',
			text: "Reason for declining this game?",
			type: 'warning',
			input: 'text',
			inputValidator: (value) => {
				return new Promise((resolve) => {
					if (value.length < 10) {
						resolve('Your reason is  not detailed enough, please re-enter your reason');
					} else {
						resolve();
					}
				})
			},
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, decline it!'
		}).then((result) => {
			if (result.value) {
				this.props.declineGameToOfficial(id, result.value);
			}
		})
	}

	positionName(name) {
		return name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
	}

	statusColor(status) {
		switch (status) {
			case "Pending": return 'yellow'
			case "Confirmed": return 'green'
			case "Declined": return 'red'
			case "Removed": return 'red'
			default: return ''
		}
	}

	handleRowExpanded(newExpanded, index, event) {
		this.setState({
			expanded: newExpanded
		})
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.className === "header-expand-toggle") {
					if (this.state.isExpandedAll === false) {
						let ObjExpand = {}
						for (let x = 0; x < state.pageSize; x++) {
							ObjExpand[x] = {};
						}
						this.setState({
							expanded: ObjExpand,
							isExpandedAll: true
						})
					} else if (this.state.isExpandedAll) {
						let ObjExpand = {}
						for (let x = 0; x < state.pageSize; x++) {
							ObjExpand[x] = false;
						}
						this.setState({
							expanded: ObjExpand,
							isExpandedAll: false
						})
					}
				}

				if (column.sortable !== false) {
					instance.sortColumn(column)
				}
			}
		}
	}

	render() {
		const data = this.props.games;
		const gender = ["", "Men", "Women", "Boys", "Girls", "Mixed"];
		const columns = [{
			Header: () => this.state.isExpandedAll ? <div style={{ margin: '0 0 0 5px' }} className="rt-expander -open">•</div> : <div style={{ margin: '0 0 0 5px' }} className="rt-expander">•</div>,
			expander: true,
			width: 40,
			className: 'header-expand-toggle'
		}, {
			Header: 'Match',
			width: 280,
			sortable: true,
			Cell: row => (
				<div className="match">
					<span className="highlight">{row.original.home_team && row.original.home_team.team_name} vs {row.original.away_team && row.original.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game_time, "HH:mm").format("LT")}</span></li>
							<li className="list-inline-item"><span className="badge badge-upcoming">{moment(row.original.game_date).startOf('day').diff(moment().startOf('day'), 'days') > 0 && 'Upcoming'}{moment(row.original.game_date).startOf('day').diff(moment().startOf('day'), 'days') === 0 && 'Today'}{moment(row.original.game_date).startOf('day').diff(moment().startOf('day'), 'days') < 0 && 'Completed'}</span></li>
						</ul>
					</div>
					<div className="actions">
						<ul className="list-inline">
							<li>
								<Link to={`/mygames/${row.original.id}/detail`}><button className="link">View</button></Link>
							</li>
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Organisation',
			width: 200,
			Cell: row => (
				<div>
					{row.original.organisation && row.original.organisation.organisation_name}
				</div>
			)
		}, {
			Header: 'Venue',
			width: 200,
			Cell: row => (
				<div className="date-time">
					{row.original.venue && row.original.venue.venue_name}
				</div>
			)
		}, {
			Header: 'League',
			width: 200,
			Cell: row => (
				<div className="date-time">
					{row.original.league && row.original.league.league_name}
				</div>
			)
		}, {
			Header: 'Gender',
			width: 100,
			Cell: row => (
				<div>
					{gender[row.original.gender]}
				</div>
			)
		},
		{
			Header: 'Actions',
			minWidth: 235,
			Cell: row => (
				row.original.status[0] === 'Pending' ? (
					<div>
						<button type="button" className="btn btn-success" style={{ marginRight: '5px' }} onClick={() => { this.handleConfirm(row.original.id) }}>Confirm</button>
						<button type="button" className="btn btn-danger" onClick={() => { this.handleDecline(row.original.id) }}>Decline</button>
					</div>
				) : row.original.status[0] === 'Confirmed' ? 'Game Confirmed' : 'Game Declined'
			)
		}];
		return (
			<div className="table-area">
				{(this.props.loading || this.props.fetchLoading) && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText="No Games Found."
					defaultPageSize={10}
					pageSizeOptions={[10, 25, 50, 100]}
					resizable={true}
					getTrProps={(state, rowInfo, column) => {
						if (rowInfo && rowInfo.row) {
							return {
								style: {
									backgroundColor: rowInfo.original.status[0] === "Pending" ? "#f6e58d" : rowInfo.original.status[0] === "Confirmed" ? "#badc58" : "#fab1a0"
								}
							}
						} else {
							return {}
						}
					}}
					expanded={this.state.expanded}
					onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
					getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
					SubComponent={row => {
						return (
							<div style={{ paddingLeft: 40 }} className="game-list">
								<ul className="list-inline">
									{row.original.fields.map((field) => (
										<li key={field.id}>
											<div className={classNames('status', this.statusColor(typeof row.original.referees[field.id] === "object" ? row.original.referees[field.id].status : ''))}></div>
											<div className="name">{typeof row.original.referees[field.id] === "object" ? row.original.referees[field.id].official.name : row.original.referees[field.id]}</div>
											<div className="post">({this.positionName(field.field_name)})</div>
										</li>
									))}
								</ul>
							</div>
						);
					}}
				/>
			</div>
		);
	}
}