import {connect} from 'react-redux';
import ResetPassword from './reset_password';
import {resetPerformed} from '../../../actions/forgot';

function mapsDispatchToProps (dispatch) {
    return {
        doResetPassword: (formData) => dispatch(resetPerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.forgot.is_loading,
        errors: state.forgot.reset_errors,
        form_success: state.forgot.reset_form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(ResetPassword);