import React, {Component} from 'react';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';

export default class NewRegion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: ''
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
				[name]: value
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		const { name } = this.state;

		const data = {
			name: name
		};

		this.props.onRegionSubmitted(data);
	}

	render() {
		let name_error = null;
		if(this.props.errors !== undefined) {
			for (var key in this.props.errors) {
				if (key === 'name') {
					name_error = <FormError error={this.props.errors[key][0]} />;
				}
			}
		}
		return (
			<div className="new-region">
				{this.props.loading && <Loader />}
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-12">
						<div className="form-group">
							<label className="label">Region Name</label>
							<div className="input-effect-1">
								<input type="text" name="name" placeholder="Region Name" value={this.state.name} onChange={event => {this.handleInputChange(event)}} className="form-control" />
								<div className="focus-border"></div>
							</div>
							{ name_error }
						</div>
					</div>
				</div>
				<div className="action text-center">
					<button type="button" onClick={this.handleSubmit} className="btn btn-orange">Save Region</button>
				</div>
			</div>
		);
	}
}

NewRegion.propTypes = {
	onRegionSubmitted: PropTypes.func.isRequired,
	errors: PropTypes.object,
	loading: PropTypes.bool,
	form_success: PropTypes.bool,
}