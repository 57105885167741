import { connect } from 'react-redux';
import GameResults from './game_results';
import { confirmGameToOfficial, declineGameToOfficial } from '../../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		confirmGameToOfficial: (id) => dispatch(confirmGameToOfficial(id)),
		declineGameToOfficial: (id, reason) => dispatch(declineGameToOfficial(id, reason))
	}
}

function mapsStateToProps(state) {
	return {
		loading: state.game.is_loading,
		fetchLoading: state.game.fetchGamesDataLoading
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameResults);