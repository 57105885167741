import React, { Component } from 'react';
// import uploadSvg from '../../../../../public/svg/upload.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Dropzone from 'react-dropzone';
// import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class BulkImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: null
		}

		this.downloadTemplate = this.downloadTemplate.bind(this);
	}
	handleDrop = acceptedFiles => {
		this.setState({
			files: acceptedFiles[0]
		});
	};

	handleSubmit = () => {
		const { files } = this.state;
		const data = {
			users: files
		}
		this.props.addBulkUsers(data);
	}
	getSettings() {
		// image tag to svg convert
		$('.imgsvg').each(function () {
			var $img = $(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgURL = $img.attr('src');
			$.get(imgURL, function (data) {
				var $svg = $(data).find('svg');
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg');
				}
				$svg = $svg.removeAttr('xmlns:a');
				if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
					$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
				}
				$img.replaceWith($svg);
			}, 'xml');
		});
	}
	
	downloadTemplate() {
		let link = document.createElement('a');
		link.href = '/assets/users_template.csv';
		link.download = 'users_template.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.bulk_import_success === true) {
			this.setState({
				files: null
			});
		}
	}

	render() {
		return (
			<div className="bulk-import">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Bulk Import</h1>
					</div>
					<div className="actions">
						<button className="btn btn-orange" onClick={this.downloadTemplate}>Download Template</button>
					</div>
				</div>
				<Dropzone
					className="drop-area d-flex align-items-center"
					multiple={false}
					accept=".xls, .csv"
					onDrop={this.handleDrop}
				>
					<div className="text">
						<p>
							<span className="icon icon-upload">
								<FontAwesomeIcon icon={faCloudUploadAlt} className="fa fa-file-excel" />
							</span>
						</p>
						<p>Drop file here to click upload</p>
					</div>
				</Dropzone>
				{this.state.files && (
					<div className="file-format">
						<span className="icon icon-excel">
							<FontAwesomeIcon icon={faFileExcel} className="fa fa-file-excel" />
						</span>
						<span className="file-name">
							{this.state.files.name}
						</span>
					</div>
				)}
				<div className="file-format-message">
					<p>The file you upload that must be a valid file in <strong>.CSV format</strong></p>
				</div>
				<div className="action">
					<input type="submit" onClick={this.handleSubmit} className="btn btn-orange" value="Upload" name="upload" />
				</div>
			</div>
		);
	}
}

BulkImport.propTypes = {
	addBulkUsers: PropTypes.func.isRequired,
	bulk_import_success: PropTypes.bool.isRequired
}