import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import dateIcon from '../../../../../public/svg/date.svg';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import userPic from '../../../../../public/images/e-logo.jpg';
import { getPermission } from '../../../../../constants';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const format = 'YYYY-MM-DD';
const now = moment();
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

export default class EditOfficialForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			address: '',
			mobile: '',
			email: '',
			birth: now,
			status: 0,
			payscale: 0,
			region: 0,
			gender: ['Men', 'Women', 'Boys', 'Girls', 'Mixed'],
			payscales: [{ Payscale_ID: 0, Payscale_Name: "Default" }],
			regions: [{ Region_ID: 0, Region_Name: "Select Region" }],
			game_data: [],
			share_mobile: false,
			share_email: false,
			disabled: false,
			showDateInput: false,
			profile_pic: ''
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.goToConflicts = this.goToConflicts.bind(this);
	}

	componentDidMount() {
		this.props.getOfficial(this.props.id);
		this.props.fetchUserData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.official !== this.props.official) {
			let date_of_birth = now;
			if (this.props.official.data.date_of_birth) {
				date_of_birth = moment(this.props.official.data.date_of_birth);
			}
			this.setState({
				name: this.props.official.data.name,
				address: this.props.official.data.address_line_1,
				mobile: this.props.official.data.mobile,
				email: this.props.official.data.email,
				status: this.props.official.data.active,
				payscale: this.props.official.data.payscale ? this.props.official.data.payscale.id : 0,
				region: this.props.official.data.region ? this.props.official.data.region.id : 0,
				birth: date_of_birth,
				share_mobile: this.props.official.data.share_mobile,
				share_email: this.props.official.data.share_email,
				profile_pic: this.props.official.data.profile_pic_url
			});

			if (this.props.official.data.game_data) {
				this.setState({ game_data: this.props.official.data.game_data })
			}
		}

		if (prevProps.userdata !== this.props.userdata) {
			let { payscales, regions } = this.state;

			if (this.props.userdata.payscale_data) {
				this.props.userdata.payscale_data.forEach(payscale => {
					payscales.push(payscale);
				});
			}
			if (this.props.userdata.region_data) {
				this.props.userdata.region_data.forEach(region => {
					regions.push(region);
				});
			}

			this.setState({ payscales, regions });
		}
	}

	onDateChange = (value) => {
		this.setState({
			birth: value,
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSave() {
		const formData = {
			name: this.state.name,
			address_line_1: this.state.address,
			mobile: this.state.mobile,
			email: this.state.email,
			status: parseInt(this.state.status),
			region: parseInt(this.state.region),
			payscale: parseInt(this.state.payscale),
			date_of_birth: this.state.birth.format(format),
			share_mobile: this.state.share_mobile,
			share_email: this.state.share_email
		}
		this.props.onOfficialSubmitted(formData, this.props.id);
	}

	goToConflicts() {
		this.props.history.push(`/official/conflicts/${this.props.id}`);
	}

	render() {
		const state = this.state;
		const permissions = getPermission();	
		const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			format={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={state.showDateInput}
		/>);
		const data = this.props.official.game_data;
		const columns = [{
			Header: 'ALLOCATED MATCH',
			minWidth: 250,
			Cell: row =>
				<div className="match">
					<span className="highlight">{row.original.home_team.team_name} vs {row.original.away_team.team_name}</span>
				</div>
		}, {
			Header: 'LEAGUE',
			Cell: row =>
				<div className="league">
					{row.original.league.league_name}
				</div>
		}, {
			Header: 'DATE / TIME',
			Cell: row =>
				<div className="date-time">
					<span className="date">{row.original.game.game_date}</span>
					<span className="seprator">/</span>
					<span className="time">{row.original.game.game_time}</span>
				</div>
		}, {
			Header: 'VENUE',
			Cell: row =>
				<div className="venue">
					{row.original.venue.venue_name}
				</div>
		}, {
			Header: 'GENDER',
			Cell: row =>
				<div className="gender">
					{this.state.gender[row.original.game.gender - 1]}
				</div>
		}]
		const optionPayScaleItems = this.state.payscales.map((payscale) =>
			<option key={payscale.Payscale_ID} value={payscale.Payscale_ID}>{payscale.Payscale_Name}</option>
		);
		const optionRegionItems = this.state.regions.map((region) =>
			<option key={region.Region_ID} value={region.Region_ID}>{region.Region_Name}</option>
		);
		return (
			<div className="box">
				<div className="official-edit-form pb-0">
					<div className="row">
						<div className="col-xl-3 col-lg-5 col-md-12 col-12">
							<div className="edit-photo">
								<div className="d-flex align-items-center h-100">
									<div className="wrap">
										<div className="thumb">
											<div className="avatar avatar-large avatar-round">
												<img src={this.state.profile_pic ? 'http://devapi.efficialtec.com/storage/profileimages/' + this.state.profile_pic : userPic} alt="avatar" />
											</div>
										</div>
										<div className="edit-photo-name">
											<div className="name" title={this.state.name}>{this.state.name}</div>
											{/* <div className="action">
                                                <button className="text" data-toggle="modal" data-target="#editPhoto">
                                                    <FontAwesomeIcon icon={faPencilAlt} className="fa fa-pencil"/>
                                                    <span className="text">Edit Photo</span>
                                                </button>
                                            </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-9 col-lg-7 col-md-12 col-12">
							<div className="form">
								{this.props.loading && <Loader />}
								<form action="#">
									<div className="row">
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Full Name</label>
												<div className="input-effect-1">
													<input type="text" name="name" value={this.state.name} onChange={event => this.handleInputChange(event)} className="form-control" />
													<span className="focus-border"></span>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Address</label>
												<div className="input-effect-1">
													<input type="text" name="address" value={this.state.address} onChange={event => this.handleInputChange(event)} className="form-control" />
													<span className="focus-border"></span>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Mobile Phone</label>
												<div className="input-effect-1">
													<input type="text" name="mobile" value={this.state.mobile} onChange={event => this.handleInputChange(event)} className="form-control" />
													<span className="focus-border"></span>
												</div>
												<div className="custom-checkbox">
													<input type="checkbox" value={this.state.share_mobile} className="custom-control-input" name="share_mobile" id="share_mobile" onChange={event => this.handleInputChange(event)} />
													<label className="custom-control-label" htmlFor="share_mobile">
														Share my mobile number with other officials
                                                    </label>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Email</label>
												<div className="input-effect-1">
													<input type="text" name="email" value={this.state.email} onChange={event => this.handleInputChange(event)} className="form-control" />
													<span className="focus-border"></span>
												</div>
												<div className="custom-checkbox">
													<input type="checkbox" value={this.state.share_email} className="custom-control-input" name="share_email" id="share_email" onChange={event => this.handleInputChange(event)} />
													<label className="custom-control-label" htmlFor="share_email">
														Share my email with other officials
                                                    </label>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Date of birth </label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={state.disabled}
															calendar={calendar}
															value={state.birth}
															onChange={this.onDateChange}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<img src={dateIcon} alt="date" className="imgsvg" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-12 col-md-6 co-12">
											<div className="form-group">
												<label className="label">Status</label>
												<div className="input-effect-1">
													<select name="status" value={this.state.status} onChange={event => this.handleInputChange(event)} className="normal-select">
														<option>Select Status</option>
														<option value={1}>Active</option>
														<option value={-1}>Inactive</option>
													</select>
													<span className="focus-border"></span>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-12 col-md-6 col-12">
											<div className="form-group">
												<label className="label">Region</label>
												<div className="input-effect-1">
													<select name="region" value={this.state.region} onChange={event => this.handleInputChange(event)} className="normal-select">
														{optionRegionItems}
													</select>
													<span className="focus-border"></span>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-12 col-md-6 col-12">
											<div className="form-group">
												<label className="label">Payscale</label>
												<select name="payscale" value={this.state.payscale} onChange={event => this.handleInputChange(event)} className="normal-select">
													{optionPayScaleItems}
												</select>
											</div>
										</div>
									</div>
									<div className="action">
										<div className="dflex align-items-start justify-content-between">
											<div>
												{permissions.some(p => ['administrator'].includes(p)) && <button className="btn btn-orange btn-forgot mr_20 mb_20">Reset Password</button>}
												{permissions.some(p => ['administrator', 'allocator'].includes(p)) && <Link to={`/official/${this.props.id}/ability`}><button className="btn btn-orange btn-forgot mr_20 mb_20">Capabilities</button></Link>}
												<button type="button" className="btn btn-orange btn-forgot mb_20" onClick={this.goToConflicts}>Conflicts</button>
											</div>
											{permissions.some(p => ['administrator'].includes(p)) && (
												<div>
													<Link to="/officials" className="btn btn-white mr_20 mb_20">Cancel</Link>
													<button type="button" className="btn btn-orange mb_20" onClick={this.handleSave}>Save</button>
												</div>
											)}
										</div>
									</div>
								</form>
								<div className="modal fade" id="forgotpass">
									<div className="modal-dialog modal-dialog-centered modal-md">
										<div className="modal-content">
											<div className="modal-header">
												<h5 className="modal-title">Reset Password</h5>
												<button type="button" className="close" data-dismiss="modal">
													<span>&times;</span>
												</button>
											</div>
											<div className="modal-body">
												<form>
													<div className="row">
														<div className="col-xl-12">
															<div className="form-group">
																<label className="label">Old Password</label>
																<div className="input-effect-1">
																	<input className="form-control" name="oldpass" />
																	<div className="focus-border"></div>
																</div>
															</div>
														</div>
														<div className="col-xl-12">
															<div className="form-group">
																<label className="label">New Password</label>
																<div className="input-effect-1">
																	<input className="form-control" name="newpass" />
																	<div className="focus-border"></div>
																</div>
															</div>
														</div>
														<div className="col-xl-12">
															<div className="form-group">
																<label className="label">Confirm Password</label>
																<div className="input-effect-1">
																	<input className="form-control" name="confirm pass" />
																	<div className="focus-border"></div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
											<div className="modal-footer">
												<button className="btn btn-orange">Submit</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="allocated-match-info">
					<div className="d-flex">
						<h2>Allocated Match Information</h2>
					</div>
					<div className="table-area">
						{this.props.loading && <Loader />}
						<ReactTable
							PaginationComponent={Pagination}
							data={data}
							minRows={5}
							columns={columns}
							noDataText="No Games Found."
							defaultPageSize={10}
							pageSizeOptions={[10, 25, 50, 100]}
							resizable={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}

EditOfficialForm.propTypes = {
	getOfficial: PropTypes.func.isRequired,
	fetchUserData: PropTypes.func.isRequired,
	id: PropTypes.string,
	official: PropTypes.object.isRequired,
	loading: PropTypes.bool,
}