import React, {Component} from 'react';
import './official-dashboard.scss';
import { Link } from 'react-router-dom';
// import userPic from '../../../../public/images/user.jpg';
// import { Scrollbars } from 'react-custom-scrollbars';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import FullCalendar from 'fullcalendar-reactwrapper';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from '../../../loader/loader'
import $ from 'jquery';
const format = 'YYYY-MM-DD';
const now = moment();

class OfficialDashboard extends Component {
	constructor(props){
		super(props);
		this.state = {
			events: []
		}
	}
	componentWillMount() {
		this.props.fetchCalendarData();
	}

	componentWillReceiveProps(nextProps) {
        if(Object.keys(nextProps.calendar_data).length > 0)
        {
            this.setState({
                events: nextProps.calendar_data
            })
        }
	}
	
    render () {
        return (
            <div className="official-dashboard">
				{this.props.loading && <Loader />}
                <div className="page-head mb_30">
                	<div className="head">
                		<h1 className="page-title">Dashboard</h1>
                		<nav>
                			<ol className="breadcrumb page-title">
                				<li className="breadcrumb-item"><Link to="/">Home</Link></li>
                				<li className="breadcrumb-item current">Dashboard</li>
                			</ol>
                		</nav>
                	</div>
                </div>
                <div className="row">
                	<div className="col-xl-6 col-12 mb-sm-30 mb-md-30 mb-lg-30">
                		<div className="card card-border card-calender">
                			<div className="card-header d-flex align-items-center justify-content-between">
                				<h2>Calendar</h2>
                			</div>
                			<div className="card-body">
                				<div className="fullcalendar">
	                                <FullCalendar
	                                    id = "your-custom-ID"
	                                    themeSystem = {'bootstrap4'}
	                                    header = {{
	                                        left: 'prev,next today myCustomButton',
	                                        center: 'title',
	                                        right: 'month,basicWeek,basicDay'
	                                    }}
	                                    defaultDate={now.format(format)}
	                                    navLinks= {true} // can click day/week names to navigate views
	                                    editable= {true}
	                                    eventLimit= {true} // allow "more" link when too many events
	                                    height= {'parent'}
										events = {this.state.events}
										eventRender = {function(event, element){
                                            $(element).popover({
                                                html: true,
                                                trigger: 'hover',
                                                container: 'body',
                                                placement: 'top',
                                                content: '<b>League :</b> '+ event.League + '<br/><b>Venue :</b> '+ event.Venue + '<br/><b>Gender :</b> '+ event.Gender,
                                                title: event.title
                                            });
                                        }}
	                                />
	                            </div>
                			</div>
                		</div>
                	</div>
                	<div className="col-xl-6 col-12">
						<div className="card card-border card-activity">
                			<div className="card-header d-flex align-items-center justify-content-between">
                				<h2>Activity</h2>
                			</div>
                			<div className="card-body">
                				{/* <Scrollbars style={{ height: 255 }}>
	                                <div className="home-activity mCustomscrollbar">
	                                    <ul className="list">
	                                        <li className="clearfix">
	                                            <div className="avatar avatar-md avatar-round">
	                                                <img src={userPic} alt="User Avatar" />
	                                            </div>
	                                            <div className="discription">
	                                                <span className="name">Jhone Die</span>
	                                                <p>Dolor sit amet, consectetur adipisicing elit mod <b>tempor</b> incididunt adipisicing elit.</p>
	                                                <div className="time">
	                                                    <span>10 min</span>
	                                                </div>
	                                            </div>
	                                        </li>
	                                        <li className="clearfix">
	                                            <div className="avatar avatar-md avatar-round">
	                                                <img src={userPic} alt="User Avatar" />
	                                            </div>
	                                            <div className="discription">
	                                                <span className="name">Kevin  Meyers</span>
	                                                <p>Ipsum dolor sit amet, consectetur <b>adipisicing</b> elit, sed do eiusmod tempor.</p>
	                                                <div className="time">
	                                                    <span>25 min</span>
	                                                </div>
	                                            </div>
	                                        </li>
	                                        <li className="clearfix">
	                                            <div className="avatar avatar-md avatar-round">
	                                                <img src={userPic} alt="User Avatar" />
	                                            </div>
	                                            <div className="discription">
	                                                <span className="name">Patricia Rosner</span>
	                                                <p>Tempor incididunt ut labore et dolore <b>magna aliqua</b>. Ut enim ad minim veniam, quis nostrud exercitation.</p>
	                                                <div className="time">
	                                                    <span>30 min</span>
	                                                </div>
	                                            </div>
	                                        </li>
	                                        <li className="clearfix">
	                                            <div className="avatar avatar-md avatar-round">
	                                                <img src={userPic} alt="User Avatar" />
	                                            </div>
	                                            <div className="discription">
	                                                <span className="name">Jhone Die</span>
	                                                <p>Dolor sit amet, consectetur adipisicing elit mod <b>tempor</b> incididunt adipisicing elit.</p>
	                                                <div className="time">
	                                                    <span>10 min</span>
	                                                </div>
	                                            </div>
	                                        </li>
	                                    </ul>
	                                </div>
	                            </Scrollbars>
	                            <div className="home-activity-action">
	                                <button className="link">See More Messages</button>
	                            </div> */}
                			</div>
                		</div>
                	</div>
                </div>
            </div>
        );
    }
}

OfficialDashboard.propTypes = {
	fetchCalendarData: PropTypes.func.isRequired,
	loading: PropTypes.bool
}

export default OfficialDashboard;