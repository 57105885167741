import {connect} from 'react-redux';
import SpecificResults from './specific_results';
import {fetchSpecificAvailabilities, deleteSpecificAvailability, specificAvailabilityEditSuccess} from '../../../../../../actions/availability';

function mapsDispatchToProps (dispatch) {
    return {
        fetchResults: () => dispatch(fetchSpecificAvailabilities()),
        removeAvailability: (id) => dispatch(deleteSpecificAvailability(id)),
        availabilitySuccess: (status) => dispatch(specificAvailabilityEditSuccess(status))
    }
}

function mapsStateToProps (state) {
    return {
        availabilities: state.availability.specific_results,
        loading: state.availability.is_loading,
        form_success: state.availability.specific_edit_form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(SpecificResults);