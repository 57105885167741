import React, {Component} from 'react';
import emailSvg from '../../../../public/svg/email.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../loader/loader';
import FormError from '../../../form-error/form-error';

export default class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInput (event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {
            email
        } = this.state;

        const data = {
            email: email
        }
        this.props.onForgotSubmitted(data);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                email: ''
            });
        }
    }
    render () {
        let email_error = null;
        // let other_error = null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'email')
                    email_error = <FormError error={this.props.errors[key][0]} />;
                // else
                //     other_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
            <form onSubmit={this.handleSubmit} method="POST">
                {this.props.loading && <Loader />}
                <div className="signin">
                    <label className="label">Forgot Password</label>
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="email" /></span>
                                </span>
                            </div>
                            <input type="email" name="email" value={this.state.email} className="form-control" placeholder="Email" onChange ={event => this.handleInput(event)} />
                        </div>
                        {email_error}
                    </div>
                </div>
                <div className="action"> 
                    <input type="button" name="forgot" value="Send" className="submit" onClick={this.handleSubmit} />
                    <div className="signup">
                        <Link to="/login">Login</Link> <span>|</span>
                        <Link to="/register">Register</Link>
                    </div>
                </div>
            </form>
        );
    }
}

ForgotPasswordForm.propTypes = {
    onForgotSubmitted: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    form_success: PropTypes.bool
}