import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FormError from '../../../form-error/form-error';
import axios from 'axios';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../../loader/loader';
import withAuth from '../../../AuthHOC/withAuth';

class SendBulkEmailPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			subject: '',
			message: '',
			officials: true,
			coaches: true,
			allocators: true,
			subjectError: false,
			messageError: false,
			groupError: false,
			loader: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		if (name === 'subject') {
			if (this.state.subjectError) this.setState({subjectError: false});
		}

		if (name === 'message') {
			if (this.state.messageError) this.setState({ messageError: false });
		}

		if (target.type === 'checkbox') {
			if (this.state.groupError) this.setState({ groupError: false });
		}

		this.setState({
			[name]: value
		});
	}

	handleSubmit() {
		let { subject, message, officials, coaches, allocators } = this.state;
		let usergroups = [officials && 'official', coaches && 'coaches', allocators && 'allocators'].filter(Boolean);

		if (subject.length < 5) {
			this.setState({
				subjectError: true
			});
		}

		if (message.length < 5) {
			this.setState({
				messageError: true
			});
		}

		if (usergroups.length === 0) {
			this.setState({
				groupError: true
			});
		}

		if (subject.length >= 5 && message.length >= 5 && usergroups.length > 0) {
			this.setState({loader: true});
			let data = {subject, message, usergroups};
			let user = JSON.parse(localStorage.getItem('user'));
			let organisation_id = user.current_org;
			axios.post(`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/communication/bulkemail`, data, {
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}).then(response => {
				swal(
					'Success',
					response.data.message,
					'success'
				);

				this.setState({
					subject: '',
					message: '',
					loader: false
				});
			}).catch(error => {
				this.setState({ loader: false });
				swal(
					'Error',
					error.response.data.message,
					'error'
				);
			})
		}
	}

	render() {
		return (
			<div className="performance-report" style={{position: 'relative'}}>
			{this.state.loader && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Send Bulk Email</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Send Bulk Email</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="row mb-4">
						<div style={{ width: 100, paddingLeft: 15, paddingRight: 15 }}>
							<div className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" id="officialsCheck" name="officials" onChange={this.handleInputChange} checked={this.state.officials} />
								<label className="custom-control-label" htmlFor="officialsCheck">Officials</label>
							</div>
						</div>
						<div style={{ width: 100, paddingLeft: 15, paddingRight: 15 }}>
							<div className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" id="coachesCheck" name="coaches" onChange={this.handleInputChange} checked={this.state.coaches} />
								<label className="custom-control-label" htmlFor="coachesCheck">Coaches</label>
							</div>
						</div>
						<div style={{ width: 100, paddingLeft: 15, paddingRight: 15 }}>
							<div className="custom-control custom-checkbox">
								<input type="checkbox" className="custom-control-input" name="allocators" onChange={this.handleInputChange} checked={this.state.allocators} id="allocatorsCheck" />
								<label className="custom-control-label" htmlFor="allocatorsCheck">Allocators</label>
							</div>
						</div>
						{this.state.groupError && (
							<div className="col-sm-12">
								<FormError error={"You must checked at least 1 item"} />
							</div>
						)}
					</div>
					
					<div className="form-group">
						<label className="label">Subject</label>
						<div className="input-effect-1">
							<input type="text" className="form-control no-transition" name="subject" onChange={this.handleInputChange} value={this.state.subject} placeholder="Subject" />
							<div className="focus-border"></div>
						</div>
						{this.state.subjectError && <FormError error={"Your subject must be at least 5 characters"} />}
					</div>
					<div className="form-group">
						<label className="label">Message</label>
						<div className="input-effect-1">
							<textarea className="form-control no-transition" rows="8" name="message" onChange={this.handleInputChange} value={this.state.message} placeholder="Message"></textarea>
							<div className="focus-border"></div>
						</div>
						{this.state.messageError && <FormError error={"Your message must be at least 5 characters"} />}
					</div>
					<div className="action">
						<button type="button" onClick={this.handleSubmit} className="btn btn-orange">Send Bulk Email</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withAuth(SendBulkEmailPage, ['administrator','allocator']);