import axios from 'axios';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
	GENERAL_AVAILABILITY_CREATE_PERFORMED,
	SPECIFIC_AVAILABILITY_CREATE_PERFORMED,
	FETCH_SPECIFIC_AVAILABILITY,
	SPECIFIC_AVAILABILITY_DELETE_PERFORMED,
	FETCH_GENERAL_AVAILABILITY,
	FETCH_SPECIFIC_AVAILABILITY_DETAIL,
	SPECIFIC_EDIT_PERFORMED,
	GO_TO_GAME_PAGE_ORG,
	SET_GAME_AVAILABILITY,
	FETCH_SPECIFIC_GAMES,
	generalAvailabilityCreateSuccess,
	specificAvailabilityCreateSuccess,
	specificAvailabilityCreateError,
	generalAvailabilityCreateError,
	fetchSpecificAvailabilitySuccess,
	fetchGeneralAvailabilitySuccess,
	fetchGeneralAvailabilities,
	fetchSpecificAvailabilities,
	getSpecificAvailabilityDetailSuccess,
	specificAvailabilityEditSuccess,
	specificAvailabilityEditErrors,
	goToGamePageOrgSuccess,
	resetSpecificGame,
	setSelectedOrg,
	fetchSpecificGames
} from '../actions/availability';
import { push } from 'connected-react-router';
import { API_BASE_URL, getHeaders, getUserId } from '../constants';
import { showLoader, hideLoader, showFullSizeLoader, hideFullSizeLoader } from '../actions/loader';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* doCreateGeneralAvailability({ formData }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/general`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(generalAvailabilityCreateSuccess(true));
		yield put(fetchGeneralAvailabilities());
	} catch (error) {
		if (error.response.status === 422) {
			if (error.response.data.errors) {
				yield put(generalAvailabilityCreateError(error.response.data.errors));
			}
		}
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* doCreateSpecificAvailability({ formData }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchSpecificAvailabilities());
		yield put(specificAvailabilityCreateSuccess(true));
	} catch (error) {
		if (error.response.status === 422) {
			if (error.response.data.errors) {
				yield put(specificAvailabilityCreateError(error.response.data.errors));
			}
		}
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getSpecificAvailabilities() {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific`,
			{ headers: getHeaders() }
		);
		yield put(fetchSpecificAvailabilitySuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* doRemoveSpecificAvailability({ id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific/${id}`,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchSpecificAvailabilities());
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getGeneralAvailabilities() {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/general`,
			{ headers: getHeaders() }
		);
		yield put(fetchGeneralAvailabilitySuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getSpecificAvailabilityDetail({ id }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific/${id}`,
			{ headers: getHeaders() }
		);
		yield put(getSpecificAvailabilityDetailSuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideFullSizeLoader());
}

function* doEditSpecificAvailability({ formData, id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific/${id}`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(specificAvailabilityEditSuccess(true));
		yield put(fetchSpecificAvailabilities());
	} catch (error) {
		if (error.response.status === 422) {
			yield put(specificAvailabilityEditErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* goToGamePagePerformed ({ id }) {
	yield put(resetSpecificGame());
	yield put(setSelectedOrg(id));
	yield put(push('/availability/game'));
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${id}/availability/games`,
			{ headers: getHeaders() }
		);
		yield put(goToGamePageOrgSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* fetchSpecificGamePerformed() {
	yield put(showLoader());
	try {
		const getSelectedOrg = (state) => state.availability.selected_org;
		let selectedOrg = yield select(getSelectedOrg);
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${selectedOrg}/availability/games`,
			{ headers: getHeaders() }
		);
		yield put(goToGamePageOrgSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* setGameAvailabilityPerformed({ id, availability }) {
	yield put(showLoader());
	try {
		const getSelectedOrg = (state) => state.availability.selected_org;
		let selectedOrg = yield select(getSelectedOrg);
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${selectedOrg}/availability/game/${id}`,
			{'available': availability},
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchSpecificGames());
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

export default function* () {
	yield takeLatest(GENERAL_AVAILABILITY_CREATE_PERFORMED, doCreateGeneralAvailability);
	yield takeLatest(SPECIFIC_AVAILABILITY_CREATE_PERFORMED, doCreateSpecificAvailability);
	yield takeLatest(FETCH_SPECIFIC_AVAILABILITY, getSpecificAvailabilities);
	yield takeLatest(SPECIFIC_AVAILABILITY_DELETE_PERFORMED, doRemoveSpecificAvailability);
	yield takeLatest(FETCH_GENERAL_AVAILABILITY, getGeneralAvailabilities);
	yield takeLatest(FETCH_SPECIFIC_AVAILABILITY_DETAIL, getSpecificAvailabilityDetail);
	yield takeLatest(SPECIFIC_EDIT_PERFORMED, doEditSpecificAvailability);
	yield takeLatest(GO_TO_GAME_PAGE_ORG, goToGamePagePerformed);
	yield takeLatest(SET_GAME_AVAILABILITY, setGameAvailabilityPerformed);
	yield takeLatest(FETCH_SPECIFIC_GAMES, fetchSpecificGamePerformed);
}