import { connect } from 'react-redux';
import { loginPerformed } from '../../actions/login';
import LoginForm from './login-form';

function mapDispatchToProps (dispatch) {
    return {
        onLoginSubmitted: (formData) => dispatch(loginPerformed(formData))
    };
}

function mapsStateToProps (state) {
	return {
        errors: state.login.errors,
        loading: state.login.is_loading,
        alert_error_msg: state.login.alert_error_msg,
        alert_error_show: state.login.is_alert_error,
	};
}

export default connect(() => (mapsStateToProps), mapDispatchToProps)(LoginForm);