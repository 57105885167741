import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { 
    FETCH_PATTERNS_PERFORMED,
    PATTERN_CREATE_PERFORMED,
    PATTERN_EDIT_PERFORMED,
    PATTERN_DELETE_PERFORMED,
    GET_PATTERN_DETAIL,
    patternLoaderStatus,
    fetchPatternsSuccess,
    patternCreateSuccess,
    patternCreateErrors,
    patternEditSuccess,
    patternEditErrors,
    fetchPatternsPerformed,
    getPatternSuccess
} from '../actions/pattern';
import { push } from 'connected-react-router';
import {API_BASE_URL} from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchPatterns() {
    yield put(patternLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/patterns`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(patternLoaderStatus(false));
		yield put(fetchPatternsSuccess(results.data));
    }catch (error) {
        yield put(patternLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* doCreateNewPattern({patternFormData}) {
	yield put(patternLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const patternResults = yield call(
            axios.post,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/patterns`,
            patternFormData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(patternLoaderStatus(false));
        swal(
            'Success',
            patternResults.data.message,
            'success'
        );
        yield put(patternCreateSuccess(true));
        yield put(fetchPatternsPerformed());
    }catch (error) {
		yield put(patternLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(patternCreateErrors(error.response.data.errors));
        }
        if(error.response.status === 409)
        {
            swal(
                'Error',
                error.response.data.message,
                'error'
            );
        }
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
    }
}

function* doEditPattern({formData, id}) {
	yield put(patternLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const patternResults = yield call(
            axios.put,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/patterns/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(patternLoaderStatus(false));
        swal(
            'Success',
            patternResults.data.message,
            'success'
        );
        yield put(patternEditSuccess(true));
        yield put(fetchPatternsPerformed());
    }catch (error) {
		yield put(patternLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(patternEditErrors(error.response.data.errors));
        }
        if(error.response.status === 409)
        {
            swal(
                'Error',
                error.response.data.message,
                'error'
            );
        }
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
    }
}

function* doRemovePattern({id}) {
    yield put(patternLoaderStatus(true));
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.delete,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/patterns/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(patternLoaderStatus(false));
		swal(
            'Success',
            results.data.message,
            'success'
        );
        yield put(fetchPatternsPerformed());
    }catch (error) {
        yield put(patternLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* fetchPatternDetail({id}) {
    yield put(patternLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/patterns/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(patternLoaderStatus(false));
		yield put(getPatternSuccess(results.data));
    }catch (error) {
        yield put(patternLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

export default function* () {
    yield takeLatest(FETCH_PATTERNS_PERFORMED, fetchPatterns);
    yield takeLatest(PATTERN_CREATE_PERFORMED, doCreateNewPattern);
    yield takeLatest(PATTERN_EDIT_PERFORMED, doEditPattern);
    yield takeLatest(PATTERN_DELETE_PERFORMED, doRemovePattern);
    yield takeLatest(GET_PATTERN_DETAIL, fetchPatternDetail);
}