import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';

export default class RegionResults extends Component {
	constructor(props) {
		super(props);

		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}
	componentWillMount() {
		this.props.fetchRegions();
	}

	handleEdit = (id) => {
		this.props.onOpenEditModal(id);
	}

	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.removeRegion(id);
			}
		})
	}
	render() {
		const data = this.props.regions.data || [];
		const verifiedData = Array.isArray(data) ? data : Object.keys(data).map(obj => data[obj]);
		const columns = [{
			Header: 'Name',
			accessor: 'name' // String-based value accessors!
		}, {
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li>
							<button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button>
						</li>
					</ul>
				</div>
			)
		}];
		return (
			<div className="table-area">
				{this.props.loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={verifiedData}
					columns={columns}
					noDataText="No Regions Found."
					defaultPageSize={10}
					resizable={true}
					minRows={5}
				/>
			</div>
		);
	}
}

RegionResults.propTypes = {
	Regions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
		}).isRequired
	),
	fetchRegions: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	form_success: PropTypes.bool,
	is_deleted: PropTypes.bool,
	edit_success: PropTypes.bool
}