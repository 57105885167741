import {connect} from 'react-redux';
import Dashboard from './dashboard';
import {fetchChartData, fetchCalendarData} from '../../../../actions/dashboard';

function mapsDispatchToProps (dispatch) {
    return {
        fetchChartData: (daterange) => dispatch(fetchChartData(daterange)),
        fetchCalendarData: () => dispatch(fetchCalendarData())
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.dashboard.is_loading,
        chart_data: state.dashboard.chart_data,
        calendar_data: state.dashboard.calendar_data
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(Dashboard);