import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './performance.scss';
import PerformanceReportResults from './performace-report-results/performance_report_results-container';
import withAuth from '../../../AuthHOC/withAuth';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import dateIcon from '../../../../public/svg/date.svg';
import Modal from 'react-responsive-modal';
import moment from 'moment';


import classNames from 'classnames';

const now = moment();
const format= 'DD-MM-YYYY';
const customStyles = {
	height: '150%',
	width: '150%'
};
class PerformanceReport extends Component {
	constructor(props){
		super(props);
		this.state = {
			modalOpened: false,
			filterModalOpened: false,
			loading: false,
			start_date: now,
			end_date: now,
			showDateInput: false,
			teams: [],
			leagues: [],
			venues: [],
			genders: [],
			gameData: {},
			filters:{
				/*home_team: '',
				away_team: '',
				venue: '',
				league: '',
				gender: '',
				date_range: 0,
				game_date: undefined,
				game_time: 0,*/
				

			},
			isFilterActive:false,
		};
		
		this.genderName = this.genderName.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChangeStartDate = this.onChangeStartDate.bind(this);
		this.onChangeEndDate = this.onChangeEndDate.bind(this);
		this.setLoading = this.setLoading.bind(this);
		this.onTimeChange = this.onTimeChange.bind(this);
	}
	
	openModal(){
		this.setState({
			modalOpened: true
		});
	}	
	closeModal(){
		this.setState({modalOpened: false});
	}
	onChangeStartDate(value){
		this.setState({start_date: value});
	}
	onChangeEndDate(value){
		this.setState({end_date: value});
	}
	setLoading(value){
		this.setState({loading: value});
	}
	closeFilterModal = e => {
		this.setState({filterModalOpened: false})
	}
	openFilterModal = e => {
		this.setState({filterModalOpened: true})
	}
	

	handleFilterChange = e =>{
		this.setState({
			filters: {
				...this.state.filters,
				[e.target.name]: e.target.value,
			}
		})
	}
	genderName(id) {
		switch (id) {
			case 0: return 'MEN';
			case 1: return 'WOMEN';
			case 2: return 'BOYS';
			case 3: return 'GIRLS';
			case 4: return 'MIXED';
			default: return '';
		}
	}
	generateparams = filters => {
		let game_date;
		let date_Now;
		let start_date;
		let end_date;
		let value = 0;
		let selected_date = filters.choosedate;
		let game;
		
		if(filters.chooseDate === 'date-range'){
			if(filters.chooseDate === "future"){
				game_date = game.game_date;
				date_Now = date_Now();
				if(game_date <= date_Now){

				}
			} else if(filters.chooseDate === "past"){
				game_date = game.game_date;
				date_Now = date_Now();
				if(game_date >= date_Now){

				}
			} else {
				start_date = date_Now();
				end_date = date_Now().addDays(value-1);
				if(!game_date >= start_date && game_date <= end_date){

				}
			}
		}
		if(filters.chooseDate){
			selected_date = filters.chooseDate;
			game_date = game.game_date;
			if(selected_date !== game_date){

			}
		}
		let params = new URLSearchParams();
		Object.keys(filters).forEach(key =>{
			console.log(filters[key]);
			if(filters[key]!= null){
				params.append(key,filters[key]);
			}
		});
		return params;
	};
	handleClearCalender = () => {
		this.setState({
				filters: {
					...this.state.filters,
					game_date: undefined
				}
		});
	}
	applyFilter = e => {
		
		this.props.fetchPerformanceReportList(this.state.filters);
		this.closeFilterModal();
	}
 
	resetFilter = e => {
		this.props.fetchPerformanceReportList(
			this.setState({
				filters:{
					home_team: '',
					away_team: '',
					venue: '',
					league: '',
					gender: '',
					date_range: 0,
					game_date: undefined,
					game_time: 0	

				}
			})

		);
		this.closeFilterModal();

	}	
	
	onTimeChange(value){
		this.setState(state => {
			return{
				filters:{
					...this.state.filters,
					game_time: value.format("HH:mm")
				}
			}

		})
	}
	
	onChange = (value) => {
		this.setState(state => {
			return{
				filters: {
					...this.state.filters,
					game_date: value.format("YYYY-MM-DD")
				}
			}
		})
	}

	render() {
		const calendar = (
			<Calendar
			style={{ zIndex: 1000, width: "100%" }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			format={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={this.state.showDateInput}
			showClear={true}
		/>);
		const params = new URLSearchParams(this.props.location.search);
		const daterange = params.get('daterange')
		return (
			<div className="performance-report">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title" >Performance Report</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Performance Report</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="actions mb_15">
					<ul className="list-inline">
						<li className="list-inline-item mb_15">
							<button
								ref={(node) => this.toggleBtn = node}
								className={classNames('btn', { 'btn-standard': !this.state.isFilterActive }, { 'btn-active': this.state.isFilterActive })}
								onClick={this.openFilterModal}
								data-toggle="modal"
								data-target="#filter"
								>Filter {this.state.isFilterActive && 'is Active'}
							</button>
						</li>	
					</ul>
				</div>
				<div className="box">
					<PerformanceReportResults 
					datrange={daterange}
					data={this.state.gameData} />
				</div>
				<Modal
					open={this.state.filterModalOpened}
					onClose={this.closeFilterModal}
					showCloseIcon={false}
					center
					style={customStyles}
					classNames={{
						modal:"modal fade show"
					}}>
						<div className="modal-dialog modal-dialog-centered modal-dialog modal-lg"  >
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Filter</h5>
									<button type="button" className="close" data-dismiss="modal" onClick={this.closeFilterModal}>
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Venue</label>
												<div className="input-effect-1">
												<select id= "venue" value={this.state.filters.venue_id} name="venue_id" onChange={this.handleFilterChange} className="normal-select">
														<option value={0}>Select Venue Name</option>
														{
															this.props.venues && this.props.venues.map((venue,i) =>
															<option key={i} value={venue.id}>{venue.venue_name}</option>)
														}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">League</label>
												<div className="input-effect-1">
													<select id= "league" value={this.state.filters.league_id} name="league_id" onChange={this.handleFilterChange} className="normal-select">
														<option value={0}>Select League</option>
															{
																this.props.leagues && this.props.leagues.map((league,i) =>
																<option key={i} value={league.id}>{league.league_name}</option>)
															}
														
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Gender</label>
												<div className="input-effect-1">
													<select value={this.state.filters.gender_id} name= "gender_id" onChange={this.handleFilterChange} className="normal-select">
														<option value={0}>select Gender</option>
														<option value={1}>MEN</option>
														<option value={2}>WOMEN</option>
														<option value={3}>BOYS</option>
														<option value={4}>GIRLS</option>
														<option value={5}>MIXED</option>
													</select>	
													<div className="focus-border">

													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center"><input className="mr-1" type="radio" name="chooseDate" value="date-range" checked={this.state.filters.chooseDate === 'date-range'} onChange={this.handleFilterChange} /> Date Range</label>
												<div className="input-effect-1">
													<select className={classNames("normal-select", { "disabled-datepicker": (this.state.filters.chooseDate !== 'date-range') })} value={this.state.filters.date_range} disabled={(this.state.filters.chooseDate !== 'date-range')} name="date_range" onChange={this.handleFilterChange}>
														<option value={0}>Select Range</option>
														<option value={1}>1 Day</option>
														<option value={3}>3 Days</option>
														<option value={7}>1 Week</option>
														<option value={14}>2 Weeks</option>
														<option value={21}>3 Weeks</option>
														<option value={28}>4 Weeks</option>
														<option value="future">All Future Games</option>
														<option value="past">All Past Games</option>
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center justify-content-between">
													<div>
														<input className="mr-1" type="radio" name="chooseDate" value="game-dates" checked={this.state.filters.chooseDate === 'game-dates'} onChange={this.handleFilterChange} />
														Game Dates
													</div>
													{this.state.filters.game_date != null && <button className="button-link" onClick={this.handleClearCalender}>Clear</button>}
												</label>
												<div className="input-effect-1">
													<div className={classNames("datepicker", { "disabled-datepicker": (this.state.filters.chooseDate !== 'game-dates') })} id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={(this.state.filters.chooseDate !== 'game-dates')}
															calendar={calendar}
															value={this.state.filters.game_date}
															onChange={this.onChange}
															getCalendarContainer={() => document.getElementById('datepicker')}
															className="custom-calendar"
															showClear={true}
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={(this.state.filters.chooseDate !== 'game-dates')}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value == null ? '' : moment(value).format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<img src={dateIcon} alt="date" className="imgsvg" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Time</label>
												<div className="timepicker">
													<div className="input-group">
														<TimePicker showSecond={false} value={moment(this.state.filters.game_time, 'HH:mm')} onChange={this.onTimeChange} />
														<div className="input-group-append">
															<span className="input-group-text">
																<img src={dateIcon} alt="date" className="imgsvg" />
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Home Team</label>
												<div className="input-effect-1">
													<select id= "home_team" value={this.state.filters.home_team_id} name="home_team_id" onChange={this.handleFilterChange} className="normal-select">
														<option value={0}>Select Home Team</option>
														{
															this.props.teams && this.props.teams.map((team,i) =>
															<option key={i} value={team.id}>{team.team_name}</option>)
														}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Away Team</label>
												<div className="input-effect-1">
													<select value={this.state.filters.away_team_id} name="away_team_id" onChange={this.handleFilterChange} className="normal-select">
														<option value={0}>Select Away Team</option>
														{
															this.props.teams && this.props.teams.map((team,i) =>
															<option key={i} value={team.id}>{team.team_name}</option>)
														}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer text-center">
									<button type="button" className="btn btn-orange" onClick={this.applyFilter}>Apply</button>
									<button type="button" className="btn btn-orange" onClick={this.resetFilter}>Reset Filter</button>
								</div>
							</div>
						</div>
				</Modal>
			</div>
		);
	}
}

export default withAuth(PerformanceReport,['administrator','allocator']);