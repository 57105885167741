import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_GAMES_PERFORMED,
	FETCH_GAMES_DATA,
	GAME_CREATE_PERFORMED,
	GAME_EDIT_PERFORMED,
	GAME_DELETE_PERFORMED,
	GET_GAME_DETAIL,
	GET_REFEREES_PERFORMED,
	ASSIGN_OFFICIAL_TO_GAME,
	GET_ASSIGN_REFEREE_PERFORMED,
	FETCH_OFFICIAL_GAMES_PERFORMED,
	CONFIRM_GAME_TO_OFFICIAL,
	DECLINE_GAME_TO_OFFICIAL,
	GET_TRAVEL_CLAIM,
	SUBMIT_TRAVEL_CLAIM,
	PUBLISH_GAMES,
	DELETE_GAMES,
	ADD_BULK_GAMES,
	GET_PERFORMANCE_REPORT,
	SAVE_PERFORMANCE_REPORT,
	PUBLISH_PERFORMANCE_REPORT,
	SUBMIT_PERFORMANCE_REPORT,
	FETCH_OFFICIAL_GAMES_DATA,
	DOWNLOAD_GAME_VIDEO,
	gameLoaderStatus,
	fetchGamesSuccess,
	fetchGamesDataSuccess,
	fetchGamesPerformed,
	gameCreateSuccess,
	gameCreateErrors,
	gameEditSuccess,
	gameEditErrors,
	getGameDetailSuccess,
	assignRefereeLoaderStatus,
	assignRefereeDataLoaderStatus,
	getRefereesSuccess,
	getAssignReferee,
	getAssignRefereesSuccess,
	fetchOfficialGamesSuccess,
	fetchOfficialGamesPerformed,
	performanceReportLoading,
	getTravelClaimSuccess,
	getTravelClaimPerformed,
	travelClaimLoading,
	fetchGamesDataLoading,
	clearBulkFiles,
	getPerformanceReportSuccess,
	getPerformanceReport,
	fetchOfficialGamesDataSuccess
} from '../actions/game';
import { API_BASE_URL, VIDEO_API_URL, getHeaders, getOrgId, getUserId } from '../constants';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment';
import { showLoader, hideLoader } from '../actions/loader';


function* fetchGames({ filters, sortBy }) {
	yield put(fetchGamesDataLoading(true));
	let params = new URLSearchParams();
	if (filters != null) {
		filters.forEach((value, key) => {
			params.append(key, value);
		});
	};

	if (sortBy != null) {
		sortBy.forEach((value, key) => {
			params.append(key, value);
		});
	};

	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games`,
			{
				headers: getHeaders(),
				params: params,
			}
		);
		yield put(fetchGamesDataLoading(false));
		yield put(fetchGamesSuccess(results.data.data));
	} catch (error) {
		yield put(fetchGamesDataLoading(false));
		yield put(handleError(error));
	}
}

function* fetchGamesData() {
	yield put(gameLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/gamedata`,
			{ headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		yield put(fetchGamesDataSuccess(results.data));
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));

	}
}

function* doCreateNewGame({ formData }) {
	yield put(gameLoaderStatus(true));
	try {
		const gameResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games`,
			formData, { headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', gameResults.data.message, 'success');
		yield put(gameCreateSuccess(true));
		yield put(fetchGamesPerformed());
	} catch (error) {
		yield put(gameLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(gameCreateErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
}

function* doEditGame({ id, formData }) {
	yield put(gameLoaderStatus(true));
	try {
		const gameResults = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/`,
			formData, { headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', gameResults.data.message, 'success');
		yield put(gameEditSuccess(true));
		yield put(fetchGamesPerformed());
	} catch (error) {
		yield put(gameLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(gameEditErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
}

function* doRemoveGame({ id }) {
	yield put(gameLoaderStatus(true));
	try {
		yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}`,
			{ headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', 'Game removed successfully', 'success');
		yield put(fetchGamesPerformed());
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* fetchGameDetail({ id }) {
	yield put(gameLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/games/${id}`,
			{ headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		yield put(getGameDetailSuccess(results.data.data));
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* doFetchReferees({ id }) {
	yield put(assignRefereeLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/availableofficials`,
			{ headers: getHeaders() }
		);
		yield put(assignRefereeLoaderStatus(false));
		yield put(getRefereesSuccess(results.data.data));
	} catch (error) {
		yield put(assignRefereeLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* doAssignOfficialToGame({ formData, id }) {
	yield put(assignRefereeLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/referees`,
			formData, { headers: getHeaders() }
		);
		yield put(assignRefereeLoaderStatus(false));
		swal('Success', results.data.message, 'success');
		yield put(getAssignReferee(id));
	} catch (error) {
		yield put(assignRefereeLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* doFetchAssignReferee({ id }) {
	yield put(assignRefereeDataLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/referees`,
			{ headers: getHeaders() }
		);
		yield put(assignRefereeDataLoaderStatus(false));
		yield put(getAssignRefereesSuccess(results.data.data));
	} catch (error) {
		yield put(assignRefereeDataLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* fetchOfficialGames({ filters, sortBy }) {
	yield put(fetchGamesDataLoading(true));
	let params = new URLSearchParams();
	if (filters != null) {
		filters.forEach((value, key) => {
			params.append(key, value);
		});
	};

	if (sortBy != null) {
		sortBy.forEach((value, key) => {
			params.append(key, value);
		});
	};
	
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/games`,
			{
				headers: getHeaders(),
				params: params
			}
		);
		yield put(fetchGamesDataLoading(false));
		yield put(fetchOfficialGamesSuccess(results.data.data));
	} catch (error) {
		yield put(fetchGamesDataLoading(false));
		yield put(handleError(error));
	}
}

function* fetchOfficialGamesData() {
	yield put(gameLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/gamedata`,
			{ headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		yield put(fetchOfficialGamesDataSuccess(results.data));
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));

	}
}

function* confirmGameToOfficial({ id, callback }) {
	yield put(gameLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/gameid/${id}/confirm`,
			null, { headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', results.data.message, 'success');
		const rememberedFilters = JSON.parse(localStorage.getItem('officialFilters'));
		if (rememberedFilters == null) {
			yield put(fetchOfficialGamesPerformed());
		} else {
			yield put(fetchOfficialGamesPerformed(generateParams(rememberedFilters)));
		}
		if (callback != null) {
			callback(id);
		}
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* declineGameToOfficial({ id, reason, callback }) {
	yield put(gameLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/gameid/${id}/decline`,
			{ "decline_reason": reason }, { headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', results.data.message, 'success');
		const rememberedFilters = JSON.parse(localStorage.getItem('officialFilters'));
		if (rememberedFilters == null) {
			yield put(fetchOfficialGamesPerformed());
		} else {
			yield put(fetchOfficialGamesPerformed(generateParams(rememberedFilters)));
		}
		if (callback != null) {
			callback(id);
		}
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* getTravelClaim({ id, }) {
	yield put(travelClaimLoading(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/travelclaim`,
			{ headers: getHeaders() }
		);
		yield put(travelClaimLoading(false));
		yield put(getTravelClaimSuccess(results.data.Claim));
	} catch (error) {
		yield put(performanceReportLoading(false));
		yield put(handleError(error));
	}
}

function* submitTravelClaim({ id, data }) {
	yield put(travelClaimLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/${id}/travelclaim`,
			data, { headers: getHeaders() }
		);
		yield put(travelClaimLoading(false));
		yield put(getTravelClaimPerformed(id));
		swal('Success', results.data.message, 'success');
	} catch (error) {
		yield put(travelClaimLoading(false));
		yield put(handleError(error));
	}
}

function* publishGames({ data }) {
	yield put(fetchGamesDataLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/publish`,
			data, { headers: getHeaders() }
		);
		yield put(fetchGamesDataLoading(false));
		yield put(fetchGamesPerformed());
		swal('Success', results.data.Message, 'success');
	} catch (error) {
		yield put(fetchGamesDataLoading(false));
		yield put(handleError(error));
	}
}
function* deleteGames({ data }) {
	yield put(fetchGamesDataLoading(true));
	try{
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/games/delete`,
			data, { headers: getHeaders() }

		);
		yield put(fetchGamesDataLoading(false));
		yield put(fetchGamesPerformed());
		swal('Success', results.data.Message, 'success');	
	} catch (error) {
		yield put(fetchGamesDataLoading(false));
		yield put(handleError(error));
	}
}

function* addBulkGames({ data }) {
	yield put(gameLoaderStatus(true));
	yield put(clearBulkFiles(false));
	try {
		let file = data.games;
		let fd = new FormData();
		fd.append('games', file);
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/upload/games`,
			fd, { headers: getHeaders() }
		);
		yield put(gameLoaderStatus(false));
		swal('Success', results.data.Message, 'success');
		yield put(clearBulkFiles(true));
	} catch (error) {
		yield put(gameLoaderStatus(false));
		yield put(clearBulkFiles(true));
		yield put(handleError(error));
	}
}

function* getPerformanceReportFunction({ id }) {
	yield put(performanceReportLoading(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance`,
			{ headers: getHeaders() }
		);
		yield put(performanceReportLoading(false));
		yield put(getPerformanceReportSuccess(results.data.report));
	} catch (error) {
		yield put(performanceReportLoading(false));
		yield put(handleError(error));
	}
}

function* savePerformanceReport({ id, data }) {
	yield put(performanceReportLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance`,
			data, { headers: getHeaders() }
		);
		yield put(performanceReportLoading(false));
		yield put(getPerformanceReport(id));
		swal('Success', results.data.Message, 'success');
	} catch (error) {
		yield put(performanceReportLoading(false));
		yield put(handleError(error));
	}
}

function* submitPerformanceReport({ id, data }) {
	yield put(performanceReportLoading(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance`,
			data, { headers: getHeaders() }
		);
		yield put(performanceReportLoading(false));
		yield put(getPerformanceReport(id));
		swal('Success', results.data.Message, 'success');
	} catch (error) {
		yield put(performanceReportLoading(false));
		yield put(handleError(error));
	}
}

function* publishPerformanceReport({ id, data }) {
	yield put(performanceReportLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance/publish`,
			data, { headers: getHeaders() }
		);
		yield put(performanceReportLoading(false));
		yield put(getPerformanceReport(id));
		swal('Success', results.data.Message, 'success');
	} catch (error) {
		yield put(performanceReportLoading(false));
		yield put(handleError(error));
	}
}

const generateParams = filters => {
	let date;
	if (filters.chooseDate === 'date-range') {
		date = {
			daterange: filters.date_range === 0 ? null : filters.date_range,
			game_date: null
		}
	} else {
		date = {
			daterange: null,
			game_date: filters.game_date == null ? null : moment(filters.game_date).format('YYYY-MM-DD'),
		}
	}

	let obj = {
		venue_id: filters.venue === 0 ? null : filters.venue,
		league_id: filters.league === 0 ? null : filters.league,
		gender: filters.gender === 0 ? null : filters.gender,
		game_time: filters.game_time === 0 ? null : filters.game_time,
		home_team_id: filters.home_team === 0 ? null : filters.home_team,
		away_team_id: filters.away_team === 0 ? null : filters.away_team,
	};

	let newObj = { ...obj, ...date };

	let params = new URLSearchParams();
	Object.keys(newObj).forEach(key => {
		if (newObj[key] != null) {
			params.append(key, newObj[key]);
		}
	});

	return params;
};

function* downloadGameVideo({ id }) {
	yield put(showLoader());
	try {
		window.open(`${VIDEO_API_URL}/download?token=${id}`);
		yield put(hideLoader());
		swal(
			'Success',
			'Download Video Success',
			'success'
		);
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(FETCH_GAMES_PERFORMED, fetchGames);
	yield takeLatest(FETCH_GAMES_DATA, fetchGamesData);
	yield takeLatest(GAME_CREATE_PERFORMED, doCreateNewGame);
	yield takeLatest(GAME_EDIT_PERFORMED, doEditGame);
	yield takeLatest(GAME_DELETE_PERFORMED, doRemoveGame);
	yield takeLatest(GET_GAME_DETAIL, fetchGameDetail);
	yield takeLatest(GET_REFEREES_PERFORMED, doFetchReferees);
	yield takeLatest(ASSIGN_OFFICIAL_TO_GAME, doAssignOfficialToGame)
	yield takeLatest(GET_ASSIGN_REFEREE_PERFORMED, doFetchAssignReferee);
	yield takeLatest(FETCH_OFFICIAL_GAMES_PERFORMED, fetchOfficialGames);
	yield takeLatest(CONFIRM_GAME_TO_OFFICIAL, confirmGameToOfficial);
	yield takeLatest(DECLINE_GAME_TO_OFFICIAL, declineGameToOfficial);
	yield takeLatest(GET_TRAVEL_CLAIM, getTravelClaim);
	yield takeLatest(SUBMIT_TRAVEL_CLAIM, submitTravelClaim);
	yield takeLatest(PUBLISH_GAMES, publishGames);
	yield takeLatest(DELETE_GAMES, deleteGames);
	yield takeLatest(ADD_BULK_GAMES, addBulkGames);
	yield takeLatest(GET_PERFORMANCE_REPORT, getPerformanceReportFunction);
	yield takeLatest(SAVE_PERFORMANCE_REPORT, savePerformanceReport);
	yield takeLatest(SUBMIT_PERFORMANCE_REPORT, submitPerformanceReport);
	yield takeLatest(PUBLISH_PERFORMANCE_REPORT, publishPerformanceReport);
	yield takeLatest(FETCH_OFFICIAL_GAMES_DATA, fetchOfficialGamesData);
	yield takeLatest(DOWNLOAD_GAME_VIDEO, downloadGameVideo);
}