import React, { Component, Fragment } from 'react';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import Modal from 'react-responsive-modal';
import ReportField from '../../../../report-field/report-field';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class PerformanceReportResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpened: false,
			hasReport: true,
			isEdit: false,
			gameData: {},
			isPublished: false,
			currentId: null
		}
		this.genderName = this.genderName.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handlePublish = this.handlePublish.bind(this);
		this.handlePublishRow = this.handlePublishRow.bind(this);
	}

	componentWillMount() {
		this.props.fetchPerformanceReportList();
	}

	genderName(id) {
		switch (id) {
			case 0: return 'NULL';
			case 1: return 'MEN';
			case 2: return 'WOMEN';
			case 3: return 'BOYS';
			case 4: return 'GIRLS';
			case 5: return 'MIXED';
			default: return '';
		}
	}

	openModal(isEdit, data, currentId) {
		const isPublished = data.status === 'Published' ? true : false
		this.setState({
			modalOpened: true,
			isEdit: isEdit,
			gameData: data,
			isPublished: isPublished,
			currentId: currentId
		});
	}

	closeModal() {
		this.setState({
			modalOpened: false,
			isEdit: false
		});
	}

	handleEdit() {
		this.setState({
			isEdit: true
		});
	}

	handlePublishRow(data, id) {
		let newFields = [];
		data.fields.forEach(obj => {
			newFields[obj.id] = obj.value == null ? "" : obj.value;
		});

		let newData = {
			report_id: data.custom_performance_report_id,
			field_values: newFields
		}

		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Publish!'
		}).then((result) => {
			if (result.value) {
				this.props.publishPerformanceReport(id, JSON.stringify(newData));
			}
		})
	}

	handleSave(data) {
		this.props.savePerformanceReport(this.state.currentId, JSON.stringify(data));
	}

	handleSubmit(data) {
		this.props.submitPerformanceReport(this.state.currentId, JSON.stringify(data));
	}

	handlePublish(data) {
		this.props.publishPerformanceReport(this.state.currentId, JSON.stringify(data));
	}

	componentWillReceiveProps(nextProps) {
		this.closeModal();
	}

	render() {
		const columns = [{
			Header: 'Match',
			minWidth: 250,
			Cell: row => (
				<div className="match">
					<span className="highlight">{row.original.game_info.home_team.team_name} vs {row.original.game_info.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game_info.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game_info.game_time, "HH:mm").format("LT")}</span></li>
						</ul>
					</div>
					<div className="actions">
						<ul className="list-inline">
							{row.original.status !== 'Published' && (
								<Fragment>
									<li><button className="link" onClick={() => { this.openModal(true, row.original, row.original.game_id) }}>Edit Report</button></li>
									<li><span className="seprator">|</span></li>
								</Fragment>)
							}
							<li><button className="link" onClick={() => { this.openModal(false, row.original, row.original.game_id) }}>View Report</button></li>
							<li><span className="seprator">|</span></li>
							<li><Link to={`/game/${row.original.game_id}/detail`}><button className="link">View Game</button></Link></li>
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Venue',
			accessor: 'game_info.venue.venue_name'
		}, {
			Header: 'League',
				accessor: 'game_info.league.league_name'
		}, {
			Header: 'Gender',
			Cell: row => (
				this.genderName(row.original.game_info.gender)
			)
		}, {
			Header: 'Actions',
			minWidth: 110,
			Cell: row => (
				row.original.status === 'Published'
				? "Report Published"
				: <button type="button" className="btn btn-success" onClick={() => { this.handlePublishRow(row.original, row.original.game_id) }}>Publish</button>
			)
		}];

		let { fetchLoading, modalLoading } = this.props;
		return (
			<div className="table-area">
				{fetchLoading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={this.props.data}
					columns={columns}
					noDataText="No Performance Report Found."
					defaultPageSize={10}
					resizable={true}
					minRows={5}
				/>

				<Modal
					open={this.state.modalOpened}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.modalType} Performance Report</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body" style={{ maxHeight: '65vh', overflowY: 'auto', position: 'relative' }}>
								{modalLoading && <Loader />}
								{/* {this.state.modalType === 'View'
									? <ViewPerformance data={this.state.data} game={this.state.game} />
									: <EditPerformance onSave={this.handleSave} onPublish={this.handlePublish} data={this.state.data} game={this.state.game} />
								} */}

								<ReportField
									hasReport={this.state.hasReport}
									isEdit={this.state.isEdit}
									isPublished={this.state.isPublished}
									data={this.state.gameData}
									handleEdit={this.handleEdit}
									hasMatchDetails={true}
									handleSave={this.handleSave}
									handleSubmit={this.handleSubmit}
									handlePublish={this.handlePublish}
								/>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}