import { connect } from 'react-redux';
import NewRegion from './new_region';
import { regionCreatePerformed } from '../../../../../actions/region';

function mapsDispatchToProps (dispatch) {
    return {
        onRegionSubmitted: (formData) => dispatch(regionCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        errors: state.region.create_errors,
        loading: state.region.is_loading,
        form_success: state.region.form_success,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewRegion);