import { connect } from 'react-redux';
import Availability from './availability';
import { fetchGeneralAvailabilities, goToGamePageOrg } from '../../../../actions/availability';

function mapsDispatchToProps(dispatch) {
	return {
		fetchAvailability: () => dispatch(fetchGeneralAvailabilities()),
		goToGamePageOrg: (id) => dispatch(goToGamePageOrg(id))
	}
}

function mapsStateToProps(state) {
	return {
		availabilities: state.availability.general_results,
		loading: state.availability.is_loading
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(Availability);