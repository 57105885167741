import {connect} from 'react-redux';
import EditRegion from './edit_region';
import { getRegion, regionEditPerformed } from '../../../../../actions/region';

function mapsDispatchToProps (dispatch) {
    return {
        getRegion: (id) => dispatch(getRegion(id)),
        onRegionSubmitted: (regionFormData, id) => dispatch(regionEditPerformed(regionFormData, id))
    }
}

function mapsStateToProps (state) {
    return {
        region: state.region.region_detail,
        loading: state.region.is_loading,
        errors: state.region.edit_errors
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditRegion);