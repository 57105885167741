import {
	LOCK_AVAILABILITY_CREATE_ERROR,
	LOCK_AVAILABILITY_CREATE_SUCCESS,
	FETCH_LOCK_AVAILABILITY_SUCCESS,
	GET_LOCK_AVAILABILITY_DETAIL_SUCCESS,
	LOCK_AVAILABILITY_EDIT_ERRORS,
	LOCK_AVAILABILITY_EDIT_SUCCESS,
	GO_TO_GAME_PAGE_ORG_SUCCESS,
	RESET_LOCK_GAME,
	SET_SELECTED_ORG
} from '../actions/lock_availability';

const initialState = {
	lock_results: [],
	form_success: false,
	lock_errors: {},
	lock_detail: {},
	lock_edit_errors: {},
	lock_edit_form_success: false,
	lock_game: [],
	specific_game: [],
	selected_org: null
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		// case GENERAL_AVAILABILITY_CREATE_SUCCESS:
		// 	return {
		// 		...state,
		// 		form_success: actions.status,
		// 		lock_errors: {}
		// 	};
		// case GENERAL_AVAILABILITY_CREATE_ERROR:
		// 	return {
		// 		...state,
		// 		lock_errors: actions.errors
		// 	}
		case LOCK_AVAILABILITY_CREATE_ERROR:
			return {
				...state,
				lock_errors: actions.errors
			}
		case LOCK_AVAILABILITY_CREATE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				lock_errors: {}
			};
		case FETCH_LOCK_AVAILABILITY_SUCCESS:
			return {
				...state,
				lock_results: actions.results
			};
		case GET_LOCK_AVAILABILITY_DETAIL_SUCCESS:
			return {
				...state,
				lock_detail: actions.result,
			}
		case LOCK_AVAILABILITY_EDIT_ERRORS:
			return {
				...state,
				lock_edit_errors: actions.errors
			}
		case LOCK_AVAILABILITY_EDIT_SUCCESS:
			return {
				...state,
				lock_edit_form_success: actions.status
			}
		case GO_TO_GAME_PAGE_ORG_SUCCESS:
			return {
				...state,
				specific_game: actions.results
			}
		case SET_SELECTED_ORG:
			return {
				...state,
				selected_org: actions.id
			}
		case RESET_LOCK_GAME:
			return {
				...state,
				specific_game: [],
				selected_org: null
			}
		default:
			return state;
	}
}