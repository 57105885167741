import {connect} from 'react-redux';
import AbilityPage from './ability';
import {fetchLeaguesPositions, fetchAbilities,updateOfficialAbilityPerformed} from '../../../../../actions/ability';

function mapsDispatchToProps (dispatch) {
    return {
        fetchLeaguesPositions: () => dispatch(fetchLeaguesPositions()),
        fetchAbilities: (id) => dispatch(fetchAbilities(id)),
        onSubmitPositions: (formData, user_id) => dispatch(updateOfficialAbilityPerformed(formData, user_id))
    }
}

function mapsStateToProps (state) {
    return {
        leagues: state.ability.leagues,
        positions: state.ability.positions,
        loading: state.ability.is_loading,
        abilities: state.ability.abilities
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(AbilityPage);