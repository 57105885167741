import {
	OFFICIAL_ERROR,
	OFFICIAL_LOADER_SHOW,
	OFFICIAL_ALERT_ERROR,
	OFFICIAL_SUCCESS,
	FETCH_OFFICIAL_SUCCESS,
	FETCH_USERDATA_SUCCESS,
	GET_OFFICIAL_DETAIL_SUCCESS,
	BULK_IMPORT_SUCCESS,
	OFFICIAL_EDIT_ERROR,
	OFFICIAL_EDIT_SUCCESS,
	PROFILE_PHOTO_LOADER_SHOW,
	BANK_EDIT_SUCCESS,
	BANK_EDIT_ERROR,
	GET_BANK_DETAIL_SUCCESS,
	GET_PROFILE_PIC_SUCCESS,
	PROFILE_PHOTO_UPLOAD_SUCCESS,
	SET_PENDING_USER_LOADER,
	GET_PENDING_USER_SUCCESS,
	SET_CONFLICTS_GENERAL_DATA,
	SET_CONFLICTS_USER_DATA,
	SET_CONFLICTS_LOADER,
	RESET_CONFLICTS_DATA,
	SET_CONFLICTS_MODAL_LOADER,
	GET_ALL_AVAILABLE_OFFICIAL_SUCCESS
} from '../actions/official';

const initialState = {
	results: [],
	userdata: [],
	official_detail: {},
	errors: {},
	is_loading: false,
	alert_error_msg: '',
	is_alert_error: false,
	form_success: false,
	bulk_import_success: false,
	success_msg: '',
	edit_errors: {},
	edit_success: false,
	is_profile_photo_loading: false,
	bank_errors: {},
	bank_success: false,
	bank_detail: {},
	profile_pic: '',
	profile_pic_success: false,
	pending_user_list: [],
	pending_user_loader: false,
	resend_invite_loader: false,
	conflictsGeneralData: [],
	conflictsUserData: [],
	conflictsLoader: false,
	conflictsModalLoader: false,
	allAvailableOfficials: [],
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case OFFICIAL_SUCCESS:
			return {
				...state,
				form_success: true,
				success_msg: actions.msg,
				errors: {}
			};
		case FETCH_OFFICIAL_SUCCESS:
			return {
				...state,
				results: actions.results
			};
		case FETCH_USERDATA_SUCCESS:
			return {
				...state,
				userdata: actions.userdata
			};
		case GET_OFFICIAL_DETAIL_SUCCESS:
			return {
				...state,
				official_detail: actions.official
			};
		case OFFICIAL_ERROR:
			return {
				...state,
				errors: actions.errors
			};
		case OFFICIAL_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case OFFICIAL_ALERT_ERROR:
			return {
				...state,
				alert_error_msg: actions.error,
				is_alert_error: actions.status,
				errors: {}
			};
		case BULK_IMPORT_SUCCESS:
			return {
				...state,
				bulk_import_success: actions.status
			}
		case OFFICIAL_EDIT_ERROR:
			return {
				...state,
				edit_errors: actions.errors
			}
		case OFFICIAL_EDIT_SUCCESS:
			return {
				...state,
				edit_success: actions.status
			}
		case PROFILE_PHOTO_LOADER_SHOW:
			return {
				...state,
				is_profile_photo_loading: actions.status
			}
		case BANK_EDIT_SUCCESS:
			return {
				...state,
				bank_success: actions.status,
				bank_errors: {}
			}
		case BANK_EDIT_ERROR:
			return {
				...state,
				bank_errors: actions.errors
			}
		case GET_BANK_DETAIL_SUCCESS:
			return {
				...state,
				bank_detail: actions.bank_detail
			}
		case GET_PROFILE_PIC_SUCCESS:
			return {
				...state,
				profile_pic: actions.profile_pic
			}
		case PROFILE_PHOTO_UPLOAD_SUCCESS:
			return {
				...state,
				profile_pic_success: actions.status
			}
		case GET_PENDING_USER_SUCCESS:
			return {
				...state,
				pending_user_list: actions.results
			}
		case SET_PENDING_USER_LOADER:
			return {
				...state,
				pending_user_loader: actions.status
			}
		case SET_CONFLICTS_GENERAL_DATA:
			return {
				...state,
				conflictsGeneralData: actions.results
			}
		case SET_CONFLICTS_USER_DATA:
			return {
				...state,
				conflictsUserData: actions.results
			}
		case SET_CONFLICTS_LOADER:
			return {
				...state,
				conflictsLoader: actions.status
			}
		case RESET_CONFLICTS_DATA:
			return {
				...state,
				conflictsGeneralData: [],
				conflictsUserData: []
			}
		case SET_CONFLICTS_MODAL_LOADER:
			return {
				...state,
				conflictsModalLoader: actions.status
			}
		case GET_ALL_AVAILABLE_OFFICIAL_SUCCESS:
			return {
				...state,
				allAvailableOfficials: actions.results
			}
		default:
			return state;
	}
}