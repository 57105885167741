import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import RegisterReducer from './register';
import LoginReducer from './login';
import OfficialReducer from './official';
import GameReducer from './game';
import LeagueReducer from './league';
import VenueReducer from './venue';
import RegionReducer from './region';
import TeamReducer from './team';
import PatternReducer from './pattern';
import PayScaleReducer from './payscale';
import GameFeeReducer from './gamefee';
import AvailabilityReducer from './availability';
import LockAvailabilityReducer from './lock_availability';
import ForgotReducer from './forgot';
import AbilityReducer from './ability';
import TravelClaimReducer from './travel_claim';
import DashbordReducer from './dashboard';
import PerformanceReportReducer from './performance_report';
import featuresReducer from './features';
import reportsReducer from './reports';
import billingReducer from './billing';
import loaderReducer from './loader';
import acknowledgementsReducer from './acknowledgements';

export default combineReducers({
	router: routerReducer,
	register: RegisterReducer,
	login: LoginReducer,
	official: OfficialReducer,
	game: GameReducer,
	league: LeagueReducer,
	venue: VenueReducer,
	region: RegionReducer,
	team: TeamReducer,
	pattern: PatternReducer,
	payscale: PayScaleReducer,
	gamefee: GameFeeReducer,
	availability: AvailabilityReducer,
	lock_availability: LockAvailabilityReducer,
	forgot: ForgotReducer,
	ability: AbilityReducer,
	travel_claim: TravelClaimReducer,
	dashboard: DashbordReducer,
	performance_report: PerformanceReportReducer,
	features: featuresReducer,
	reports: reportsReducer,
	billing: billingReducer,
	loader: loaderReducer,
	acknowledgements: acknowledgementsReducer
});