import {connect} from 'react-redux';
import NewLeaguePage from './new_league';
import {leaguePerformed} from '../../../../../actions/league';

function mapsDispatchToProps (dispatch) {
    return {
        onLeagueSubmitted: (leagueFormData) => dispatch(leaguePerformed(leagueFormData))
    };
}

function mapsStateToProps (state) {
    return {
        errors: state.league.errors,
        loading: state.league.is_loading,
        form_success: state.league.form_success,
    };
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewLeaguePage);