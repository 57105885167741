import React, {Component} from 'react';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import withAuth from '../../../../AuthHOC/withAuth';

class EditPayScale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scale_name: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getPayScale(this.props.id);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.payscale !== this.props.payscale) {
            this.setState({ scale_name: this.props.payscale.data.name });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const scale_name = this.state;

        const data = scale_name;
        this.props.onPayScaleSubmitted(data, this.props.id);
    }

    render () {
        let payscale_name_error = null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'scale_name')
                    payscale_name_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
            <form className="organisation-paysacle-form">
                { this.props.loading && <Loader /> }
                <div className="form-group">
                    <label className="label">Name of PayScale</label>
                    <div className="input-effect-1">
                        <input type="text" name="scale_name" placeholder="Name of PayScale" className="form-control" value={this.state.scale_name} onChange={event => this.handleChange(event)} />
                        <div className="focus-border"></div>
                    </div>
                    {payscale_name_error}
                </div>
                <div className="action text-center">
                    <button type="button" className="btn btn-orange" onClick={this.handleSubmit}>Edit PayScale</button>
                </div>
            </form>
        );
    }
}

export default withAuth(EditPayScale, ['administrator']);

EditPayScale.propTypes = {
    loading: PropTypes.bool,
    errors: PropTypes.object,
    getPayScale: PropTypes.func.isRequired,
    onPayScaleSubmitted: PropTypes.func.isRequired,
    payscale: PropTypes.object.isRequired
}