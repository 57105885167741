import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers';
// import { createLogger } from 'redux-logger';
import rootSaga from './sagas/index';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import App from './components/app/app.container';
import ReactGA from 'react-ga';

const sagas = createSagaMiddleware();
const history = createBrowserHistory();

const store = createStore(
	connectRouter(history)(reducer),
	applyMiddleware(
		routerMiddleware(history),
		// createLogger(),
		sagas
	)
);
sagas.run(rootSaga);

ReactGA.initialize('UA-126739185-3');
history.listen(location => {
	ReactGA.pageview(location.pathname + location.search);
});

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'));
