export const CLEAR_BILLING_DATA = 'CLEAR_BILLING_DATA';
export const TOGGLE_BILLING_LOADER = 'TOGGLE_BILLING_LOADER';
export const TOGGLE_MODAL_BILLING_LOADER = 'TOGGLE_MODAL_BILLING_LOADER';
export const FETCH_SUBSCRIPTION_INFO = 'FETCH_SUBSCRIPTION_INFO';
export const FETCH_SUBSCRIPTION_INFO_SUCCESS = 'FETCH_SUBSCRIPTION_INFO_SUCCESS';
export const FETCH_INVOICES_DATA = 'FETCH_INVOICES_DATA';
export const FETCH_INVOICES_DATA_SUCCESS = 'FETCH_INVOICES_DATA_SUCCESS';
export const FETCH_CARD_DATA = 'FETCH_CARD_DATA';
export const FETCH_CARD_DATA_SUCCESS = 'FETCH_CARD_DATA_SUCCESS';
export const UPDATE_CARD = 'UPDATE_CARD';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const UPGRADE_USER = 'UPGRADE_USER';
export const GET_TRIAL_END = 'GET_TRIAL_END';
export const SHOW_TRIAL_BANNER = 'SHOW_TRIAL_BANNER';
export const CLOSE_TRIAL_BANNER = 'CLOSE_TRIAL_BANNER';

export const clearBillingData = () => ({
	type: CLEAR_BILLING_DATA
});

export const toggleBillingLoader = status => ({
	type: TOGGLE_BILLING_LOADER,
	status
});

export const toggleModalBillingLoader = status => ({
	type: TOGGLE_MODAL_BILLING_LOADER,
	status
});

export const fetchSubscriptionInfo = () => ({
	type: FETCH_SUBSCRIPTION_INFO
});

export const fetchSubscriptionInfoSuccess = results => ({
	type: FETCH_SUBSCRIPTION_INFO_SUCCESS,
	results
});

export const fetchInvoicesData = () => ({
	type: FETCH_INVOICES_DATA
});

export const fetchInvoicesDataSuccess = results => ({
	type: FETCH_INVOICES_DATA_SUCCESS,
	results
});

export const fetchCardData = () => ({
	type: FETCH_CARD_DATA
});

export const fetchCardDataSuccess = results => ({
	type: FETCH_CARD_DATA_SUCCESS,
	results
});

export const updateCard = (token, callback) => ({
	type: UPDATE_CARD,
	token,
	callback
});

export const downloadInvoice = (id, name, date) => ({
	type: DOWNLOAD_INVOICE,
	id,
	name,
	date
});

export const upgradeUser = (qty, callback) => ({
	type: UPGRADE_USER,
	qty,
	callback
});

export const getTrialEnd = () => ({
	type: GET_TRIAL_END
});

export const showTrialBanner = results => ({
	type: SHOW_TRIAL_BANNER,
	results
});

export const closeTrialBanner = () => ({
	type: CLOSE_TRIAL_BANNER
});