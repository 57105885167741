export const FETCH_PAYSCALES_PERFORMED = 'FETCH_PAYSCALES_PERFORMED';
export const FETCH_PAYSCALES_SUCCESS = 'FETCH_PAYSCALES_SUCCESS';
export const PAYSCALE_LOADER_SHOW = 'PAYSCALE_LOADER_SHOW';
export const PAYSCALE_CREATE_PERFORMED = 'PAYSCALE_CREATE_PERFORMED';
export const PAYSCALE_CREATE_SUCCESS = 'PAYSCALE_CREATE_SUCCESS';
export const PAYSCALE_CREATE_ERROR = 'PAYSCALE_CREATE_ERROR';
export const PAYSCALE_DELETE_PERFORMED = 'PAYSCALE_DELETE_PERFORMED';
export const GET_PAYSCALE_DETAIL = 'GET_PAYSCALE_DETAIL';
export const GET_PAYSCALE_DETAIL_SUCCESS = 'GET_PAYSCALE_DETAIL_SUCCESS';
export const PAYSCALE_EDIT_PERFORMED = 'PAYSCALE_EDIT_PERFORMED';
export const PAYSCALE_EDIT_ERROR = 'PAYSCALE_EDIT_ERROR';
export const PAYSCALE_EDIT_SUCCESS = 'PAYSCALE_EDIT_SUCCESS';

export const fetchPayScalesPerformed = () => ({
    type: FETCH_PAYSCALES_PERFORMED,
});

export const fetchPayScalesSuccess = (results) => ({
    type: FETCH_PAYSCALES_SUCCESS,
    results
});

export const payscaleLoaderStatus = (status) => ({
	type: PAYSCALE_LOADER_SHOW,
	status
});

export const payscaleCreatePerformed = (payscaleFormData) => ({
    type: PAYSCALE_CREATE_PERFORMED,
    payscaleFormData
});

export const payscaleCreateSuccess = (status) => ({
    type: PAYSCALE_CREATE_SUCCESS,
    status
});

export const payscaleCreateErrors = (errors) => ({
    type: PAYSCALE_CREATE_ERROR,
	errors
});

export const deletePayScale = (id) => ({
    type: PAYSCALE_DELETE_PERFORMED,
    id
});

export const getPayScale = (id) => ({
    type: GET_PAYSCALE_DETAIL,
    id
});

export const getPayScaleSuccess = (payscale) => ({
    type: GET_PAYSCALE_DETAIL_SUCCESS,
    payscale
});

export const payscaleEditPerformed = (formData, id) => ({
    type: PAYSCALE_EDIT_PERFORMED,
    formData,
    id
});

export const payscaleEditErrors = (errors) => ({
    type: PAYSCALE_EDIT_ERROR,
	errors
});

export const payscaleEditSuccess = (status) => ({
    type: PAYSCALE_EDIT_SUCCESS,
    status
});
