import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';

export default class VenueResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			region_list: []
		}
		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.regionName = this.regionName.bind(this);
	}
	componentWillMount() {
		this.props.fetchVenues();
		this.props.getRegionsForVenue();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			region_list: nextProps.region_list.data
		})
	}

	handleEdit = (id) => {
		this.props.onOpenEditModal(id);
	}

	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.removeVenue(id);
			}
		})
	}

	regionName(id) {
		if (id != null) {
			if (this.state.region_list != null) {
				const { region_list } = this.state;
				const verifiedData = Array.isArray(region_list) ? region_list : Object.keys(region_list).map(obj => region_list[obj]);
				return verifiedData.length > 0 ? verifiedData.filter(obj => obj.id === id)[0].name : '';
			}
		} else {
			return '';
		}
	}


	render() {
		const data = this.props.venues || [];
		const verifiedData = Array.isArray(data) ? data : Object.keys(data).map(obj => data[obj]);
		const columns = [{
			Header: 'Name',
			accessor: 'venue_name' // String-based value accessors!
		}, {
			Header: 'Location',
			accessor: 'venue_location',
		},
		{
			Header: 'Region',
			Cell: row => (
				<p>{this.regionName(row.original.region)}</p>
			)
		}, {
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li>
							<button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button>
						</li>
					</ul>
				</div>
			)
		}];
		return (
			<div className="table-area">
				{this.props.loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={verifiedData}
					columns={columns}
					noDataText="No Venues Found."
					defaultPageSize={10}
					resizable={true}
					minRows={5}
				/>
			</div>
		);
	}
}

VenueResults.propTypes = {
	Venues: PropTypes.arrayOf(
		PropTypes.shape({
			venue_name: PropTypes.string,
			venue_location: PropTypes.string,
		}).isRequired
	),
	fetchVenues: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	form_success: PropTypes.bool,
	removeVenue: PropTypes.func.isRequired,
	is_deleted: PropTypes.bool,
	edit_success: PropTypes.bool
}