import {
	SHOW_LOADER,
	HIDE_LOADER,
	SHOW_FULL_SIZE_LOADER,
	HIDE_FULL_SIZE_LOADER
} from '../actions/loader';

const initialState = {
	showLoader: 0,
	showFullSizeLoader: 0
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case SHOW_LOADER:
			return {
				...state,
				showLoader: state.showLoader + 1
			};
		case HIDE_LOADER:
			return {
				...state,
				showLoader: state.showLoader - 1
			};
		case SHOW_FULL_SIZE_LOADER:
			return {
				...state,
				showFullSizeLoader: state.showFullSizeLoader + 1
			}
		case HIDE_FULL_SIZE_LOADER:
			return {
				...state,
				showFullSizeLoader: state.showFullSizeLoader - 1
			}
		default:
			return state;
	}
}
