import {connect} from 'react-redux';
import GameEditPage from './game_edit';
import { fetchGamesData, getGameDetail, resetData, gameEditPerformed} from '../../../../../actions/game';

function mapsDispatchToProps (dispatch) {
    return {
        resetData: () => dispatch(resetData()),
        fetchGamesdata: () => dispatch(fetchGamesData()),
        fetchGame: (id) => dispatch(getGameDetail(id)),
        onGameSubmitted: ({id, data}) => dispatch(gameEditPerformed({id, formData: data})) 
    }
}

function mapsStateToProps (state) {
    return {
        teams: state.game.teams,
        leagues: state.game.leagues,
        venues: state.game.venues,
        genders: state.game.genders,
        patterns: state.game.patterns,
        game_loading: state.game.is_loading,
        errors: state.game.create_errors,
        form_success: state.game.form_success,
        game: state.game.game_detail
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameEditPage);