import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './league_list.scss';
import LeaguesResults from '../league-results/league_results.container';
import Modal from 'react-responsive-modal';
import NewLeaguePage from '../new-league/new_league.container';
import EditLeague from '../edit-league/edit_league.container';
import PropTypes from 'prop-types';
import withAuth from '../../../../AuthHOC/withAuth';

class LeaguesListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			editModalOpen: false,
			id: ''
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
	}

	onOpenModal = () => {
		this.setState({open: true});
	}

	onCloseModal = () => {
		this.setState({open: false});
	}

	onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.form_success === true)
		{
			this.setState({open: false});
		}
		if(nextProps.edit_success === true)
		{
			this.setState({editModalOpen: false});
		}
	}
    render () {
		const { open, editModalOpen } = this.state;
        return (
            <div className="leagues">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">League</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">League</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						<button onClick={this.onOpenModal}className="btn btn-orange">Create League</button>
					</div>
				</div>
				<div className="box">
					<div className="row">
						<div className="col-xl-3 col-lg-4 col-md-12 col-12">
							<div className="organisation-logo mb-sm-30">
								<div className="logo-wrap">Organisation Logo</div>
							</div>
						</div>
						<LeaguesResults />
					</div>
				</div>
				<Modal
					open={open}
					onClose={this.onCloseModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">League</h5>
								<button type="button" className="close" onClick={this.onCloseModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<NewLeaguePage />
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={editModalOpen}
					onClose={this.onCloseEditModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">League</h5>
								<button type="button" className="close" onClick={this.onCloseEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<EditLeague id={this.state.id} />
							</div>
						</div>
					</div>
				</Modal>
			</div>
        );
    }
}

LeaguesListPage.propTypes = {
	form_success: PropTypes.bool
}
export default withAuth(LeaguesListPage,['administrator']);