import { connect } from 'react-redux';
import { registerPerformed, registerCaptcha } from '../../actions/register';
import RegisterForm from './register-form';

function mapDispatchToProps(dispatch) {
	return {
		onSignupSubmitted: (formData, verified) => dispatch(registerPerformed(formData, verified)),
		checkRecaptcha: (status) => dispatch(registerCaptcha(status))
	};
}

function mapsStateToProps(state) {
	return {
		errors: state.register.errors,
		loading: state.register.is_loading,
		alert_error_msg: state.register.alert_error_msg,
		alert_error_show: state.register.is_alert_error,
		alert_success_msg: state.register.alert_success_msg,
		alert_success_show: state.register.is_alert_success,
		verified: state.register.is_verified,
	};
}

export default connect(() => (mapsStateToProps), mapDispatchToProps)(RegisterForm);