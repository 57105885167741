import {connect} from 'react-redux';
import NewGamePage from './new-game';
import {fetchGamesData, gameCreatePerformed} from '../../../../../actions/game';

function mapsDispatchToProps (dispatch) {
    return {
        fetchGamesdata: () => dispatch(fetchGamesData()),
        onGameSubmitted: (formData) => dispatch(gameCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        teams: state.game.teams,
        leagues: state.game.leagues,
        venues: state.game.venues,
        genders: state.game.genders,
        patterns: state.game.patterns,
        game_loading: state.game.is_loading,
        errors: state.game.create_errors,
        form_success: state.game.form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewGamePage);