import React from 'react';
import './page-404.scss';
import errorPage from '../../../public/images/page-404.jpg';

export default () => {
    return (
        <div className="page-404">
        	<div className="wrap">
        		<div className="text">
        			<img src={errorPage} alt="page 404" />
        		</div>
        	</div>
        </div>
    );
}