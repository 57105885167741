import { connect } from 'react-redux';
import EditLeague from './edit_league';
import { getLeague, leagueEditPerformed, getListCustomReport } from '../../../../../actions/league';

function mapsDispatchToProps(dispatch) {
	return {
		getLeague: (id) => dispatch(getLeague(id)),
		onLeagueSubmitted: (leagueFormData, customReportData, id) => dispatch(leagueEditPerformed(leagueFormData, customReportData, id)),
		getListCustomReport: () => dispatch(getListCustomReport())
	}
}

function mapsStateToProps(state) {
	return {
		league: state.league.league_detail,
		loading: state.league.is_loading,
		errors: state.league.edit_errors,
		list_custom_report: state.league.list_custom_report
	};
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditLeague);