import {connect} from 'react-redux';
import Form from './form';
import {specificAvailabilityEditPerformed, fetchSpecificAvailabilityDetail} from '../../../../../../actions/availability';

function mapsDispatchToProps (dispatch) {
    return {
        onAvailabilitySubmitted: (formData, id) => dispatch(specificAvailabilityEditPerformed(formData, id)),
        fetchAvailability: (id) => dispatch(fetchSpecificAvailabilityDetail(id))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.availability.is_loading,
        form_success: state.availability.specific_edit_form_success,
        errors: state.availability.specific_edit_errors,
        availability: state.availability.specific_detail
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(Form);