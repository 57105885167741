import {connect} from 'react-redux';
import EditPhoto from './edit_photo';
import { profilePhotoUpload, getProfilePicPerformed } from '../../../../../actions/official';

function mapsDispatchToProps (dispatch) {
    return {
        onSubmitPhoto: (formData) => dispatch(profilePhotoUpload(formData)),
        getprofilePic: () => dispatch(getProfilePicPerformed())
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.official.is_profile_photo_loading,
        profile_pic: state.official.profile_pic,
        profile_pic_success: state.official.profile_pic_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditPhoto);