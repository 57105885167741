import React, {Component} from 'react';
import userPic from '../../../../../public/images/e-logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default class EditPhoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: userPic,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 200,
            height: 200,
            windowWidth: 0,
            windowHeight: 0,
            editClicked: false
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    handleNewImage = e => {
        this.setState({ image: e.target.files[0] });
    };

    handleSave = data => {
        if(typeof(this.state.image) === 'string'){
            swal(
                'Error',
                'Please upload new photo',
                'error'
            );
        }
        else
        {
            const img = this.editor.getImageScaledToCanvas().toDataURL();
            const rect = this.editor.getCroppingRect();
            this.props.onSubmitPhoto({
                image: isNaN(rect.x) ? "" : img,
            });
            // this.setState({
            // preview: {
            //     img,
            //     rect,
            //     scale: this.state.scale,
            //     width: this.state.width,
            //     height: this.state.height,
            //     borderRadius: 50
            // },
            // //remove preview altogether?
            // editClicked: false
            // });
        }
    };

    handleScale = e => {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    };

    rotateLeft = e => {
        e.preventDefault();
        this.setState({
          rotate: this.state.rotate - 90
        });
    };

    rotateRight = e => {
        e.preventDefault();
        this.setState({
            rotate: this.state.rotate + 90
        });
    };

    logCallback(e) {
        console.log("callback", e);
    }

    setEditorRef = editor => {
        if (editor) this.editor = editor;
    };

    handlePositionChange = position => {
        this.setState({ position });
    };

    handleDrop = acceptedFiles => {
        this.setState({ image: acceptedFiles[0] });
    };

    updateDimensions() {
        var newWidth =
            window.innerWidth < 765
            ? window.innerWidth * 0.4
            : window.innerWidth * 0.12;
        if (newWidth > 200) newWidth = 150;
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.window.innerHeight,
            width: newWidth,
            height: newWidth,
            borderRadius: newWidth / 4
        });
    }
    componentWillMount() {
        this.props.getprofilePic();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.profile_pic !== '')
        {
            this.setState({
                image: nextProps.profile_pic
            });
        }

        if(nextProps.profile_pic_success === true)
        {
            this.setState({
                editClicked: false,
                image: nextProps.profile_pic
            });
        }
    }
    componentDidMount() {
        this.setState({
            image: this.props.profile_pic
        });
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        }
        componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render () {
        const user = JSON.parse(localStorage.getItem('user')).user;
        if (this.state.editClicked) {
            return (
                <div className="col-xl-3 col-lg-5 col-md-12 col-12">
                    {this.props.loading && <Loader />}
                    <div className="edit-photo">
                    <div className="thumb">
                    <div className="avatar avatar-large avatar-round">
                    <Dropzone
                        onDrop={this.handleDrop}
                        disableClick
                        multiple={false}
                        style={{
                        width: this.state.width,
                        height: this.state.height,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "35px"
                        }}
                    >
                        <ReactAvatarEditor
                            ref={this.setEditorRef}
                            scale={parseFloat(this.state.scale)}
                            width={this.state.width}
                            height={this.state.height}
                            position={this.state.position}
                            onPositionChange={this.handlePositionChange}
                            rotate={parseFloat(this.state.rotate)}
                            borderRadius={this.state.width / (100 / 50)}
                            onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
                            onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
                            onImageReady={this.logCallback.bind(this, "onImageReady")}
                            image={this.state.image}
                            className="editor-canvas"
                        />
                    </Dropzone>
                    </div>
                    </div>
                    <div className="choose-file">
                        {/* <Input
                        name="newImage"
                        className="fileInput"
                        type="file"
                        accept="image/*"
                        onChange={this.handleNewImage}
                        /> */}
                        <input type="file" name="newImage" className="" onChange={this.handleNewImage} />
                    </div>
                    <div className="zoomDiv">
                        <label>{`Zoom:`}</label>
                        <input
                        name="scale"
                        className="custom-range"
                        type="range"
                        onChange={this.handleScale}
                        min="1"
                        max="2"
                        step="0.01"
                        defaultValue="1"
                        />
                    </div>
                    <div className="rotate">
                        <label>{`Rotate:   `}</label>
                        <button className="btn mr_15 btn-orange" onClick={this.rotateLeft}>
                        Left
                        </button>
                        <button className="btn btn-right btn-orange" onClick={this.rotateRight}>
                        Right
                        </button>
                    </div>
                    <div className="submit-action">
                        <input
                            type="button"
                            className="btn btn-orange"
                            onClick={this.handleSave}
                            value="SAVE"
                        />
                    </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="col-xl-3 col-lg-5 col-md-12 col-12">
                    <div className="edit-photo">
                        <div className="d-flex align-items-center h-100">
                            <div className="wrap">
                                <div className="thumb">
                                    <div className="avatar avatar-large avatar-round">
                                        {!!this.state.preview && (
                                            <img src={this.state.preview.img} alt="avatar"/>
                                        )}
                                        {!this.state.preview && (
                                            <img src={this.state.image ? this.state.image : userPic} alt="avatar"/>
                                        )}
                                    </div>
                                </div>
                                <div className="edit-photo-name">
                                    <div className="name">{user.name}</div>
                                    <div className="action">
                                        <button className="text" style={{ cursor: "pointer" }} onClick={() => this.setState({editClicked: true})}>
                                        {/* <button className="text" style={{ cursor: "pointer" }}> */}
                                            <FontAwesomeIcon icon={faPencilAlt} className="fa fa-pencil"/>
                                            <span className="text">Edit Photo</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

EditPhoto.propTypes = {
    onSubmitPhoto: PropTypes.func.isRequired,
    loading: PropTypes.bool
}