import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_PERFORMANCE_REPORT_LIST,
	SAVE_PERFORMANCE_REPORT_PERFORMED,
	PUBLISH_PERFORMANCE_REPORT_PERFORMED,
	fetchPerformanceReportListSuccess,
	fetchPerformanceReportList,
	fetchPerformanceReportLoading,
} from '../actions/performance_report';
import { API_BASE_URL, getHeaders, getOrgId } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { handleError } from '../actions/handleError';
import moment from 'moment';


function* fetchList({filters}) {
	yield put(fetchPerformanceReportLoading(true));
	try {
		let params = new URLSearchParams();
		console.log('filters', filters);
		if(filters != null){
			Object.keys(filters).forEach((key)=>{
				params.append(key,filters[key]);
			})
		}
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/custom/get`,
			{ headers: getHeaders(), 
			
			params: params,
			}
		);
		const teams = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/teams`,
			{
				headers: getHeaders()
			}
		);
		console.log('teams', teams);

		const leagues = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/leagues`,
			{
				headers: getHeaders()
			}
		);
		console.log('leaguesData', leagues);

		const venues = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/venues`,
			{
				headers: getHeaders()
			}
		);
		console.log('venuesData', venues);
		
		yield put(fetchPerformanceReportLoading(false));
		yield put(fetchPerformanceReportListSuccess(results.data.reports, teams.data, leagues, venues));
	} catch (error) {
		yield put(fetchPerformanceReportLoading(false));
		yield put(handleError(error));
	}
}

function* savePerformanceReport({ id, data }) {
	yield put(fetchPerformanceReportLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance`,
			data, { headers: getHeaders() }
		);
		yield put(fetchPerformanceReportLoading(false));
		
		swal('Success', results.data.Message, 'success');
		const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		if(rememberedFilters == null){
			yield put(fetchPerformanceReportList());
		}else{
			yield put(fetchPerformanceReportList(generateParams(rememberedFilters)));
		}
	} catch (error) {
		yield put(fetchPerformanceReportLoading(false));
		yield put(handleError(error));
	}
}
const generateParams = filters => {
	let date;
	if (filters.chooseDate === 'date-range') {
		date = {
			daterange: filters.date_range === 0 ? null : filters.date_range,
			game_date: null
		}
	} else {
		date = {
			daterange: null,
			game_date: filters.game_date == null ? null : moment(filters.game_date).format('YYYY-MM-DD'),
		}
	}
	let obj = {
		game_time: filters.game_time === 0 ? null : filters.game_time
	};
	let newobj = { ...obj, ...date};
	let params = new URLSearchParams();
		Object.keys(newobj).forEach(key =>{
			
			if(newobj[key]!= null){
				params.append(key,newobj[key]);
			}
		});
		return params;
};

function* publishPerformanceReport({ id, data }) {
	yield put(fetchPerformanceReportLoading(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/${id}/customperformance/publish`,
			data, { headers: getHeaders() }
		);
		yield put(fetchPerformanceReportLoading(false));
	
		swal('Success', results.data.Message, 'success');
		const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		if(rememberedFilters == null){
			yield put(fetchPerformanceReportList());
		}else{
			yield put(fetchPerformanceReportList(generateParams(rememberedFilters)));
		}
		
	} catch (error) {
		yield put(fetchPerformanceReportLoading(false));
		yield put(handleError(error));
	}
}


export default function* () {
	yield takeLatest(FETCH_PERFORMANCE_REPORT_LIST, fetchList);
	yield takeLatest(SAVE_PERFORMANCE_REPORT_PERFORMED, savePerformanceReport);
	yield takeLatest(PUBLISH_PERFORMANCE_REPORT_PERFORMED, publishPerformanceReport);
}