import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { LEAGUE_PERFORMED,
	LEAGUE_EDIT_PERFORMED,
	FETCH_LEAGUES_PERFORMED,
	GET_LEAGUE_DETAIL,
	LEAGUE_PERFORMED_DELETE,
	GET_LIST_CUSTOM_REPORT,
	leagueLoaderStatus,
	fetchLeaguesSuccess,
	leagueSuccess,
	leagueErrors,
	leagueEditErrors,
	getLeagueSuccess,
	leagueDeleteSuccess,
	fetchLeaguesPerformed,
	getListCustomReportSuccess
} from '../actions/league';
import { push } from 'connected-react-router';
import { API_BASE_URL } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchLeagues() {
	yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(leagueLoaderStatus(false));
		yield put(fetchLeaguesSuccess(results.data));
	} catch (error) {
		yield put(leagueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* doCreateNewLeague({ leagueFormData }) {
	yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const leagueResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues`,
			leagueFormData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(leagueLoaderStatus(false));
		swal(
			'Success',
			leagueResults.data.message,
			'success'
		);
		yield put(leagueSuccess(true));
		// yield put(push('/officials'));
	} catch (error) {
		yield put(leagueLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(leagueErrors(error.response.data.errors));
		}
		if (error.response.status === 409) {
			swal(
				'Error',
				error.response.data.message,
				'error'
			);
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
}

function* doEditLeague({ leagueFormData, customReportData, id }) {
	yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues/${id}/report`,
			customReportData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		const leagueResults = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues/${id}`,
			leagueFormData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(leagueLoaderStatus(false));
		swal(
			'Success',
			leagueResults.data.message,
			'success'
		);
		yield put(push('/organisation/leagues'));
	} catch (error) {
		yield put(leagueLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(leagueEditErrors(error.response.data.errors));
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
}

function* fetchLeague({ id }) {
	yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(leagueLoaderStatus(false));
		yield put(getLeagueSuccess(results.data));
	} catch (error) {
		yield put(leagueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* doRemoveLeague({ id }) {
	yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(leagueLoaderStatus(false));
		yield put(leagueDeleteSuccess(true));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchLeaguesPerformed());
	} catch (error) {
		yield put(leagueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* fetchCustomReportList() {
	// yield put(leagueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/reports/custom`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		// yield put(leagueLoaderStatus(false));
		yield put(getListCustomReportSuccess(results.data.reports));
	} catch (error) {
		// yield put(leagueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

export default function* () {
	yield takeLatest(FETCH_LEAGUES_PERFORMED, fetchLeagues);
	yield takeLatest(LEAGUE_PERFORMED, doCreateNewLeague);
	yield takeLatest(LEAGUE_EDIT_PERFORMED, doEditLeague);
	yield takeLatest(LEAGUE_PERFORMED_DELETE, doRemoveLeague);
	yield takeLatest(GET_LEAGUE_DETAIL, fetchLeague);
	yield takeLatest(GET_LIST_CUSTOM_REPORT, fetchCustomReportList);
}