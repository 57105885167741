export const FORGOT_PERFORMED = 'FORGOT_PERFORMED';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';
export const RESET_PERFORMED = 'RESET_PERFORMED';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';
export const FORGOT_LOADER_SHOW = 'FORGOT_LOADER_SHOW';

export const forgotLoaderStatus = (status) => ({
	type: FORGOT_LOADER_SHOW,
	status
});

export const forgotPerformed = (formData) => ({
	type: FORGOT_PERFORMED,
	formData
});

export const forgotSuccess = (status) => ({
	type: FORGOT_SUCCESS,
	status
});

export const forgotError = (errors) => ({
	type: FORGOT_ERROR,
	errors
});

export const resetPerformed = (formData) => ({
	type: RESET_PERFORMED,
	formData
});

export const resetSuccess = (status) => ({
	type: RESET_SUCCESS,
	status
});

export const resetError = (errors) => ({
	type: RESET_ERROR,
	errors
});
