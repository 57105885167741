import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import GameFeeResults from '../gamefee-results/gamefee_results.container';
import withAuth from '../../../../AuthHOC/withAuth';

class GameFeeList extends Component {

    render() {
        return (
            <div className="organization-gamefee">
                <div className="page-head mb_20">
                    <div className="head">
                        <h1 className="page-title">Default Game Fees</h1>
                        <nav>
                            <ol className="breadcrumb page-title">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/organisation/payscales">PayScales</Link>
                                </li>
                                <li className="breadcrumb-item current">Game Fees</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <GameFeeResults leagueId={this.props.match.params.id}/>
            </div>
        )
    }
}

export default withAuth(GameFeeList,"administrator");