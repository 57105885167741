import {
	FETCH_CUSTOM_REPORTS_LIST_SUCCESS,
	SET_REPORTS_LOADER,
	FETCH_CUSTOM_REPORTS_DETAILS_SUCCESS,
	FETCH_DECLINED_REPORT_SUCCESS,
	FETCH_BANK_REPORT_SUCCESS,
	FETCH_ALLUSERS_REPORT_SUCCESS,
	FETCH_CUSTOM_GAME_REPORTS_LIST_SUCCESS,
	FETCH_CUSTOM_GAME_REPORTS_DETAILS_SUCCESS
} from '../actions/reports';

const initialState = {
	data: [],
	loader: false,
	detail: {},
	declined_data: [],
	bank_data: [],
	allUsers_data:[],
	gamereport: [],
	gamedetail: {}
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_CUSTOM_REPORTS_LIST_SUCCESS:
			return {
				...state,
				data: actions.results
			};
		case SET_REPORTS_LOADER:
			return {
				...state,
				loader: actions.status
			};
		case FETCH_CUSTOM_REPORTS_DETAILS_SUCCESS:
			return {
				...state,
				detail: actions.results
			};
		case FETCH_DECLINED_REPORT_SUCCESS:
			return {
				...state,
				declined_data: actions.results
			};
		case FETCH_BANK_REPORT_SUCCESS:
			return {
				...state,
				bank_data: actions.results
			};
			case FETCH_ALLUSERS_REPORT_SUCCESS:
				return{
					...state,
					allUsers_data:actions.results
				};
				
		case FETCH_CUSTOM_GAME_REPORTS_LIST_SUCCESS:
			return {
				...state,
				gamereport: actions.results
			};
		case FETCH_CUSTOM_GAME_REPORTS_DETAILS_SUCCESS:
			return {
				...state,
				gamedetail: actions.results
			};
		default:
			return state;
	}
}