import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_GAMEFEES_PERFORMED,
    GAMEFEE_EDIT_PERFORMED,
    fetchGameFeesPerformed,
    fetchGameFeesSuccess,
    gamefeeLoaderStatus,
    gamefeeEditSuccess,
    gamefeeEditErrors
} from '../actions/gamefee';
import { push } from 'connected-react-router';
import {API_BASE_URL} from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchGameFees({leagueId}) {
    yield put(gamefeeLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales/fees/${leagueId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(gamefeeLoaderStatus(false));
		yield put(fetchGameFeesSuccess(results.data));
    }catch (error) {
        yield put(gamefeeLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* doEditGameFee({formData, id}) {
	yield put(gamefeeLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const results = yield call(
            axios.put,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales/fees/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(gamefeeLoaderStatus(false));
        swal(
            'Success',
            results.data.message,
            'success'
        );
        yield put(gamefeeEditSuccess(true));
        yield put(fetchGameFeesPerformed(id));
    }catch (error) {
		yield put(gamefeeLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(gamefeeEditErrors(error.response.data.errors));
        }
        if(error.response.status === 409)
        {
            swal(
                'Error',
                error.response.data.message,
                'error'
            );
        }
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
    }
}

export default function* () {
    yield takeLatest(FETCH_GAMEFEES_PERFORMED, fetchGameFees);
    yield takeLatest(GAMEFEE_EDIT_PERFORMED, doEditGameFee);
}
