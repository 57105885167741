import React, { Component } from 'react';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class TravelClaimResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpened: false,
			distance_travelled: '',
			travelled_from: ''
		}

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleApprove = this.handleApprove.bind(this);
		this.handleDecline = this.handleDecline.bind(this);
	}

	openModal(data) {
		this.setState({ 
			modalOpened : true,
			distance_travelled: data.claim.distance_travelled,
			travelled_from: data.claim.travelled_from,
			claim_id: data.claim.id
		});
	}

	closeModal() {
		this.setState({ modalOpened: false }, () => {
			setTimeout(() => {
				this.setState({
					distance_travelled: '',
					travelled_from: '',
					claim_id: ''
				});
			}, 300);
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		let value;
		if (name === 'distance_travelled') {
			value = Number(target.value);
		} else {
			value = target.value;
		}

		this.setState({
				[name]: value
		});
	}

	handleSubmit() {
		let { distance_travelled, travelled_from, claim_id } = this.state;
		let data = { distance_travelled, travelled_from };
		this.props.editTravelClaim(claim_id, data);
	}

	handleApprove(id) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Approve!'
		}).then((result) => {
			if (result.value) {
				let data = {claims: [id]}
				this.props.approveTravelClaim(data);
			}
		})
	}

	handleDecline(id) {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Decline!'
		}).then((result) => {
			if (result.value) {
				let data = {claims: [id]}
				this.props.declineTravelClaim(data);
			}
		})
	}

	componentWillMount() {
		this.props.fetchTravelClaim();
	}

	render() {
		const data = this.props.data;
		const columns = [{
			Header: 'Match',
			minWidth: 250,
			Cell: row => (
				<div className="match">
					<span className="highlight">{row.original.game.home_team && row.original.game.home_team.team_name} vs {row.original.game.away_team && row.original.game.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game.game_time, "HH:mm").format("LT")}</span></li>
						</ul>
					</div>
					<div className="actions">
						<ul className="list-inline">
							<li><button className="link" onClick={() => this.openModal(row.original)}>Edit</button></li>
							<li><span className="seprator">|</span></li>
							<li><Link to={`/game/${row.original.game.id}/detail`}><button className="link">View Game</button></Link></li>
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Official Name',
			accessor: 'user.name'
		}, {
			Header: 'Distance Travelled',
			accessor: 'claim.distance_travelled'
		}, {
			Header: 'Travelled From',
			accessor: 'claim.travelled_from'
		}, {
			Header: 'League',
			accessor: 'game.league.league_name'
			
		}, {
			Header: 'Actions',
			minWidth: 180,
			Cell: row => (
				row.original.claim.status === 'Pending'
				? (<div className="actions">
						<button type="button" className="btn btn-success mb-3" style={{ marginRight: '10px' }} onClick={() => this.handleApprove(row.original.claim.id)}>Approve</button>
						<button type="button" className="btn btn-danger mb-3" onClick={() => this.handleDecline(row.original.claim.id)}>Decline</button>
					</div>)
				: row.original.claim.status === 'Approved' ? 'Travel Claim Approved' : 'Travel Claim Declined'
			)
		}];

		let { fetch_loader, edit_loader } = this.props;
		return (
			<div className="table-area">
				{fetch_loader && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					columns={columns}
					noDataText="No Travel Claim Found."
					defaultPageSize={10}
					resizable={true}
					minRows={5}
					getTrProps={(state, rowInfo, column) => {
						if (rowInfo && rowInfo.row) {
							return {
								style: {
									backgroundColor: rowInfo.original.claim.status === "Pending" ? "#f6e58d" : rowInfo.original.claim.status === "Approved" ? "#badc58" : "#fab1a0"
								}
							}
						} else {
							return {}
						}
					}}
				/>

				<Modal
					open={this.state.modalOpened}
					onClose={this.closeModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Travel Claim</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body" style={{position: 'relative'}}>
							{edit_loader && <Loader />}
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Distance Travelled</label>
											<div className="input-effect-1">
												<input type="number" name="distance_travelled" onChange={(event) => this.handleInputChange(event)} value={this.state.distance_travelled} placeholder="Distance Travelled" className="form-control" />
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Travelled From</label>
											<div className="input-effect-1">
												<input type="text" name="travelled_from" onChange={(event) => this.handleInputChange(event)} value={this.state.travelled_from} placeholder="Travelled From" className="form-control" />
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
										<button type="button" onClick={this.handleSubmit} className="btn btn-orange mt-2">Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}