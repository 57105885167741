export const FETCH_PATTERNS_PERFORMED = 'FETCH_PATTERNS_PERFORMED';
export const FETCH_PATTERNS_SUCCESS = 'FETCH_PATTERNS_SUCCESS';
export const PATTERN_LOADER_SHOW = 'PATTERN_LOADER_SHOW';
export const PATTERN_CREATE_PERFORMED = 'PATTERN_CREATE_PERFORMED';
export const PATTERN_CREATE_SUCCESS = 'PATTERN_CREATE_SUCCESS';
export const PATTERN_CREATE_ERROR = 'PATTERN_CREATE_ERROR';
export const PATTERN_DELETE_PERFORMED = 'PATTERN_DELETE_PERFORMED';
export const GET_PATTERN_DETAIL = 'GET_PATTERN_DETAIL';
export const GET_PATTERN_DETAIL_SUCCESS = 'GET_PATTERN_DETAIL_SUCCESS';
export const PATTERN_EDIT_PERFORMED = 'PATTERN_EDIT_PERFORMED';
export const PATTERN_EDIT_ERROR = 'PATTERN_EDIT_ERROR';
export const PATTERN_EDIT_SUCCESS = 'PATTERN_EDIT_SUCCESS';

export const fetchPatternsPerformed = () => ({
    type: FETCH_PATTERNS_PERFORMED,
});

export const fetchPatternsSuccess = (results) => ({
    type: FETCH_PATTERNS_SUCCESS,
    results
});

export const patternLoaderStatus = (status) => ({
	type: PATTERN_LOADER_SHOW,
	status
});

export const patternCreatePerformed = (patternFormData) => ({
    type: PATTERN_CREATE_PERFORMED,
    patternFormData
});

export const patternCreateSuccess = (status) => ({
    type: PATTERN_CREATE_SUCCESS,
    status
});

export const patternCreateErrors = (errors) => ({
    type: PATTERN_CREATE_ERROR,
	errors
});

export const deletePattern = (id) => ({
    type: PATTERN_DELETE_PERFORMED,
    id
});

export const getPattern = (id) => ({
    type: GET_PATTERN_DETAIL,
    id
});

export const getPatternSuccess = (pattern) => ({
    type: GET_PATTERN_DETAIL_SUCCESS,
    pattern
});

export const patternEditPerformed = ({formData, id}) => ({
    type: PATTERN_EDIT_PERFORMED,
    formData,
    id  
});

export const patternEditErrors = (errors) => ({
    type: PATTERN_EDIT_ERROR,
	errors
});

export const patternEditSuccess = (status) => ({
    type: PATTERN_EDIT_SUCCESS,
    status
});