import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import PayScaleResults from '../payscale-results/payscale_results.container';
import EditPayScale from '../edit-payscale/edit_payscale.container';
import NewPayScale from '../new-payscale/new_payscale.container';
import withAuth from '../../../../AuthHOC/withAuth';

class PayScalesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editModalOpen: false,
			createModalOpen: false,
			id: ''
		}
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
		this.onOpenCreateModal = this.onOpenCreateModal.bind(this);
		this.onCloseCreateModal = this.onCloseCreateModal.bind(this);
    }

    onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
    }
	
	onOpenCreateModal() {
		this.setState({createModalOpen: true});
	}

	onCloseCreateModal() {
		this.setState({createModalOpen: false});
	}

    render () {
    	const { createModalOpen, editModalOpen } = this.state;
        return (
            <div className="organization-payscales">
                <div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title">Pay Scales</h1>
                        <nav>
                            <ol className="breadcrumb page-title">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item current">Pay Scales</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="actions">
                        <button className="btn btn-orange" onClick={this.onOpenCreateModal}>Add New PayScale</button>
                    </div>
                </div>
				<div className="box">
					<PayScaleResults onOpenEditModal={this.onOpenEditModal} />
					<Modal
						open={editModalOpen}
						onClose={this.onCloseEditModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-md modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Edit PayScale</h5>
									<button type="button" className="close" onClick={this.onCloseEditModal}>
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<EditPayScale id={this.state.id} />
								</div>
							</div>
						</div>
					</Modal>
					<Modal
						open={createModalOpen}
						onClose={this.onCloseCreateModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-md modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Create PayScale</h5>
									<button type="button" className="close" onClick={this.onCloseCreateModal}>
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<NewPayScale />
								</div>
							</div>
						</div>
					</Modal>
				</div>
            </div>
        );
    }
}

export default withAuth(PayScalesList,['administrator']);
