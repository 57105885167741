import {connect} from 'react-redux';
import AcceptToken from './accept_token';
import {acceptToken} from '../../../actions/register';

function mapsDispatchToProps (dispatch) {
    return {
        doAcceptToken: (formData) => dispatch(acceptToken(formData))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.register.is_loading,
        errors: state.register.accept_errors,
        form_success: state.register.form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(AcceptToken);