import {connect} from 'react-redux';
import Form from './form';
import {generalAvailabilityCreatePerformed} from '../../../../../../actions/availability';

function mapsDispatchToProps (dispatch) {
    return {
        onAvailabilitySubmitted: (formData) => dispatch(generalAvailabilityCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.availability.is_loading,
        form_success: state.availability.form_success,
        errors: state.availability.general_errors,
        availabilities: state.availability.general_results,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(Form);