import React, {Component} from 'react';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import classNames from 'classnames';
import powerByGoogle from '../../../../../public/images/powered_by_google_default.png';

export default class NewVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            location: '',
            errorMessage:'',
            latitude: '',
            longitude: '',
            isGeocoding:false,
            region_id: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleChange = location => {
        this.setState({
            location,
        });
    };

    async handleSubmit(event) {
        event.preventDefault();
        const {
            name,
            location,
            latitude,
            longitude,
            region_id
        } = this.state;

        let data = {
            venue_name: name,
            venue_location: location,
            venue_coords: latitude ? latitude + ', ' + longitude : '',
        }

        if (region_id) {
            data.region = Number(region_id);
        }

        this.props.onVenueSubmitted(data);
    }

    handleSelect = selected => {
        this.setState({ isGeocoding: true, location: selected });
        geocodeByAddress(selected)
          .then(res => getLatLng(res[0]))
          .then(({ lat, lng }) => {
            this.setState({
              latitude: lat,
              longitude: lng,
              isGeocoding: false,
            });
          })
          .catch(error => {
            this.setState({ isGeocoding: false });
            console.log('error', error); // eslint-disable-line no-console
          });
    }

    handleCloseClick = () => {
        this.setState({
          location: '',
          latitude: null,
          longitude: null,
        });
    }

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
          clearSuggestions();
        });
    }

    componentWillMount() {
        this.props.getRegionsForVenue();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                name: '',
                location: '',
                latitude: '',
                longitude: '',
                errorMessage:'',
                isGeocoding:false,
                region_id: ''
            });
        }
    }

    render () {
        let name_error = null;
        let location_error= null;
        if(this.props.errors !== undefined) {
            for (var key in this.props.errors) {
                if(key === 'venue_name')
                    name_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'venue_location')
                    location_error = <FormError error={this.props.errors[key][0]} />;
            }
        }

        let regions = this.props.region_list.data;
        let verifiedRegions = Array.isArray(regions) ? regions : Object.keys(regions).map(obj => regions[obj]);
        let optionRegionItems = verifiedRegions.map((region) =>
            <option key={region.id} value={region.id}>{region.name}</option>
        );
        const {
            location,
            errorMessage
          } = this.state;
        return (
            <div className="new-venue">
                { this.props.loading && <Loader /> }
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Venue Name</label>
                            <div className="input-effect-1">
                                <input type="text" name="name" placeholder="Venue Name" value={this.state.name} onChange={event => {this.handleInputChange(event)}} className="form-control" />
                                <div className="focus-border"></div>
                            </div>
                            {name_error}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Region</label>
                            <div className="input-effect-1">
                                <select name="region_id" value={this.state.region_id} onChange={event => { this.handleInputChange(event) }} className="normal-select">
                                    <option value="">Select Region</option>
                                    {optionRegionItems}
                                </select>
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="form-group">
                            <label className="label">Location</label>
                            <div className="input-effect-1">
                                {/* <input type="text" name="location" placeholder="Enter Location" value={this.state.location} onChange={event => {this.handleInputChange(event)}} className="form-control" /> */}
                                <PlacesAutocomplete
                                    onChange={this.handleChange}
                                    value={location}
                                    onSelect={this.handleSelect}
                                    onError={this.handleError}
                                    shouldFetchSuggestions={location.length > 2}
                                    >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                                        return (
                                        <div className="Demo__search-bar-container">
                                            <div className="Demo__search-input-container">
                                            <input
                                                {...getInputProps({
                                                placeholder: 'Enter Location',
                                                className: 'Demo__search-input form-control',
                                                })}
                                            />
                                            {this.state.location.length > 0 && (
                                                <button
                                                className="Demo__clear-button"
                                                onClick={this.handleCloseClick}
                                                >
                                                x
                                                </button>
                                            )}
                                            </div>
                                            {suggestions.length > 0 && (
                                            <div className="Demo__autocomplete-container">
                                                {suggestions.map(suggestion => {
                                                const className = classNames('Demo__suggestion-item', {
                                                    'Demo__suggestion-item--active': suggestion.active,
                                                });

                                                return (
                                                    /* eslint-disable react/jsx-key */
                                                    <div
                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                    >
                                                    <strong>
                                                        {suggestion.formattedSuggestion.mainText}
                                                    </strong>{' '}
                                                    <small>
                                                        {suggestion.formattedSuggestion.secondaryText}
                                                    </small>
                                                    </div>
                                                );
                                                /* eslint-enable react/jsx-key */
                                                })}
                                                <div className="Demo__dropdown-footer">
                                                <div>
                                                    <img
                                                    alt="logo"
                                                    src={powerByGoogle}
                                                    className="Demo__dropdown-footer-image"
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                        );
                                    }}
                                </PlacesAutocomplete>

                                <div className="focus-border"></div>
                            </div>
                            {errorMessage.length > 0 && (
                                <div className="Demo__error-message">{this.state.errorMessage}</div>
                            )}
                            {location_error}
                        </div>
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Start Co-ordinates</label>
                            <div className="input-effect-1">
                                <input type="text" name="start" placeholder="start" value={this.state.start} onChange={event => {this.handleInputChange(event)}} className="form-control" />
                                <div className="focus-border"></div>
                            </div>
                            {coords_error}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">End Co-ordinates</label>
                            <div className="input-effect-1">
                                <input type="text" name="end" placeholder="End" value={this.state.end} onChange={event => {this.handleInputChange(event)}} className="form-control" />
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="action text-center">
                    <button type="button" onClick={this.handleSubmit} className="btn btn-orange">Save Venue</button>
                </div>
            </div>
        );
    }
}

NewVenue.propTypes = {
    onVenueSubmitted: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    form_success: PropTypes.bool,
}