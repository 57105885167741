import { connect } from 'react-redux';
import GameListPage from './game_list';
import { fetchGamesPerformed, fetchGamesData, publishGames, deleteGames } from '../../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		fetchGames: (filters, sortBy) => dispatch(fetchGamesPerformed(filters, sortBy)),
		fetchGamesdata: () => dispatch(fetchGamesData()),
		publishGames: (data) => dispatch(publishGames(data)),
		deleteGames: (data) => dispatch(deleteGames(data)),
	}
}

function mapsStateToProps(state) {
	return {
		teams: state.game.teams,
		leagues: state.game.leagues,
		venues: state.game.venues,
		genders: state.game.genders,
		patterns: state.game.patterns,
		game_loading: state.game.is_loading,
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameListPage);