export const FETCH_LEAGUES_POSITIONS = 'FETCH_LEAGUES_POSITIONS';
export const FETCH_LEAGUES_POSITIONS_SUCCESS = 'FETCH_LEAGUES_POSITIONS_SUCCESS';
export const FETCH_ABILITIES = 'FETCH_ABILITIES';
export const FETCH_ABILITIES_SUCCESS = 'FETCH_ABILITIES_SUCCESS';
export const UPDATE_OFFICIAL_ABILITY_PERFORMED = 'UPDATE_OFFICIAL_ABILITY_PERFORMED';

export const fetchLeaguesPositions = () => ({
	type: FETCH_LEAGUES_POSITIONS
});

export const fetchLeaguesPositionsSuccess = (results) => ({
	type: FETCH_LEAGUES_POSITIONS_SUCCESS,
	results
});

export const fetchAbilities = (id) => ({
	type: FETCH_ABILITIES,
	id
});

export const fetchAbilitiesSuccess = (results) => ({
	type: FETCH_ABILITIES_SUCCESS,
	results
});

export const updateOfficialAbilityPerformed = (formData, user_id) => ({
	type: UPDATE_OFFICIAL_ABILITY_PERFORMED,
	formData,
	user_id
});

