import {
	GAME_LOADER_SHOW,
	FETCH_GAMES_SUCCESS,
	FETCH_GAMES_DATA_SUCCESS,
	GAME_CREATE_SUCCESS,
	GAME_EDIT_SUCCESS,
	GAME_EDIT_ERROR,
	GAME_CREATE_ERROR,
	GET_GAME_DETAIL_SUCCESS,
	GET_REFEREES_SUCCESS,
	ASSIGN_REFEREE_LOADER_SHOW,
	GET_ASSIGN_REFEREES_SUCCESS,
	FETCH_OFFICIAL_GAMES_SUCCESS,
	ASSIGN_REFEREE_DATA_LOADER_SHOW,
	RESET_DATA_REFEREES,
	PERFORMANCE_REPORT_LOADING,
	GET_TRAVEL_CLAIM_SUCCESS,
	TRAVEL_CLAIM_LOADING,
	FETCH_GAMES_DATA_LOADING,
	CLEAR_BULK_FILES,
	GET_PERFORMANCE_REPORT_SUCCESS,
	FETCH_OFFICIAL_GAMES_DATA_SUCCESS
} from '../actions/game';

const initialState = {
	results: [],
	teams: [],
	leagues: [],
	venues: [],
	genders: [],
	patterns: [],
	is_loading: false,
	form_success: false,
	create_errors: {},
	game_detail: {},
	assign_referee_loading: false,
	assign_referee_data_loading: false,
	referees: {},
	assign_referees: [],
	official_games: [],
	travel_claim: [],
	travel_claim_loading: false,
	fetchGamesDataLoading: false,
	clearBulkFiles: false,
	perf_report_loading: false,
	perf_report_data: null,
	officialGamesData: {}
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case RESET_DATA_REFEREES:
			return {
				...state,
				referees: {},
				assign_referees: [],
				game_detail: {}
			};
		case FETCH_GAMES_SUCCESS:
			return {
				...state,
				results: actions.results
			};
		case FETCH_GAMES_DATA_SUCCESS:
			return {
				...state,
				genders: actions.results.gender_data,
				leagues: actions.results.league_data,
				teams: actions.results.team_data,
				venues: actions.results.venue_data,
				patterns: actions.results.pattern_data
			}
		case GAME_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case GAME_CREATE_SUCCESS:
		case GAME_EDIT_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				create_errors: {}
			};
		case GAME_CREATE_ERROR:
		case GAME_EDIT_ERROR:
			return {
				...state,
				create_errors: actions.errors
			};
		case GET_GAME_DETAIL_SUCCESS:
			//console.log(actions.game);
			//console.log("FILLER");
			return {
				...state,
				game_detail: actions.game
			}
		case GET_REFEREES_SUCCESS:
			return {
				...state,
				referees: actions.results
			}
		case GET_ASSIGN_REFEREES_SUCCESS:
			return {
				...state,
				assign_referees: actions.results
			}
		case ASSIGN_REFEREE_LOADER_SHOW:
			return {
				...state,
				assign_referee_loading: actions.status
			}
		case ASSIGN_REFEREE_DATA_LOADER_SHOW:
			return {
				...state,
				assign_referee_data_loading: actions.status
			}
		case FETCH_OFFICIAL_GAMES_SUCCESS:
			return {
				...state,
				official_games: actions.results
			}
		case PERFORMANCE_REPORT_LOADING:
			return {
				...state,
				perf_report_loading: actions.status
			}
		case GET_TRAVEL_CLAIM_SUCCESS:
			return {
				...state,
				travel_claim: actions.results
			}
		case TRAVEL_CLAIM_LOADING:
			return {
				...state,
				travel_claim_loading: actions.status
			}
		case FETCH_GAMES_DATA_LOADING:
			return {
				...state,
				fetchGamesDataLoading: actions.status
			};
		case CLEAR_BULK_FILES:
			return {
				...state,
				clearBulkFiles: actions.status
			};
		case GET_PERFORMANCE_REPORT_SUCCESS:
			return {
				...state,
				perf_report_data: actions.results
			};
		case FETCH_OFFICIAL_GAMES_DATA_SUCCESS:
			return {
				...state,
				officialGamesData: actions.results
			};
		default:
			return state;
	}
}