import {connect} from 'react-redux';
import PatternResults from './pattern_results';
import {fetchPatternsPerformed, deletePattern} from '../../../../../actions/pattern';


function mapsDispatchToProps (dispatch) {
    return {
        fetchPatterns: () => dispatch(fetchPatternsPerformed()),
        removePattern: (id) => dispatch(deletePattern(id)),
    }
}

function mapsStateToProps (state) {
    return {
        patterns: state.pattern.results,
        loading: state.pattern.is_loading
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(PatternResults);