import { connect } from 'react-redux';
import EditOfficialForm from './edit-official-form';
import {
	fetchUserDataPerformed,
	getOfficial,
	officialEditPerformed
} from '../../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		getOfficial: (id) => dispatch(getOfficial(id)),
		onOfficialSubmitted: (formData, id) => dispatch(officialEditPerformed({ formData, id })),
		fetchUserData: () => dispatch(fetchUserDataPerformed())
	}
}

function mapsStateToProps(state) {
	return {
		official: state.official.official_detail,
		userdata: state.official.userdata,
		loading: state.official.is_loading
	};
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditOfficialForm);