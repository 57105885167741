import React, { Component } from 'react';
import Logo from '../../logo/logo';
import LogoBackground from '../../logo/logo-background';
import Form from './form';
import { Elements, StripeProvider } from 'react-stripe-elements';
import './confirm.scss';
import Loader from '../../loader/loader';


export default class Confirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
		this.setLoader = this.setLoader.bind(this);
	}

	componentWillMount() {
		this.props.authCheck();
	}

	setLoader(val) {
		this.setState({
			loading: val
		})
	}
/*<input id="card-holder-name" type="text"/>
									<div id="card-element"></div>
									<button id="card-button" data-secret="{{$intent->client_secret}}">
										update Payment Method
									</button>
									<script src="https:://js.stripe.com/v3/"></script>
									<script>
										const stripe = stripe('stripe-public-key');
										const elements = stripe.elements();
										const CardElement = elements.create('card');
										CardElement.mount('#card-element');
										</script>	*/	

	render() {
		return (
			<div className="authentication">
				<LogoBackground />
				<div className="confirm-container">
					<div className="confirm-wrap">
						{this.state.loading && <Loader />}
						<Logo />
						<StripeProvider apiKey="pk_test_WVCt47HHMfHAiF7dAJ8mJ5Vq">
							<Elements>
								<Form
									location={this.props.location}
									setLoader={this.setLoader}
									/>
															
							</Elements>
						</StripeProvider>
					</div>
				</div>
			</div>
		);
	}
}