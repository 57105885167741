import React, { Component } from 'react';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Pagination from '../../../../pagination/pagination';
import moment from 'moment';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default class GameResults extends Component {
	constructor(props) {
		super(props);

		this.setAvailable = this.setAvailable.bind(this);
		this.setUnavailable = this.setUnavailable.bind(this);
	}

	setUnavailable(id) {
		swal({
			title: 'Are you sure?',
			text: "Do you want to set yourself Unavailable for this game?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes!'
		}).then((result) => {
			if (result.value) {
				this.props.setGameAvailability(id, -1);
			}
		})
	}

	setAvailable(id) {
		swal({
			title: 'Are you sure?',
			text: "Do you want to set yourself Available for this game?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes!'
		}).then((result) => {
			if (result.value) {
				this.props.setGameAvailability(id, 1);
			}
		})
	}
	render() {
		const data = this.props.specific_game;
		const gender = ["NULL", "MEN", "WOMEN", "BOYS", "GIRLS", "MIXED"];
		const columns = [{
			Header: 'Match',
			minWidth: 250,
			sortable: true,
			Cell: row => (
				<div className="match">
					<span className="highlight">{row.original.home_team && row.original.home_team.team_name} vs {row.original.away_team && row.original.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game_time, "HH:mm").format("LT")}</span></li>
							<li className="list-inline-item"><span className="badge badge-upcoming">Upcoming</span></li>
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Organisation',
			Cell: row => (
				<div>
					{row.original.organisation && row.original.organisation.organisation_name}
				</div>
			)
		}, {
			Header: 'Venue',
			Cell: row => (
				<div className="date-time">
					{row.original.venue && row.original.venue.venue_name}
				</div>
			)
		}, {
			Header: 'League',
			Cell: row => (
				<div className="date-time">
					{row.original.league && row.original.league.league_name}
				</div>
			)
		}, {
			Header: 'Gender',
			Cell: row => (
				<div>
					{gender[row.original.gender]}
				</div>
			)
			}, {
				Header: 'Actions',
				Cell: row => (
					row.original.available[0] === 1
						? <button type="button" className="btn btn-danger" onClick={() => {this.setUnavailable(row.original.id)}}>Unvailable</button>
						: <button type="button" className="btn btn-success" onClick={() => {this.setAvailable(row.original.id)}}>Available</button>
				)
			}];
		return (
			<div className="table-area">
				{this.props.game_loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText="No Games Found."
					defaultPageSize={10}
					pageSizeOptions={[10, 25, 50, 100]}
					resizable={true}
					getTrProps={(state, rowInfo, column) => {
						if (rowInfo && rowInfo.row) {
							return {
								style: {
									backgroundColor: rowInfo.original.available[0] === 1 ? "#badc58" :  "#fab1a0"
								}
							}
						} else {
							return {}
						}
					}}
				/>
			</div>
		);
	}
}