import React, { Component } from 'react';
import Loader from '../../../../loader/loader';

export default class ResetPassword extends Component {
	state = {
		current_password: '',
		new_password: '',
		new_password_confirmation: ''
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	clearField = () => {
		this.setState({
			current_password: '',
			new_password: '',
			new_password_confirmation: ''
		})
	}

	handleSubmit = () => {
		this.props.resetPasswordProfile(this.state, this.clearField);
	}

	render() {
		return (
			<div>
				{this.props.loading && <Loader />}
				<div className="modal-body">
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label className="label">Current Password</label>
								<div className="input-effect-1">
									<input type="password" name="current_password" value={this.state.current_password} className="form-control" onChange={this.handleChange} />
									<div className="focus-border"></div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="label">New Password</label>
								<div className="input-effect-1">
									<input type="password" name="new_password" value={this.state.new_password} className="form-control" onChange={this.handleChange} />
									<div className="focus-border"></div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="label">Confirm New Password</label>
								<div className="input-effect-1">
									<input type="password" name="new_password_confirmation" value={this.state.new_password_confirmation} className="form-control" onChange={this.handleChange} />
									<div className="focus-border"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-orange" onClick={this.handleSubmit}>Reset</button>
				</div>
			</div>
		);
	}
}