import { connect } from 'react-redux';
import Login from './login';
import { authCheck } from '../../../actions/login';

function mapsDispatchToProps(dispatch) {
	return {
		authCheck: () => dispatch(authCheck())
	};
}

export default connect(() => ({}), mapsDispatchToProps)(Login);