export const FETCH_TEAMS_PERFORMED = 'FETCH_TEAMS_PERFORMED';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const TEAM_LOADER_SHOW = 'TEAM_LOADER_SHOW';
export const TEAM_CREATE_PERFORMED = 'TEAM_CREATE_PERFORMED';
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_ERROR = 'TEAM_CREATE_ERROR';
export const TEAM_DELETE_PERFORMED = 'TEAM_DELETE_PERFORMED';
export const GET_TEAM_DETAIL = 'GET_TEAM_DETAIL';
export const GET_TEAM_DETAIL_SUCCESS = 'GET_TEAM_DETAIL_SUCCESS';
export const TEAM_EDIT_PERFORMED = 'TEAM_EDIT_PERFORMED';
export const TEAM_EDIT_ERROR = 'TEAM_EDIT_ERROR';
export const TEAM_EDIT_SUCCESS = 'TEAM_EDIT_SUCCESS';

export const fetchTeamsPerformed = () => ({
    type: FETCH_TEAMS_PERFORMED,
});

export const fetchTeamsSuccess = (results) => ({
    type: FETCH_TEAMS_SUCCESS,
    results
});

export const teamLoaderStatus = (status) => ({
	type: TEAM_LOADER_SHOW,
	status
});

export const teamCreatePerformed = (teamFormData) => ({
    type: TEAM_CREATE_PERFORMED,
    teamFormData
});

export const teamCreateSuccess = (status) => ({
    type: TEAM_CREATE_SUCCESS,
    status
});

export const teamCreateErrors = (errors) => ({
    type: TEAM_CREATE_ERROR,
	errors
});

export const deleteTeam = (id) => ({
    type: TEAM_DELETE_PERFORMED,
    id
});

export const getTeam = (id) => ({
    type: GET_TEAM_DETAIL,
    id
});

export const getTeamSuccess = (team) => ({
    type: GET_TEAM_DETAIL_SUCCESS,
    team
});

export const teamEditPerformed = (teamFormData, id) => ({
    type: TEAM_EDIT_PERFORMED,
    teamFormData,
    id
});

export const teamEditErrors = (errors) => ({
    type: TEAM_EDIT_ERROR,
	errors
});

export const teamEditSuccess = (status) => ({
    type: TEAM_EDIT_SUCCESS,
    status
});
