import React, { Fragment } from 'react';
import './game-list.scss';
import { Link } from 'react-router-dom';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import dateIcon from '../../../../../public/svg/date.svg';
import moment from 'moment';
import GameResults from './game_results/game_results.container';
import classNames from 'classnames';
import withAuth from '../../../../AuthHOC/withAuth';
import swal from 'sweetalert2/dist/sweetalert2.js';
const format = 'YYYY-MM-DD';
const now = moment();

class GameListPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			team_data: [],
			gender_data: [],
			venue_data: [],
			league_data: [],
			pattern_data: [],
			showDateInput: false,
			showAssign: false,
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				pattern: 0,
				official_status: 0,
				chooseDate: 'date-range'
			},
			games: [],
			isFilterActive: false,
			isSortByActive: false,
			sortByFilters: []
		};
		this.toggleBtn = React.createRef();
		this.toggleBtnSortBy = React.createRef();
		this.toggleAssign = this.toggleAssign.bind(this);
		this.onTimeChange = this.onTimeChange.bind(this);
		this.selectedGames = this.selectedGames.bind(this);
		this.handlePublish = this.handlePublish.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		this.props.fetchGamesdata();
		const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		const rememberedSortBy = JSON.parse(localStorage.getItem('sortby'));

		let fetch = [];

		if (rememberedFilters != null) {
			fetch.push(this.generateParams(rememberedFilters));
			this.setState({
				isFilterActive: true,
				filters: rememberedFilters
			});
		} else {
			fetch.push(new URLSearchParams());
			this.setState({
				isFilterActive: false
			});
		}

		if (rememberedSortBy != null) {
			fetch.push(this.generateParamsSortBy(rememberedSortBy));
			this.setState({
				isSortByActive: true,
				sortByFilters: rememberedSortBy
			});
		} else {
			fetch.push(new URLSearchParams());
			this.setState({
				isSortByActive: false
			});
		}

		this.props.fetchGames(...fetch);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.teams !== this.props.teams) {
			this.setState({ team_data: this.props.teams });
		}
		if (prevProps.leagues !== this.props.leagues) {
			this.setState({ league_data: this.props.leagues });
		}
		if (prevProps.venues !== this.props.venues) {
			this.setState({ venue_data: this.props.venues });
		}
		if (prevProps.genders !== this.props.genders) {
			this.setState({ gender_data: this.props.genders });
		}
		if (prevProps.patterns !== this.props.patterns) {
			this.setState({ pattern_data: this.props.patterns });
		}
	}

	selectedGames(data) {
		let games = data.map((data) => {
			return Number(data);
		});
		this.setState({ games: games });
	}

	handlePublish() {
		swal({
			title: 'Are you sure?',
			text: "Do you want to publish selected games?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, publish selected games!'
		}).then((result) => {
			if (result.value) {
				this.props.publishGames({ games: this.state.games });
			}
		})
	}
	handleDelete(){
		swal({
			title: 'Are you sure?',
			text: "Do you want to delete selected games?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Delete selected games!'
		}).then((result)=>{
			if(result.value){
				this.props.deleteGames({ games: this.state.games });
			}
		})
	}

	toggleAssign() {
		this.setState({
			showAssign: !this.state.showAssign
		});
	}

	onTimeChange(value) {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_time: value.format("HH:mm")
				}
			}
		})
	}

	onChange = (value) => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: value
				}
			}
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	handleFilterChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState(state => {
			return {
				filters: {
					...state.filters,
					[name]: value
				}
			}
		});
	}

	onResetFilter = () => {
		const { sortByFilters, isSortByActive } = this.state;
		this.setState({
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				pattern: 0,
				official_status: 0,
				chooseDate: 'date-range'
			},
			isFilterActive: false
		}, () => {
			localStorage.removeItem('filters');
		});
		this.toggleBtn.click();

		let fetch = [];
		fetch.push(new URLSearchParams());

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		} else {
			fetch.push(new URLSearchParams());
		}
		
		this.props.fetchGames(...fetch);
	}

	onApplyFilter = () => {
		const { filters, sortByFilters, isSortByActive } = this.state;
		localStorage.setItem('filters', JSON.stringify(filters));
		this.toggleBtn.click();
		this.setState({
			isFilterActive: true
		})

		let fetch = [];
		fetch.push(this.generateParams(filters));

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		}

		this.props.fetchGames(...fetch);
	};

	generateParams = filters => {
		let date;
		if (filters.chooseDate === 'date-range') {
			date = {
				daterange: filters.date_range === 0 ? null : filters.date_range,
				game_date: null
			}
		} else {
			date = {
				daterange: null,
				game_date: filters.game_date == null ? null : moment(filters.game_date).format(format),
			}
		}

		let obj = {
			venue_id: filters.venue === 0 ? null : filters.venue,
			league_id: filters.league === 0 ? null : filters.league,
			gender: filters.gender === 0 ? null : filters.gender,
			game_time: filters.game_time === 0 ? null : filters.game_time,
			home_team_id: filters.home_team === 0 ? null : filters.home_team,
			away_team_id: filters.away_team === 0 ? null : filters.away_team,
			pattern: filters.pattern === 0 ? null : filters.pattern,
			status: filters.official_status === 0 ? null : filters.official_status
		};

		let newObj = {...obj, ...date};

		let params = new URLSearchParams();
		Object.keys(newObj).forEach(key => {
			if (newObj[key] != null) {
				params.append(key, newObj[key]);
			}
		});

		return params;
	};

	handleClearCalender = () => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: undefined
				}
			}
		});
	}

	sortByOptions = () => {
		return (
			<Fragment>
				<option value="0">Unselect</option>
				<option value="1" disabled={this.state.sortByFilters.includes("1")}>Date, newest to oldest</option>
				<option value="2" disabled={this.state.sortByFilters.includes("2")}>Date oldest to newest</option>
				<option value="3" disabled={this.state.sortByFilters.includes("3")}>Time newest to oldest</option>
				<option value="4" disabled={this.state.sortByFilters.includes("4")}>Time oldest to newest</option>
				<option value="5" disabled={this.state.sortByFilters.includes("5")}>Venue A- Z</option>
				<option value="6" disabled={this.state.sortByFilters.includes("6")}>Venue Z - A</option>
				<option value="7" disabled={this.state.sortByFilters.includes("7")}>League A - Z</option>
				<option value="8" disabled={this.state.sortByFilters.includes("8")}>League Z - A</option>
			</Fragment>
		)
	};

	handleSortBy = (e, index) => {
		let newArr = [...this.state.sortByFilters];

		if (Number(e.target.value) === 0) {
			newArr.splice(index, 1);
		} else {
			newArr[index] = e.target.value;
		}

		this.setState({
			sortByFilters: newArr
		});
	}

	generateParamsSortBy = sortBy => {
		let params = new URLSearchParams();
		sortBy.forEach((value, key) => {
			params.append(`sortby[${key}]`, value);
		});
		return params;
	}

	onApplySortBy = () => {
		const { filters, sortByFilters, isFilterActive } = this.state;

		localStorage.setItem('sortby', JSON.stringify(sortByFilters));
		this.toggleBtnSortBy.click();
		this.setState({
			isSortByActive: true
		});

		let params = new URLSearchParams();
		this.state.sortByFilters.forEach(value => {
			params.append('sortby', value);
		});

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}
		fetch.push(this.generateParamsSortBy(sortByFilters));
		this.props.fetchGames(...fetch);
	};

	onResetSortBy = () => {
		const { filters, isFilterActive } = this.state;
		this.setState({
			sortByFilters: [],
			isSortByActive: false
		}, () => {
			localStorage.removeItem('sortby');
		});
		this.toggleBtnSortBy.click();

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}

		fetch.push(new URLSearchParams());
		this.props.fetchGames(...fetch);
	};

	render() {
		const state = this.state;
		const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={state.showDateInput}
			showClear={true}
		/>);

		const optionGender = this.state.gender_data.map((gender, key) =>
			<option key={key + 1} value={key + 1}>{gender.Gender_Name}</option>
		);
		const optionTeam = this.state.team_data.map(team =>
			<option key={team.Team_ID} value={team.Team_ID}>{team.Team_Name}</option>
		);
		
		const optionVenue = this.state.venue_data.map(venue =>
			<option key={venue.Venue_ID} value={venue.Venue_ID}>{venue.Venue_Name}</option>
		);
		const optionPattern = this.state.pattern_data.map(pattern =>
			<option key={pattern.Pattern_ID} value={pattern.Pattern_ID}>{pattern.Pattern_Name}</option>
		);
		const optionLeague = this.state.league_data.map(league =>
			<option key={league.League_ID} value={league.League_ID}>{league.League_Name}</option>
		);
		const params = new URLSearchParams(this.props.location.search);
		const status = params.get('status');
		const daterange = params.get('daterange');
		return (
			<div className="game-list-page">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title" onClick={this.props.showLoader}>Games</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">All Games</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						<Link to="/games/create">
							<button className="btn btn-orange">Add New Game</button>
						</Link>
					</div>
				</div>
				<div className="actions mb_15">
					<ul className="list-inline">
						<li className="list-inline-item mb_15">
							<button className="btn btn-orange" onClick={this.handlePublish} disabled={this.state.games.length < 1} >Publish</button>
						</li>
						<li className="list-inline-item mb 15">
							<button className="btn btn-orange" onClick={this.handleDelete} disabled={this.state.games.length<1}>Delete</button>
						</li>
						<li className="list-inline-item mb_15">
							<button
								ref={(node) => this.toggleBtnSortBy = node}
								className={classNames('btn', { 'btn-standard': !this.state.isSortByActive }, { 'btn-active': this.state.isSortByActive })}
								data-toggle="modal"
								data-target="#setup"
							>
								View Setup {this.state.isSortByActive && '(Active)'}
							</button>
						</li>
						<li className="list-inline-item mb_15">
							<button
								ref={(node) => this.toggleBtn = node}
								className={classNames('btn', { 'btn-standard': !this.state.isFilterActive }, { 'btn-active': this.state.isFilterActive })}
								data-toggle="modal"
								data-target="#filter"
							>
								Filter {this.state.isFilterActive && 'is Active'}
							</button>
						</li>
						<li className="list-inline-item mb_15">
							<button className="btn btn-black" onClick={this.toggleAssign}>{this.state.showAssign ? 'Hide' : 'Show'} Bulk Allocate</button>
						</li>
					</ul>
					<div className="modal fade" id="filter">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Filter</h5>
									<button type="button" className="close" data-dismiss="modal">
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Venue</label>
												<div className="input-effect-1">
													<select value={this.state.filters.venue} name="venue" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Venue</option>
														{optionVenue}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">League</label>
												<div className="input-effect-1">
													<select value={this.state.filters.league} name="league" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select League</option>
														{optionLeague}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Gender</label>
												<div className="input-effect-1">
													<select value={this.state.filters.gender} name="gender" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Gender</option>
														{optionGender}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center"><input className="mr-1" type="radio" name="chooseDate" value="date-range" checked={state.filters.chooseDate === 'date-range'} onChange={event => this.handleFilterChange(event)} /> Date Range</label>
												<div className="input-effect-1">
													<select className={classNames("normal-select", { "disabled-datepicker": (state.filters.chooseDate !== 'date-range') })} value={this.state.filters.date_range} disabled={(state.filters.chooseDate !== 'date-range')} name="date_range" onChange={event => this.handleFilterChange(event)}>
														<option value={0}>Select Range</option>
														<option value={1}>1 Day</option>
														<option value={3}>3 Days</option>
														<option value={7}>1 Week</option>
														<option value={14}>2 Weeks</option>
														<option value={21}>3 Weeks</option>
														<option value={28}>4 Weeks</option>
														<option value="future">All Future Games</option>
														<option value="past">All Past Games</option>
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center justify-content-between">
													<div>
														<input className="mr-1" type="radio" name="chooseDate" value="game-dates" checked={state.filters.chooseDate === 'game-dates'} onChange={event => this.handleFilterChange(event)} />
														Game Dates
													</div>
													{state.filters.game_date != null && <button className="button-link" onClick={this.handleClearCalender}>Clear</button>}
												</label>
												<div className="input-effect-1">
													<div className={classNames("datepicker", { "disabled-datepicker": (state.filters.chooseDate !== 'game-dates') })} id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={(state.filters.chooseDate !== 'game-dates')}
															calendar={calendar}
															value={state.filters.game_date}
															onChange={this.onChange}
															getCalendarContainer={() => document.getElementById('datepicker')}
															className="custom-calendar"
															showClear={true}
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={(state.filters.chooseDate !== 'game-dates')}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value == null ? '' : moment(value).format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<img src={dateIcon} alt="date" className="imgsvg" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Time</label>
												<div className="timepicker">
													<div className="input-group">
														<TimePicker showSecond={false} value={moment(this.state.filters.game_time, 'HH:mm')} onChange={this.onTimeChange} />
														<div className="input-group-append">
															<span className="input-group-text">
																<img src={dateIcon} alt="date" className="imgsvg" />
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Home Team</label>
												<div className="input-effect-1">
													<select id= "home_team" value={this.state.filters.home_team} name="home_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Home Team</option>
														{optionTeam}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Away Team</label>
												<div className="input-effect-1">
													<select value={this.state.filters.away_team} name="away_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Away Team</option>
														{optionTeam}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Pattern</label>
												<div className="input-effect-1">
													<select value={this.state.filters.pattern} name="pattern" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Pattern</option>
														{optionPattern}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-12 col-lg-12 col-md-12 col-12">
											<div className="form-group">
												<label className="label">Official Status</label>
												<div className="input-effect-1">
													<select value={this.state.filters.official_status} name="official_status" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Status</option>
														<option value="Pending">Pending</option>
														<option value="Confirmed">Confirmed</option>
														<option value="Declined">Declined</option>
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer text-center">
									<button type="button" className="btn btn-orange" onClick={this.onApplyFilter}>Apply</button>
									<button type="button" className="btn btn-orange" onClick={this.onResetFilter}>Reset Filter</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade" id="setup">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">View Setup</h5>
									<button type="button" className="close" data-dismiss="modal">
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Sort By</label>
												<div className="input-effect-1">
													<select
														className="normal-select"
														onChange={ e => this.handleSortBy(e, 0)}
														value={
															this.state.sortByFilters[0] == null
																? 0
																: this.state.sortByFilters[0]
														}
													>
														<this.sortByOptions />
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										{[...Array(5)].map((element, index) => (
											<div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12">
												<div className="form-group">
													<label className="label">Then By</label>
													<div className="input-effect-1">
														<select
															className="normal-select"
															disabled={this.state.sortByFilters.length < (Number(index + 1))}
															onChange={e => this.handleSortBy(e, Number(index + 1))}
															value={
																this.state.sortByFilters[Number(index + 1)] == null
																	? 0
																	: this.state.sortByFilters[Number(index + 1)]
															}
														>
															<this.sortByOptions />
														</select>
														<div className="focus-border"></div>
													</div>
												</div>
											</div>
										))}										
									</div>
								</div>
								<div className="modal-footer text-center">
									<button type="button" className="btn btn-orange" onClick={this.onApplySortBy}>Apply</button>
									<button type="button" className="btn btn-orange" onClick={this.onResetSortBy}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<GameResults
					showAssign={this.state.showAssign}
					official_status={status}
					daterange={daterange}
					selectedGames={this.selectedGames}
				/>
			</div>
		);
	}
}

export default withAuth(GameListPage, ['administrator', 'allocator']);
