import React, { Component } from 'react';

import withAuth from '../../../AuthHOC/withAuth';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { saveAcknowledgement } from '../../../../actions/acknowledgements';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.scss';
import Loading from '../../../loader/loader';
const toolbar = {
	options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link',],
	inline: {
		options: ['bold', 'italic', 'underline', 'strikethrough']
	}
};

class AcknowledgementsNewPage extends Component {
	state = {
		required: true,
		official: true,
		allocator: true,
		administrator: true,
		billing: true,
		coach: true,
		acknowledgement_name: '',
		editorState: EditorState.createEmpty()
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit = () => {
		const { editorState, required, official, allocator, administrator, billing, coach, acknowledgement_name } = this.state;
		const rawObject = convertToRaw(editorState.getCurrentContent());
		const data = {
			required,
			official,
			allocator,
			administrator,
			billing,
			coach,
			acknowledgement_name,
			text: JSON.stringify(rawObject)
		}
		this.props.saveAcknowledgement(data);
	};

	onEditorStateChange = (editorState) => {
		this.setState({ editorState });
	};

	render() {
		return (
			<div className="acknowledgements">
				{this.props.loader && <Loading/>}
				<div className="box">
					<div className="row mb-4">
						<div style={{ paddingLeft: 15 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="requiredCheckBox" name="required" onChange={this.handleInputChange} checked={this.state.required} />
								<label className="custom-control-label" htmlFor="requiredCheckBox">Required</label>
							</div>
						</div>
						<div style={{ paddingLeft: 25 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="officialCheckBox" name="official" onChange={this.handleInputChange} checked={this.state.official} />
								<label className="custom-control-label" htmlFor="officialCheckBox">Official</label>
							</div>
						</div>
						<div style={{ paddingLeft: 25 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="allocatorCheckBox" name="allocator" onChange={this.handleInputChange} checked={this.state.allocator} />
								<label className="custom-control-label" htmlFor="allocatorCheckBox">Allocator</label>
							</div>
						</div>
						<div style={{ paddingLeft: 25 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="administratorCheckBox" name="administrator" onChange={this.handleInputChange} checked={this.state.administrator}/>
								<label className="custom-control-label" htmlFor="administratorCheckBox">Administrator</label>
							</div>
						</div>
						<div style={{ paddingLeft: 25 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="billingCheckBox" name="billing" onChange={this.handleInputChange} checked={this.state.billing} />
								<label className="custom-control-label" htmlFor="billingCheckBox">Billing</label>
							</div>
						</div>
						<div style={{ paddingLeft: 25 }}>
							<div className="custom-control custom-checkbox mr-0">
								<input type="checkbox" className="custom-control-input" id="coachCheckBox" name="coach" onChange={this.handleInputChange} checked={this.state.coach} />
								<label className="custom-control-label" htmlFor="coachCheckBox">Coach</label>
							</div>
						</div>
					</div>
					<div className="form-group mb-4">
						<label className="label">Acknowledgement's Name</label>
						<div className="input-effect-1">
							<input type="text" className="form-control no-transition" name="acknowledgement_name" onChange={this.handleInputChange} value={this.state.acknowledgement_name} placeholder="Acknowledgement's Name" />
							<div className="focus-border"></div>
						</div>
					</div>
					<Editor
						editorState={this.state.editorState}
						toolbar={toolbar}
						wrapperClassName="wrapperCustomEditor"
						editorClassName="draftCustomEditor"
						placeholder="Message"
						onEditorStateChange={this.onEditorStateChange}
					/>
					<div className="action">
						<button type="button" onClick={this.handleSubmit} className="btn btn-orange">Save</button>
					</div>
				</div>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		saveAcknowledgement: (data) => dispatch(saveAcknowledgement(data))
	}
}

function mapsStateToProps(state) {
	return {
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(AcknowledgementsNewPage, ['administrator']));