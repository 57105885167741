export const FETCH_FEATURES_DATA = 'FETCH_FEATURES_DATA';
export const SET_FEATURES_LOADER = 'SET_FEATURES_LOADER';
export const FETCH_FEATURES_DATA_SUCCESS = 'FETCH_FEATURES_DATA_SUCCESS';
export const TOGGLE_REGIONS = 'TOGGLE_REGIONS';
export const TOGGLE_LOCKDATES = 'TOGGLE_LOCKDATES';
export const TOGGLE_GAME_AVAILABILITY = 'TOGGLE_GAME_AVAILABILITY';
export const SET_TOGGLE_LOADER = 'SET_TOGGLE_LOADER';
export const TOGGLE_GAME_REPORTS = 'TOGGLE_GAME_REPORTS';
export const TOGGLE_ACKNOWLEDGEMENTS = 'TOGGLE_ACKNOWLEDGEMENTS';
export const TOGGLE_XEROINTEGRATION='TOGGLE_XEROINTEGRATION';

export const fetchFeaturesData = () => ({
	type: FETCH_FEATURES_DATA
});

export const setFeaturesLoader = (status) => ({
	type: SET_FEATURES_LOADER,
	status
});

export const fetchFeaturesDataSuccess = (results) => ({
	type: FETCH_FEATURES_DATA_SUCCESS,
	results
});

export const toggleRegions = (value) => ({
	type: TOGGLE_REGIONS,
	value
});

export const toggleLockDates = (value) => ({
	type: TOGGLE_LOCKDATES,
	value
});

export const toggleGameAvailability = (value) => ({
	type: TOGGLE_GAME_AVAILABILITY,
	value
});

export const toggleGameReports = (value) => ({
	type: TOGGLE_GAME_REPORTS,
	value
});

export const toggleAcknowledgements = (value) => ({
	type: TOGGLE_ACKNOWLEDGEMENTS,
	value
});
export const toggleXeroIntegration = (value) => ({
	type: TOGGLE_XEROINTEGRATION,
	value
});

export const setToggleLoader = (status) => ({
	type: SET_TOGGLE_LOADER,
	status
});