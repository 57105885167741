import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_VENUES_PERFORMED,
	VENUE_CREATE_PERFORMED,
	VENUE_DELETE_PERFORMED,
	GET_VENUE_DETAIL,
	VENUE_EDIT_PERFORMED,
	GET_REGIONS_FOR_VENUE,
	venueLoaderStatus,
	fetchVenuesSuccess,
	venueCreateSuccess,
	venueCreateErrors,
	venueDeleteSuccess,
	getVenueSuccess,
	venueEditSuccess,
	venueEditErrors,
	fetchVenuesPerformed,
	getRegionsForVenueSuccess
} from '../actions/venue';
import { push } from 'connected-react-router';
import { API_BASE_URL } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchVenues() {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/venues`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		yield put(fetchVenuesSuccess(results.data));
	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* doCreateNewVenue({ venueFormData }) {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const venueResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/venues`,
			venueFormData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		swal(
			'Success',
			venueResults.data.message,
			'success'
		);
		yield put(venueCreateSuccess(true));
		yield put(fetchVenuesPerformed());
	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(venueCreateErrors(error.response.data.errors));
		}
		if (error.response.status === 409) {
			swal(
				'Error',
				error.response.data.message,
				'error'
			);
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
}

function* fetchVenueDetail({ id }) {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/venues/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		yield put(getVenueSuccess(results.data));
	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* doRemoveVenue({ id }) {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/venues/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		yield put(venueDeleteSuccess(true));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchVenuesPerformed());
	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* doUpdateVenue({ venueFormData, id }) {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const venueResults = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/venues/${id}`,
			venueFormData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		swal(
			'Success',
			venueResults.data.message,
			'success'
		);
		yield put(venueEditSuccess(true));
		yield put(fetchVenuesPerformed());

	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(venueEditErrors(error.response.data.errors));
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
}

function* getRegionsForVenue() {
	yield put(venueLoaderStatus(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/regions`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(venueLoaderStatus(false));
		yield put(getRegionsForVenueSuccess(results.data));
	} catch (error) {
		yield put(venueLoaderStatus(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

export default function* () {
	yield takeLatest(FETCH_VENUES_PERFORMED, fetchVenues);
	yield takeLatest(VENUE_CREATE_PERFORMED, doCreateNewVenue);
	yield takeLatest(VENUE_DELETE_PERFORMED, doRemoveVenue);
	yield takeLatest(GET_VENUE_DETAIL, fetchVenueDetail);
	yield takeLatest(VENUE_EDIT_PERFORMED, doUpdateVenue);
	yield takeLatest(GET_REGIONS_FOR_VENUE, getRegionsForVenue);
}