import { connect } from 'react-redux';
import GameDetailPage from './game_detail';
import {
	getGameDetail,
	getAssignReferee,
	resetData,
	getPerformanceReport,
	savePerformanceReport,
	submitPerformanceReport,
	publishPerformanceReport,
	downloadGameVideo
} from '../../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		resetData: () => dispatch(resetData()),
		fetchGame: (id) => dispatch(getGameDetail(id)),
		getAssignReferee: (id) => dispatch(getAssignReferee(id)),
		getPerformanceReport: (id) => dispatch(getPerformanceReport(id)),
		savePerformanceReport: (id, data) => dispatch(savePerformanceReport(id, data)),
		submitPerformanceReport: (id, data) => dispatch(submitPerformanceReport(id, data)),
		publishPerformanceReport: (id, data) => dispatch(publishPerformanceReport(id, data)),
		downloadGameVideo: (id) => dispatch(downloadGameVideo(id))
	}
}

function mapsStateToProps(state) {
	return {
		game: state.game.game_detail,
		loading: state.game.is_loading,
		assign_referees: state.game.assign_referees,
		perf_report_loading: state.game.perf_report_loading,
		perf_report_data: state.game.perf_report_data
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameDetailPage);