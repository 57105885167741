export const LOCK_AVAILABILITY_CREATE_PERFORMED = 'LOCK_AVAILABILITY_CREATE_PERFORMED';
export const LOCK_AVAILABILITY_CREATE_SUCCESS = 'LOCK_AVAILABILITY_CREATE_SUCCESS';
export const LOCK_AVAILABILITY_CREATE_ERROR = 'LOCK_AVAILABILITY_CREATE_ERROR';
export const FETCH_LOCK_AVAILABILITY = 'FETCH_LOCK_AVAILABILITY';
export const FETCH_LOCK_AVAILABILITY_SUCCESS = 'FETCH_LOCK_AVAILABILITY_SUCCESS';
export const LOCK_AVAILABILITY_DELETE_PERFORMED = 'LOCK_AVAILABILITY_DELETE_PERFORMED';
export const FETCH_LOCK_AVAILABILITY_DETAIL = 'FETCH_LOCK_AVAILABILITY_DETAIL';
export const GET_LOCK_AVAILABILITY_DETAIL_SUCCESS = 'GET_LOCK_AVAILABILITY_DETAIL_SUCCESS';
export const LOCK_EDIT_PERFORMED = 'LOCK_EDIT_PERFORMED';
export const LOCK_AVAILABILITY_EDIT_ERRORS = 'LOCK_AVAILABILITY_EDIT_ERRORS';
export const LOCK_AVAILABILITY_EDIT_SUCCESS = 'LOCK_AVAILABILITY_EDIT_SUCCESS';
export const GO_TO_GAME_PAGE_ORG = 'GO_TO_GAME_PAGE_ORG';
export const GO_TO_GAME_PAGE_ORG_SUCCESS = 'GO_TO_GAME_PAGE_ORG_SUCCESS';
export const RESET_LOCK_GAME = 'RESET_LOCK_GAME';
export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_GAME_AVAILABILITY = 'SET_GAME_AVAILABILITY';
export const FETCH_LOCK_GAMES = 'FETCH_LOCK_GAMES';



export const lockAvailabilityCreatePerformed = (formData) => ({
	type: LOCK_AVAILABILITY_CREATE_PERFORMED,
	formData,
	
})


export const lockAvailabilityCreateSuccess = (status) => ({
	type: LOCK_AVAILABILITY_CREATE_SUCCESS,
	status
});

export const lockAvailabilityCreateError = (errors) => ({
	type: LOCK_AVAILABILITY_CREATE_ERROR,
	errors
});

export const fetchLockAvailabilities = () => ({
	type: FETCH_LOCK_AVAILABILITY,
});

export const fetchLockAvailabilitySuccess = (results) => ({
	type: FETCH_LOCK_AVAILABILITY_SUCCESS,
	results
});

export const deleteLockAvailability = (id) => ({
	type: LOCK_AVAILABILITY_DELETE_PERFORMED,
	id
});

// export const fetchGeneralAvailabilities = () => ({
// 	type: FETCH_GENERAL_AVAILABILITY
// });

// export const fetchGeneralAvailabilitySuccess = (results) => ({
// 	type: FETCH_GENERAL_AVAILABILITY_SUCCESS,
// 	results
// });

export const fetchLockAvailabilityDetail = (id) => ({
	type: FETCH_LOCK_AVAILABILITY_DETAIL,
	id
});

export const getLockAvailabilityDetailSuccess = (result) => ({
	type: GET_LOCK_AVAILABILITY_DETAIL_SUCCESS,
	result
});

export const lockAvailabilityEditPerformed = (formData, id) => ({
	type: LOCK_EDIT_PERFORMED,
	formData,
	id
});

export const lockAvailabilityEditErrors = (errors) => ({
	type: LOCK_AVAILABILITY_EDIT_ERRORS,
	errors
});

export const lockAvailabilityEditSuccess = (status) => ({
	type: LOCK_AVAILABILITY_EDIT_SUCCESS,
	status
});

export const goToGamePageOrg = (id) => ({
	type: GO_TO_GAME_PAGE_ORG,
	id
});

export const goToGamePageOrgSuccess = (results) => ({
	type: GO_TO_GAME_PAGE_ORG_SUCCESS,
	results
});

export const resetLockGame = () => ({
	type: RESET_LOCK_GAME
});

export const setSelectedOrg = (id) => ({
	type: SET_SELECTED_ORG,
	id
});

export const setGameAvailability = (id, availability) => ({
	type: SET_GAME_AVAILABILITY,
	id,
	availability
});

export const fetchLockGames = () => ({
	type: FETCH_LOCK_GAMES,
});