import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { CHANGE_ORGANISATION } from '../actions/organisation';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { API_BASE_URL, getHeaders, getUserId } from '../constants';
import { handleError } from '../actions/handleError';
import { showFullSizeLoader, hideFullSizeLoader } from '../actions/loader';


function* changeOrganisation({ id }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/${id}/changeorg`,
			{}, { headers: getHeaders() }
		);
		let currentUser = JSON.parse(localStorage.getItem('user'));
		currentUser.current_org = Number(id);
		localStorage.setItem('user', JSON.stringify(currentUser));
		swal('Success', results.data.message, 'success');
		swal({
			title: 'Success',
			text: results.data.message,
			type: 'success',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.value) {
				document.location.reload();
			}
		})
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideFullSizeLoader());
}

export default function* () {
	yield takeLatest(CHANGE_ORGANISATION, changeOrganisation);
}