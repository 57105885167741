import React from 'react';
import { Link } from 'react-router-dom';
import GameResults from './game-results/game_results-container';

export default class SpecificGame extends React.Component {
	render() {
		return (
			<div className="specific-game-page">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Specific Games</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/availability">Availability</Link></li>
								<li className="breadcrumb-item current">Specific Games</li>
							</ol>
						</nav>
					</div>
				</div>
				<GameResults />
			</div>
		)
	}
}