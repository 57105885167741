export const GENERAL_AVAILABILITY_CREATE_PERFORMED = 'GENERAL_AVAILABILITY_CREATE_PERFORMED';
export const GENERAL_AVAILABILITY_CREATE_SUCCESS = 'GENERAL_AVAILABILITY_CREATE_SUCCESS';
export const GENERAL_AVAILABILITY_CREATE_ERROR = 'GENERAL_AVAILABILITY_CREATE_ERROR';
export const SPECIFIC_AVAILABILITY_CREATE_PERFORMED = 'SPECIFIC_AVAILABILITY_CREATE_PERFORMED';
export const SPECIFIC_AVAILABILITY_CREATE_SUCCESS = 'SPECIFIC_AVAILABILITY_CREATE_SUCCESS';
export const SPECIFIC_AVAILABILITY_CREATE_ERROR = 'SPECIFIC_AVAILABILITY_CREATE_ERROR';
export const FETCH_SPECIFIC_AVAILABILITY = 'FETCH_SPECIFIC_AVAILABILITY';
export const FETCH_SPECIFIC_AVAILABILITY_SUCCESS = 'FETCH_SPECIFIC_AVAILABILITY_SUCCESS';
export const SPECIFIC_AVAILABILITY_DELETE_PERFORMED = 'SPECIFIC_AVAILABILITY_DELETE_PERFORMED';
export const FETCH_GENERAL_AVAILABILITY = 'FETCH_GENERAL_AVAILABILITY';
export const FETCH_GENERAL_AVAILABILITY_SUCCESS = 'FETCH_GENERAL_AVAILABILITY_SUCCESS';
export const FETCH_SPECIFIC_AVAILABILITY_DETAIL = 'FETCH_SPECIFIC_AVAILABILITY_DETAIL';
export const GET_SPECIFIC_AVAILABILITY_DETAIL_SUCCESS = 'GET_SPECIFIC_AVAILABILITY_DETAIL_SUCCESS';
export const SPECIFIC_EDIT_PERFORMED = 'SPECIFIC_EDIT_PERFORMED';
export const SPECIFIC_AVAILABILITY_EDIT_ERRORS = 'SPECIFIC_AVAILABILITY_EDIT_ERRORS';
export const SPECIFIC_AVAILABILITY_EDIT_SUCCESS = 'SPECIFIC_AVAILABILITY_EDIT_SUCCESS';
export const GO_TO_GAME_PAGE_ORG = 'GO_TO_GAME_PAGE_ORG';
export const GO_TO_GAME_PAGE_ORG_SUCCESS = 'GO_TO_GAME_PAGE_ORG_SUCCESS';
export const RESET_SPECIFIC_GAME = 'RESET_SPECIFIC_GAME';
export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_GAME_AVAILABILITY = 'SET_GAME_AVAILABILITY';
export const FETCH_SPECIFIC_GAMES = 'FETCH_SPECIFIC_GAMES';

export const generalAvailabilityCreatePerformed = (formData) => ({
	type: GENERAL_AVAILABILITY_CREATE_PERFORMED,
	formData
});

export const specificAvailabilityCreatePerformed = (formData) => ({
	type: SPECIFIC_AVAILABILITY_CREATE_PERFORMED,
	formData
})

export const generalAvailabilityCreateSuccess = (status) => ({
	type: GENERAL_AVAILABILITY_CREATE_SUCCESS,
	status
});

export const generalAvailabilityCreateError = (errors) => ({
	type: GENERAL_AVAILABILITY_CREATE_ERROR,
	errors
});

export const specificAvailabilityCreateSuccess = (status) => ({
	type: SPECIFIC_AVAILABILITY_CREATE_SUCCESS,
	status
});

export const specificAvailabilityCreateError = (errors) => ({
	type: SPECIFIC_AVAILABILITY_CREATE_ERROR,
	errors
});

export const fetchSpecificAvailabilities = () => ({
	type: FETCH_SPECIFIC_AVAILABILITY,
});

export const fetchSpecificAvailabilitySuccess = (results) => ({
	type: FETCH_SPECIFIC_AVAILABILITY_SUCCESS,
	results
});

export const deleteSpecificAvailability = (id) => ({
	type: SPECIFIC_AVAILABILITY_DELETE_PERFORMED,
	id
});

export const fetchGeneralAvailabilities = () => ({
	type: FETCH_GENERAL_AVAILABILITY
});

export const fetchGeneralAvailabilitySuccess = (results) => ({
	type: FETCH_GENERAL_AVAILABILITY_SUCCESS,
	results
});

export const fetchSpecificAvailabilityDetail = (id) => ({
	type: FETCH_SPECIFIC_AVAILABILITY_DETAIL,
	id
});

export const getSpecificAvailabilityDetailSuccess = (result) => ({
	type: GET_SPECIFIC_AVAILABILITY_DETAIL_SUCCESS,
	result
});

export const specificAvailabilityEditPerformed = (formData, id) => ({
	type: SPECIFIC_EDIT_PERFORMED,
	formData,
	id
});

export const specificAvailabilityEditErrors = (errors) => ({
	type: SPECIFIC_AVAILABILITY_EDIT_ERRORS,
	errors
});

export const specificAvailabilityEditSuccess = (status) => ({
	type: SPECIFIC_AVAILABILITY_EDIT_SUCCESS,
	status
});

export const goToGamePageOrg = (id) => ({
	type: GO_TO_GAME_PAGE_ORG,
	id
});

export const goToGamePageOrgSuccess = (results) => ({
	type: GO_TO_GAME_PAGE_ORG_SUCCESS,
	results
});

export const resetSpecificGame = () => ({
	type: RESET_SPECIFIC_GAME
});

export const setSelectedOrg = (id) => ({
	type: SET_SELECTED_ORG,
	id
});

export const setGameAvailability = (id, availability) => ({
	type: SET_GAME_AVAILABILITY,
	id,
	availability
});

export const fetchSpecificGames = () => ({
	type: FETCH_SPECIFIC_GAMES,
});