export const LOGIN_PERFORMED = 'LOGIN_PERFORMED';
export const LOGOUT_PERFORMED = 'LOGIN_PERFORMED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_ALERT_ERROR = 'LOGIN_ALERT_ERROR';
export const LOGIN_LOADER_SHOW = 'LOGIN_LOADER_SHOW';
export const AUTH_CHECK = 'AUTH_CHECK';

export const loginError = (errors) => ({
	type: LOGIN_ERROR,
	errors
});

export const authCheck = () => ({
	type: AUTH_CHECK,
});

export const loginAlertError = (error, status) => ({
	type: LOGIN_ALERT_ERROR,
	error,
	status
});

export const loginPerformed = (formData) => ({
	type: LOGIN_PERFORMED,
	formData
});

export const logoutPerformed = () => ({
	type: LOGOUT_PERFORMED,
});

export const loginSuccess = (results) => ({
	type: LOGIN_SUCCESS,
	results
});

export const loginLoaderStatus = (status) => ({
	type: LOGIN_LOADER_SHOW,
	status
});