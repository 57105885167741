import {connect} from 'react-redux';
import VenueListPage from './venue_list';

function mapsStateToProps (state) {
    return {
        form_success: state.venue.form_success,
        venue: state.venue.venue_detail,
        loading: state.venue.is_loading,
        edit_success: state.venue.edit_success
    }
}

export default connect(() => (mapsStateToProps))(VenueListPage);