import React from 'react';
import './official-new.scss';
import OfficialForm from './official-form/official-form-container';
import {Link} from 'react-router-dom';
import BulkImport from './bulk-import/bulk_import.container';
import withAuth from '../../../AuthHOC/withAuth';

function NewOfficialPage () {
    return (
        <div className="official-add">
        	<div className="page-head mb_30">
                <div className="head">
                    <h1 className="page-title">Add Officials</h1>
                    <nav>
                        <ol className="breadcrumb page-title">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/officials">Officials</Link></li>
                            <li className="breadcrumb-item current">Add Officials</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="box">
                <div className="card card-border card-invite-official mb_30">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h2>Invite Official</h2>
                    </div>
                    <OfficialForm />
                </div>
                <BulkImport />
            </div>
        </div>
    );
}

export default withAuth(NewOfficialPage,['administrator']);