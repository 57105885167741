import { connect } from 'react-redux';
import GameResults from './game_results';
import { deleteGame } from '../../../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		removeGames: (id) => dispatch(deleteGame(id))
	};
}

function mapsStateToProps(state) {
	return {
		games: state.game.results,
		loading: state.game.is_loading,
		fetchLoading: state.game.fetchGamesDataLoading
	};
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameResults);