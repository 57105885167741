import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { API_BASE_URL, getHeaders, getOrgId, getUserId } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
	SUBMIT_CUSTOM_REPORTS,
	FETCH_CUSTOM_REPORTS_LIST,
	DELETE_CUSTOM_REPORTS,
	FETCH_CUSTOM_REPORTS_DETAILS,
	EXPORT_ROSTER_REPORT,
	FETCH_DECLINED_REPORT,
	EXPORT_DECLINED_REPORT,
	FETCH_BANK_REPORT,
	FETCH_ALLUSERS_REPORT,
	FETCH_CUSTOM_GAME_REPORTS_LIST,
	FETCH_CUSTOM_GAME_REPORTS_DETAILS,
	DELETE_CUSTOM_GAME_REPORTS,
	SUBMIT_CUSTOM_GAME_REPORTS,
	fetchCustomReportsList,
	fetchCustomReportsListSuccess,
	setReportsLoader,
	fetchCustomReportsDetailsSuccess,
	fetchDeclinedReportSuccess,
	fetchBankReportSuccess,
	fetchallUsersReportSuccess,
	fetchCustomGameReportsListSuccess,
	fetchCustomGameReportsDetailsSuccess
} from '../actions/reports';
import { handleError } from '../actions/handleError';

function* submitCustomReports({ formData, callback }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/custom/build`,
			formData, { headers: getHeaders() }
		);
		yield put(setReportsLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		callback();
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* fetchCustomReportsListFunc() {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/custom`,
			{ headers: getHeaders() }
		);
		yield put(fetchCustomReportsListSuccess(results.data.reports));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* deleteCustomReports({ id }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/custom/${id}`,
			{ headers: getHeaders() }
		);
		yield put(setReportsLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchCustomReportsList());
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* fetchCustomReportsDetails({ id }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/custom/${id}`,
			{ headers: getHeaders() }
		);
		yield put(fetchCustomReportsDetailsSuccess(results.data.report));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* exportRosterReport({ format, startDate, endDate }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/roster/${format}`,
			{
				headers: getHeaders(),
				params: {
					start_date: startDate.format('YYYY-MM-DD'),
					end_date: endDate.format('YYYY-MM-DD')
				},
				responseType: 'blob'
			}
		);

		const mimeType = format === 'excel'
			? 'application / vnd.openxmlformats - officedocument.spreadsheetml.sheet'
			: format === 'csv'
				? 'text/csv'
				: format === 'pdf'
					? 'application/pdf'
					: '';

		const fileExtension = format === 'excel'
			? 'xlsx'
			: format === 'csv'
				? 'csv'
				: format === 'pdf'
					? 'pdf'
					: '';

		let link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([results.data], { type: mimeType }));
		link.download = `Roster-Report-${startDate.format('DD-MM-YYYY')}-${endDate.format('DD-MM-YYYY')}.${fileExtension}`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		swal(
			'Success',
			'Download Report Success',
			'success'
		);
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setReportsLoader(false));
}

function* fetchDeclinedReport() {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/declines`,
			{ headers: getHeaders() }
		);
		yield put(fetchDeclinedReportSuccess(results.data.data));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* exportDeclinedReport({ report, startDate, endDate }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/declinedgames/${report}`,
			{
				headers: getHeaders(),
				params: {
					start: startDate.format('YYYY-MM-DD'),
					end: endDate.format('YYYY-MM-DD')
				},
				responseType: 'blob'
			}
		)

		let link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([results.data], { type: 'text/csv' }));
		link.download = `Declined-Report-${report === 'summary' ? 'Summary' : 'Details'}-${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}.csv`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		swal(
			'Success',
			`Download Report ${report === 'summary' ? 'Summary' : 'Details'} Success`,
			'success'
		);
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setReportsLoader(false));
}

//fetch bank record
function* fetchBankReport() {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/bankrecord/${getOrgId()}/CSV/${getUserId()}`,
			{
				headers: getHeaders()
			}
		);
		yield put(fetchBankReportSuccess(results.data.data));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}


//fetch all users  record
function* fetchallUsersReport() {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/all`,
            { headers: getHeaders() }
		);
		yield put(fetchallUsersReportSuccess(results.data.data));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}
function* fetchCustomGameReportsListFunc() {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/gamereports`,
			{ headers: getHeaders() }
		);
		yield put(fetchCustomGameReportsListSuccess(results.data.reports));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* fetchCustomGameReportsDetails({ id }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/gamereports/${id}`,
			{ headers: getHeaders() }
		);
		yield put(fetchCustomGameReportsDetailsSuccess(results.data.report));
		yield put(setReportsLoader(false));
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* deleteCustomGameReports({ id }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/gamereports/${id}`,
			{ headers: getHeaders() }
		);
		yield put(setReportsLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchCustomReportsList());
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

function* submitCustomGameReports({ formData, callback }) {
	try {
		yield put(setReportsLoader(true));
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/gamereports/build`,
			formData, { headers: getHeaders() }
		);
		yield put(setReportsLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		callback();
	} catch (error) {
		yield put(setReportsLoader(false));
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(SUBMIT_CUSTOM_REPORTS, submitCustomReports);
	yield takeLatest(FETCH_CUSTOM_REPORTS_LIST, fetchCustomReportsListFunc);
	yield takeLatest(DELETE_CUSTOM_REPORTS, deleteCustomReports);
	yield takeLatest(FETCH_CUSTOM_REPORTS_DETAILS, fetchCustomReportsDetails);
	yield takeLatest(EXPORT_ROSTER_REPORT, exportRosterReport);
	yield takeLatest(FETCH_DECLINED_REPORT, fetchDeclinedReport);
	yield takeLatest(FETCH_BANK_REPORT, fetchBankReport);
	yield takeLatest(FETCH_ALLUSERS_REPORT, fetchallUsersReport);
	yield takeLatest(EXPORT_DECLINED_REPORT, exportDeclinedReport);
	yield takeLatest(FETCH_CUSTOM_GAME_REPORTS_LIST, fetchCustomGameReportsListFunc);
	yield takeLatest(FETCH_CUSTOM_GAME_REPORTS_DETAILS, fetchCustomGameReportsDetails);
	yield takeLatest(DELETE_CUSTOM_GAME_REPORTS, deleteCustomGameReports);
	yield takeLatest(SUBMIT_CUSTOM_GAME_REPORTS, submitCustomGameReports);
}