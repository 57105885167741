import {
	GENERAL_AVAILABILITY_CREATE_SUCCESS,
	SPECIFIC_AVAILABILITY_CREATE_ERROR,
	SPECIFIC_AVAILABILITY_CREATE_SUCCESS,
	GENERAL_AVAILABILITY_CREATE_ERROR,
	FETCH_SPECIFIC_AVAILABILITY_SUCCESS,
	FETCH_GENERAL_AVAILABILITY_SUCCESS,
	GET_SPECIFIC_AVAILABILITY_DETAIL_SUCCESS,
	SPECIFIC_AVAILABILITY_EDIT_ERRORS,
	SPECIFIC_AVAILABILITY_EDIT_SUCCESS,
	GO_TO_GAME_PAGE_ORG_SUCCESS,
	RESET_SPECIFIC_GAME,
	SET_SELECTED_ORG
} from '../actions/availability';

const initialState = {
	general_results: [],
	form_success: false,
	general_errors: {},
	specific_errors: {},
	specific_results: [],
	specific_detail: {},
	specific_edit_errors: {},
	specific_edit_form_success: false,
	specific_game: [],
	selected_org: null
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case GENERAL_AVAILABILITY_CREATE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				general_errors: {}
			};
		case GENERAL_AVAILABILITY_CREATE_ERROR:
			return {
				...state,
				general_errors: actions.errors
			}
		case SPECIFIC_AVAILABILITY_CREATE_ERROR:
			return {
				...state,
				specific_errors: actions.errors
			}
		case SPECIFIC_AVAILABILITY_CREATE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				specific_errors: {}
			};
		case FETCH_SPECIFIC_AVAILABILITY_SUCCESS:
			return {
				...state,
				specific_results: actions.results
			};
		case FETCH_GENERAL_AVAILABILITY_SUCCESS:
			return {
				...state,
				general_results: actions.results
			}
		case GET_SPECIFIC_AVAILABILITY_DETAIL_SUCCESS:
			return {
				...state,
				specific_detail: actions.result,
			}
		case SPECIFIC_AVAILABILITY_EDIT_ERRORS:
			return {
				...state,
				specific_edit_errors: actions.errors
			}
		case SPECIFIC_AVAILABILITY_EDIT_SUCCESS:
			return {
				...state,
				specific_edit_form_success: actions.status
			}
		case GO_TO_GAME_PAGE_ORG_SUCCESS:
			return {
				...state,
				specific_game: actions.results
			}
		case SET_SELECTED_ORG:
			return {
				...state,
				selected_org: actions.id
			}
		case RESET_SPECIFIC_GAME:
			return {
				...state,
				specific_game: [],
				selected_org: null
			}
		default:
			return state;
	}
}