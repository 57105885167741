import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	GET_ALL_ACKNOWLEDGEMENTS,
	GET_ACKNOWLEDGEMENT,
	SAVE_ACKNOWLEDGEMENT,
	EDIT_ACKNOWLEDGEMENT,
	DELETE_ACKNOWLEDGEMENT,
	TOGGLE_ACTIVATE_ACKNOWLEDGEMENT,
	CONFIRM_ACKNOWLEDGEMENT,
	DECLINE_ACKNOWLEDGEMENT,
	getAllAcknowledgements,
	getAllAcknowledgementsSuccess,
	getAcknowledgement,
	getAcknowledgementSuccess,
	resetAcknowledgementModalState
} from '../actions/acknowledgements';
import { closeTrialBanner } from '../actions/billing';
import { showLoader, hideLoader, showFullSizeLoader, hideFullSizeLoader } from '../actions/loader';
import { API_BASE_URL, getHeaders, getOrgId, getUserId } from '../constants';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { push } from 'connected-react-router';

function* getAllAcknowledgementsFunc() {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements`,
			{ headers: getHeaders() }
		);
		yield put(hideLoader());
		yield put(getAllAcknowledgementsSuccess(results.data));
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* getAcknowledgementFunc({id}) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements/${id}`,
			{ headers: getHeaders() }
		);
		yield put(hideLoader());
		yield put(getAcknowledgementSuccess(results.data));
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* saveAcknowledgement({ data }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements`,
			data, { headers: getHeaders() }
		);
		yield put(hideLoader());
		swal('Success', results.data.message, 'success');
		yield put(push('/acknowledgements'));
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* editAcknowledgement({ id, data }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements/${id}`,
			data, { headers: getHeaders() }
		);
		yield put(hideLoader());
		swal('Success', results.data.message, 'success');
		yield put(getAcknowledgement(id));
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* deleteAcknowledgement({ id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements/${id}`,
			{ headers: getHeaders() }
		);
		yield put(hideLoader());
		swal('Success', results.data.message, 'success');
		yield put(getAllAcknowledgements());
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* toggleActivateAcknowledgement({ id, value }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements/${id}/activate`,
			{ active: value }, { headers: getHeaders() }
		);
		yield put(hideLoader());
		swal('Success', results.data.message, 'success');
		yield put(getAllAcknowledgements());
	} catch (error) {
		yield put(hideLoader());
		yield put(handleError(error));
	}
}

function* confirmAcknowledgement({ id }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/user/${getUserId()}/acknowledgements/${id}/confirm`,
			null, { headers: getHeaders() }
		);
		yield put(hideFullSizeLoader());
		swal({
			title: "Success!",
			text: results.data.message,
			type: "success"
		}).then(() => {
			window.location.reload(true);
		});
		yield put(resetAcknowledgementModalState());		
	} catch (error) {
		yield put(hideFullSizeLoader());
		yield put(handleError(error));
	}
}

function* declineAcknowledgement({ id }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/user/${getUserId()}/acknowledgements/${id}/decline`,
			null, { headers: getHeaders() }
		);
		yield put(hideFullSizeLoader());
		swal({
			title: "Success!",
			text: results.data.message,
			type: "success"
		}).then(() => {
			put(closeTrialBanner());
			localStorage.removeItem('user');
			put(push('/login'))
			window.location.reload(true);
		});
		yield put(resetAcknowledgementModalState());
	} catch (error) {
		yield put(hideFullSizeLoader());
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(GET_ALL_ACKNOWLEDGEMENTS, getAllAcknowledgementsFunc);
	yield takeLatest(GET_ACKNOWLEDGEMENT, getAcknowledgementFunc);
	yield takeLatest(SAVE_ACKNOWLEDGEMENT, saveAcknowledgement);
	yield takeLatest(EDIT_ACKNOWLEDGEMENT, editAcknowledgement);
	yield takeLatest(DELETE_ACKNOWLEDGEMENT, deleteAcknowledgement);
	yield takeLatest(TOGGLE_ACTIVATE_ACKNOWLEDGEMENT, toggleActivateAcknowledgement);
	yield takeLatest(CONFIRM_ACKNOWLEDGEMENT, confirmAcknowledgement);
	yield takeLatest(DECLINE_ACKNOWLEDGEMENT, declineAcknowledgement);	
}