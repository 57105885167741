import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import {Link} from 'react-router-dom';

export default class LeaguesResults extends Component {
    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillMount() {
        this.props.fetchLeagues();
    }

    handleDelete = (id) => {
        swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if(result.value)
            {
                this.props.removeLeague(id);
            }
          })
    }

    render () {
        const data = this.props.leagues;
        const columns = [{
            Header: 'Name',
            accessor: 'league_name' // String-based value accessors!
          }, {
            Header: 'Game Length',
            accessor: 'game_length',
          }, {
            Header: 'Actions',
            Cell: row => (
                <div className="actions">
                    <ul className="list-inline">
                        <li>
                            <button className="link"><Link to={`/organisation/leagues/edit/${row.original.id}`}>Edit</Link></button>
                        </li>
                        <li><span className="seprator">|</span></li>
                        <li>
                            <button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button>
                        </li>
                    </ul>
                </div>
            )
         }];

        return (
            <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                <div className="table-area">
                {this.props.loading && <Loader />}
                <ReactTable
                    PaginationComponent={Pagination}
                    data={data}
                    columns={columns}
                    noDataText="No Leagues Found."
                    defaultPageSize= {10}
                    resizable={true}
                    minRows={5}
                />
                </div>
            </div>
        );
    }
}

LeaguesResults.propTypes = {
    fetchLeagues: PropTypes.func.isRequired,
    leagues: PropTypes.arrayOf(
        PropTypes.shape({
            league_name: PropTypes.string,
            game_length: PropTypes.number,
        }).isRequired
    ),
    loading: PropTypes.bool,
    removeLeague: PropTypes.func.isRequired
}