import React, {Component} from 'react'
import {Doughnut} from 'react-chartjs-2';

export default class Chart extends Component{
    constructor(props) {
        super(props);
        this.state = {
            chart_lables: [],
            chart_data: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(Object.keys(nextProps.data).length > 0)
        {
            let data = [];
            Object.keys(nextProps.data).forEach(function(key) {
                if(key !== 'Message')
                {
                    data.push(nextProps.data[key]);
                }
            });
            this.setState({
                chart_data: data
            });
        }
    }

    render() {
        const data = {
            labels: [
                'Declined Games',
                'Pending Games',
                'Confirmed Games',
                'Unassigned Games'
            ],
            datasets: [{
                data: this.state.chart_data,
                backgroundColor: [
                '#fd252b',
                '#ffce55',
                '#6fe200',
                '#c0c0c0',
                ],
                hoverBackgroundColor: [
                '#fd252b',
                '#ffce55',
                '#6fe200',
                '#c0c0c0',
                ]
            }]
        };
        if(Object.keys(this.props.data).length > 0)
        {
            if(this.props.data.Declined === 0 && this.props.data.Pending === 0 && this.props.data.Confirmed === 0 && this.props.data.Unassigned === 0)
            {
                return <div className="card-body"><center>No Data Found</center></div>;
            }
            else
            {
                return(
                    <div className="card-body">
                        <div className="canvas-container">
                            <Doughnut data={data} options={{responsive: true, maintainAspectRatio: false}} onElementsClick={elems => {
                                if(elems[0] && elems[0]['_view']['label'] === 'Confirmed Games')
                                {
                                    this.props.history.push(`/games?status=Confirmed&daterange=${this.props.daterange}`);
                                }
                                else if(elems[0] && elems[0]['_view']['label'] === 'Pending Games')
                                {
                                    this.props.history.push(`/games?status=Pending&daterange=${this.props.daterange}`);
                                }
                                else if(elems[0] && elems[0]['_view']['label'] === 'Declined Games')
                                {
                                    this.props.history.push(`/games?status=Declined&daterange=${this.props.daterange}`);
                                }
                                else if(elems[0] && elems[0]['_view']['label'] === 'Unassigned Games')
                                {
                                    this.props.history.push(`/games?status=Unassigned&daterange=${this.props.daterange}`);
                                }
                                else{

                                }
                            }} />
                        </div>
                    </div>
                );
            }
        }
        else
        {
            return <div><center>No Data Found</center></div>;
        }
    }
}