export const FETCH_LEAGUES_PERFORMED = 'FETCH_LEAGUES_PERFORMED';
export const FETCH_LEAGUES_SUCCESS = 'FETCH_LEAGUES_SUCCESS';
export const LEAGUE_LOADER_SHOW = 'LEAGUE_LOADER_SHOW';
export const LEAGUE_PERFORMED = 'LEAGUE_PERFORMED';
export const LEAGUE_SUCCESS = 'LEAGUE_SUCCESS';
export const LEAGUE_ERROR = 'LEAGUE_ERROR';
export const LEAGUE_PERFORMED_DELETE = 'LEAGUE_PERFORMED_DELETE';
export const GET_LEAGUE_DETAIL = 'GET_LEAGUE_DETAIL';
export const GET_LEAGUE_DETAIL_SUCCESS = 'GET_LEAGUE_DETAIL_SUCCESS';
export const LEAGUE_EDIT_PERFORMED = 'LEAGUE_EDIT_PERFORMED';
export const LEAGUE_EDIT_ERROR = 'LEAGUE_EDIT_ERROR';
export const LEAGUE_DELETE_SUCCESS = 'LEAGUE_DELETE_SUCCESS';
export const GET_LIST_CUSTOM_REPORT = 'GET_LIST_CUSTOM_REPORT';
export const GET_LIST_CUSTOM_REPORT_SUCCESS = 'GET_LIST_CUSTOM_REPORT_SUCCESS';


export const fetchLeaguesPerformed = () => ({
    type: FETCH_LEAGUES_PERFORMED,
});

export const leaguePerformed = (leagueFormData) => ({
    type: LEAGUE_PERFORMED,
    leagueFormData
});

export const leagueEditPerformed = (leagueFormData, customReportData, id) => ({
    type: LEAGUE_EDIT_PERFORMED,
    leagueFormData,
    customReportData,
    id
});

export const fetchLeaguesSuccess = (results) => ({
    type: FETCH_LEAGUES_SUCCESS,
    results
});

export const leagueLoaderStatus = (status) => ({
	type: LEAGUE_LOADER_SHOW,
	status
});

export const leagueSuccess = (status) => ({
    type: LEAGUE_SUCCESS,
    status
});

export const leagueDeleteSuccess = (status) => ({
    type: LEAGUE_DELETE_SUCCESS,
    status
});

export const leagueErrors = (errors) => ({
    type: LEAGUE_ERROR,
	errors
});

export const leagueEditErrors = (errors) => ({
    type: LEAGUE_EDIT_ERROR,
	errors
});

export const getLeague = (id) => ({
    type: GET_LEAGUE_DETAIL,
    id
});

export const getLeagueSuccess = (league) => ({
    type: GET_LEAGUE_DETAIL_SUCCESS,
    league
});

export const deleteLeague = (id) => ({
    type: LEAGUE_PERFORMED_DELETE,
    id
});

export const getListCustomReport = () => ({
    type: GET_LIST_CUSTOM_REPORT
});

export const getListCustomReportSuccess = (status) => ({
    type: GET_LIST_CUSTOM_REPORT_SUCCESS,
    status
});