import React, {Component} from 'react';
import FormError from '../../../../form-error/form-error';
import withAuth from '../../../../AuthHOC/withAuth';
import Loader from '../../../../loader/loader';

class NewPayScale extends Component {
    constructor(props){
        super(props);

        this.state = {
            scale_name: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit (event) {
        event.preventDefault();
        const scale_name = this.state.scale_name;
        const data = {scale_name};

        this.props.onPayScaleSubmitted(data);
    }

    render () {
        let payscale_name_error = null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'scale_name')
                    payscale_name_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
            <form className="organisation-payscale-form">
                { this.props.loading && <Loader /> }
                <div className="form-group">
                    <label className="label">Name of PayScale</label>
                    <div className="input-effect-1">
                        <input type="text" name="scale_name" value={this.state.scale_name} className="form-control" onChange={event => {this.handleChange(event)}} />
                        <div className="focus-border"></div>
                    </div>
                    {payscale_name_error}
                </div>
                <div className="action text-center">
                    <button type="button" className="btn btn-orange" onClick={this.handleSubmit}>Create PayScale</button>
                </div>
            </form>
        );
    }
}
export default withAuth(NewPayScale,['administrator']);