import {connect} from 'react-redux';
import ForgotPassword from './forgot_password';
import {authCheck} from '../../../actions/login';

function mapsDispatchToProps (dispatch) {
    return {
        authCheck: () => dispatch(authCheck())
    }
}

export default connect(() => ({}), mapsDispatchToProps)(ForgotPassword);