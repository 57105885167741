import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../../AuthHOC/withAuth';
import Loading from '../../../loader/loader';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../pagination/pagination';
import { connect } from 'react-redux';
import {
	fetchCustomGameReportsList,
	deleteCustomGameReports
} from '../../../../actions/reports';
import Modal from 'react-responsive-modal';
import CustomReportsPage from './custom_game_reports';
import CustomReportDetail from './detail_report';

class CustomReportList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			editModalOpen: false,
			id: ''
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
	}

	onOpenModal = () => {
		this.setState({open: true});
	}

	onCloseModal = () => {
		this.setState({open: false});
	}

	onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.form_success === true)
		{
			this.setState({open: false});
		}
		if(nextProps.edit_success === true)
		{
			this.setState({editModalOpen: false});
		}
	}

	componentWillMount() {
		this.props.fetchCustomGameReportsList();
	};

	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.deleteCustomGameReports(id);
			}
		})
	}

	render() {
		const { open, editModalOpen } = this.state;
		const data = this.props.data;
		const columns = [{
			Header: 'Report Name',
			accessor: 'report_name'
		}, {
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li>
							<Link to={`/report/game/${row.original.id}/detail`}><button className="link">View</button></Link>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<button className="link" onClick={() => { this.handleDelete(row.original.id) }}>Delete</button>
						</li>
					</ul>
				</div>
			)
		}];
		return (
			<div className="regions">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Game Reports Template</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Game Reports Template</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						
							<button onClick={this.onOpenModal} className="btn btn-orange">Create Custom Reports</button>
					
					</div>
				</div>
				<div className="box">
					{this.props.loader && <Loading />}
					<ReactTable
						PaginationComponent={Pagination}
						data={data}
						columns={columns}
						noDataText="No Data Found."
						defaultPageSize={10}
						resizable={true}
						minRows={5}
						onOpenEditModal={this.onOpenEditModal}
					/>
				</div>
				<Modal
					open={open}
					onClose={this.onCloseModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Custom Reports</h5>
								<button type="button" className="close" onClick={this.onCloseModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<CustomReportsPage />
							</div>
						</div>
					</div>
				</Modal>
				<Modal 
					open={editModalOpen}
					onClose={this.onCloseEditModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Custom Reports</h5>
								<button type="button" className="close" onClick={this.onCloseEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<CustomReportDetail id={this.state.id} />
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		fetchCustomGameReportsList: () => dispatch(fetchCustomGameReportsList()),
		deleteCustomGameReports: (id) => dispatch(deleteCustomGameReports(id))
	};
}

function mapsStateToProps(state) {
	return {
		data: state.reports.gamereport,
		loader: state.reports.loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(CustomReportList, ['administrator']));