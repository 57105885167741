import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';

// export default function OfficialForm ({ onOfficialSubmitted, errors, loading, form_success, success_msg }) {
export default class OfficialForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            administrator: false,
            allocator: false,
            coach: false,
            official: false,
            billing: false
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleAdministratorChange = this.handleAdministratorChange.bind(this);
        this.handleAllocatorChange = this.handleAllocatorChange.bind(this);
        this.handleCoachChange = this.handleCoachChange.bind(this);
        this.handleOfficialChange = this.handleOfficialChange.bind(this);
        this.handleBillingChange = this.handleBillingChange.bind(this);
    }



    handleEmailChange (e) {
        this.setState({ email: e.target.value });
    }

    handleNameChange (e) {
        this.setState({ name: e.target.value });
    }

    handleAdministratorChange (e) {
        this.setState({ administrator: e.target.checked });
    }

    handleAllocatorChange (e) {
        this.setState({ allocator: e.target.checked });
    }

    handleCoachChange (e) {
        this.setState({ coach: e.target.checked });
    }

    handleOfficialChange (e) {
        this.setState({ official: e.target.checked });
    }

    handleBillingChange (e) {
        this.setState({ billing: e.target.checked });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                email: '',
                name: '',
                administrator: false,
                allocator: false,
                coach: false,
                official: false,
                billing: false
            });
        }
    }
    render () {
        const officialSubmitted = (e) => {
            e.preventDefault();
            let data = {
                name: this.state.name,
                email: this.state.email,
                administrator: this.state.administrator,
                allocator: this.state.allocator,
                coach: this.state.coach,
                official: this.state.official,
                billing: this.state.billing
            }
            this.props.onOfficialSubmitted(data);
        }

        let email_error = null;
        let name_error= null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'email')
                    email_error = <FormError error={this.props.errors[key][0]} />;
                else
                    name_error = <FormError error={this.props.errors[key][0]} />;
            }
        }

        return (
            <form onSubmit={officialSubmitted} id="official-invite-form">
                <div>{this.props.form_success}</div>
                { this.props.loading && <Loader /> }
                <div className="card-body">
                    <div className="permission">
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                                <div className="form-group">
                                    <label className="label">Name</label>
                                    <div className="input-effect-1">
                                     <input type="text" value={this.state.name} onChange={this.handleNameChange} className="form-control" placeholder="Your Name" />
                                        <span className="focus-border"></span>
                                    </div>
                                    {name_error}
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                                <div className="form-group">
                                    <label className="label">Email</label>
                                    <div className="input-effect-1">
                                    <input type="text" value={this.state.email} onChange={this.handleEmailChange} className="form-control" placeholder="Your Email" />
                                        <span className="focus-border"></span>
                                    </div>
                                    {email_error}
                                </div>
                            </div>
                        </div>
                        <h3 className="subtitle">Permission</h3>
                        <div className="form-group">
                            <div className="custom-checkbox custom-control-inline">
                                <input type="checkbox" checked={this.state.administrator} onChange={this.handleAdministratorChange} id="administrator" className="custom-control-input" />
                                <label htmlFor="administrator" className="custom-control-label">
                                    Administrator
                                </label>
                            </div>
                            <div className="custom-checkbox custom-control-inline">
                                <input type="checkbox" checked={this.state.allocator} onChange={this.handleAllocatorChange} id="allocator" className="custom-control-input" />
                                <label htmlFor="allocator" className="custom-control-label">
                                    Allocator
                                </label>
                            </div>
                            <div className="custom-checkbox custom-control-inline">
                                <input type="checkbox" checked={this.state.coach} id="coach" onChange={this.handleCoachChange} className="custom-control-input" />
                                <label htmlFor="coach" className="custom-control-label">
                                    Coach
                                </label>
                            </div>
                            <div className="custom-checkbox custom-control-inline">
                                <input type="checkbox" checked={this.state.official} id="official" onChange={this.handleOfficialChange} className="custom-control-input" />
                                <label htmlFor="official" className="custom-control-label">
                                    Official
                                </label>
                            </div>
                            <div className="custom-checkbox custom-control-inline">
                                <input type="checkbox" checked={this.state.billing} id="billing" onChange={this.handleBillingChange} className="custom-control-input" />
                                <label htmlFor="billing" className="custom-control-label">
                                    Billing
                                </label>
                            </div>
                        </div>
                        <div className="action">
                            <input type="submit" className="btn btn-orange" value="Send" name="submit" />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

OfficialForm.propTypes = {
    onOfficialSubmitted: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    form_success: PropTypes.bool,
    success_msg: PropTypes.string
}