import PerformanceReport from './performance_report';
import { connect} from 'react-redux';
import{ fetchPerformanceReportList } from '../../../../actions/performance_report';
function mapsDispatchToProps(dispatch){
    return{
        fetchPerformanceReportList: (filters) => dispatch(fetchPerformanceReportList(filters)),
    }
}
function mapsStateToProps(state){
    return{
    
        teams: state.performance_report.teams.data,
        leagues: state.performance_report.leagues.data,
        venues: state.performance_report.venues.data,
        fetchPerformanceReportLoading: state.performance_report.fetchPerformanceReportLoading,

    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(PerformanceReport);