import React, {Component} from 'react';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';

export default class NewPattern extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pattern_name: '',
            pattern_fields: [""],
            coaches: [],
            pattern_field_error: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }

    handleCheckBox = idx => event => {
        let coaches = this.state.coaches.slice();
        let id = coaches.indexOf(idx);

        if (id > -1) {
            coaches = coaches.filter((c, i) => id !== i);
        } else {
            coaches.push(idx);
        }

        this.setState({ coaches });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handlePositionChange = idx => evt => {
        const newPatternFields = this.state.pattern_fields.map((position, pidx) => {
            if (idx !== pidx) return position;
            return evt.target.value;
          });
      
        this.setState({ pattern_fields: newPatternFields });
    }

    handleAddPosition = (event) => {
        event.preventDefault();
        this.setState({
          pattern_fields: this.state.pattern_fields.concat([""])
        });
    };
    
    handleRemovePatternField = idx => (event) => {
        event.preventDefault();
        this.setState({
            pattern_fields: this.state.pattern_fields.filter((s, sidx) => idx !== sidx),
            coaches: this.state.coaches.filter(c => c !== idx)
        });
    };

    async handleSubmit(event) {
        event.preventDefault();
        let patternState = [];
        patternState = this.state.pattern_fields.map((pattern) => {
            if (pattern === '') return true;
            return false;
        });

        this.setState({
            pattern_field_error: patternState.includes(true)
        });
        
        const {
            pattern_name,
            pattern_fields,
            coaches
        } = this.state;

        const data = {
            pattern_name,
            pattern_fields,
            coaches
        }

        if (!patternState.includes(true)) {
            this.props.onPatternSubmitted(data);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form_success === true)
        {
            this.setState({
                pattern_name: '',
                pattern_fields: [{0: ""}],
                coaches: [],
            });
        }
    }

    render () {
        let pattern_name_error = null;
        if (this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if (key === 'pattern_name')
                    pattern_name_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
            <form className="organisation-pattern-form">
                { this.props.loading && <Loader /> }
                {this.state.pattern_field_error && <FormError error={'Please enter value for added position field.'} />}
                <div className="form-group">
                    <label className="label">Name of Pattern</label>
                    <div className="input-effect-1">
                        <input type="text" name="pattern_name" placeholder="Name of pattern" className="form-control" value={this.state.pattern_name} onChange={event => this.handleInputChange(event)} />
                        <div className="focus-border"></div>
                    </div>
                    {pattern_name_error}
                </div>
                {this.state.pattern_fields.map((position, idx) => (
                    <div className="form-group" key={idx}>
                        <label className="label">Position 
                            <input className="ml-4" type="checkbox" checked={this.state.coaches.indexOf(idx) + 1} id="coach" onChange={this.handleCheckBox(idx)} /> CoachRole
                        {this.state.pattern_fields.length > 1 && idx > 0 && 
                            <button className="btn-text-link delete" onClick={this.handleRemovePatternField(idx)}>Delete</button>
                        }
                        </label>
                        <div className="input-effect-1">
                            <input type="text" placeholder="Position Name" className="form-control" value={position.name} onChange={this.handlePositionChange(idx)} />
                            <div className="focus-border"></div>
                        </div>
                    </div>
                ))}
                <div className="form-group">
                    <button className="btn-text-link" onClick={this.handleAddPosition}>Add</button>
                </div>
                <div className="action text-center">
                    <button type="button" className="btn btn-orange" onClick={this.handleSubmit}>Create Pattern</button>
                </div>
            </form>
        );
    }
}

NewPattern.propTypes = {
    loading: PropTypes.bool,
    errors: PropTypes.object,
    form_success: PropTypes.bool,
    onPatternSubmitted: PropTypes.func.isRequired
}
