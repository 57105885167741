import React from 'react';
import emailSvg from '../../public/svg/email.svg';
import passwordSvg from '../../public/svg/password.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../loader/loader';
import FormError from '../form-error/form-error';
import AlertError from '../Alert/error';

export default function LoginForm({ loading, onLoginSubmitted, errors, alert_error_msg, alert_error_show, route_error }) {
	let email_field = null;
	let password_field = null;
	const loginSubmitted = (e) => {
		e.preventDefault();
		let data = {
			email: email_field.value,
			password: password_field.value
		}
		onLoginSubmitted(data);
	}

	let login_loader = null;
	if (loading === true) {
		login_loader = <Loader />;
	}

	let alert_error = null;
	if (alert_error_show === true) {
		alert_error = <AlertError msg={alert_error_msg} />
	}

	let email_error = null;
	let password_error = null;
	if (errors !== undefined) {
		for (var key in errors) {
			if (key === 'email')
				email_error = <FormError error={errors[key][0]} />;
			else
				password_error = <FormError error={errors[key][0]} />;
		}
	}

	return (
		<form onSubmit={loginSubmitted} method="POST" id="loginForm">
			{login_loader}
			{alert_error}
			<div className="signin">
				<label className="label">Sign In</label>
				<div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="email" /></span>
							</span>
						</div>
						<input type="email" ref={(e) => email_field = e} className="form-control" placeholder="Email" />
					</div>
					{email_error}
				</div>
				<div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<span className="icon icon-pass">
									<img src={passwordSvg} className="imgsvg" alt="password" />
								</span>
							</span>
						</div>
						<input type="password" ref={(e) => password_field = e} className="form-control" placeholder="Password" />
					</div>
					{password_error}
				</div>
			</div>
			<div className="forgot-keepme">
				<div className="row no-gutters">
					<div className="col-5">
						<div className="forgot-link">
							<Link to="/forgot-password">Forgot Password</Link>
						</div>
					</div>
					<div className="col-7">
						<div className="keep-me">
							<label htmlFor="signedin" className="check-box">
								<input type="checkbox" name="signedin" id="signedin" />
								<span className="text"><span>Keep Me Signed in</span></span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="action">
				<input type="submit" name="signin" value="Sign In" className="submit" />
				{/* <div className="signup">
                    New user?<Link to="/register">Sign Up</Link>
                </div> */}
			</div>
		</form>
	);
}

LoginForm.propTypes = {
	onLoginSubmitted: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	errors: PropTypes.object,
	alert_error_show: PropTypes.bool,
	alert_error_msg: PropTypes.string,
	route_error: PropTypes.string
}