import React, {Component} from 'react';
import './dashboard.scss';
// import userPic from '../../../../public/images/user.jpg';
// import { Scrollbars } from 'react-custom-scrollbars';
// import {Doughnut} from 'react-chartjs-2';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import FullCalendar from 'fullcalendar-reactwrapper';
import PropTypes from 'prop-types';
import Loader from '../../../loader/loader';
import moment from 'moment';
import Chart from './chart/chart.container';
import $ from 'jquery';
const now = moment();

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            daterange: 1,
            chart_lables: [],
            chart_data: [],
            events: []
        }
        this.handleDateRange = this.handleDateRange.bind(this);
    }

    componentWillMount() {
        this.props.fetchChartData(this.state.daterange);
        this.props.fetchCalendarData();
    }

    componentWillReceiveProps(nextProps) {
        if(Object.keys(nextProps.chart_data).length > 0)
        {
            let data = [];
            Object.keys(nextProps.chart_data).forEach(function(key) {
                if(key !== 'Message')
                {
                    data.push(nextProps.chart_data[key]);
                }
            });
            this.setState({
                chart_data: data
            });
        }

        if(Object.keys(nextProps.calendar_data).length > 0)
        {
            this.setState({
                events: nextProps.calendar_data
            })
        }
    }

    handleDateRange (event) {
        event.preventDefault();
        let daterange = event.target.value;
        this.props.fetchChartData(daterange);
        this.setState({
            daterange: daterange
        });
    }

    render () {
        return (
            <div className="dashboard">
                {this.props.loading && <Loader />}
                <div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title">Dashboard</h1>
                    </div>
                </div>
                <div className="row mb_30">
                    <div className="col-xl-6 col-lg-6 col-12 mb-sm-30 mb-md-30">
                        <div className="card card-border card-upcoming-games">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h2>Upcoming Games</h2>
                                <div className="action">
                                    <select className="normal-select" name="daterange" value={this.state.daterange} onChange={e => this.handleDateRange(e)}>
                                        <option value="1">1 Day</option>
                                        <option value="3">3 Days</option>
                                        <option value="7">1 Week</option>
                                        <option value="14">2 Weeks</option>
                                        <option value="21">3 Weeks</option>
                                        <option value="28">4 Weeks</option>
                                    </select>
                                </div>
                            </div>
                            <Chart history={this.props.history} daterange={this.state.daterange} />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="card card-border card-activity">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h2>Activity</h2>
                            </div>
                            <div className="card-body">
                                <center><p>No Data Found</p></center>
                                {/* <Scrollbars style={{ height: 255 }}>
                                    <div className="home-activity mCustomscrollbar">
                                        <ul className="list">
                                            <li className="clearfix">
                                                <div className="avatar avatar-md avatar-round">
                                                    <img src={userPic} alt="User Avatar"/>
                                                </div>
                                                <div className="discription">
                                                    <span className="name">Jhone Die</span>
                                                    <p>Dolor sit amet, consectetur adipisicing elit mod <b>tempor</b> incididunt adipisicing elit.</p>
                                                    <div className="time">
                                                        <span>10 min</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Scrollbars>
                                <div className="home-activity-action">
                                    <button className="link">See More Messages</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="fullcalendar">
                                    <FullCalendar
                                        id = "upcoming-games"
                                        themeSystem = {'bootstrap4'}
                                        header = {{
                                            left: 'prev,next today myCustomButton',
                                            center: 'title',
                                            right: 'month,basicWeek,basicDay'
                                        }}
                                        defaultDate={now}
                                        navLinks= {true} // can click day/week names to navigate views
                                        editable= {false}
                                        eventLimit= {true} // allow "more" link when too many events
                                        height= {'parent'}
                                        events = {this.state.events}
                                        firstDay = {1}
                                        eventRender = {function(event, element){
                                            $(element).popover({
                                                html: true,
                                                trigger: 'hover',
                                                container: 'body',
                                                placement: 'top',
                                                content: '<b>League :</b> '+ event.League + '<br/><b>Venue :</b> '+ event.Venue + '<br/><b>Gender :</b> '+ event.Gender,
                                                title: event.title
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    fetchChartData: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    fetchCalendarData: PropTypes.func.isRequired
}

export default Dashboard;