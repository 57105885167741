import { connect } from 'react-redux';
import EditForm from './edit_form';
import {
	getOfficial,
	officialEditPerformed,
	bankEditSuccess,
	connectToGoogleCalendar,
	disconnectGoogleCalendar
} from '../../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		fetchOfficial: (id) => dispatch(getOfficial(id)),
		onSubmitOfficial: (formData, id) => dispatch(officialEditPerformed({ formData, id })),
		bankEditStatus: () => dispatch(bankEditSuccess(false)),
		connectToGoogleCalendar: () => dispatch(connectToGoogleCalendar()),
		disconnectGoogleCalendar: () => dispatch(disconnectGoogleCalendar())
	}
}

function mapsStateToProps(state) {
	return {
		official: state.official.official_detail,
		loading: state.official.is_loading,
		errors: state.official.edit_errors,
		form_success: state.official.edit_success,
		bank_form_success: state.official.bank_success
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditForm);