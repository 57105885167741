import { connect } from 'react-redux';
import GameResults from './game_results';
import { setGameAvailability } from '../../../../../actions/availability';

function mapsDispatchToProps(dispatch) {
	return {
		setGameAvailability: (id, availability) => dispatch(setGameAvailability(id, availability))
	}
}

function mapsStateToProps(state) {
	return {
		game_loading: state.availability.specific_game_loader,
		specific_game: state.availability.specific_game
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameResults);