import React, { Component } from 'react';

export default class EditForm extends Component {
    render () {
        return (
            <div className="col-xl-9 col-lg-6 col-md-12 col-12">
                <div className="row mb_30">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Contact Name</label>
                            <div className="input-effect-1">
                                <input className="form-control" name="" />
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Contact Email</label>
                            <div className="input-effect-1">
                                <input className="form-control" name="" />
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Address</label>
                            <div className="input-effect-1">
                                <input className="form-control" name="" />
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Default Availability</label>
                            <div className="input-effect-1">
                                <select className="normal-select">
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                </select>
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Date</label>
                            <div className="input-effect-1">
                                <input className="form-control" name="" />
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                        <div className="form-group">
                            <label className="label">Sport</label>
                            <div className="input-effect-1">
                                <select className="normal-select">
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                    <option>Option 1</option>
                                </select>
                                <div className="focus-border"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action text-right">
                    <button className="btn btn-white mr_15">Cancel</button>
                    <button className="btn btn-orange">Save</button>
                </div>
            </div>
        );
    }
}