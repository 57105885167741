import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_FEATURES_DATA,
	TOGGLE_REGIONS,
	TOGGLE_LOCKDATES,
	TOGGLE_GAME_AVAILABILITY,
	TOGGLE_GAME_REPORTS,
	TOGGLE_ACKNOWLEDGEMENTS,
	fetchFeaturesData,
	setFeaturesLoader,
	fetchFeaturesDataSuccess,
	setToggleLoader
} from '../actions/features';
import { API_BASE_URL, getHeaders, getOrgId } from '../constants';
import { handleError } from '../actions/handleError';

function* fetchFeaturesDataPerformed() {
	yield put(setFeaturesLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/features`,
			{ headers: getHeaders() }
		);
		yield put(setFeaturesLoader(false));
		yield put(fetchFeaturesDataSuccess(results.data.data));
		console.log('data', results.data.data);
	} catch (error) {
		yield put(setFeaturesLoader(false));
		yield put(handleError(error));
	}
}

function* toggleRegions({value}) {
	yield put(setToggleLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions/change`,
			{ 'using_regions': value },
			{ headers: getHeaders() }
		);
		yield put(fetchFeaturesData());
		yield put(setToggleLoader(false));
		return results;
	} catch (error) {
		yield put(setToggleLoader(false));
		yield put(handleError(error));
	}
}

function* toggleLockDates({ value }) {
	console.log('value', value);
	yield put(setToggleLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/availability/lockdates`,
			{ 'using_lockdates': value },
			{ headers: getHeaders() }
		);
		yield put(fetchFeaturesData());
		yield put(setToggleLoader(false));
		return results;
	} catch (error) {
		yield put(setToggleLoader(false));
		yield put(handleError(error));
	}
}

function* toggleGameAvailability({ value }) {
	yield put(setToggleLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/availability`,
			{ 'process': value },
			{ headers: getHeaders() }
		);
		yield put(fetchFeaturesData());
		yield put(setToggleLoader(false));
		return results;
	} catch (error) {
		yield put(setToggleLoader(false));
		yield put(handleError(error));
	}
}

function* toggleGameReports({ value }) {
	yield put(setToggleLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/gamereports/using`,
			{ 'process': value },
			{ headers: getHeaders() }
		);
		yield put(fetchFeaturesData());
		yield put(setToggleLoader(false));
		return results;
	} catch (error) {
		yield put(setToggleLoader(false));
		yield put(handleError(error));
	}
}

function* toggleAcknowledgements({ value }) {
	yield put(setToggleLoader(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/acknowledgements/change`,
			{ 'using_acknowledgements': value },
			{ headers: getHeaders() }
		);
		yield put(fetchFeaturesData());
		yield put(setToggleLoader(false));
		return results;
	} catch (error) {
		yield put(setToggleLoader(false));
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(FETCH_FEATURES_DATA, fetchFeaturesDataPerformed);
	yield takeLatest(TOGGLE_REGIONS, toggleRegions);
	yield takeLatest(TOGGLE_LOCKDATES, toggleLockDates);
	yield takeLatest(TOGGLE_GAME_AVAILABILITY, toggleGameAvailability);
	yield takeLatest(TOGGLE_GAME_REPORTS, toggleGameReports);
	yield takeLatest(TOGGLE_ACKNOWLEDGEMENTS, toggleAcknowledgements);
}