import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import Dropzone from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import './style.scss';
import { getPermission } from '../../constants';

class WriteReportField extends Component {
	state = {
		data: {}
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState(state => {
			return { data: { ...state.data, [name]: { ...state.data[name], value: value } } }
		});
	}

	handleSave = () => {
		for (const obj in this.state.data) {
			if (this.state.data[obj].required === 1 & this.state.data[obj].value === '') {
				swal('Error', 'Required field must not be blank', 'error');
				return;
			}
		}

		let newFieldValues = {}
		Object.keys(this.state.data).forEach(obj => {
			newFieldValues[obj] = this.state.data[obj].value == null ? "" : this.state.data[obj].value;
		});

		let data = {
			report_id: this.props.data.custom_performance_report_id,
			field_values: newFieldValues
		}

		this.props.handleSave(data);
	};

	handleSubmit = () => {
		for (const obj in this.state.data) {
			if (this.state.data[obj].required === 1 & this.state.data[obj].value === '') {
				swal('Error', 'Required field must not be blank', 'error');
				return;
			}
		}

		let newFieldValues = {}
		Object.keys(this.state.data).forEach(obj => {
			newFieldValues[obj] = this.state.data[obj].value == null ? "" : this.state.data[obj].value;
		});

		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Submit!'
		}).then((result) => {
			if (result.value) {
				let data = {
					report_id: this.props.data.custom_performance_report_id,
					field_values: newFieldValues
				}
				this.props.handleSubmit(data);
			}
		})
	}

	handlePublish = () => {
		for (const obj in this.state.data) {
			if (this.state.data[obj].required === 1 & this.state.data[obj].value === '') {
				swal('Error', 'Required field must not be blank', 'error');
				return;
			}
		}

		let newFieldValues = {}
		Object.keys(this.state.data).forEach(obj => {
			newFieldValues[obj] = this.state.data[obj].value == null ? "" : this.state.data[obj].value;
		});

		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Publish!'
		}).then((result) => {
			if (result.value) {
				let data = {
					report_id: this.props.data.custom_performance_report_id,
					field_values: newFieldValues
				}
				this.props.handlePublish(data);
			}
		})
	}

	componentWillMount() {
		if (this.props.data != null) {
			let newObj = {};
			this.props.data.fields.forEach(obj => {
				// newObj[obj.id] = obj.value == null ? "" : obj.value;
				newObj[obj.id] = {
					value: (obj.value == null ? "" : obj.value),
					required: obj.required
				}
			});

			this.setState({
				data: newObj
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.data != null) {
			let newObj = {};
			nextProps.data.fields.forEach(obj => {
				// newObj[obj.id] = obj.value == null ? "" : obj.value;
				newObj[obj.id] = {
					value: (obj.value == null ? "" : obj.value),
					required: obj.required
				}
			});

			this.setState({
				data: newObj
			});
		}
	}

	render() {
		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD'}
				disabledTime={null}
				timePicker={null}
				defaultValue={moment()}
				showDateInput={false}
			/>
		);

		const calendarAndTime = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD HH:mm:ss'}
				disabledTime={null}
				defaultValue={moment()}
				showDateInput={false}
				timePicker={<TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} />}
			/>
		);
		return (
			<Fragment>
				<div className="report-field">
					{(this.props.data.fields != null) && this.props.data.fields.map((field, index) => {
						if (field.type === 'text') {
							return (
								<div key={index} className="form-group field-item">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<TextareaAutosize
										minRows={3}
										maxRows={14}
										className="form-control no-transition"
										placeholder={field.title}
										name={field.id}
										onChange={event => this.handleInputChange(event)}
										value={this.state.data[field.id].value}
									/>
								</div>
							)
						} else if (field.type === 'checkbox') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="d-flex flex-wrap">
										{field.fields.map((option, optionIndex) => {
											return (
												<div key={optionIndex} className="custom-control custom-checkbox mr-4">
													<input type="checkbox" className="custom-control-input" id={`${option.textvalue}-${optionIndex}-${index}`} />
													<label className="custom-control-label" htmlFor={`${option.textvalue}-${optionIndex}-${index}`}>{option.textvalue}</label>
												</div>
											)
										})}
									</div>
								</div>
							)
						} else if (field.type === 'radio') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="d-flex flex-wrap">
										{field.fields.map((option, optionIndex) => {
											return (
												<div key={optionIndex} className="custom-control custom-radio mr-4">
													<input type="radio" name={field.id} onChange={event => this.handleInputChange(event)} checked={this.state.data[field.id].value} value={option.textvalue} className="custom-control-input" id={`${option.textvalue}-${optionIndex}-${index}`} />
													<label className="custom-control-label" htmlFor={`${option.textvalue}-${optionIndex}-${index}`}>{option.textvalue}</label>
												</div>
											)
										})}
									</div>
								</div>
							)
						} else if (field.type === 'dropdown') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<select name={field.id} value={this.state.data[field.id].value} onChange={event => this.handleInputChange(event)} className="form-control custom-select">
										<option>Open this select menu</option>
										{field.fields.map((option, optionIndex) => {
											return (
												<option key={optionIndex} value={option.textvalue}>{option.textvalue}</option>
											)
										})}
									</select>
								</div>
							);
						} else if (field.type === 'number') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<input type="number" className="form-control" name={field.id} onChange={event => this.handleInputChange(event)} value={this.state.data[field.id].value} placeholder={field.title} />
								</div>
							);
						} else if (field.type === 'date') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="input-effect-1 p-0 col-sm-4">
										<div className="datepicker" id="datepicker">
											<DatePicker
												animation="slide-up"
												disabled={false}
												calendar={calendar}
												value={moment()}
												getCalendarContainer={() => document.getElementById('datepicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={false}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={value.format('MM-DD-YYYY')}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
								</div>
							);
						} else if (field.type === 'time') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="timepicker input-group p-0 col-sm-4">
										<TimePicker showSecond={false} />
										<div className="input-group-append">
											<span className="input-group-text">
												<FontAwesomeIcon icon={faClock} />
											</span>
										</div>
									</div>
								</div>
							);
						} else if (field.type === 'datetime') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="input-effect-1 p-0 col-sm-4">
										<div className="datepicker" id="datepicker">
											<DatePicker
												animation="slide-up"
												disabled={false}
												calendar={calendarAndTime}
												value={moment()}
												getCalendarContainer={() => document.getElementById('datepicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={false}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={value.format('MM-DD-YYYY - HH:mm:ss')}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
								</div>
							);
						} else if (field.type === 'file') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<Dropzone
										className="drop-area d-flex align-items-center"
										multiple={false}
										accept=".xls, .csv"
									>
										<div className="text">
											<p>
												<span className="icon icon-upload">
													<FontAwesomeIcon icon={faCloudUploadAlt} className="fa fa-file-excel" />
												</span>
											</p>
											<p>Drop file here to click upload</p>
										</div>
									</Dropzone>
								</div>
							);
						}
						return 0;
					})}
					<button onClick={this.handleSave} className="btn btn-orange mr-2">Save Report</button>
					<button onClick={this.handleSubmit} className="btn btn-orange mr-2">Submit Report</button>
					{getPermission().some(role => ['administrator', 'allocator'].includes(role))
						&& <button onClick={this.handlePublish} className="btn btn-orange">Publish Report</button>
					}
				</div>
			</Fragment>
		);
	}
}

export default WriteReportField;