import {connect} from 'react-redux';
import OfficialDashboard from './official_dashboard';
import {fetchOfficialCalendarData} from '../../../../actions/dashboard';

function mapsDispatchToProps (dispatch) {
    return {
        fetchCalendarData: () => dispatch(fetchOfficialCalendarData())
    }
}

function mapsStateToProps (state) {
    return {
        calendar_data: state.dashboard.official_calendar_data,
        loading: state.dashboard.is_loading
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(OfficialDashboard);