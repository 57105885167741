import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../loader/loader';
import ReactTable from 'react-table';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../../pagination/pagination';
import moment from 'moment';
import { getOrganisations } from '../../../../../../constants';


export default class SpecificResults extends Component {
	constructor(props) {
		super(props);

		this.handleEdit = this.handleEdit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentWillMount() {
		this.props.fetchResults();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.form_success === true) {
			this.props.onCloseEditSpecificModal();
			this.props.availabilitySuccess(false);
		}
	}

	handleEdit = (id) => {
		this.props.onOpenEditSpecificModal(id);
	}

	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.removeAvailability(id);
			}
		})
	}

	getOrganisationName = id => {
		return getOrganisations().filter(org => org.org_id === id)[0].organisation_name;
	};

	render() {
		const data = this.props.availabilities;

		const columns = [{
			Header: 'Organisation',
			Cell: row => (
				<div>
					{console.log('row', row.original)}
					{row.original.specific_org === 0 ? 'All Organisations' : this.getOrganisationName(row.original.specific_org)}
				</div>
			)
		},{
			Header: 'Date',
			minWidth: 110,
			Cell: row => (
				<div className="date-time">
					{/* {moment(row.original.date).format("MMM DD, YYYY")} */}
					{row.original.type === 1 || row.original.type === 2
						? moment(row.original.date).format("MMM DD, YYYY")
						: row.original.type === 3
							? moment(row.original.startdate).format("MMM DD, YYYY") + ' - ' + moment(row.original.enddate).format("MMM DD, YYYY")
							: ''
					}
				</div>
			)
		}, {
			Header: 'Time',
			Cell: row => (
				<div className="date-time">
					{/* {moment(row.original.starttime, "HH:mm").format("LT")} to {moment(row.original.endtime, "HH:mm").format("LT")} */}
					{row.original.type === 1
						? 'Full Day'
						: row.original.type === 2
							? moment(row.original.starttime, "HH:mm").format("LT") + ' to ' + moment(row.original.endtime, "HH:mm").format("LT")
							: row.original.type === 3
								? '-'
								: ''
					}
				</div>
			)
		}, {
			Header: 'Hours',
			Cell: row => (
				<div className="date-time">
					{/* {moment.duration(moment(row.original.endtime, "HH:mm").diff(moment(row.original.starttime, "HH:mm"))).asHours()} Hours */}
					{row.original.type === 1
						? 'Full Day'
						: row.original.type === 2
							? moment.utc(moment.duration(moment(row.original.endtime, "HH:mm").diff(moment(row.original.starttime, "HH:mm"))).asMilliseconds()).format("H[ Hours] m[ Minutes]")
							: row.original.type === 3
								? '-'
								: ''
					}
				</div>
			)
		}, {
			Header: 'Availability',
			Cell: row => (
				<div>
					{row.original.available === -1 && 'Unavailable'}
					{row.original.available === 1 && 'Available'}
				</div>
			)
		}, {
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li><button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button></li>
						<li><span className="seprator">|</span></li>
						<li><button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button></li>
					</ul>
				</div>
			)
		}];
		return (
			<div className="table-area">
				{this.props.loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText="No Specific Availability Found."
					defaultPageSize={10}
					resizable={true}
				/>
			</div>
		);
	}
}

SpecificResults.propTypes = {
	fetchResults: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	availabilities: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
			available: PropTypes.number,
			start_time: PropTypes.string,
			end_time: PropTypes.string,
		}).isRequired
	),
	removeAvailability: PropTypes.func.isRequired,
	availabilitySuccess: PropTypes.func.isRequired
}