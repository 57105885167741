import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { REGISTER_PERFORMED, REGISTER_CAPTCHA, registerError, loaderStatus, registerAlertError, registerAlertSuccess, registerVerified, ACCEPT_TOKEN_PERFORMED, acceptTokenSuccess, acceptTokenError } from '../actions/register';
// import { push } from 'connected-react-router';
import {API_BASE_URL} from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';


function* doRegister({formData, verified}) {
	yield put(loaderStatus(true));
	if (verified)
	{
		try {
			const registerResults = yield call(
				axios.post,
				`${API_BASE_URL}/v1/auth/signup`,
				formData,
				{
					headers: {
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'Access-Control-Allow-Origin': '*'
					}
				}
			);
			yield put(loaderStatus(false));
			yield put(registerAlertSuccess(registerResults.data.message, true));
			//yield put(registerSuccess(registerResults.data.data));
		}catch (error) {
			if(error.response.status === 422)
			{
				yield put(loaderStatus(false));
				yield put(registerError(error.response.data.errors));
			}
			else
			{
				yield put(loaderStatus(false));
				yield put(registerAlertError(error.response.data.message, true));
			}
		}
	}
	else
	{
		yield put(registerAlertError('Please verify that you are a human!', true));
		yield put(loaderStatus(false));
	}

}

function* checkCaptchaStatus({status}) {
	if (status) {
		yield put(registerVerified(status));
		yield put(registerAlertError('', false));
	}
	else
	{
		yield put(registerAlertError('Please verify that you are a human!', true));
		yield put(registerVerified(status));
	}
}

function* doAcceptToken({formData}) {
	yield put(loaderStatus(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/accept`,
			formData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*'
				}
			}
		);
		yield put(loaderStatus(false));
		swal(
            'Success',
            results.data.message,
            'success'
		);
		yield put(acceptTokenSuccess(true));
		// yield put(registerAlertSuccess(results.data.message, true));
		//yield put(registerSuccess(registerResults.data.data));
	}catch (error) {
		yield put(loaderStatus(false));
		console.log(error);
		if(error.response.status === 422)
        {
            yield put(acceptTokenError(error.response.data.errors));
		}
		if(error.response.status === 409)
		{
			swal(
                'Error',
                error.response.data.message,
                'error'
            );
		}
		// if(error.response.status === 422)
		// {
		// 	yield put(loaderStatus(false));
		// 	yield put(registerError(error.response.data.errors));
		// }
		// else
		// {
		// 	yield put(loaderStatus(false));
		// 	yield put(registerAlertError(error.response.data.message, true));
		// }
	}
}


export default function* () {
	yield takeLatest(REGISTER_PERFORMED, doRegister);
	yield takeLatest(REGISTER_CAPTCHA, checkCaptchaStatus);
	yield takeLatest(ACCEPT_TOKEN_PERFORMED, doAcceptToken);
}
