import {
	VENUE_LOADER_SHOW,
	FETCH_VENUES_SUCCESS,
	VENUE_CREATE_SUCCESS,
	VENUE_CREATE_ERROR,
	VENUE_DELETE_SUCCESS,
	GET_VENUE_DETAIL_SUCCESS,
	VENUE_EDIT_SUCCESS,
	VENUE_EDIT_ERROR,
	GET_REGIONS_FOR_VENUE_SUCCESS
} from '../actions/venue';

const initialState = {
	results: [],
	is_loading: false,
	form_success: false,
	create_errors: {},
	is_deleted: false,
	venue_detail: {},
	edit_errors: {},
	edit_success: false,
	region_list: []
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_VENUES_SUCCESS:
			return {
				...state,
				results: actions.results,
				form_success: false,
				is_deleted: false,
				create_errors: {},
				edit_errors: {},
				edit_success: false
			};
		case VENUE_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case VENUE_CREATE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				create_errors: {}
			};
		case VENUE_CREATE_ERROR:
			return {
				...state,
				create_errors: actions.errors
			};
		case VENUE_DELETE_SUCCESS:
			return {
				...state,
				is_deleted: actions.status
			}
		case GET_VENUE_DETAIL_SUCCESS:
			return {
				...state,
				venue_detail: actions.venue
			}
		case VENUE_EDIT_ERROR:
			return {
				...state,
				edit_errors: actions.errors
			}
		case VENUE_EDIT_SUCCESS:
			return {
				...state,
				edit_success: actions.status
			}

		case GET_REGIONS_FOR_VENUE_SUCCESS:
			return {
				...state,
				region_list: actions.results
			}
		default:
			return state;
	}
}