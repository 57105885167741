import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import Pagination from '../../../pagination/pagination';
import withAuth from '../../../AuthHOC/withAuth';
import { connect } from 'react-redux';
import { fetchBankReport, exportBankReport } from '../../../../actions/reports';
import Loader from '../../../loader/loader';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL, getUserId } from '../../../../constants/index';
import swal from 'sweetalert2/dist/sweetalert2.js';
import axios from 'axios';

const now = moment();
const before = moment().subtract(7, 'd');
const format = 'YYYY-MM-DD';

class BankReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_start_date: before,
            report_end_date: now,
            loading: false
        };
        this.setLoading = this.setLoading.bind(this);
    }

    setLoading(val) {
        this.setState({ loading: val });
    }
    // state = {
    //     report_start_date: before,
    //     report_end_date: now,
    //     loading: false,
    //     modal: false,
    // };
    // setLoading(val) {
    //     this.setState({ loading: val });
    // }

    componentWillMount() {
        this.props.fetchBankReport();
    }
    exportBankRecordToCSV() {
        let start_date = this.report_start_date;
        let end_date = this.report_end_date;
        console.log("Dates:", start_date, end_date, this.report_start_date);
        let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        this.setLoading(true);
        // axios.get(`${API_BASE_URL}/v1/auth/users/bankrecords/${getUserId()}/organisation/${organisation_id}/reports/bankrecords/csv`, {
        axios.get(`${API_BASE_URL}/v1/auth/users/bankrecord/${organisation_id}/CSV/${getUserId()}`, {
            // params: {
            // 	start_date: "2021-09-22",
            // 	end_date: "2021-09-29"
            // },
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + user.access_token,
            },
            //headers: getHeaders()
            responseType: 'blob'

        }).then((response) => {
            // this.setLoading(false);
            // swal(
            // 	'Success',
            // 	response.data.message,
            // 	'success'
            // );
            this.setLoading(false);
            const mimeType = format === 'excel'
                ? 'application / vnd.openxmlformats - officedocument.spreadsheetml.sheet'
                : format === 'csv'
                    ? 'text/csv'
                    : format === 'pdf'
                        ? 'application/pdf'
                        : '';

            const fileExtension = format === 'excel'
                ? 'xlsx'
                : format === 'csv'
                    ? 'csv'
                    : format === 'pdf'
                        ? 'pdf'
                        : '';

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            link.download = `Bank Record-Report.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            swal(
                'Success',
                'Download Report Success',
                'success'
            );
        }).catch((error) => {
            this.setLoading(true);
            console.log(error);
        })
    }

    
    render() {
        //console.log('BankRecord_props', this.props);
        
        const columns = [{
            Header: 'User ID',
            Cell: row => (
				<div>
					{row.original.user_id}
                </div>
            )
        }, {
            Header: 'Bank Information',
            Cell: row => (
                <div className="match">
                    <span className="highlight" style={{ fontSize: 'inherit' }}>Bank Name:{row.original.bankname}</span>
                    <div className="date-time">
                        <ul className="list-inline">
                            <li className="list-inline-item"><span className="date">BSB:{row.original.BSB}</span></li>
                            <li className="list-inline-item"><span className="seprator">/</span></li>
                            <li className="list-inline-item"><span className="time">ACC. NO.:{row.original.accountnumber}</span></li>
                        </ul>
                    </div>
                </div>
            )
        },
        {
            Header: 'Created At',
            Cell: row => (
                moment(row.original.created_at, '').format('MMMM Do YYYY - h:mm:ss a')
            )
        }];

        return (
            <div className="bank-report">
                {this.props.loading && <Loader />}
                <div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title">Bank Report</h1>
                        <nav>
                            <ol className="breadcrumb page-title">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item current">Bank Report</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="actions">
                        <button className="btn btn-orange" onClick={() => this.exportBankRecordToCSV()}>Export to CSV</button>
                    </div>
                </div>
                <div className="box">
                    <div className="table-area">
                        <ReactTable
                            PaginationComponent={Pagination}
                            data={this.props.data}
                            columns={columns}
                            noDataText="No Bank Report Found."
                            defaultPageSize={10}
                            resizable={true}
                            minRows={5}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapsDispatchToProps(dispatch) {
    return {
        fetchBankReport: () => dispatch(fetchBankReport()),
        exportBankReport: (report, startDate, endDate) => dispatch(exportBankReport(report, startDate, endDate))
    }
}

function mapsStateToProps(state) {
    return {
        loading: state.reports.loader,
        data: state.reports.bank_data,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(BankReportPage, ['administrator', 'billing']));