import { REGION_LOADER_SHOW, FETCH_REGIONS_SUCCESS, REGION_CREATE_SUCCESS, REGION_CREATE_ERROR, GET_REGION_DETAIL_SUCCESS, REGION_EDIT_SUCCESS, REGION_EDIT_ERROR, REGION_DELETE_SUCCESS } from '../actions/region';

const initialState = {
	results: [],
	is_loading: false,
	form_success: false,
	create_errors: {},
	is_deleted: false,
	region_detail: {},
	edit_errors: {},
	edit_success: false
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_REGIONS_SUCCESS:
		console.log(actions);
			return {
				...state,
				results: actions.results,
				form_success: false,
				is_deleted: false,
				create_errors: {},
				edit_errors: {},
				edit_success: false
			};
		case REGION_LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case REGION_CREATE_SUCCESS:
			return {
				...state,
				form_success: actions.status,
				create_errors: {}
			};
		case REGION_CREATE_ERROR:
			return {
				...state,
				create_errors: actions.errors
			}
		case GET_REGION_DETAIL_SUCCESS:
			return {
				...state,
				region_detail: actions.region
			}
		case REGION_EDIT_ERROR:
			return {
				...state,
				edit_errors: actions.errors
			}
		case REGION_EDIT_SUCCESS:
			return {
				...state,
				edit_success: actions.status
			}
		case REGION_DELETE_SUCCESS:
			return {
				...state,
				is_deleted: actions.status
			}
		default:
			return state;
	}
}