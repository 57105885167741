import React, { Component } from 'react';
import RegisterForm from '../../register-form/register-form-container';
import Logo from '../../logo/logo';
import LogoBackground from '../../logo/logo-background';
import '../../../public/styles/authentication.scss';
import './register.scss';
import PropTypes from 'prop-types';
import qs from 'query-string';
import Cookies from 'js-cookie';

export default class Register extends Component {
	componentWillMount() {
		this.props.authCheck();
		const referrer = qs.parse(this.props.location.search).referrer;
		if (referrer != null) {
			Cookies.set('efficialtec-referrer', referrer, { expires: 30, path: '/register'});
		};
	}
	
	render() {
		return (
			<div className="authentication">
				<LogoBackground />
				<div className="register-container">
					<div className="register-wrap">
						<Logo />
						<RegisterForm />
					</div>
				</div>
			</div>
		);
	}
}

Register.propTypes = {
	authCheck: PropTypes.func.isRequired,
}