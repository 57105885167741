import React from 'react';
import './game-detail.scss';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../../loader/loader';
import moment from 'moment';
import withAuth from '../../../../AuthHOC/withAuth';
import classNames from 'classnames';
import Modal from 'react-responsive-modal';
import ReportField from '../../../../report-field/report-field';

class GameDetailPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			game: null,
			home: null,
			away: null,
			league: null,
			organisation : null,
			pattern: null,
			venue: null,
			fields: [],
			referees: {},
			isModalOpened: false,
			hasReport: false,
			isEdit: false,
			isError: false,
			isPublished: false,
			modalGameReports: false,
			video: null
		}
		this.modalBody = React.createRef();
		this.statusColor = this.statusColor.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handlePublish = this.handlePublish.bind(this);
		this.scrollToTop = this.scrollToTop.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	statusColor(status) {
		switch(status) {
			case "Pending": return 'yellow'
			case "Confirmed": return 'green'
			case "Declined": return 'red'
			case "Removed": return 'red'
			default: return ''
		}
	}

	openModal = () => {
		this.setState({ isModalOpened: true });
	}

	closeModal = () => {
		this.setState({ isModalOpened: false, isEdit: false });
	}

	openModalGameReports = () => {
		this.setState({ modalGameReports: true });
	}

	closeModalGameReports = () => {
		this.setState({ modalGameReports: false });
	}

	handleSave(data) {
		this.props.savePerformanceReport(this.props.match.params.id, JSON.stringify(data));
	}

	handleSubmit(data) {
		this.props.submitPerformanceReport(this.props.match.params.id, JSON.stringify(data));
	}

	handlePublish(data) {
		this.props.publishPerformanceReport(this.props.match.params.id, JSON.stringify(data));
	}

	handleEdit() {
		this.setState({
			isEdit: true
		});
	}

	scrollToTop() {
		this.modalBody.current.scrollTop = 0;
	}

	downloadGameVideo = () => {
		this.props.downloadGameVideo(this.state.video);
	};

	componentWillMount() {
		this.props.resetData();
		this.props.fetchGame(this.props.match.params.id);
		this.props.getPerformanceReport(this.props.match.params.id);
	}

	componentWillReceiveProps(nextProps) {
		if(Object.keys(nextProps.game).length > 0) {
			this.setState({
				game: nextProps.game.game,
				home: nextProps.game.home_team,
				away: nextProps.game.away_team,
				league: nextProps.game.league,
				organisation: nextProps.game.organisation,
				pattern: nextProps.game.pattern,
				venue: nextProps.game.venue,
				fields: nextProps.game.fields,
				referees: nextProps.game.referees,
				video: nextProps.game.video
			});
		}

		if (nextProps.perf_report_data == null) {
			this.setState({
				hasReport: false
			});
		} else if (nextProps.perf_report_data != null) {
			if (nextProps.perf_report_data.status === 'Submitted' || nextProps.perf_report_data.status === "Published" || nextProps.perf_report_data.status === 'Saved') {
				this.setState({
					hasReport: true
				});
			} else {
				this.setState({
					hasReport: false
				});
			}

			if (nextProps.perf_report_data.error) {
				this.setState({
					isError: true
				});
			} else {
				this.setState({
					isError: false
				});
			}

			if (nextProps.perf_report_data.status === 'Published') {
				this.setState({
					isPublished: true
				})
			} else {
				this.setState({
					isPublished: false
				})
			}
		}
	}

	render () {
		const gender = {
			'1': 'Men',
			'2': 'Women',
			'3': 'Boys',
			'4': 'Girls',
			'5': 'Mixed'
		}
		let latitude = null;
		let longitude = null;
		if (this.state.venue && this.state.venue.venue_coords != null) {
			let coords = this.state.venue.venue_coords.split(', ');
			latitude = coords[0];
			longitude = coords[1];
		}
		const { isModalOpened, modalGameReports } = this.state;
		let modalLoading = this.props.perf_report_loading;
		return (
			<div className="game-detail">
				{this.props.loading && <Loading />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">{this.state.home && `${this.state.home.team_name} vs`} {this.state.away && this.state.away.team_name}</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/games">Games</Link></li>
								<li className="breadcrumb-item current">{this.state.home && `${this.state.home.team_name} vs`} {this.state.away && this.state.away.team_name}</li>
							</ol>
						</nav>
					</div>

					<div className="actions">
						<Link to="/games"><button className="btn btn-white mr_15">Back to all Games</button></Link>
						<Link to={`/game/edit/${this.props.match.params.id}`}><button className="btn btn-orange">Edit Game</button></Link>
					</div>

				</div>
				<div className="box">
					<div className="games mb_30">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-sm-30 mb-md-30">
								<table className="table-data">
									<thead>
										<tr>
											<th width="30%">League</th>
											<td width="70%">{this.state.league && this.state.league.league_name}</td>
										</tr>
										<tr>
											<th>Date / Time</th>
											<td>{this.state.game && `${moment(this.state.game.game_date).format("MMM DD, YYYY")} /`} {this.state.game && moment(this.state.game.game_time,"HH:mm").format("LT")}</td>
										</tr>
										<tr>
											<th>Venue</th>
											<td>{this.state.venue && this.state.venue.venue_name}</td>
										</tr>
										<tr>
											<th>Gender</th>
											<td>{this.state.game && gender[this.state.game.gender]}</td>
										</tr>
										<tr>
											<th>Pattern</th>
											<td>{this.state.pattern && this.state.pattern.pattern_name}</td>
										</tr>
									</thead>
								</table>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-12 col-12">
								<div className="map">
									{/* <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=40.7127837,-74.0059413&amp;key=YOUR_API_KEY"></iframe> */}
									<iframe title="map" src={`//maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`} width="360" height="270" frameBorder="0" style={{border:0}}></iframe>
									{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4424751.652531875!2d130.68192050976077!3d-25.25529845479727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2sin!4v1543322921710" title="Venue Location"></iframe> */}
								</div>
							</div>
						</div>
					</div>
					<div className="asign-game mb_30">
						<h2>Assign Officials</h2>
						<div className="wrap">
							<div className="row">
								{this.state.fields.map((field, index) => (
									<div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12" key={index}>
										<div className="list-items">
											<div className="row no-gutters justify-content-between">
												<div className="post col-lg-4 col-md-12 col-12 mb-2 mb-lg-0 d-flex align-items-center">{field.field_name}</div>
												<div className="name-status col-lg-8 col-md-12 col-12 d-flex align-items-center">
													<div className="row no-gutters">
														<div className={classNames('status', 'align-self-center', this.statusColor(Object.keys(this.state.referees).length > 0 ? this.state.referees[field.id].status : ''))}></div>
														<div className="name align-self-center">{Object.keys(this.state.referees).length > 0 && typeof this.state.referees[field.id] === "object" ? this.state.referees[field.id].official.name : this.state.referees[field.id] }</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
	        </div>
					<div className="game-actions">
						<div className="d-flex flex-wrap align-items-center">
							{this.state.isError
								? <p style={{ color: 'red' }} className="mb-3 mr-3">{this.props.perf_report_data.message}</p>
								: <button onClick={this.openModal} className="btn btn-orange mb-3 mr-3">{this.state.hasReport ? 'View' : 'Create'} Performance Report</button>
							}
							<button className="btn btn-orange mb-3" onClick={this.openModalGameReports}>Game Report</button>
						</div>

						{this.state.video && (
							<button className="btn btn-orange mb-3" onClick={this.downloadGameVideo}>Download Game Video</button>
						)}
						
						<Modal
							open={isModalOpened}
							onClose={this.closeModal}
							showCloseIcon= {false}
							center
							classNames={{
								modal: "modal fade show auto-width-modal"
							}}
						>
							<div className="modal-dialog modal-lg modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Performance Report</h5>
										<button type="button" className="close" onClick={this.closeModal}>
											<span>&times;</span>
										</button>
									</div>
									<div className="modal-body" ref={this.modalBody} style={{ position: 'relative' }}>
										{modalLoading && <Loading />}
										<ReportField
											hasReport={this.state.hasReport}
											isEdit={this.state.isEdit}
											isPublished={this.state.isPublished}
											data={this.props.perf_report_data}
											handleEdit={this.handleEdit}
											handleSave={this.handleSave}
											handleSubmit={this.handleSubmit}
											handlePublish={this.handlePublish}
										/>
									</div>
								</div>
							</div>
						</Modal>

						<Modal
							open={modalGameReports}
							onClose={this.closeModalGameReports}
							showCloseIcon={false}
							center
							classNames={{
								modal: "modal fade show auto-width-modal"
							}}
						>
							<div className="modal-dialog modal-lg modal-dialog-centered">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Game Reports</h5>
										<button type="button" className="close" onClick={this.closeModalGameReports}>
											<span>&times;</span>
										</button>
									</div>
								</div>
							</div>
						</Modal>
					</div>
				</div>
			</div>
		);
	}
}

GameDetailPage.propTypes = {
	fetchGame: PropTypes.func.isRequired,
	getAssignReferee: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
}

export default withAuth(GameDetailPage,['administrator','allocator']);