import { connect } from 'react-redux';
import ConflictPage from './conflicts';
import {
	fetchConflictsData,
	submitConflicts,
	submitEditConflicts,
	deleteConflicts
} from '../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		fetchConflictsData: (id) => dispatch(fetchConflictsData(id)),
		submitConflicts: (data, callback) => dispatch(submitConflicts(data, callback)),
		submitEditConflicts: (data, reportId, userId, callback) => dispatch(submitEditConflicts(data, reportId, userId, callback)),
		deleteConflicts: (reportId, userId) => dispatch(deleteConflicts(reportId, userId)),
	}
}

function mapsStateToProps(state) {
	return {
		loader: state.official.conflictsLoader,
		generalData: state.official.conflictsGeneralData,
		userData: state.official.conflictsUserData,
		modalLoader: state.official.conflictsModalLoader
	};
}

export default connect(mapsStateToProps, mapsDispatchToProps)(ConflictPage);