import { all } from 'redux-saga/effects'
import loginSaga from './login';
import registerSaga from './register';
import officialSaga from './official';
import gameSaga from './game';
import leagueSaga from './league';
import venueSaga from './venue';
import regionSaga from './region';
import teamSaga from './team';
import availabilitySaga from './availability';
import lockavailabilitySaga from './lock_availability';
import patternSaga from './pattern';
import payscaleSaga from './payscale';
import gamefeeSaga from './gamefee'
import forgotSaga from './forgot';
import abilitySaga from './ability';
import travelClaimSaga from './travel_claim';
import dashBoardSaga from './dashboard';
import performanceReportSaga from './performance_report';
import featuresSaga from './features';
import reportsSaga from './reports';
import billingSaga from './billing';
import handleErrorSaga from './handleError';
import organisationSaga from './organisation';
import acknowledgementsSaga from './acknowledgements';
import oauthSaga from './oauth';

export default function* () {
	yield all([
		loginSaga(),
		registerSaga(),
		officialSaga(),
		gameSaga(),
		leagueSaga(),
		venueSaga(),
		regionSaga(),
		teamSaga(),
		availabilitySaga(),
		lockavailabilitySaga(),
		patternSaga(),
		payscaleSaga(),
		gamefeeSaga(),
		forgotSaga(),
		abilitySaga(),
		travelClaimSaga(),
		dashBoardSaga(),
		performanceReportSaga(),
		featuresSaga(),
		reportsSaga(),
		billingSaga(),
		handleErrorSaga(),
		organisationSaga(),
		acknowledgementsSaga(),
		oauthSaga()
	])
}