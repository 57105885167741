import { connect } from 'react-redux';
import OfficialResults from './official-results';
import { fetchOfficialPerformed, deleteOfficial } from '../../../../../actions/official';

function mapsDispatchToProps (dispatch) {
    return {
        fetchOfficials: () => dispatch(fetchOfficialPerformed()),
        removeOfficial: (id) => dispatch(deleteOfficial(id))
    };
}

function mapsStateToProps (state) {
    return {
        officials: state.official.results,
        loading: state.official.is_loading

    };
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(OfficialResults);