import React, { Fragment } from 'react';
import './official-game.scss';
import { Link } from 'react-router-dom';
import GameResults from './game-results/game_results.container';
import withAuth from '../../../AuthHOC/withAuth';
import classNames from 'classnames';
import moment from 'moment';
import dateIcon from '../../../../public/svg/date.svg';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
const format = 'YYYY-MM-DD';
const now = moment();

class OfficialGames extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showDateInput: false,
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				chooseDate: 'date-range'
			},
			isFilterActive: false,
			isSortByActive: false,
			sortByFilters: []
		};
		this.toggleBtn = React.createRef();
		this.toggleBtnSortBy = React.createRef();
		this.onTimeChange = this.onTimeChange.bind(this);
	}

	componentDidMount() {
		this.props.fetchGamesData();
		const rememberedFilters = JSON.parse(localStorage.getItem('officialFilters'));
		const rememberedSortBy = JSON.parse(localStorage.getItem('officialSortby'));

		let fetch = [];

		if (rememberedFilters != null) {
			fetch.push(this.generateParams(rememberedFilters));
			this.setState({
				isFilterActive: true,
				filters: rememberedFilters
			});
		} else {
			fetch.push(new URLSearchParams());
			this.setState({
				isFilterActive: false
			});
		}

		if (rememberedSortBy != null) {
			fetch.push(this.generateParamsSortBy(rememberedSortBy));
			this.setState({
				isSortByActive: true,
				sortByFilters: rememberedSortBy
			});
		} else {
			fetch.push(new URLSearchParams());
			this.setState({
				isSortByActive: false
			});
		}

		this.props.fetchGames(...fetch);
	};

	onTimeChange(value) {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_time: value.format("HH:mm")
				}
			}
		})
	}

	onChange = (value) => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: value
				}
			}
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	handleFilterChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState(state => {
			return {
				filters: {
					...state.filters,
					[name]: value
				}
			}
		});
	}

	onResetFilter = () => {
		const { sortByFilters, isSortByActive } = this.state;
		this.setState({
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				chooseDate: 'date-range'
			},
			isFilterActive: false
		}, () => {
				localStorage.removeItem('officialFilters');
		});
		this.toggleBtn.click();

		let fetch = [];
		fetch.push(new URLSearchParams());

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		} else {
			fetch.push(new URLSearchParams());
		}

		this.props.fetchGames(...fetch);
	}

	onApplyFilter = () => {
		const { filters, sortByFilters, isSortByActive } = this.state;
		localStorage.setItem('officialFilters', JSON.stringify(filters));
		this.toggleBtn.click();
		this.setState({
			isFilterActive: true
		})

		let fetch = [];
		fetch.push(this.generateParams(filters));

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		}

		this.props.fetchGames(...fetch);
	};

	generateParams = filters => {
		let date;
		if (filters.chooseDate === 'date-range') {
			date = {
				daterange: filters.date_range === 0 ? null : filters.date_range,
				game_date: null
			}
		} else {
			date = {
				daterange: null,
				game_date: filters.game_date == null ? null : moment(filters.game_date).format(format),
			}
		}

		let obj = {
			venue_id: filters.venue === 0 ? null : filters.venue,
			league_id: filters.league === 0 ? null : filters.league,
			gender: filters.gender === 0 ? null : filters.gender,
			game_time: filters.game_time === 0 ? null : filters.game_time,
			home_team_id: filters.home_team === 0 ? null : filters.home_team,
			away_team_id: filters.away_team === 0 ? null : filters.away_team,
		};

		let newObj = { ...obj, ...date };

		let params = new URLSearchParams();
		Object.keys(newObj).forEach(key => {
			if (newObj[key] != null) {
				params.append(key, newObj[key]);
			}
		});

		return params;
	};

	handleClearCalender = () => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: undefined
				}
			}
		});
	}

	genderName = id => {
		switch (id) {
			case 0: return 'NULL';
			case 1: return 'MEN';
			case 2: return 'WOMEN';
			case 3: return 'BOYS';
			case 4: return 'GIRLS';
			case 5: return 'MIXED';
			default: return '';
		}
	}

	sortByOptions = () => {
		return (
			<Fragment>
				<option value="0">Unselect</option>
				<option value="1" disabled={this.state.sortByFilters.includes("1")}>Date, newest to oldest</option>
				<option value="2" disabled={this.state.sortByFilters.includes("2")}>Date oldest to newest</option>
				<option value="3" disabled={this.state.sortByFilters.includes("3")}>Time newest to oldest</option>
				<option value="4" disabled={this.state.sortByFilters.includes("4")}>Time oldest to newest</option>
				<option value="5" disabled={this.state.sortByFilters.includes("5")}>Venue A- Z</option>
				<option value="6" disabled={this.state.sortByFilters.includes("6")}>Venue Z - A</option>
				<option value="7" disabled={this.state.sortByFilters.includes("7")}>League A - Z</option>
				<option value="8" disabled={this.state.sortByFilters.includes("8")}>League Z - A</option>
			</Fragment>
		)
	};

	handleSortBy = (e, index) => {
		let newArr = [...this.state.sortByFilters];

		if (Number(e.target.value) === 0) {
			newArr.splice(index, 1);
		} else {
			newArr[index] = e.target.value;
		}

		this.setState({
			sortByFilters: newArr
		});
	}

	generateParamsSortBy = sortBy => {
		let params = new URLSearchParams();
		sortBy.forEach((value, key) => {
			params.append(`sortby[${key}]`, value);
		});
		return params;
	}

	onApplySortBy = () => {
		const { filters, sortByFilters, isFilterActive } = this.state;

		localStorage.setItem('officialSortby', JSON.stringify(sortByFilters));
		this.toggleBtnSortBy.click();
		this.setState({
			isSortByActive: true
		});

		let params = new URLSearchParams();
		this.state.sortByFilters.forEach(value => {
			params.append('sortby', value);
		});

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}
		fetch.push(this.generateParamsSortBy(sortByFilters));
		this.props.fetchGames(...fetch);
	};

	onResetSortBy = () => {
		const { filters, isFilterActive } = this.state;
		this.setState({
			sortByFilters: [],
			isSortByActive: false
		}, () => {
			localStorage.removeItem('officialSortby');
		});
		this.toggleBtnSortBy.click();

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}

		fetch.push(new URLSearchParams());
		this.props.fetchGames(...fetch);
	};

	render() {
		const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={this.state.showDateInput}
			showClear={true}
		/>);
		const dataIsNotEmpty = Object.keys(this.props.gamesData).length !== 0;
		const optionVenue = dataIsNotEmpty && this.props.gamesData.venue_data.map(venue =>
			<option key={venue.id} value={venue.id}>{venue.venue_name}</option>
		);
		const optionLeague = dataIsNotEmpty && this.props.gamesData.league_data.map(league =>
			<option key={league.id} value={league.id}>{league.league_name}</option>
		);
		const optionGender = dataIsNotEmpty && this.props.gamesData.gender_data.map(gender =>
			<option key={gender} value={gender}>{this.genderName(gender)}</option>
		);
		const optionTeam = dataIsNotEmpty && this.props.gamesData.team_data.map(team =>
			<option key={team.id} value={team.id}>{team.team_name}</option>
		);

		return (
			<div className="official-game game-list-page">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Allocated Games</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item">
									<Link to="/">Home</Link>
								</li>
								<li className="breadcrumb-item current">Allocated Games</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="action mb_30">
					<button
						ref={(node) => this.toggleBtnSortBy = node}
						className={classNames('btn', { 'btn-standard': !this.state.isSortByActive }, { 'btn-active': this.state.isSortByActive })}
						data-toggle="modal"
						data-target="#setup"
					>
						View Setup {this.state.isSortByActive && '(Active)'}
					</button>
					<button
						ref={(node) => this.toggleBtn = node}
						className={classNames('btn', { 'btn-standard': !this.state.isFilterActive }, { 'btn-active': this.state.isFilterActive })}
						data-toggle="modal"
						data-target="#filter"
					>
						Filter {this.state.isFilterActive && 'is Active'}
					</button>
				</div>

				<div className="modal fade" id="filter">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Filter</h5>
								<button type="button" className="close" data-dismiss="modal">
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label">Venue</label>
											<div className="input-effect-1">
												<select value={this.state.filters.venue} name="venue" onChange={event => this.handleFilterChange(event)} className="normal-select">
													<option value={0}>Select Venue</option>
													{optionVenue}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label">League</label>
											<div className="input-effect-1">
												<select value={this.state.filters.league} name="league" onChange={event => this.handleFilterChange(event)} className="normal-select">
													<option value={0}>Select League</option>
													{optionLeague}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label">Gender</label>
											<div className="input-effect-1">
												<select value={this.state.filters.gender} name="gender" onChange={event => this.handleFilterChange(event)} className="normal-select">
													<option value={0}>Select Gender</option>
													{optionGender}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label d-flex align-items-center"><input className="mr-1" type="radio" name="chooseDate" value="date-range" checked={this.state.filters.chooseDate === 'date-range'} onChange={event => this.handleFilterChange(event)} /> Date Range</label>
											<div className="input-effect-1">
												<select className={classNames("normal-select", { "disabled-datepicker": (this.state.filters.chooseDate !== 'date-range') })} value={this.state.filters.date_range} disabled={(this.state.filters.chooseDate !== 'date-range')} name="date_range" onChange={event => this.handleFilterChange(event)}>
													<option value={0}>Select Range</option>
													<option value={1}>1 Day</option>
													<option value={3}>3 Days</option>
													<option value={7}>1 Week</option>
													<option value={14}>2 Weeks</option>
													<option value={21}>3 Weeks</option>
													<option value={28}>4 Weeks</option>
													<option value="future">All Future Games</option>
													<option value="past">All Past Games</option>
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label d-flex align-items-center justify-content-between">
												<div>
													<input className="mr-1" type="radio" name="chooseDate" value="game-dates" checked={this.state.filters.chooseDate === 'game-dates'} onChange={event => this.handleFilterChange(event)} />
													Game Dates
													</div>
												{this.state.filters.game_date != null && <button className="button-link" onClick={this.handleClearCalender}>Clear</button>}
											</label>
											<div className="input-effect-1">
												<div className={classNames("datepicker", { "disabled-datepicker": (this.state.filters.chooseDate !== 'game-dates') })} id="datepicker">
													<DatePicker
														animation="slide-up"
														disabled={(this.state.filters.chooseDate !== 'game-dates')}
														calendar={calendar}
														value={this.state.filters.game_date}
														onChange={this.onChange}
														getCalendarContainer={() => document.getElementById('datepicker')}
														className="custom-calendar"
														showClear={true}
													>
														{
															({ value }) => {
																return (
																	<div className="input-group">
																		<input
																			placeholder="Select Date"
																			disabled={(this.state.filters.chooseDate !== 'game-dates')}
																			readOnly
																			tabIndex="-1"
																			className="ant-calendar-picker-input ant-input form-control"
																			value={value == null ? '' : moment(value).format(format)}
																		/>
																		<div className="input-group-append">
																			<span className="input-group-text">
																				<img src={dateIcon} alt="date" className="imgsvg" />
																			</span>
																		</div>
																	</div>
																);
															}
														}
													</DatePicker>
												</div>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label">Time</label>
											<div className="timepicker">
												<div className="input-group">
													<TimePicker showSecond={false} value={moment(this.state.filters.game_time, 'HH:mm')} onChange={this.onTimeChange} />
													<div className="input-group-append">
														<span className="input-group-text">
															<img src={dateIcon} alt="date" className="imgsvg" />
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Home Team</label>
											<div className="input-effect-1">
												<select value={this.state.filters.home_team} name="home_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
													<option value={0}>Select Home Team</option>
													{optionTeam}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<label className="label">Away Team</label>
											<div className="input-effect-1">
												<select value={this.state.filters.away_team} name="away_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
													<option value={0}>Select Away Team</option>
													{optionTeam}
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer text-center">
								<button type="button" className="btn btn-orange" onClick={this.onApplyFilter}>Apply</button>
								<button type="button" className="btn btn-orange" onClick={this.onResetFilter}>Reset Filter</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" id="setup">
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">View Setup</h5>
								<button type="button" className="close" data-dismiss="modal">
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-xl-4 col-lg-4 col-md-4 col-12">
										<div className="form-group">
											<label className="label">Sort By</label>
											<div className="input-effect-1">
												<select
													className="normal-select"
													onChange={e => this.handleSortBy(e, 0)}
													value={
														this.state.sortByFilters[0] == null
															? 0
															: this.state.sortByFilters[0]
													}
												>
													<this.sortByOptions />
												</select>
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
									{[...Array(5)].map((element, index) => (
										<div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Then By</label>
												<div className="input-effect-1">
													<select
														className="normal-select"
														disabled={this.state.sortByFilters.length < (Number(index + 1))}
														onChange={e => this.handleSortBy(e, Number(index + 1))}
														value={
															this.state.sortByFilters[Number(index + 1)] == null
																? 0
																: this.state.sortByFilters[Number(index + 1)]
														}
													>
														<this.sortByOptions />
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
							<div className="modal-footer text-center">
								<button type="button" className="btn btn-orange" onClick={this.onApplySortBy}>Apply</button>
								<button type="button" className="btn btn-orange" onClick={this.onResetSortBy}>Reset</button>
							</div>
						</div>
					</div>
				</div>
				<GameResults
					games={this.props.games}
				/>
			</div>
		);
	}
}
export default withAuth(OfficialGames, ['official']);