import React, { Component } from 'react';
import Charts from './charts';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import withAuth from '../../../AuthHOC/withAuth';
import { getAllAvaialbleOfficial } from '../../../../actions/official';
import { connect } from 'react-redux';
import Loader from '../../../loader/loader';
import { addMinutes, subMinutes } from 'date-fns';

class AvailableOfficials extends Component {
	state = {
		date: new Date(),
		startTime: new Date(null, null, null, 8),
		endTime: new Date(null, null, null, 20),
		loading: false
	};

	chartRef = React.createRef();

	componentDidMount() {
		// this.props.getAllAvaialbleOfficial(...this.getCurrentData(), this.chartRef.current.setDefaultScrollPosition);
		this.props.getAllAvaialbleOfficial(...this.getCurrentData());
	}

	getCurrentData = () => {
		const date = moment(this.state.date).format('YYYY-MM-DD');
		const start = moment(this.state.startTime).format('HH:mm');
		const end = moment(this.state.endTime).format('HH:mm');
		return [date, start, end];
	}

	formattedData = data => {
		if (data != null) {
			return Object.keys(data).map(obj => data[obj]);
		} else {
			return []
		}
	};

	handleChangeDate = value => {
		this.props.data.data = undefined;
		this.setState({
			date: value
		}, () => {
				this.props.getAllAvaialbleOfficial(...this.getCurrentData());
		});
	}

	incrementDate = () => {
		this.props.data.data = undefined;
		let date = this.state.date;
		date.setDate(date.getDate() + 1);

		this.handleChangeDate(date);
	};

	decrementDate = () => {
		this.props.data.data = undefined;
		let date = this.state.date;
		date.setDate(date.getDate() - 1);

		this.handleChangeDate(date);
	}

	handleChangeStartTime = value => {
		this.props.data.data = undefined;
		this.setState({
			startTime: value
		}, () => {
				this.props.getAllAvaialbleOfficial(...this.getCurrentData());
		});
	}

	handleChangeEndTime = value => {
		this.props.data.data = undefined;
		this.setState({
			endTime: value
		}, () => {
				this.props.getAllAvaialbleOfficial(...this.getCurrentData());
		});
	}

	getExcludedTimes = (currentTime, value) => {
		if (value === 'before') {
			let timesInterval = moment(moment(currentTime).format('HH:mm'), 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes') / 15;
			let excludedTimes = [];
			for (let x = 0; x <= timesInterval; x++) {
				excludedTimes.push(
					addMinutes(new Date(null, null, null, 0), (x * 15))
				);
			}
			return excludedTimes;
		} else if (value === 'after') {
			let timesInterval = moment('23:45', 'HH:mm').diff(moment(moment(currentTime).format('HH:mm'), 'HH:mm'), 'minutes') / 15;
			let excludedTimes = [];
			for (let x = 0; x <= timesInterval; x++) {
				excludedTimes.push(
					addMinutes(currentTime, (x * 15))
				);
			}
			return excludedTimes;
		};
		return [];
	};

	render() {
		return (
			<div className="available-official">
				{!this.props.data.data && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Available Officials</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Available Officials</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
						<div className="date-box-available">
							<FontAwesomeIcon icon={faChevronLeft} className="icon-date" onClick={this.decrementDate} />
							<DatePicker
								selected={this.state.date}
								onChange={this.handleChangeDate}
								customInput={<CustomDate />}
							/>
							<FontAwesomeIcon icon={faChevronRight} className="icon-date" onClick={this.incrementDate} />
						</div>
						<div className="d-flex flex-wrap justify-content-end align-items-center">
							<div className="mr-4 d-flex flex-wrap justify-content-end align-items-center">
								<p className="mr-2">Start Time :</p>
								<DatePicker
									selected={this.state.startTime}
									onChange={this.handleChangeStartTime}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									timeCaption="Time"
									dateFormat="h:mm aa"
									customInput={<CustomTime />}
									// excludeTimes={this.getExcludedTimes(this.state.endTime, 'after')}
									minTime={new Date(null, null, null, 0)}
									maxTime={subMinutes(this.state.endTime, 15)}
								/>
							</div>
							<div className="d-flex flex-wrap justify-content-end align-items-center">
								<p className="mr-2">End Time :</p>
								<DatePicker
									selected={this.state.endTime}
									onChange={this.handleChangeEndTime}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									timeCaption="Time"
									dateFormat="h:mm aa"
									customInput={<CustomTime />}
									// excludeTimes={this.getExcludedTimes(this.state.startTime, 'before')}
									minTime={addMinutes(this.state.startTime, 15)}
									maxTime={new Date(null, null, null, 23, 45)}
								/>
							</div>
						</div>
					</div>
					
					<Charts
						ref={this.chartRef}
						data={this.formattedData(this.props.data['data'])}
						startTime={this.state.startTime}
						endTime={this.state.endTime}
					/>
				</div>
			</div>
		);
	}
}

class CustomDate extends Component {
	render() {
		return (
			<p className="date-text" onClick={this.props.onClick}>{moment(this.props.value, 'MM/DD/YYYY').format('DD MMM, YYYY')}</p>
		);
	}
}

class CustomTime extends Component {
	render() {
		return (
			<p className="time-text" onClick={this.props.onClick}>{this.props.value}</p>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		getAllAvaialbleOfficial: (date, startTime, endTime, callback) => dispatch(getAllAvaialbleOfficial(date, startTime, endTime, callback))
	}
}

function mapsStateToProps(state) {
	return {
		loading: state.official.is_loading,
		data: state.official.allAvailableOfficials
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(AvailableOfficials, ['administrator']));