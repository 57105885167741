export const REGISTER_PERFORMED = 'REGISTER_PERFORMED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_ALERT_ERROR = 'REGISTER_ALERT_ERROR';
export const REGISTER_ALERT_SUCCESS = 'REGISTER_ALERT_SUCCESS';
export const LOADER_SHOW = 'LOADER_SHOW';
export const REGISTER_CAPTCHA = 'REGISTER_CAPTCHA';
export const REGISTER_VERIFIED = 'REGISTER_VERIFIED';
export const ACCEPT_TOKEN_PERFORMED = 'ACCEPT_TOKEN_PERFORMED';
export const ACCEPT_TOKEN_SUCCESS = 'ACCEPT_TOKEN_SUCCESS';
export const ACCEPT_TOKEN_ERROR = 'ACCEPT_TOKEN_ERROR';	

export const registerError = (errors) => ({
	type: REGISTER_ERROR,
	errors
});

export const registerAlertError = (error, status) => ({
	type: REGISTER_ALERT_ERROR,
	error,
	status
});

export const registerAlertSuccess = (message, status) => ({
	type: REGISTER_ALERT_SUCCESS,
	message,
	status
});

export const registerPerformed = (formData, verified) => ({
	type: REGISTER_PERFORMED,
	formData,
	verified
});

export const registerCaptcha = (status) => ({
	type: REGISTER_CAPTCHA,
	status
});

export const registerVerified = (status) => ({
	type: REGISTER_VERIFIED,
	status
});

export const registerSuccess = (results) => ({
	type: REGISTER_SUCCESS,
	results
});

export const loaderStatus = (status) => ({
	type: LOADER_SHOW,
	status
});

export const acceptToken = (formData) => ({
	type: ACCEPT_TOKEN_PERFORMED,
	formData
});

export const acceptTokenSuccess = (status) => ({
	type: ACCEPT_TOKEN_SUCCESS,
	status
});

export const acceptTokenError = (errors) => ({
	type: ACCEPT_TOKEN_ERROR,
	errors
});