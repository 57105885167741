import React, { Component } from 'react';
import FormError from '../../../../form-error/form-error';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';

export default class NewLeaguePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            league_name: '',
            game_length: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {
            league_name,
            game_length
        } = this.state;

        const data = {
            league_name: league_name,
            game_length: game_length
        }

        this.props.onLeagueSubmitted(data);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                league_name: '',
                game_length: ''
            });
        }
    }

    render () {
        let league_name_error = null;
        let game_length_error= null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'league_name')
                    league_name_error = <FormError error={this.props.errors[key][0]} />;
                else
                    game_length_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
           <div className="new-league">
                { this.props.loading && <Loader /> }
               
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">League Name</label>
                                <div className="input-effect-1">
                                    <input type="text" name="league_name" value={this.state.league_name} onChange={event => {this.handleInputChange(event)}} className="form-control"/>
                                    <div className="focus-border"></div>
                                </div>
                                {league_name_error}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">Game Length</label>
                                <div className="input-effect-1">
                                    <input type="text" name="game_length" value={this.state.game_length} onChange={event => {this.handleInputChange(event)}} className="form-control"/>
                                    <div className="focus-border"></div>
                                </div>
                                {game_length_error}
                            </div>
                        </div>
                    </div>
                    <div className="action text-center">
                        <button className="btn btn-orange" onClick={this.handleSubmit}>Submit</button>
                    </div>
                
           </div> 
        );
    }
}

NewLeaguePage.propTypes = {
    onLeagueSubmitted: PropTypes.func.isRequired,
    errors: PropTypes.object,
    loading: PropTypes.bool,
    form_success: PropTypes.bool,
}
