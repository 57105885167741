import {connect} from 'react-redux';
import TeamResults from './team_results';
import {fetchTeamsPerformed, deleteTeam} from '../../../../../actions/team';

function mapsDispatchToProps (dispatch) {
    return {
        fetchTeams: () => dispatch(fetchTeamsPerformed()),
        removeTeam: (id) => dispatch(deleteTeam(id))
    }
}

function mapsStateToProps (state) {
    return {
        teams: state.team.results,
        loading: state.team.is_loading
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(TeamResults);