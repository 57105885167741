import { connect } from 'react-redux';
import PerformanceReportResults from './performance_report_results';
import {
	fetchPerformanceReportList,
	savePerformanceReportPerformed,
	publishPerformanceReportPerformed,
} from '../../../../../actions/performance_report';

function mapsDispatchToProps(dispatch) {
	return {
		fetchPerformanceReportList: () => dispatch(fetchPerformanceReportList()),
		savePerformanceReport: (id, data) => dispatch(savePerformanceReportPerformed(id, data)),
		publishPerformanceReport: (id, data) => dispatch(publishPerformanceReportPerformed(id, data))
	}
}

function mapsStateToProps(state) {
	return {
		data: state.performance_report.data,
		fetchLoading: state.performance_report.fetchLoading,
		modalLoading: state.performance_report.modalLoading
	}
}
export default connect(() => (mapsStateToProps), mapsDispatchToProps)(PerformanceReportResults);