import {connect} from 'react-redux';
import AssignReferee from './assign_referee';
import {getReferees, assignOfficiaToGame, getAssignReferee} from '../../../../../../actions/game';

function mapsDispatchToProps (dispatch) {
    return {
        getReferees: (id) => dispatch(getReferees(id)),
        onAssignOfficial: (formData, id) => dispatch(assignOfficiaToGame(formData, id)),
        getAssignReferee: (id) => dispatch(getAssignReferee(id))
    }
}

function mapsStateToProps (state) {
    return {
        referees: state.game.referees,
        assign_referees: state.game.assign_referees,
        loading: state.game.assign_referee_loading,
        loading_data: state.game.assign_referee_data_loading
    }
}

export default connect (() => (mapsStateToProps), mapsDispatchToProps)(AssignReferee);