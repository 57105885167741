import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import withAuth from '../../../../AuthHOC/withAuth';
import './ability.scss';
import allowYesIcon from '../../../../../public/svg/allow-yes.svg';
import allowNotIcon from '../../../../../public/svg/allow-not.svg';
import sortingIcon from '../../../../../public/svg/sorting.svg';

class AbilityPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			abilities: [],
			positions: [],
			sort: null
		}
		this.handleSinglePositionSubmit = this.handleSinglePositionSubmit.bind(this);
		this.handleAllowAllPositionForLeague = this.handleAllowAllPositionForLeague.bind(this);
		this.handleAllpositionForAllLeague = this.handleAllpositionForAllLeague.bind(this);
		this.sortPositions = this.sortPositions.bind(this);
	}
	componentWillMount(){
		this.props.fetchLeaguesPositions();
		this.props.fetchAbilities(this.props.match.params.id);
	}

	componentWillReceiveProps(nextProps) {
		if(Object.keys(nextProps.abilities).length > 0) 
		{
			this.setState({
				abilities: nextProps.abilities
			});
		}

		if(Object.keys(nextProps.positions).length > 0)
		{
			this.setState({
				positions: nextProps.positions
			});
		}
	}

	sortPositions(event) {
		event.preventDefault();
		let positions = this.state.positions;

		if(!this.state.sort)
		{
			this.setState({
				sort: 'asc'
			});
			positions.sort();
		}
		else if(this.state.sort === 'asc')
		{
			this.setState({
				sort: 'desc'
			});
			positions.sort();
			positions.reverse();
		}
		else
		{
			this.setState({
				sort: 'asc'
			});
			positions.sort();
		}
		this.setState({
			positions: positions
		});
	}

	async handleSinglePositionSubmit (event, league_id, position, status) {
		event.preventDefault();
		let obj = {};
		obj[league_id] = {[position]: status};
		let abilities = [];
		abilities.push(obj);
		const data = {
			league_x_position: abilities
		}
		this.props.onSubmitPositions(data,this.props.match.params.id);
	}

	async handleAllowAllPositionForLeague (event, league_id) {
		event.preventDefault();
		let abilities = [];
		let leagues = {};
		let obj = {};
		this.props.positions.forEach(position => {
			obj[position] = 1;
		});
		leagues[league_id] = obj;
		abilities.push(leagues);
		const data = {
			league_x_position: abilities
		};
		this.props.onSubmitPositions(data,this.props.match.params.id);
	}

	async handleAllpositionForAllLeague (event, status) {
		event.preventDefault();
		let abilities = [];
		this.props.leagues.forEach(league => {
			let leagues = {};
			let obj = {};
			this.props.positions.forEach(position => {
				obj[position]= status;
			});
			// let leagues = {};
			leagues[league.league_id] = obj;
			abilities.push(leagues);
		});

		const data = {
			league_x_position: abilities
		}
		this.props.onSubmitPositions(data,this.props.match.params.id);
	}
    render () {
        return (
            <div className="ability">
				{this.props.loading && <Loader />}
            	<div className="page-head mb_30">
            		<div className="head">
        				<h1 className="page-title">Ability</h1>
        				<nav>
        					<ol className="breadcrumb page-title">
        						<li className="breadcrumb-item"><a href="/">Home</a></li>
        						<li className="breadcrumb-item current">Ability</li>
        					</ol>
        				</nav>
        			</div>
            	</div>
            	<div className="actions mb_30">
            		<ul className="list-inline">
						<li>
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text">
										<img src={allowYesIcon}  alt="Allow for All Position"/>
									</div>
								</div>
								<div className="text"><a href="#/" onClick={(e) => this.handleAllpositionForAllLeague(e,1)}>Allow for All Position</a></div>
							</div>
						</li>
						<li>
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text">
										<img src={allowNotIcon}  alt="Block for All Position"/>
									</div>
								</div>
								<div className="text"><a href="#/" onClick={(e) => this.handleAllpositionForAllLeague(e,0)}>Block for All Position</a></div>
							</div>
						</li>
						<li>
							<div className="input-group">
								<div className="input-group-prepend">
									<div className="input-group-text">
										<img src={sortingIcon}  alt="Sort Position Alphabetically"/>
									</div>
								</div>
								<div className="text"><a href="#/" onClick={this.sortPositions}>Sort Position Alphabetically</a></div>
							</div>
						</li>
            		</ul>
    			</div>
    			<div className="box">
    				<div className="table-area">
	    				<table cellPadding="0" cellSpacing="0" className="table table-striped table-bordered">
	    					<thead>
	    						<tr>
	    							<th width="150">Position</th>
									{this.props.positions.map((position,index) => (
										<th key={index} width="20">{position}</th>
									))}
	    						</tr>
	    					</thead>
	    					<tbody>
								{this.props.leagues.map((league, idx) => (
									<tr key={idx}>
										<td>
											<span className="ptext">{league.league_name}</span>
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text">
														<img src={allowYesIcon}  alt="position"/>
													</div>
												</div>
												<div className="text"><a href="#/" onClick={(e) => this.handleAllowAllPositionForLeague(e,league.league_id)}>Allow All</a></div>
											</div>
										</td>
										{this.props.positions.map((position, pidx) => (
											<td key={pidx}>
												{this.state.abilities[league.league_id] && this.state.abilities[league.league_id][position] === 0 && (
													<span className="icon" onClick={(e) => this.handleSinglePositionSubmit(e, league.league_id, position, 1)}>
														<img src={allowNotIcon}  alt="position"/>
													</span>
												)}
												{this.state.abilities[league.league_id] && this.state.abilities[league.league_id][position] === 1 && (
													<span className="icon" onClick={(e) => this.handleSinglePositionSubmit(e, league.league_id, position, 0)}>
														<img src={allowYesIcon}  alt="position"/>
													</span>
												)}
											</td>
										))}
									</tr>
								))}
	    					</tbody>
	    				</table>
    				</div>
    			</div>
            </div>
        );
    }
}

export default withAuth(AbilityPage, ['administrator','allocator']);

AbilityPage.propTypes = {
	fetchLeaguesPositions: PropTypes.func.isRequired,
	fetchAbilities: PropTypes.func.isRequired,
	onSubmitPositions: PropTypes.func.isRequired
}
