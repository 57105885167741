import { connect } from 'react-redux';
import OfficialForm from './official-form';
import { officialPerformed } from '../../../../../actions/official';

function mapsDispatchToProps (dispatch) {
    return {
        onOfficialSubmitted: (formData) => dispatch(officialPerformed(formData))
    };
}

function mapsStateToProps (state) {
    return {
        errors: state.official.errors,
        loading: state.official.is_loading,
        form_success: state.official.form_success,
        success_msg: state.official.success_msg
    };
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(OfficialForm);

