import React, { Component } from 'react';
import './subscription.scss';
import withAuth from '../../../AuthHOC/withAuth';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { fetchSubscriptionInfo, upgradeUser } from '../../../../actions/billing';
import { connect } from 'react-redux';
import Loader from '../../../loader/loader';
import moment from 'moment';
import Modal from 'react-responsive-modal';

class Subscription extends Component {
	state = {
		modal: false,
		quantity: ''
	}
	/*Stripe
	.createpaymentMethod({
		type: 'card',
		card: cardElement,
		nilling_details:{
			name: 'Jenny Rosen',
		},

	})
	.then(function(result){
		//Handle result.error or result.paymentMethod
	});
	.retriveSetupIntent(
		'{SETUP_INTENT_CLIENT_SECRET}'
	)
	.then(function(result){
		//Handle result.error or result.paymentIntent
	})
*/
	componentWillMount() {
		this.props.fetchSubscriptionInfo();
	};

	openModal = () => {
		this.setState({
			modal: true,
			quantity: Object.keys(this.props.data).length > 0 ? this.props.data.quantity : ''
		});
	}

	closeModal = () => {
		this.setState({
			modal: false,
		});
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleNumber = () => {
		this.setState({
			quantity: this.state.quantity.toString().replace(/[^0-9]/g, '')
		})
	}

	handleUpgrade = () => {
		this.props.upgradeUser(this.state.quantity, this.closeModal);
	}

	render() {
		const notEmpty = Object.keys(this.props.data).length > 0;
		const { data } = this.props;
		return (
			<div className="subscription">
				{this.props.loader && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Subscription</h1>
					</div>
				</div>
				<div className="box">
					<div className="actions mb_30 d-flex justify-content-between flex-column flex-sm-row">
						<div className="btn-group">
							<button type="button" className="btn btn-subscription active">Subscription Detail</button>
							<Link to="/invoices"><button type="button" className="btn btn-subscription">Invoices</button></Link>
							<Link to="/payment-info"><button type="button" className="btn btn-subscription">Payment Information</button></Link>
						</div>
						<button type="button" className="btn btn-orange" onClick={this.openModal}>Upgrade User</button>
					</div>
					<div className="box">
						<div className="details">
							<h2>Subscription Detail</h2>
							<p>
								<span>Sign Up Date</span>
								<span className="divide">:</span>
								<span>{notEmpty && moment(data.signupDate, 'YYYY-MM-DD hh:mm:ss').format('MMMM Do, YYYY')}</span>
							</p>
							<p>
								<span>Subscription Start</span>
								<span className="divide">:</span>
								<span>{notEmpty && moment(data.currentPeriodStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM Do, YYYY')}</span>
							</p>
							<p>
								<span>Subscription End</span>
								<span className="divide">:</span>
								<span>{notEmpty && moment(data.currentPeriodEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM Do, YYYY')}</span>
							</p>
							<p>
								<span>Plan Name</span>
								<span className="divide">:</span>
								<span>{notEmpty && data.planName}</span>
							</p>
							<p>
								<span>Renew Date</span>
								<span className="divide">:</span>
								<span>{notEmpty && moment(data.renewDate, 'YYYY-MM-DD hh:mm:ss').format('MMMM Do, YYYY')}</span>
							</p>
							<p>
								<span>Quantity</span>
								<span className="divide">:</span>
								<span>{notEmpty && data.quantity}</span>
							</p>
							<p>
								<span>Status</span>
								<span className="divide">:</span>
								<span style={{ textTransform: 'capitalize' }}>{notEmpty && data.status}</span>
							</p>
						</div>
					</div>
				</div>
				<Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Upgrade User</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col-12">
										<div className="form-group">
											<label className="label">Quantity</label>
											<div className="input-effect-1">
												<input type="number" name="quantity" placeholder="Quantity" value={this.state.quantity} onChange={event => { this.handleInputChange(event) }} onBlur={this.handleNumber} className="form-control" />
												<div className="focus-border"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="action text-center">
									<button className="btn btn-orange" onClick={this.handleUpgrade}>Upgrade</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		fetchSubscriptionInfo: () => dispatch(fetchSubscriptionInfo()),
		upgradeUser: (qty, callback) => dispatch(upgradeUser(qty, callback))
	}
}

function mapsStateToProps(state) {
	return {
		loader: state.billing.loader,
		modalLoader: state.billing.modalLoader,
		data: state.billing.subcriptionData
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(Subscription, ['administrator']));