import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';
import dateIcon from '../../../../../public/svg/date.svg';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import Modal from 'react-responsive-modal';
import ResetPassword from '../reset-password/form.container';
import BankForm from './bank_form.container';
import { getCurrentUser } from '../../../../../constants';

const format = 'YYYY-MM-DD';
const now = moment();
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

export default class EditForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDateInput: false,
			disabled: false,
			email: '',
			name: '',
			address_line_1: '',
			mobile: '',
			date_of_birth: now,
			openReset: false,
			openBank: false
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onOpenResetModal = this.onOpenResetModal.bind(this);
		this.onCloseResetModal = this.onCloseResetModal.bind(this);
		this.onOpenBankModal = this.onOpenBankModal.bind(this);
		this.onCloseBankModal = this.onCloseBankModal.bind(this);
	}

	componentWillMount() {
		let user = JSON.parse(localStorage.getItem('user'));
		let user_id = user.user.id;
		this.props.fetchOfficial(user_id);
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.official).length > 0) {
			let date_of_birth = now;
			if (nextProps.official.data.date_of_birth) {
				date_of_birth = moment(nextProps.official.data.date_of_birth);
			}
			this.setState({
				email: nextProps.official.data.email,
				name: nextProps.official.data.name ? nextProps.official.data.name : '',
				address_line_1: nextProps.official.data.address_line_1 ? nextProps.official.data.address_line_1 : '',
				mobile: nextProps.official.data.mobile ? nextProps.official.data.mobile : '',
				date_of_birth: date_of_birth
			});
		}
		if (nextProps.bank_form_success === true) {
			this.onCloseBankModal();
		}
	}

	onOpenResetModal = (event) => {
		event.preventDefault();
		this.setState({ openReset: true });
	}

	onCloseResetModal = () => {
		this.setState({ openReset: false });
	}


	onOpenBankModal = (event) => {
		event.preventDefault();
		this.props.bankEditStatus();
		this.setState({ openBank: true });
	}

	onCloseBankModal = () => {
		this.setState({ openBank: false });
	}

	onChange = (value) => {
		this.setState({
			date_of_birth: value,
		});
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		const {
			name,
			email,
			address_line_1,
			mobile,
			date_of_birth
		} = this.state;

		const data = {
			name: name,
			email: email,
			address_line_1: address_line_1,
			mobile: mobile,
			date_of_birth: date_of_birth.format(format),
		}
		let user = JSON.parse(localStorage.getItem('user'));
		let user_id = user.user.id;
		this.props.onSubmitOfficial(data, user_id);
	}

	connectToGoogleCalendar = event => {
		event.preventDefault();
		this.props.connectToGoogleCalendar();
	};

	disconnectGoogleCalendar = event => {
		event.preventDefault();
		this.props.disconnectGoogleCalendar();
	};

	render() {
		const state = this.state;
		const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={state.showDateInput}
		/>);
		let name_error = null;
		let address_error = null;
		let mobile_error = null;
		let email_error = null;
		let date_of_birth_error = null;
		// let other_error = null;
		if (this.props.errors !== undefined) {
			for (var key in this.props.errors) {
				if (key === 'name')
					name_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'address')
					address_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'mobile')
					mobile_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'email')
					email_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'date_of_birth')
					date_of_birth_error = <FormError error={this.props.errors[key][0]} />;
				// else
				//     other_error = <FormError error={this.props.errors[key][0]} />
			}
		}
		const { openReset } = this.state;
		const { openBank } = this.state;
		return (
			<div className="col-xl-9 col-lg-7 col-md-12 col-12">
				{this.props.loading && <Loader />}
				<form>
					<div className="row mb_15">
						<div className="col-xl-4 col-lg-12 col-md-6 co-12">
							<div className="form-group">
								<label className="label">Full Name</label>
								<div className="input-effect-1">
									<input name="name" type="text" value={this.state.name} onChange={event => this.handleInputChange(event)} className="form-control" disabled />
									<span className="focus-border"></span>
								</div>
							</div>
							{name_error}
						</div>
						<div className="col-xl-4 col-lg-12 col-md-6 co-12">
							<div className="form-group">
								<label className="label">Address</label>
								<div className="input-effect-1">
									<input type="text" name="address_line_1" value={this.state.address_line_1} onChange={event => this.handleInputChange(event)} className="form-control" />
									<span className="focus-border"></span>
								</div>
							</div>
							{address_error}
						</div>
						<div className="col-xl-4 col-lg-12 col-md-6 co-12">
							<div className="form-group">
								<label className="label">Mobile Phone</label>
								<div className="input-effect-1">
									<input type="text" name="mobile" value={this.state.mobile} onChange={event => this.handleInputChange(event)} className="form-control" />
									<span className="focus-border"></span>
								</div>
								<div className="custom-checkbox">
									<input type="checkbox" className="custom-control-input" name="share-mobile-number" id="share-mobile-number" />
									<label className="custom-control-label" htmlFor="share-mobile-number">
										Share my mobile number with other officials
                                    </label>
								</div>
							</div>
							{mobile_error}
						</div>
						<div className="col-xl-4 col-lg-12 col-md-6 co-12">
							<div className="form-group">
								<label className="label">Email</label>
								<div className="input-effect-1">
									<input type="text" name="email" value={this.state.email} onChange={event => this.handleInputChange(event)} className="form-control" />
									<span className="focus-border"></span>
								</div>
								<div className="custom-checkbox">
									<input type="checkbox" className="custom-control-input" name="share-email" id="share-email" />
									<label className="custom-control-label" htmlFor="share-email">
										Share my email with other officials
									</label>
								</div>
							</div>
							{email_error}
						</div>
						<div className="col-xl-4 col-lg-12 col-md-6 co-12">
							<div className="form-group">
								<label className="label">Date of birth</label>
								<div className="input-effect-1">
									<div className="datepicker" id="datepicker">
										<DatePicker
											animation="slide-up"
											disabled={state.disabled}
											calendar={calendar}
											value={state.date_of_birth}
											onChange={this.onChange}
											getCalendarContainer={() => document.getElementById('datepicker')}
											className="custom-calendar"
										>
											{
												({ value }) => {
													return (
														<div className="input-group">
															<input
																placeholder="Select Date"
																disabled={state.disabled}
																readOnly
																tabIndex="-1"
																className="ant-calendar-picker-input ant-input form-control"
																value={value.format(format)}
															/>
															<div className="input-group-append">
																<span className="input-group-text">
																	<img src={dateIcon} alt="date" className="imgsvg" />
																</span>
															</div>
														</div>
													);
												}
											}
										</DatePicker>
									</div>
									<div className="focus-border"></div>
								</div>
							</div>
							{date_of_birth_error}
						</div>
					</div>
					<div className="action">
						<div className="row align-items-center justify-content-between">
							<div className="col-xl-9 col-lg-12 col-md-12 col-12 first">
								<button className="btn btn-orange btn-forgot mb-3 mr-3" onClick={this.onOpenResetModal}>Reset Password</button>
								<button className="btn btn-orange btn-forgot mb-3 mr-3" onClick={this.onOpenBankModal}>Edit Bank Records</button>
								{getCurrentUser().gcal === 1
									? <button className="btn btn-orange btn-forgot mb-3" onClick={this.disconnectGoogleCalendar}>Disconnect Google Calendar</button>
									: <button className="btn btn-orange btn-forgot mb-3" onClick={this.connectToGoogleCalendar}>Connect to Google Calendar</button>
								}
							</div>
							<div className="col-xl-3 col-lg-12 col-md-12 col-12 d-xl-flex justify-content-xl-end">
								<button className="btn btn-orange mb-3" type="button" onClick={this.handleSubmit}>Save</button>
							</div>
						</div>
					</div>
				</form>
				<Modal
					open={openReset}
					onClose={this.onCloseResetModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-md modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Reset Password</h5>
								<button type="button" className="close" onClick={this.onCloseResetModal}>
									<span>&times;</span>
								</button>
							</div>
							<ResetPassword />
						</div>
					</div>
				</Modal>

				<Modal
					open={openBank}
					onClose={this.onCloseBankModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Bank Information</h5>
								<button type="button" className="close" onClick={this.onCloseBankModal}>
									<span>&times;</span>
								</button>
							</div>
							<BankForm />
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

EditForm.propTypes = {
	fetchOfficial: PropTypes.func.isRequired,
	official: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	onSubmitOfficial: PropTypes.func.isRequired,
	errors: PropTypes.object,
	form_success: PropTypes.bool,
	bank_form_success: PropTypes.bool,
	bankEditStatus: PropTypes.func.isRequired
}