import React, { Component } from 'react';
import './lock_availability.scss';
import Modal from 'react-responsive-modal';
import LockForm from './new/form.container';
import LockResults from './results/lock_availability_result_container';
import withAuth from '../../../AuthHOC/withAuth';

class OrgLockAvailability extends Component {
	constructor(props) {
		super(props);
		this.state = {
			
			openLock: false,
			specific_id: '',
		}
		this.onOpenLockModal = this.onOpenLockModal.bind(this);
		this.onCloseLockModal = this.onCloseLockModal.bind(this);
		this.goToSpecificGame = this.goToSpecificGame.bind(this);
	}

	componentWillMount() {
		//this.props.fetchLockAvailability();
	}

	onOpenLockModal = () => {
		this.setState({ openLock: true });
	}

	onCloseLockModal = () => {
		this.setState({ openLock: false });
	}

	goToSpecificGame(id) {
		this.props.goToGamePageOrg(id);
	}

	render() {
		const {  openLock } = this.state;
		//const { lock_availabilities } = this.props;
		return (
			<div className="official-lock-availability">

					{/* <NewLockAvailabilityModal message={'test message'} /> */}

					
					<Modal
						open={openLock}
						onClose={this.onCloseLockModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-lg modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Lock Availability</h5>
									<button type="button" className="close" onClick={this.onCloseLockModal}>
										<span>&times;</span>
									</button>
								</div>
								<LockForm   />

							</div>
						</div>
					</Modal>
					
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Lock Availability</h1>
					</div>
					<div className="actions">
						<button onClick={this.onOpenLockModal} className="btn btn-orange">Add New</button>
					</div>
				</div>
				<div className="box mb-4">
					<LockResults onOpenEditLockModal={this.onOpenEditLockModal} onCloseEditLockModal={this.onCloseEditLockModal} />
				</div>
				{/* <div className="actions mb_30">
					{Object.keys(lock_availabilities).length > 0 && lock_availabilities.game_availability.map((obj) => {
						return <button key={obj.org_id} onClick={() => this.goToSpecificGame(obj.org_id)} className="btn btn-orange mr-3">Update Lock Game Availability for {obj.org_name}</button>
					})}
				</div> */}
			</div>
		);
	}
}

export default withAuth(OrgLockAvailability, ['administrator','allocator']);