import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_SUBSCRIPTION_INFO,
	FETCH_INVOICES_DATA,
	FETCH_CARD_DATA,
	UPDATE_CARD,
	DOWNLOAD_INVOICE,
	UPGRADE_USER,
	GET_TRIAL_END,
	clearBillingData,
	toggleBillingLoader,
	toggleModalBillingLoader,
	fetchSubscriptionInfoSuccess,
	fetchInvoicesDataSuccess,
	fetchCardData,
	fetchCardDataSuccess,
	fetchSubscriptionInfo,
	showTrialBanner
} from '../actions/billing';
import { API_BASE_URL, getHeaders, getOrgId } from '../constants';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment';

function* fetchSubscriptionInfoFunction() {
	yield put(clearBillingData());
	yield put(toggleBillingLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/subscription`,
			{ headers: getHeaders() }
		);
		yield put(toggleBillingLoader(false));
		yield put(fetchSubscriptionInfoSuccess(results.data));
	} catch (error) {
		yield put(toggleBillingLoader(false));
		yield put(handleError(error));
	}
}

function* fetchInvoicesDataFunction() {
	yield put(clearBillingData());
	yield put(toggleBillingLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/invoices`,
			{ headers: getHeaders() }
		);
		yield put(toggleBillingLoader(false));
		yield put(fetchInvoicesDataSuccess(results.data.data));
	} catch (error) {
		yield put(toggleBillingLoader(false));
		yield put(handleError(error));
	}
}

function* fetchCardDataFunction() {
	yield put(clearBillingData());
	yield put(toggleBillingLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/card`,
			{ headers: getHeaders() }
		);
		yield put(toggleBillingLoader(false));
		yield put(fetchCardDataSuccess(results.data.card));
	} catch (error) {
		yield put(toggleBillingLoader(false));
		yield put(handleError(error));
	}
}

function* updateCardFunction({ token, callback }) {
	yield put(clearBillingData());
	yield put(toggleBillingLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/card/update`,
			{ stripeToken: token },
			{ headers: getHeaders() }
		);
		yield put(toggleBillingLoader(false));
		swal(
			'Success',
			 results.data.message,
			'success'
		);
		callback();
		yield put(fetchCardData());
	} catch (error) {
		yield put(toggleBillingLoader(false));
		yield put(handleError(error));
	}
}

function* downloadInvoiceFunction({ id, name, date }) {
	yield put(toggleBillingLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/invoice/${id}`,
			{ 
				headers: getHeaders(),
				responseType: 'blob'
			}
		);
		yield put(toggleBillingLoader(false));
		let link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([results.data], { type: 'application/pdf' }));
		link.download = `Invoice-${name}-${moment(date, 'MMM D, YYYY').format('DD-MM-YYYY')}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		swal(
			'Success',
			'Download Invoice Success',
			'success'
		);
	} catch (error) {
		yield put(toggleBillingLoader(false));
		yield put(handleError(error));
	}
}

function* upgradeUserFunction({ qty, callback }) {
	yield put(toggleModalBillingLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/upgrade`,
			{ quantity: Number(qty) },
			{ headers: getHeaders() }
		);
		yield put(toggleModalBillingLoader(false));
		yield put(fetchSubscriptionInfo());
		swal(
			'Success',
			results.data.message,
			'success'
		);
		callback();
	} catch (error) {
		yield put(toggleModalBillingLoader(false));
		yield put(handleError(error));
	}
}

function* getTrialEnd() {
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/billing/subscription/trialEnd`,
			{ headers: getHeaders() }
		);
		yield put(showTrialBanner(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(FETCH_SUBSCRIPTION_INFO, fetchSubscriptionInfoFunction);
	yield takeLatest(FETCH_INVOICES_DATA, fetchInvoicesDataFunction);
	yield takeLatest(FETCH_CARD_DATA, fetchCardDataFunction);
	yield takeLatest(UPDATE_CARD, updateCardFunction);
	yield takeLatest(DOWNLOAD_INVOICE, downloadInvoiceFunction);
	yield takeLatest(UPGRADE_USER, upgradeUserFunction);
	yield takeLatest(GET_TRIAL_END, getTrialEnd);
}