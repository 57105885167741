import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../../AuthHOC/withAuth';
import ReactTable from "react-table";
import Loading from '../../../loader/loader';
import 'react-table/react-table.css';
import Pagination from '../../../pagination/pagination';
import { connect } from 'react-redux';
import {
	getAllAcknowledgements,
	editAcknowledgement,
	deleteAcknowledgement,
	toggleActivateAcknowledgement
} from '../../../../actions/acknowledgements';
import swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
import AcknowledgementsNewPage from './acknowledgements-new';
import AcknowledgementsEditPage from './acknowledgements-edit';

class AcknowledgementsPage extends Component {
	constructor(props){
		super(props);
	
		this.state = {
			Open: false,
			acknowledgementId: null,
			editModalOpen: false,
			editorState: EditorState.createEmpty(),
			id: ''
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
	}
	onOpenModal = () => {
		this.setState({open: true});
	}

	onCloseModal = () => {
		this.setState({Open: false});
	}
	onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
	}

	componentDidMount() {
		this.props.getAllAcknowledgements();
	}
	componentWillReceiveProps(nextProps) {
		if(nextProps.form_success === true)
		{
			this.setState({open: false});
		}
		if(nextProps.edit_success === true)
		{
			this.setState({editModalOpen: false});
		}
	}


	handleDelete = id => {
		swal({
			title: 'Are you sure?',
			text: "Do you want to delete this acknowledgement?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				this.props.deleteAcknowledgement(id);
			}
		})
	}

	viewAcknowledgement = (data) => {
		this.setState({
			modalOpen: true,
			acknowledgementName: data.acknowledgement_name,
			editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(data.acknowledgement_text)))
		})
	}
	

	toggleActivate = (id, value) => {
		this.props.toggleActivateAcknowledgement(id, value);
	}

	render() {
		const { open, editModalOpen } = this.state;
		//const data = this.props.data;
		const columns = [{
			Header: "Acknowledgement's Name",
			accessor: 'acknowledgement_name'
		}, {
			Header: 'Actions',
			width: 180,
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						<li>
							<button className="link" onClick={() => { this.viewAcknowledgement(row.original) }}>View</button>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<Link to={`/acknowledgements/edit/${row.original.id}`}><button className="link">Edit</button></Link>
						</li>
						<li><span className="seprator">|</span></li>
						<li>
							<button className="link" onClick={() => { this.handleDelete(row.original.id) }}>Delete</button>
						</li>
					</ul>
				</div>
			)
			}, {
				Header: 'Activate',
				width: 150,
				Cell: row => (
					<div className="actions">
						{!!row.original.active
							? <button type="button" className="btn btn-danger" onClick={() => this.toggleActivate(row.original.id, false)}>Deactivate</button>
							: <button type="button" className="btn btn-success" onClick={() => this.toggleActivate(row.original.id, true)}>Activate</button>
						}
					</div>
				)
			}];

		return (
			<div className="acknowledgements">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Acknowledgements</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Acknowledgements</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						
							<button onClick={this.onOpenModal} className="btn btn-orange">Create New Acknowledgements</button>
						
					</div>
				</div>
				<div className="box">
					{this.props.loader && <Loading/> }
					<ReactTable
						PaginationComponent={Pagination}
						data={this.props.data.data || []}
						columns={columns}
						noDataText="No Data Found."
						defaultPageSize={10}
						resizable={true}
						minRows={5}
						onOpenEditModal={this.onOpenEditModal}
					/>
				</div>
					
				<Modal
					open={open}
					onClose={this.onCloseModal}
					showCloseIcon={false}
					center
					classNames={{ modal: "modal fade show" }}
				>
					<div className="modal-dialog modal-md modal-dialog-centered" style={{maxWidth: 700}}>
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">New Acknowledgement</h5>
								<button type="button" className="close" onClick={this.onCloseModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<Editor
									editorState={this.state.editorState}
									toolbarHidden={true}
									readOnly={true}
								/>
								<AcknowledgementsNewPage/>
							</div>
						</div>
					</div>
				</Modal>
				<Modal 
					open={editModalOpen}
					onClose={this.onCloseEditModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Acknowledgements</h5>
								<button type="button" className="close" onClick={this.onCloseEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<AcknowledgementsEditPage id={this.state.id} />
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		getAllAcknowledgements: () => dispatch(getAllAcknowledgements()),
		editAcknowledgement: (id, data) => dispatch(editAcknowledgement(id, data)),
		deleteAcknowledgement: (id) => dispatch(deleteAcknowledgement(id)),
		toggleActivateAcknowledgement: (id, value) => dispatch(toggleActivateAcknowledgement(id, value))
	}
}

function mapsStateToProps(state) {
	return {
		data: state.acknowledgements.data
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(AcknowledgementsPage, ['administrator']));