import React, {Component} from 'react';
import LoginForm from '../../login-form/login-form-container';
import Logo from '../../logo/logo';
import LogoBackground from '../../logo/logo-background';
import '../../../public/styles/authentication.scss';
import './login.scss';
import $ from 'jquery';
import PropTypes from 'prop-types';

export default class Login extends Component {
    getSettings() {
        $('.imgsvg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
                var $svg = $(data).find('svg');
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
                $svg = $svg.removeAttr('xmlns:a');
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                }
                $img.replaceWith($svg);
            }, 'xml');
        });
    }
    componentWillMount() {
        this.props.authCheck();
    }
    componentDidMount() {
        this.getSettings();
    }
    render () {
        return (
            <div className="authentication">
                <LogoBackground />
                <div className="login-container">
                    <div className="login-wrap">
                        <Logo />
                        <LoginForm />
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    authCheck: PropTypes.func.isRequired
}