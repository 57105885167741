import React from 'react';
//import { Route } from 'react-router';
import { Route, Switch } from "react-router-dom";
// import PrivateRoute from '../private-route/private-route';
import LoginPage from '../pages/login/login.container';
import RegisterPage from '../pages/register/register.container';
import AcceptToken from '../pages/register/accept_token.container';
import ConfirmPage from '../pages/confirm/confirm-container';
import DashboardPage from '../pages/Dashboard/dashboard'
// import DashboardPage from '../pages/admin/dashboard/dashboard.container';
import NewOfficialPage from '../pages/admin/officials/new-official';
import EditOfficialPage from '../pages/admin/officials/edit-official';
import OfficialListPage from '../pages/admin/officials/official-list';
import AbilityPage from '../pages/admin/officials/ability/ability.container';
import Availability from '../pages/official/availability/availability-container';
import OfficialGames from '../pages/official/games/game_list-container';
import AvailableOfficials from '../pages/admin/available-officials/available-officials';
// import OfficialDashboard from '../pages/official/dashboard/official_dashboard.container';
// import OfficialGeneralAvailability from '../pages/official/general-availability/official_general_availability';
import GameListPage from '../pages/admin/game/game_list/game_list.container';
import GameDetailPage from '../pages/admin/game/game_detail/game_detail.container';
import NewGamePage from '../pages/admin/game/game_new/new_game.container';
import GameEditPage from '../pages/admin/game/game_edit/game_edit.container';
import LeaguesListPage from '../pages/admin/league/league-list/league_list';
import OrganisationEdit from '../pages/admin/organisation-edit/organisation-edit';
// import ViewConflictPage from '../pages/organisation/view-conflict/view-conflict';
// import ReportsPage from '../pages/reports/report';
import EditOfficialProfilePage from '../pages/official/edit-profile/edit-profile';
import NewLeaguePage from '../pages/admin/league/new-league/new_league.container';
import EditLeague from '../pages/admin/league/edit-league/edit_league.container';
import VenueListPage from '../pages/admin/venue/venue-list/venue_list.container';
import RegionListPage from '../pages/admin/region/region-list/region_list.container';
import TeamListPage from '../pages/admin/teams/team-list/team_list.container';
import PatternListPage from '../pages/admin/pattern/pattern-list/pattern_list.container';
import PayScalePage from '../pages/admin/payscales/payscales-list/payscales_list';
import NewPayScalePage from '../pages/admin/payscales/new-payscale/new_payscale.container'
import GameFeePage from '../pages/admin/gamefee/gamefee-list/gamefee_list';
import ForgotPassword from '../pages/forgot-password/forgot_password.container';
import ResetPassword from '../pages/reset-password/reset_password.container';
import TravelClaimListPage from '../pages/admin/travel-claim/travel-claim-list/travel_claim_list-container';
import PerformanceReportPage from '../pages/admin/performance-report/performance_report-container';
import SendBulkEmailPage from '../pages/admin/send-bulk-email/send_bulk_email-container';
import NoMatch from '../pages/404/404';
import OfficialGameDetailPage from '../pages/official/games/game_detail/game_detail.container';
import SubscriptionPage from '../pages/admin/subscription/subscription.js';
import InvoicesPage from '../pages/admin/invoices/invoices-page.js';
import PaymentInfoPage from '../pages/admin/payment-info/payment-info.js';
import SpecificGames from '../pages/official/specific-games/specific_games-container';
import FeaturesPage from '../pages/admin/features/features-container';
import PendingUserPage from '../pages/admin/pending-user/pending_user-container';
import PaymentReportsPage from '../pages/admin/payment-reports/payment_reports-container';
import CustomReportsPage from '../pages/admin/custom-reports/custom_reports-container';
import CustomReportsListPage from '../pages/admin/custom-reports/list/list-container';
import CustomReportsDetailPage from '../pages/admin/custom-reports/detail/detail-container';
import ConflictPage from '../pages/admin/conflicts/conflict-container';
import RedirectCalendar from '../pages/official/edit-profile/redirectCalendar';
import RosterReport from '../pages/admin/roster-report/roster-report';
import BankReport from '../pages/admin/bank-report/bank-report';
import AllUsersReport from '../pages/admin/allusers-report/allUsers-report';
import DeclineReport from '../pages/admin/decline-report/decline-report';
import CustomGameReportsPage from '../pages/admin/custom-game-reports/custom_game_reports';
import CustomGameReportListPage from '../pages/admin/custom-game-reports/report_list';
import CustomGameReportsDetailPage from '../pages/admin/custom-game-reports/detail_report';
import AcknowledgementsPage from '../pages/admin/acknowledgements/acknowledgements';
import AcknowledgementsNewPage from '../pages/admin/acknowledgements/acknowledgements-new';
import AcknowledgementsEditPage from '../pages/admin/acknowledgements/acknowledgements-edit';
import OAuth from '../pages/oauth';
import LockAvailability from '../pages/admin/lock_availablity/lock_availability_container';

export default function Routes({trialStatus}) {
	if (trialStatus === 'ended') {
		return (
			<div>
				<Switch>
					<Route exact path="/edit/profile" component={EditOfficialProfilePage} />
					<Route path="/subscription" component={SubscriptionPage} />
					<Route path="/invoices" component={InvoicesPage} />
					<Route path="/payment-info" component={PaymentInfoPage} />
					<Route path="/organisation/edit" component={OrganisationEdit} />
					<Route component={NoMatch} />
				</Switch>
			</div>
		);
	}

	return (
		<div>
			<Switch>
				<Route path="/login" component={LoginPage} />
				<Route path="/register" component={RegisterPage} />
				<Route path="/forgot-password" component={ForgotPassword} />
				<Route path="/accept" component={AcceptToken} />
				<Route path="/reset" component={ResetPassword} />
				<Route path="/confirm" component={ConfirmPage} />
				<Route exact path="/" component={DashboardPage} />
				<Route path="/officials" exact component={OfficialListPage} />
				<Route path="/official/new" component={NewOfficialPage} />
				<Route path="/official/:id/ability" component={AbilityPage} />
				<Route path="/official/edit/:id" component={EditOfficialPage} />
				<Route path="/official/pending" component={PendingUserPage} />
				<Route path="/official/available" component={AvailableOfficials} />
				<Route path="/organisation/leagues" exact component={LeaguesListPage} />
				<Route path="/organisation/leagues/create" component={NewLeaguePage} />
				<Route path="/organisation/leagues/edit/:id" component={EditLeague} />
				<Route path="/organisation/venues" exact component={VenueListPage} />
				<Route path="/organisation/regions" exact component={RegionListPage} />
				<Route path="/organisation/teams" exact component={TeamListPage} />
				<Route path="/organisation/edit" component={OrganisationEdit} />
				<Route exact path="/games" component={GameListPage} />
				<Route exact path="/games/create" component={NewGamePage} />
				<Route exact path="/game/edit/:id" component={GameEditPage} />
				<Route exact path="/game/:id/detail" component={GameDetailPage} />
				<Route path="/organisation/patterns" exact component={PatternListPage} />
				<Route path="/organisation/payscales" exact component={PayScalePage} />
				<Route path="/organisation/payscales/create" component={NewPayScalePage} />
				<Route path="/organisation/gamefee/:id" component={GameFeePage} />
				<Route exact path="/availability" component={Availability} />
				<Route exact path="/lock-availability" component={LockAvailability} />
				<Route exact path="/mygames" component={OfficialGames} />
				<Route path="/mygames/:id/detail" component={OfficialGameDetailPage} />
				<Route exact path="/edit/profile" component={EditOfficialProfilePage} />
				<Route exact path="/edit/profile/calendar" component={RedirectCalendar} />
				<Route exact path="/report/travel-claim" component={TravelClaimListPage} />
				<Route exact path="/report/performance" component={PerformanceReportPage} />
				<Route exact path="/report/payment" component={PaymentReportsPage} />
				<Route exact path="/report/custom" component={CustomReportsListPage} />
				<Route exact path="/report/custom/new" component={CustomReportsPage} />
				<Route exact path="/report/custom/:id/detail" component={CustomReportsDetailPage} />
				<Route exact path="/report/roster" component={RosterReport} />
				<Route exact path="/report/decline" component={DeclineReport} />
				<Route exact path="/report/bankrecord" component={BankReport} />
				<Route exact path="/report/allusers-record" component={AllUsersReport}/>
				<Route exact path="/report/game" component={CustomGameReportListPage} />
				<Route exact path="/report/game/new" component={CustomGameReportsPage} />
				<Route exact path="/report/game/:id/detail" component={CustomGameReportsDetailPage} />
				<Route exact path="/comunication/send-bulk-email" component={SendBulkEmailPage} />
				<Route path="/subscription" component={SubscriptionPage} />
				<Route path="/invoices" component={InvoicesPage} />
				<Route path="/payment-info" component={PaymentInfoPage} />
				<Route exact path="/availability/game" component={SpecificGames} />
				<Route path="/features" component={FeaturesPage} />
				<Route path="/official/conflicts/:id" component={ConflictPage} />
				<Route path="/acknowledgements" exact component={AcknowledgementsPage} />
				<Route path="/acknowledgements/new" exact component={AcknowledgementsNewPage} />
				<Route path="/acknowledgements/edit/:id" exact component={AcknowledgementsEditPage} />
				<Route path="/oauth/connect" exact component={OAuth} />
				<Route component={NoMatch} />
				{/* <Route path="/organisation/conflict" component={ViewConflictPage} /> */}
				{/* <Route path="/reports/report" component={ReportsPage} /> */}
			</Switch>
		</div>
	);
}