import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
	FETCH_LEAGUES_POSITIONS,
	UPDATE_OFFICIAL_ABILITY_PERFORMED,
	FETCH_ABILITIES,
	fetchLeaguesPositionsSuccess,
	fetchAbilitiesSuccess,
	fetchAbilities
} from '../actions/ability';
import { showLoader, hideLoader } from '../actions/loader';
import { API_BASE_URL, getHeaders, getOrgId } from '../constants';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* getLeaguesPositions() {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/ability/datanames`,
			{ headers: getHeaders() }
		);
		yield put(fetchLeaguesPositionsSuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getAbilities({ id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/ability/user/${id}`,
			{ headers: getHeaders() }
		);
		yield put(fetchAbilitiesSuccess(results.data.abilities));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* updateOfficialAbility({ formData, user_id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/ability/user/${user_id}`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchAbilities(user_id));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

export default function* () {
	yield takeLatest(FETCH_LEAGUES_POSITIONS, getLeaguesPositions);
	yield takeLatest(FETCH_ABILITIES, getAbilities);
	yield takeLatest(UPDATE_OFFICIAL_ABILITY_PERFORMED, updateOfficialAbility);
}