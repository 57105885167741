import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';
import { Link } from 'react-router-dom';
import withAuth from '../../../../AuthHOC/withAuth';
import './edit-league.scss';

class EditLeague extends Component {
	constructor(props) {
		super(props);
		this.state = {
			league_name: '',
			game_length: '',
			performance_report: 0
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const {
			league_name,
			game_length,
			performance_report
		} = this.state;

		const data = {
			league_name: league_name,
			game_length: game_length
		}

		const customReportData = {
			customperformancereport: Number(performance_report)
		}

		this.props.onLeagueSubmitted(data, customReportData, this.props.match.params.id);
	}

	componentWillMount() {
		this.props.getListCustomReport();
		this.props.getLeague(this.props.match.params.id);
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.league).length > 0) {
			this.setState({
				league_name: nextProps.league.league_name,
				game_length: nextProps.league.game_length,
				performance_report: nextProps.league.customperformancereport_id
			});
		}
	}

	render() {
		let league_name_error = null;
		let game_length_error = null;
		if (this.props.errors !== undefined) {
			for (var key in this.props.errors) {
				if (key === 'league_name')
					league_name_error = <FormError error={this.props.errors[key][0]} />;
				else
					game_length_error = <FormError error={this.props.errors[key][0]} />;
			}
		}
		return (
			<div className="league-create">
				{this.props.loading && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Edit League</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/organisation/leagues">League</Link></li>
								<li className="breadcrumb-item current">Edit League</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-12">
							<div className="form-group">
								<label className="label">League Name</label>
								<div className="input-effect-1">
									<input type="text" name="league_name" value={this.state.league_name} onChange={event => { this.handleInputChange(event) }} className="form-control" />
									<div className="focus-border"></div>
								</div>
								{league_name_error}
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-12">
							<div className="form-group">
								<label className="label">Game Length</label>
								<div className="input-effect-1">
									<input type="text" name="game_length" value={this.state.game_length} onChange={event => { this.handleInputChange(event) }} className="form-control" />
									<div className="focus-border"></div>
								</div>
								{game_length_error}
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-4 col-12">
							<div className="form-group">
								<label className="label">Custom Performance Report</label>
								<div className="input-effect-1">
									<select value={this.state.performance_report} onChange={event => { this.handleInputChange(event) }} name="performance_report" className="normal-select">
										<option value={0}>Select Performance Report</option>
										{this.props.list_custom_report != null && this.props.list_custom_report.map(obj => {
											return (
												<option key={obj.id} value={obj.id}>{obj.report_name}</option>
											);
										})}
									</select>
									<span className="focus-border"></span>
								</div>
							</div>
						</div>
					</div>
					<div className="action text-center">
						<button className="btn btn-orange mr-4" onClick={this.handleSubmit}>Submit</button>
						<Link to={`/organisation/gamefee/${this.props.match.params.id}`}>
							<button className="btn btn-orange">Default Game Fees</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

EditLeague.propTypes = {
	getLeague: PropTypes.func.isRequired,
	league: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	errors: PropTypes.object
}

export default withAuth(EditLeague, ['administrator']);