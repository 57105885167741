import React from 'react';
import withAuth from '../../../../../AuthHOC/withAuth';

class ModalTravel extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			distance_travelled: '',
			travelled_from: '',
			input_disabled: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.enableEdit = this.enableEdit.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		let value;
		if (name === 'distance_travelled') {
			value = Number(target.value);
		} else {
			value = target.value;
		}

		this.setState({
				[name]: value
		});
	}

	handleSubmit() {
		let { distance_travelled, travelled_from } = this.state;
		let data = { distance_travelled, travelled_from };
		this.props.onSubmit(data);
	}

	enableEdit() {
		this.setState({
			input_disabled: false
		})
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.alreadyHasTravelClaim) {
			this.setState({
				input_disabled: true,
				distance_travelled: nextProps.travel_claim.distance_travelled,
				travelled_from: nextProps.travel_claim.travelled_from,
			});
		}
	}

	render() {
		return(
			<div className="row">
				<div className="col-xl-6 col-lg-6 col-md-6 col-12">
					<div className="form-group">
						<label className="label">Distance Travelled</label>
						<div className="input-effect-1">
							<input type="number" disabled={this.state.input_disabled} onChange={(event) => this.handleInputChange(event)} value={this.state.distance_travelled} name="distance_travelled" placeholder="Distance Travelled" className="form-control no-transition" />
							<div className="focus-border"></div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-6 col-12">
					<div className="form-group">
						<label className="label">Travelled From</label>
						<div className="input-effect-1">
							<input type="text" disabled={this.state.input_disabled} onChange={(event) => this.handleInputChange(event)} value={this.state.travelled_from} name="travelled_from" placeholder="Travelled From" className="form-control no-transition" />
							<div className="focus-border"></div>
						</div>
					</div>
				</div>
				<div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
					{this.state.input_disabled
						? <button type="button" onClick={this.enableEdit} className="btn btn-orange mt-2">Edit Travel Claim</button>
						: <button type="button" onClick={this.handleSubmit} className="btn btn-orange mt-2">Submit</button>
					}
				</div>
			</div>
		);
	}
}

export default withAuth(ModalTravel, 'official');