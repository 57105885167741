import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUser, getOrganisations } from '../../../constants';
import { connect } from 'react-redux';
import {
	postAuthCode
} from '../../../actions/oauth';
import qs from 'query-string';
import swal from 'sweetalert2';

class OAuthPage extends Component {
	state = {
		organisation: getOrganisations() && getOrganisations()[0].org_id
	}

	componentDidMount() {
		if (!getCurrentUser()) {
			swal('Error', 'You have to login first', 'error');
			this.props.history.push(`/login${this.props.location.search}`);
		}
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	submitAuthCode = () => {
		this.props.postAuthCode({
			organisation_id: this.state.organisation,
			...qs.parse(this.props.location.search)
		});
	}

	render() {
		return (
			<div className="game-detail">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">OAuth</h1>
						<nav>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">OAuth</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="row">
						<div className="col-xl-4 col-lg-4 col-md-4 col-12">
							<div className="form-group">
								<label className="label">Select Organisation</label>
								<div className="input-effect-1">
									<select className="normal-select" name="organisation" value={this.state.organisation} onChange={this.handleInputChange}>
										{getOrganisations() && getOrganisations().map(org => (
											<option key={org.org_id} value={org.org_id}>{org.organisation_name}</option>
										))}
									</select>
									<div className="focus-border"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="action">
						<button className="btn btn-orange" onClick={this.submitAuthCode}>Submit</button>
					</div>
				</div>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		postAuthCode: (data) => dispatch(postAuthCode(data))
	}
}

function mapsStateToProps(state) {
	return {}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(OAuthPage);