import React, { Component } from 'react';
import './payment-info.scss';
import withAuth from '../../../AuthHOC/withAuth';
import { Link } from 'react-router-dom';
import { fetchCardData, updateCard } from '../../../../actions/billing';
import { connect } from 'react-redux';
import Loader from '../../../loader/loader';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Form from './form';

class PaymentInfo extends Component {
	componentWillMount() {
		this.props.fetchCardData();
	}

	handleUpdateCard = (token, callback) => {
		this.props.updateCard(token, callback);
	}
	/*const cardholderName = document.getElementById('card-holder-name');
	const cardButton = document.getElementById('card-button');
	 cardButton.addEventlistener('click', async(e)) => {
		 const { PaymentMethod, error } = await stripe.createPaymentMethod(
			 'card', cardElement, {
				 billing_details: { name: cardHolderName.value }
			 }
		 );
		if(error){
			//Display "error.message" to the user ...
		} else {
			//the card has been verified sucessfully...
		}

	 });*/
	render() {
		const notEmpty = Object.keys(this.props.data).length > 0;
		const { data } = this.props;
		return (
			<div className="payment-info">
				{this.props.loader && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Payment Information</h1>
					</div>
				</div>
				<div className="box">
					<div className="row no-gutters">
						<div className="actions mb_30">
							<div className="btn-group">
								<Link to="/subscription"><button type="button" className="btn btn-subscription">Subscription Detail</button></Link>
								<Link to="/invoices"><button type="button" className="btn btn-subscription">Invoices</button></Link>
								<button type="button" className="btn btn-subscription active">Payment Information</button>
							</div>
						</div>
					</div>
					<div className="credit-card-info">
						<h2>Credit Card Information</h2>
						<div className="card-detail">
							<p>
								<span className="title-name">Card Type</span>
								<span className="divide">:</span>
								<span>{notEmpty && data.brand}</span>
							</p>
							<p>
								<span className="title-name">Card Number</span>
								<span className="divide">:</span>
								<span>{notEmpty && <><span className="dot-text">{data.format}</span><span className="card-number">{data.last4}</span></>}</span>
							</p>
							<p>
								<span className="title-name">Expiry Date</span>
								<span className="divide">:</span>
								<span>{notEmpty && data.expiry}</span>
							</p>
						</div>
						
						<h2>Update Credit Card</h2>
						<StripeProvider apiKey="pk_test_WVCt47HHMfHAiF7dAJ8mJ5Vq">
							<Elements>
								<Form
									handleUpdateCard={this.handleUpdateCard}
								/>
							
							</Elements>
						</StripeProvider>
					</div>
				</div>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		fetchCardData: () => dispatch(fetchCardData()),
		updateCard: (token, callback) => dispatch(updateCard(token, callback))
	}
}

function mapsStateToProps(state) {
	return {
		loader: state.billing.loader,
		data: state.billing.cardData
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(PaymentInfo, ['administrator']));