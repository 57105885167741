import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	OFFICIAL_PERFORMED,
	OFFICIAL_PERFORMED_DELETE,
	FETCH_OFFICIAL_PERFORMED,
	FETCH_USERDATA_PERFORMED,
	GET_OFFICIAL_DETAIL,
	ADD_BULK_OFFICIAL,
	OFFICIAL_EDIT_PERFORMED,
	PROFILE_PHOTO_UPLOAD,
	GET_BANK_DETAIL_PERFORMED,
	BANK_EDIT_PERFORMED,
	REMOVE_PENDING_USER,
	GET_PENDING_USER,
	GET_PROFILE_PIC_PERFORMED,
	FETCH_CONFLICTS_DATA,
	SUBMIT_CONFLICTS,
	SUBMIT_EDIT_CONFLICTS,
	DELETE_CONFLICTS,
	CONNECT_TO_GOOGLE_CALENDAR,
	DISCONNECT_GOOGLE_CALENDAR,
	GET_ALL_AVAILABLE_OFFICIAL,
	RESET_PASSWORD_PROFILE,
	officialError,
	officialLoaderStatus,
	officialSuccess,
	fetchOfficialSuccess,
	fetchUserDataSuccess,
	getOfficialSuccess,
	fetchOfficialPerformed,
	bulkImportSuccess,
	officialEditSuccess,
	officialEditErrors,
	getOfficial,
	profilePhotoLoaderStatus,
	profilePhotoUploadSuccess,
	getBankDetailSuccess,
	bankEditSuccess,
	bankEditErrors,
	getBankDetailPerformed,
	getProfilePicSuccess,
	setPendingUserLoader,
	RESEND_INVITE,
	getPendingUserSuccess,
	getPendingUser,
	setConflictsLoader,
	setConflictsGeneralData,
	setConflictsUserData,
	resetConflictsData,
	fetchConflictsData,
	setConflictsModalLoader,
	getAllAvaialbleOfficialSuccess,
} from '../actions/official';
import { push } from 'connected-react-router';
import { API_BASE_URL, getHeaders, getOrgId, getUserId, getCurrentUser } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { handleError } from '../actions/handleError';

function* doInviteNewOfficial({ formData }) {
	yield put(officialLoaderStatus(true));
	try {
		const officialResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users`,
			formData, { headers: getHeaders() }
		);
		swal(
			'Success',
			officialResults.data.message,
			'success'
		);
		yield put(officialSuccess(officialResults.data.message));
	} catch (error) {
		if (error.response.status === 422) {
			yield put(officialError(error.response.data.errors));
		}
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* addBulkUser({ formData }) {
	yield put(officialLoaderStatus(true));
	try {
		let file = formData.users;
		let fd = new FormData();
		fd.append('users', file);
		const officialResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/upload/users`,
			fd, { headers: getHeaders() }
		);
		swal(
			'Success',
			officialResults.data.message,
			'success'
		);
		yield put(bulkImportSuccess(true));
	} catch (error) {
		if (error.response.status === 500) {
			yield put(bulkImportSuccess(true));
			swal(
				'Error',
				'Something went wrong. Please contact to administration.',
				'error'
			);
		}
		if (error.response.status === 400) {
			yield put(bulkImportSuccess(true));
			swal(
				'Error',
				'No users invited. Blank CSV file.',
				'error'
			);
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
	yield put(officialLoaderStatus(false));
}

function* fetchOfficials() {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users`,
			{ headers: getHeaders() }
		);
		yield put(fetchOfficialSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* fetchUserData() {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/data/all`,
			{ headers: getHeaders() }
		);
		yield put(fetchUserDataSuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* fetchOfficial({ id }) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/${id}`,
			{ headers: getHeaders() }
		);
		yield put(getOfficialSuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* doRemoveOfficial({ id }) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/${id}`,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchOfficialPerformed());
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* doUpdateOfficial({ formData, id }) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/edit/${id}`,
			formData, { headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(officialEditSuccess(true));
		yield put(getOfficial(id));
	} catch (error) {
		console.log(error);
		if (error.response.status === 422) {
			yield put(officialEditErrors(error.response.data.errors));
		}
		if (error.response.status === 403) {
			console.log(error.response);
			swal(
				'Error',
				error.response.data.message,
				'error'
			);
		}
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
	}
	yield put(officialLoaderStatus(false));
}

function* doProfilePhotoUpload({ formData }) {
	yield put(profilePhotoLoaderStatus(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/profilepic/`,
			formData, { headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(profilePhotoUploadSuccess(true));
		yield put(getProfilePicSuccess(results.data.path));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(profilePhotoLoaderStatus(false));
}

function* doFetchBankDetail() {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/bankrecord/${getUserId()}`,
			{ headers: getHeaders() }
		);
		yield put(getBankDetailSuccess(results.data.Record));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* doEditBankDetail({ formData }) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/bankrecord/${getUserId()}`,
			formData, { headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(bankEditSuccess(true));
		yield put(getBankDetailPerformed());
	} catch (error) {
		if (error.response.status === 422) {
			yield put(bankEditErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* fetchProfilePic() {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/profilepic`,
			{ headers: getHeaders() }
		);
		yield put(getProfilePicSuccess(results.data.path ? results.data.path : ''));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* getPendingUserPerformed() {
	yield put(setPendingUserLoader(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/pending`,
			{ headers: getHeaders() }
		);
		yield put(getPendingUserSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setPendingUserLoader(false));
}

function* resendInvite({ data }) {
	yield put(setPendingUserLoader(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/resendinvite`,
			{ user_id: data }, { headers: getHeaders() }
		);
		yield put(getPendingUser());
		swal(
			'Success',
			results.data.message,
			'success'
		);
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setPendingUserLoader(false));
}

function* removePendingUser({ data }) {
	yield put(setPendingUserLoader(true));
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/users/pending`,
			{ data: { pending_users: data }, headers: getHeaders() }
		);
		yield put(getPendingUser());
		swal(
			'Success',
			results.data.message,
			'success'
		);
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setPendingUserLoader(false));
}

function* fetchConflictsDataFunc({ id }) {
	yield put(resetConflictsData());
	yield put(setConflictsLoader(true));
	try {
		const generalData = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/conflicts/${id}/conflictdata`,
			{ headers: getHeaders() }
		);

		const userData = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/conflicts/user/${id}`,
			{ headers: getHeaders() }
		);

		yield put(setConflictsGeneralData(generalData.data));
		yield put(setConflictsUserData(userData.data.conflicts));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setConflictsLoader(false));
}

function* submitConflicts({ data, callback }) {
	yield put(setConflictsModalLoader(true));
	try {
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/conflicts`,
			data, { headers: getHeaders() }
		);
		yield put(fetchConflictsData(data.official_id));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		callback();
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setConflictsModalLoader(false));

}

function* submitEditConflicts({ data, reportId, userId, callback }) {
	yield put(setConflictsModalLoader(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/conflicts/${reportId}`,
			data, { headers: getHeaders() }
		);
		yield put(fetchConflictsData(userId));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		callback();
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setConflictsModalLoader(false));
}

function* deleteConflicts({ reportId, userId }) {
	yield put(setConflictsLoader(true));
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/conflicts/${reportId}`,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchConflictsData(userId));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(setConflictsLoader(false));
}

function* connectToGoogleCalendar() {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/calendar/connect/google`,
			{ headers: getHeaders() }
		);
		window.location.href = results.data.redirecturl;
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* disconnectGoogleCalendar() {
	yield put(officialLoaderStatus(true));
	let currentUser = getCurrentUser();
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/calendar/connect/google`,
			{ headers: getHeaders() }
		);
		currentUser['gcal'] = -1;
		localStorage.setItem('user', JSON.stringify(currentUser));
		swal(
			'Success',
			results.data.message,
			'success'
		);
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

function* getAllAvaialbleOfficial({date, startTime, endTime, callback}) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/reports/availableofficialschart`,
			{
				headers: getHeaders(),
				params: {
					date,
					starttime: startTime,
					endtime: endTime,
					available: true
				},
			}
		)
		yield put(getAllAvaialbleOfficialSuccess(results.data));
		if(results.data) {
			yield put(officialLoaderStatus(false));
		}
		if (callback) callback();
	} catch (error) {
		yield put(handleError(error));
		yield put(officialLoaderStatus(false));
	}
}

function* resetPasswordProfile({ formData, callback }) {
	yield put(officialLoaderStatus(true));
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/resetpassword`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.Message,
			'success'
		);
		if (callback) callback();
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(officialLoaderStatus(false));
}

export default function* () {
	yield takeLatest(OFFICIAL_PERFORMED, doInviteNewOfficial);
	yield takeLatest(ADD_BULK_OFFICIAL, addBulkUser);
	yield takeLatest(FETCH_OFFICIAL_PERFORMED, fetchOfficials);
	yield takeLatest(FETCH_USERDATA_PERFORMED, fetchUserData);
	yield takeLatest(OFFICIAL_PERFORMED_DELETE, doRemoveOfficial);
	yield takeLatest(GET_OFFICIAL_DETAIL, fetchOfficial);
	yield takeLatest(OFFICIAL_EDIT_PERFORMED, doUpdateOfficial);
	yield takeLatest(PROFILE_PHOTO_UPLOAD, doProfilePhotoUpload);
	yield takeLatest(GET_BANK_DETAIL_PERFORMED, doFetchBankDetail);
	yield takeLatest(BANK_EDIT_PERFORMED, doEditBankDetail);
	yield takeLatest(GET_PROFILE_PIC_PERFORMED, fetchProfilePic);
	yield takeLatest(GET_PENDING_USER, getPendingUserPerformed);
	yield takeLatest(RESEND_INVITE, resendInvite);
	yield takeLatest(REMOVE_PENDING_USER, removePendingUser);
	yield takeLatest(FETCH_CONFLICTS_DATA, fetchConflictsDataFunc);
	yield takeLatest(SUBMIT_CONFLICTS, submitConflicts);
	yield takeLatest(SUBMIT_EDIT_CONFLICTS, submitEditConflicts);
	yield takeLatest(DELETE_CONFLICTS, deleteConflicts);
	yield takeLatest(CONNECT_TO_GOOGLE_CALENDAR, connectToGoogleCalendar);
	yield takeLatest(DISCONNECT_GOOGLE_CALENDAR, disconnectGoogleCalendar);
	yield takeLatest(GET_ALL_AVAILABLE_OFFICIAL, getAllAvaialbleOfficial);
	yield takeLatest(RESET_PASSWORD_PROFILE, resetPasswordProfile);
}