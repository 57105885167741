import React, { Component } from 'react';
import withAuth from '../../../AuthHOC/withAuth';
import { Link } from 'react-router-dom';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { exportRosterReport } from '../../../../actions/reports';
import { connect } from 'react-redux';
import Loader from '../../../loader/loader';

const now = moment();
const before = moment().subtract(7, 'd');
const format = 'DD-MM-YYYY';

class RosterReportPage extends Component {
	state ={
		start_date: before,
		end_date: now
	};

	handleStartDate = value => {
		this.setState({
			start_date: value
		});
	};

	handleEndDate = value => {
		this.setState({
			end_date: value
		})
	};

	exportTo = format => {
		const {start_date, end_date} = this.state;
		this.props.exportRosterReport(format, start_date, end_date);
	};

	render() {
		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={format}
				format={format}
				disabledTime={null}
				timePicker={null}
				defaultValue={now}
				showDateInput={false}
			/>);
		return (
			<div className="roster-report">
				{this.props.loading && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Roster Report</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Roster Report</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="card card-border card-invite-official mb-5">
						<div className="card-header d-flex align-items-center justify-content-between">
							<h2>Export Roster Report</h2>
						</div>
						<div>
							<div className="card-body">
								<div>
									<div className="row">
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">Start Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.start_date}
															onChange={this.handleStartDate}
															getCalendarContainer={() => document.getElementById('datepicker')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">End Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker2">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.end_date}
															onChange={this.handleEndDate}
															getCalendarContainer={() => document.getElementById('datepicker2')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="action d-flex flex-wrap align-items-start">
										<button className="btn btn-orange mr-3 mb-3 mb-sm-0" onClick={() => this.exportTo('csv')}>Export to CSV</button>
										<button className="btn btn-orange mr-3 mb-3 mb-sm-0" onClick={() => this.exportTo('excel')}>Export to XLSX</button>
										<button className="btn btn-orange" onClick={() => this.exportTo('pdf')}>Export to PDF</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		exportRosterReport: (format, startDate, endDate) => dispatch(exportRosterReport(format, startDate, endDate))
	}
}

function mapsStateToProps(state) {
	return {
		loading: state.reports.loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(RosterReportPage, ['administrator']));