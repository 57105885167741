import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './organisation-pattern.scss';
import Modal from 'react-responsive-modal';
import PatternResults from '../pattern-results/pattern_results.container';
import NewPattern from '../new-pattern/new_pattern.container';
import EditPattern from '../edit-pattern/edit_pattern.container';
import withAuth from '../../../../AuthHOC/withAuth';

class PatternList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			editModalOpen: false,
			id: ''
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    onOpenModal = () => {
		this.setState({open: true});
	}

	onCloseModal = () => {
		this.setState({open: false});
	}

	onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.form_success === true)
		{
			this.setState({open: false});
		}
		if(nextProps.edit_success === true)
		{
			this.setState({editModalOpen: false});
		}
	}

    render () {
    	const { open, editModalOpen } = this.state;
        return (
            <div className="organization-pattern">
            	<div className="page-head mb_30">
                	<div className="head">
                		<h1 className="page-title">Patterns</h1>
                		<nav>
                			<ol className="breadcrumb page-title">
                				<li className="breadcrumb-item">
                					<Link to="/">Home</Link>
                				</li>
                				<li className="breadcrumb-item current">Patterns</li>
                			</ol>
                		</nav>
                	</div>
                	<div className="actions">
						<button onClick={this.onOpenModal} className="btn btn-orange">Create Pattern</button>
                	</div>
                </div>
				<div className="box">
				<PatternResults onOpenEditModal={this.onOpenEditModal} />
				</div>
				<Modal
					open={open}
					onClose={this.onCloseModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show pattern-modal"
					}}
				>
					<div className="modal-dialog modal-md modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Create Patterns</h5>
								<button type="button" className="close" onClick={this.onCloseModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<NewPattern />
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={editModalOpen}
					onClose={this.onCloseEditModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show pattern-modal"
					}}
				>
					<div className="modal-dialog modal-md modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit Patterns</h5>
								<button type="button" className="close" onClick={this.onCloseEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<EditPattern id={this.state.id} />
							</div>
						</div>
					</div>
				</Modal>
            </div>
        );
    }
}

export default withAuth(PatternList,['administrator']);