import {connect} from 'react-redux';
import ForgotPasswordForm from './forgot_form';
import {forgotPerformed} from '../../../../actions/forgot';

function mapsDispatchToProps (dispatch) {
    return {
        onForgotSubmitted: (formData) => dispatch(forgotPerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.forgot.is_loading,
        errors: state.forgot.errors,
        form_success: state.forgot.form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(ForgotPasswordForm);