import { connect } from 'react-redux';
import FeaturesPage from './features';
import { 
	fetchFeaturesData,
	toggleRegions,
	toggleLockDates,
	toggleGameAvailability,
	toggleGameReports,
	toggleAcknowledgements,
	toggleXeroIntegration
} from '../../../../actions/features';

function mapsDispatchToProps(dispatch) {
	return {
		fetchFeaturesData: () => dispatch(fetchFeaturesData()),
		toggleRegions: (value) => dispatch(toggleRegions(value)),
		toggleLockDates: (value) => dispatch(toggleLockDates(value)),
		toggleGameAvailability: (value) => dispatch(toggleGameAvailability(value)),
		toggleGameReports: (value) => dispatch(toggleGameReports(value)),
		toggleAcknowledgements: (value) => dispatch(toggleAcknowledgements(value)),
		toggleXeroIntegration: (value) => dispatch(toggleXeroIntegration(value)),
	};
}

function mapsStateToProps(state) {
	return {
		loader: state.features.loader,
		toggle_loader: state.features.toggle_loader,
		data: state.features.data
	};
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(FeaturesPage);