import {connect} from 'react-redux';
import Form from './form';
import {lockAvailabilityCreatePerformed} from '../../../../../actions/lock_availability';

function mapsDispatchToProps (dispatch) {
    return {
        onLockAvailabilitySubmitted: (formData) => dispatch(lockAvailabilityCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        //lock_availabilities: state.lock_availability.lock_results,
        loading: state.lock_availability.is_loading,
        form_success: state.lock_availability.form_success,
        errors: state.lock_availability.lock_errors
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(Form);