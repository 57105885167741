import {connect} from 'react-redux';
import NewTeam from './new_team';
import {fetchLeaguesPerformed} from '../../../../../actions/league';
import {fetchVenuesPerformed} from '../../../../../actions/venue';
import {teamCreatePerformed} from '../../../../../actions/team';

function mapsDispatchToProps (dispatch) {
    return {
        fetchLeagues: () => dispatch(fetchLeaguesPerformed()),
        fetchVenues: () => dispatch(fetchVenuesPerformed()),
        onTeamSubmitted: (formData) => dispatch(teamCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        leagues: state.league.results,
        venues: state.venue.results,
        league_loading: state.league.is_loading,
        venue_loading: state.venue.is_loading,
        errors: state.team.create_errors,
        loading: state.team.is_loading,
        form_success: state.team.form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewTeam);