import { 
    PATTERN_LOADER_SHOW,
    FETCH_PATTERNS_SUCCESS,
    PATTERN_CREATE_SUCCESS,
    PATTERN_CREATE_ERROR,
    GET_PATTERN_DETAIL_SUCCESS,
    PATTERN_EDIT_ERROR,
    PATTERN_EDIT_SUCCESS,
} from '../actions/pattern';

const initialState = {
    results: [],
    is_loading: false,
    form_success: false,
    create_errors: {},
    pattern_detail: {},
    edit_errors: {},
    edit_success: false
}

export default (state, actions) => {
    if (state === undefined) {
        return initialState;
    }

    switch (actions.type) {
        case FETCH_PATTERNS_SUCCESS:
            return {
                ...state,
                results: actions.results,
                form_success: false,
                create_errors: {},
                edit_errors: {},
                edit_success: false
            };
        case PATTERN_LOADER_SHOW:
            return {
                ...state,
                is_loading: actions.status
            };
        case PATTERN_CREATE_SUCCESS:
            return {
                ...state,
                form_success: actions.status,
                create_errors: {}
            };
        case PATTERN_CREATE_ERROR:
            return {
                ...state,
                create_errors: actions.errors
            };
        case GET_PATTERN_DETAIL_SUCCESS:
            return {
                ...state,
                pattern_detail: actions.pattern
            }
        case PATTERN_EDIT_ERROR:
            return {
                ...state,
                edit_errors: actions.errors
            }
        case PATTERN_EDIT_SUCCESS:
            return {
                ...state,
                edit_success: actions.status
            }
        default:
            return state;
    }
}