import {connect} from 'react-redux';
import RegionResults from './region_results';
import {fetchRegionsPerformed, deleteRegion} from '../../../../../actions/region';

function mapsDispatchToProps (dispatch) {
    return {
        fetchRegions: () => dispatch(fetchRegionsPerformed()),
        removeRegion: (id) => dispatch(deleteRegion(id))
    }
}

function mapsStateToProps (state) {
    return {
        regions: state.region.results,
        loading: state.region.is_loading,
        form_success: state.region.form_success,
        is_deleted: state.region.is_deleted,
        edit_success: state.region.edit_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(RegionResults);