import { connect } from 'react-redux';
import GameListPage from './game_list';
import { fetchOfficialGamesPerformed, fetchOfficialGamesData } from '../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		fetchGames: (filters, sortBy) => dispatch(fetchOfficialGamesPerformed(filters, sortBy)),
		fetchGamesData: () => dispatch(fetchOfficialGamesData()),
	}
}

function mapsStateToProps(state) {
	return {
		games: state.game.official_games,
		loading: state.game.is_loading,
		gamesData: state.game.officialGamesData
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameListPage);