export const FETCH_PERFORMANCE_REPORT_LIST = 'FETCH_PERFORMANCE_REPORT_LIST';
export const FETCH_PERFORMANCE_REPORT_LIST_SUCCESS = 'FETCH_PERFORMANCE_REPORT_LIST_SUCCESS';
export const SAVE_PERFORMANCE_REPORT_PERFORMED = 'SAVE_PERFORMANCE_REPORT_PERFORMED';
export const PUBLISH_PERFORMANCE_REPORT_PERFORMED = 'PUBLISH_PERFORMANCE_REPORT_PERFORMED';
export const FETCH_PERFORMANCE_REPORT_LOADING = 'FETCH_PERFORMANCE_REPORT_LOADING';
export const PERFORMANCE_REPORT_MODAL_LOADING = 'PERFORMANCE_REPORT_MODAL_LOADING';

export const fetchPerformanceReportList = (filters) => ({
	type: FETCH_PERFORMANCE_REPORT_LIST,
	filters
});

export const fetchPerformanceReportListSuccess = (results, teams, leagues, venues) => ({
	type: FETCH_PERFORMANCE_REPORT_LIST_SUCCESS,
	results,
	teams,
	leagues,
	venues
	
});

export const savePerformanceReportPerformed = (id, data) => ({
	type: SAVE_PERFORMANCE_REPORT_PERFORMED,
	id,
	data
});

export const publishPerformanceReportPerformed = (id, data) => ({
	type: PUBLISH_PERFORMANCE_REPORT_PERFORMED,
	id,
	data
});

export const fetchPerformanceReportLoading = (status) => ({
	type: FETCH_PERFORMANCE_REPORT_LOADING,
	status
});

export const performanceReportModalLoading = (status) => ({
	type: PERFORMANCE_REPORT_MODAL_LOADING,
	status
});