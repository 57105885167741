import React, { Fragment } from 'react';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { getPermission } from '../../constants';
import moment from 'moment';

class ReadReportField extends React.Component {

	handleEdit = () => {
		this.props.handleEdit();
	};

	handleSubmit = () => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Submit!'
		}).then((result) => {
			if (result.value) {
				let newObj = {};
				this.props.data.fields.forEach(obj => {
					newObj[obj.id] = obj.value;
				});

				let data = {
					report_id: this.props.data.custom_performance_report_id,
					field_values: newObj
				}

				this.props.handleSubmit(data);
			}
		})
	}

	handlePublish = () => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Publish!'
		}).then((result) => {
			if (result.value) {
				let newObj = {};
				this.props.data.fields.forEach(obj => {
					newObj[obj.id] = obj.value;
				});

				let data = {
					report_id: this.props.data.custom_performance_report_id,
					field_values: newObj
				}

				this.props.handlePublish(data);
			}
		})
	}


	render() {
		console.log(this.props);
		let genderList = ['NULL', 'MEN', 'WOMEN', 'BOYS', 'GIRLS', 'MIXED'];
		return (
			<div className="report-field">
				{
					this.props.hasMatchDetails &&
					<Fragment>
						<span className="header-title">Match Detail</span>
						<div className="container-fluid p-0 mb-4">
							<div className="row">
								<div className="col-sm-3 title">Game</div>
								<div className="col-sm-9 text">{this.props.data.game_info.home_team.team_name} vs {this.props.data.game_info.away_team.team_name}</div>
							</div>
							<div className="row">
								<div className="col-sm-3 title">Date & Time</div>
								<div className="col-sm-9 text">{moment(this.props.data.game_info.game_date).format("MMM DD, YYYY")} / {moment(this.props.data.game_info.game_time, "HH:mm").format("LT")}</div>
							</div>
							<div className="row">
								<div className="col-sm-3 title">Venue</div>
								<div className="col-sm-9 text">{this.props.data.game_info.venue.venue_name}</div>
							</div>
							<div className="row">
								<div className="col-sm-3 title">League</div>
								<div className="col-sm-9 text">{this.props.data.game_info.league.league_name}</div>
							</div>
							<div className="row">
								<div className="col-sm-3 title">Gender</div>
								<div className="col-sm-9 text">{genderList[this.props.data.game_info.gender]}</div>
							</div>
							{Object.keys(this.props.data.game_info.referees).map((obj, index) => {
								return (
									<div className="row" key={index}>
										<div className="col-sm-3 title">{obj}</div>
										<div className="col-sm-9 text">{typeof this.props.data.game_info.referees[obj] === 'string' ? this.props.data.game_info.referees[obj] : this.props.data.game_info.referees[obj].official}</div>
									</div>
								);
							})}
						</div>
						<hr className="mb-4" />
					</Fragment>
				}
				<div className="field-container">
					{(this.props.data.fields != null) && this.props.data.fields.map((obj, index) => (
						<div className="field-item mb-4" key={index}>
							<label className="label m-0">{obj.title}</label>
							{obj.value == null || obj.value === ''
								? <p style={{ fontStyle: 'italic', color: '#9c9c9c'}}>No value entered</p>
								: <p>{obj.value}</p>
							}
						</div>
					))}
					{this.props.isPublished
						? <p style={{color: 'green'}}>Report has been published</p>
						: (
								<Fragment>
									<button onClick={this.handleEdit} className="btn btn-orange mr-2">Edit Report</button>
									{getPermission().includes('administrator')
										? <button onClick={this.handlePublish} className="btn btn-orange">Publish Report</button>
										: <button onClick={this.handleSubmit} className="btn btn-orange">Submit Report</button>
									}
								</Fragment>
							)
					}
				</div>
			</div>
		);
	}
}

export default ReadReportField;