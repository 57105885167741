import {connect} from 'react-redux';
import EditTeam from './edit_team';
import {fetchLeaguesPerformed} from '../../../../../actions/league';
import {fetchVenuesPerformed} from '../../../../../actions/venue';
import {getTeam, teamEditPerformed} from '../../../../../actions/team';

function mapsDispatchToProps (dispatch) {
    return {
        fetchLeagues: () => dispatch(fetchLeaguesPerformed()),
        fetchVenues: () => dispatch(fetchVenuesPerformed()),
        getTeam: (id) => dispatch(getTeam(id)),
        onTeamSubmitted: (teamFormData, id) => dispatch(teamEditPerformed(teamFormData, id))
    }
}

function mapsStateToProps (state) {
    return {
        leagues: state.league.results,
        venues: state.venue.results,
        league_loading: state.league.is_loading,
        venue_loading: state.venue.is_loading,
        loading: state.team.is_loading,
        errors: state.team.edit_errors,
        team: state.team.team_detail,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditTeam);