import { connect } from 'react-redux';
import Logout from './logout';
import { push } from 'react-router-redux';
import { closeTrialBanner } from '../../actions/billing';

function mapDispatchToProps(dispatch) {
	return {
		onLogoutNavigate: () => dispatch(push('/login')),
		closeTrialBanner: () => dispatch(closeTrialBanner())
	};
}

export default connect(() => ({}), mapDispatchToProps)(Logout);