import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../../../AuthHOC/withAuth';
import Loading from '../../../../loader/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePicker from 'rc-time-picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import Dropzone from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';

import './detail.scss';

class CustomReportDetail extends Component {
	componentWillMount() {
		this.props.fetchCustomReportsDetails(this.props.match.params.id);
	};

	render() {
		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD'}
				disabledTime={null}
				timePicker={null}
				defaultValue={moment()}
				showDateInput={false}
			/>
		);

		const calendarAndTime = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={'YYYY-MM-DD HH:mm:ss'}
				disabledTime={null}
				defaultValue={moment()}
				showDateInput={false}
				timePicker={<TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm:ss')} />}
			/>
		);
		return (
			<div className="regions">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">{this.props.detail.report_name}</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item"><Link to="/report/custom">Custom Reports</Link></li>
								<li className="breadcrumb-item current">{this.props.detail.report_name}</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					{this.props.loader && <Loading />}
					{(this.props.detail.fields != null) && this.props.detail.fields.map((field, index) => {
						if (field.type === 'text') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<TextareaAutosize
										minRows={3}
										maxRows={14}
										className="form-control no-transition"
										placeholder={field.title}
									/>
								</div>
							)
						} else if (field.type === 'checkbox') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="d-flex flex-wrap">
										{field.fields.map((option, optionIndex) => {
											return (
												<div key={optionIndex} className="custom-control custom-checkbox mr-4">
													<input type="checkbox" className="custom-control-input" id={`${option.textvalue}-${optionIndex}-${index}`} />
													<label className="custom-control-label" htmlFor={`${option.textvalue}-${optionIndex}-${index}`}>{option.textvalue}</label>
												</div>
											)
										})}
									</div>
								</div>
							)
						} else if (field.type === 'radio') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="d-flex flex-wrap">
										{field.fields.map((option, optionIndex) => {
											return (
												<div key={optionIndex} className="custom-control custom-radio mr-4">
													<input type="radio" className="custom-control-input" id={`${option.textvalue}-${optionIndex}-${index}`} name={`${field.title}-${index}`} />
													<label className="custom-control-label" htmlFor={`${option.textvalue}-${optionIndex}-${index}`}>{option.textvalue}</label>
												</div>
											)
										})}
									</div>
								</div>
							)
						} else if (field.type === 'dropdown') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<select className="form-control custom-select">
										<option>Open this select menu</option>
										{field.fields.map((option, optionIndex) => {
											return (
												<option key={optionIndex} value={option.textvalue}>{option.textvalue}</option>
											)
										})}
									</select>
								</div>
							);
						} else if (field.type === 'number') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<input type="number" className="form-control" placeholder={field.title} />
								</div>
							);
						} else if (field.type === 'date') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="input-effect-1 p-0 col-sm-4">
										<div className="datepicker" id="datepicker">
											<DatePicker
												animation="slide-up"
												disabled={false}
												calendar={calendar}
												value={moment()}
												getCalendarContainer={() => document.getElementById('datepicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={false}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={value.format('MM-DD-YYYY')}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
								</div>
							);
						} else if (field.type === 'time') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="timepicker input-group p-0 col-sm-4">
										<TimePicker showSecond={false} />
										<div className="input-group-append">
											<span className="input-group-text">
												<FontAwesomeIcon icon={faClock} />
											</span>
										</div>
									</div>
								</div>
							);
						} else if (field.type === 'datetime') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<div className="input-effect-1 p-0 col-sm-4">
										<div className="datepicker" id="datepicker">
											<DatePicker
												animation="slide-up"
												disabled={false}
												calendar={calendarAndTime}
												value={moment()}
												getCalendarContainer={() => document.getElementById('datepicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={false}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={value.format('MM-DD-YYYY - HH:mm:ss')}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
								</div>
							);
						} else if (field.type === 'file') {
							return (
								<div key={index} className="form-group mb-4">
									<label className="label mb-2">{field.title}{(field.required === 1) && <span className="required-text">Required</span>}</label>
									<Dropzone
										className="drop-area d-flex align-items-center"
										multiple={false}
										accept=".xls, .csv"
									>
										<div className="text">
											<p>
												<span className="icon icon-upload">
													<FontAwesomeIcon icon={faCloudUploadAlt} className="fa fa-file-excel" />
												</span>
											</p>
											<p>Drop file here to click upload</p>
										</div>
									</Dropzone>
								</div>
							);
						}
						return 0;
					})}
				</div>
			</div>
		);
	}
}

export default withAuth(CustomReportDetail, ['administrator']);