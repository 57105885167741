import {connect} from 'react-redux';
import EditPattern from './edit_pattern';
import {getPattern, patternEditPerformed} from '../../../../../actions/pattern';

function mapsDispatchToProps (dispatch) {
    return {
        getPattern: (id) => dispatch(getPattern(id)),
        onPatternSubmitted: (formData, id) => dispatch(patternEditPerformed({formData, id}))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.pattern.is_loading,
        errors: state.pattern.edit_errors,
        pattern: state.pattern.pattern_detail,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditPattern);