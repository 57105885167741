import {connect} from 'react-redux';
import RegionListPage from './region_list';

function mapsStateToProps (state) {
    return {
        form_success: state.region.form_success,
        region: state.region.region_detail,
        loading: state.region.is_loading,
        edit_success: state.region.edit_success
    }
}

export default connect(() => (mapsStateToProps))(RegionListPage);