import React, { Component, Fragment } from 'react';
import withAuth from '../AuthHOC/withAuth';
import ReadReportField from './read-report-field';
import WriteReportField from './write-report-field';

class NewPerformance extends Component {
	render() {
		return (
			<Fragment>
				{(this.props.hasReport && !this.props.isEdit)
					? <ReadReportField 
							isPublished={this.props.isPublished}
							data={this.props.data}
							handleEdit={this.props.handleEdit}
							handleSubmit={this.props.handleSubmit}
							handlePublish={this.props.handlePublish}
							hasMatchDetails={this.props.hasMatchDetails}
						/>
					: <WriteReportField 
							data={this.props.data}
							handleSave={this.props.handleSave}
							handleSubmit={this.props.handleSubmit}
							handlePublish={this.props.handlePublish}
						/>
				}
			</Fragment>
		);
	}
}

export default withAuth(NewPerformance, ['administrator', 'official', 'allocator', 'coach']);