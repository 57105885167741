import React, { Component, Fragment } from 'react';
import './charts.scss';
import moment from 'moment';
const times = [
	'12 AM',
	'1 AM',
	'2 AM',
	'3 AM',
	'4 AM',
	'5 AM',
	'6 AM',
	'7 AM',
	'8 AM',
	'9 AM',
	'10 AM',
	'11 AM',
	'12 PM',
	'1 PM',
	'2 PM',
	'3 PM',
	'4 PM',
	'5 PM',
	'6 PM',
	'7 PM',
	'8 PM',
	'9 PM',
	'10 PM',
	'11 PM'
];

class Charts extends Component {
	state = {
		tableWidth: 0
	}

	containerRef = React.createRef();
	tableRef = React.createRef();

	// componentDidMount() {
	// 	this.setState({
	// 		tableWidth: this.tableRef.current.offsetWidth
	// 	});
	// };

	// updateTableWidth = () => {
	// 	this.setState({
	// 		tableWidth: this.tableRef.current.offsetWidth
	// 	});
	// };

	filteredTimes = () => {
		const { startTime, endTime } = this.props;
		let startIndex = moment(moment(startTime).format('HH:mm'), 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes') / 60;
		let endIndex = moment('24:00', 'HH:mm').diff(moment(moment(endTime).format('HH:mm'), 'HH:mm'), 'minutes') / 60;

		let roundedDownStart = Math.floor(startIndex);
		let roundedDownEnd = Math.floor(endIndex);
		
		return times.slice(roundedDownStart, (times.length - roundedDownEnd));
	};

	setDefaultScrollPosition = () => {
		this.containerRef.current.scrollLeft = (90 * 6) + 1;
	};

	convertTimeToData = time => {
		let timeStart = moment(`2020-01-01 ${time.start}`).format('HH:mm');
		let timeEnd = moment(`2020-01-01 ${time.end}`).format('HH:mm');
		
		let difference = moment(timeEnd, 'HH:mm').diff(moment(timeStart, 'HH:mm'), 'minutes') * 1.5;
		let distance = moment(timeStart, 'HH:mm').diff(moment(moment(this.filteredTimes()[0], 'h A').format('HH:mm'), 'HH:mm'), 'minutes') * 1.5;

		return {
			left: distance,
			width: difference
		}
	};

	render() {
		const { data } = this.props;
		this.filteredTimes();
		return (
			<div className="charts-table" onClick={this.convertTimeToData}>
				<table className="side-table">
					<thead>
						<tr>
							<td className="title">Official Name</td>
						</tr>
					</thead>
					<tbody>
						{data.map((obj, index) => (
							<tr key={index}>
								<td className="official">{obj.name}</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="content-table" ref={this.containerRef}>
					<div className="table-front">
						<table>
							<tbody>
								{data.map((obj, index) => (
									<tr key={index}>
										<td style={{minWidth: this.state.tableWidth}}>
											<div className="event-container">
												{obj.times.map((time, index) => (
													<div
														key={index}
														className={`event`}
														style={{
															left: this.convertTimeToData(time)['left'],
															width: this.convertTimeToData(time)['width'],
															backgroundColor: time.available === "Available" ? '#4cd137' : '#e84118'
														}}
													>
													</div>
												))}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="table-bg">
						<table ref={this.tableRef}>
							<thead>
								<tr>
									{this.filteredTimes().map((x, index) => (
										<td key={index} colSpan="2" className="title">{x}</td>
									))}
								</tr>
							</thead>
							<tbody>
								{data.map((obj, index) => (
									<tr key={index}>
										{this.filteredTimes().map((x, index) => (
											<Fragment key={index}>
												<td></td>
												<td></td>
											</Fragment>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default Charts;