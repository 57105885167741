import {connect} from 'react-redux';
import NewPayScale from './new_payscale';
import {payscaleCreatePerformed} from '../../../../../actions/payscale';

function mapsDispatchToProps (dispatch) {
    return {
        onPayScaleSubmitted: (payscaleFormData) => dispatch(payscaleCreatePerformed(payscaleFormData))
    };
}

function mapsStateToProps (state) {
    return {
        errors: state.league.errors,
        loading: state.league.is_loading,
        form_success: state.league.form_success,
    };
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewPayScale);
