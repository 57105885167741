import React from 'react';
import { Link } from 'react-router-dom';
import withAuth from '../../../AuthHOC/withAuth';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import Loader from '../../../loader/loader';
import axios from 'axios';
import { API_BASE_URL } from '../../../../constants';

const now = moment();
const before = moment().subtract(7, 'd');
const format = 'DD-MM-YYYY';

class PaymentReportsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			payment_start_date: before,
			payment_end_date: now,
			game_start_date: before,
			game_end_date: now,
			loading: false
		};

		this.onChangePaymentStartDate = this.onChangePaymentStartDate.bind(this);
		this.onChangePaymentEndDate = this.onChangePaymentEndDate.bind(this);
		this.onChangeGameStartDate = this.onChangeGameStartDate.bind(this);
		this.onChangeGameEndDate = this.onChangeGameEndDate.bind(this);
		this.exportToCSV = this.exportToCSV.bind(this);
		this.setLoading = this.setLoading.bind(this);
	}

	onChangePaymentStartDate(value) {
		this.setState({ payment_start_date: value });
	}

	onChangePaymentEndDate(value) {
		this.setState({ payment_end_date: value });
	}

	onChangeGameStartDate(value) {
		this.setState({ game_start_date: value });
	}

	onChangeGameEndDate(value) {
		this.setState({ game_end_date: value });
	}

	setLoading(val) {
		this.setState({loading: val});
	}

	exportToCSV(type) {
		let url;
		let start_date;
		let end_date;
		//let fileName;

		if (type === 'Payment') {
			url = 'gamefees';
			start_date = this.state.payment_start_date;
			end_date = this.state.payment_end_date;
			//fileName = 'paymentsummary';
		} else if (type === 'Game') {
			url = 'detailedgamefees';
			start_date = this.state.game_start_date;
			end_date = this.state.game_end_date;
			//fileName = 'gamepayment';
		}

		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		this.setLoading(true);
		axios.get(`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/reports/${url}`, {
			params: {
				start_date: start_date.format('YYYY-MM-DD'),
				end_date: end_date.format('YYYY-MM-DD')
			},
			headers: {
				'Content-Type': 'text/csv',
				'X-Requested-With': 'XMLHttpRequest',
				'Access-Control-Allow-Origin': '*',
				'Authorization': 'Bearer ' + user.access_token,
			}
		}).then((response) => {
			this.setLoading(false);
			swal(
				'Success',
				response.data.message,
				'success'
			);
		}).catch((error) => {
			this.setLoading(true);
			console.log(error);
		})
	}

	render() {
		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={format}
				format={format}
				disabledTime={null}
				timePicker={null}
				defaultValue={now}
				showDateInput={false}
			/>);
		return (
			<div className="payment-reports">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Payment Reports</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Payment Reports</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box" style={{position: 'relative', minHeight: '80vh'}}>
				{this.state.loading && <Loader />}
					<div className="card card-border card-invite-official mb-5">
						<div className="card-header d-flex align-items-center justify-content-between">
							<h2>Payment Summary Report</h2>
						</div>
						<div>
							<div className="card-body">
								<div>
									<div className="row">
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">Start Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.payment_start_date}
															onChange={this.onChangePaymentStartDate}
															getCalendarContainer={() => document.getElementById('datepicker')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">End Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker2">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.payment_end_date}
															onChange={this.onChangePaymentEndDate}
															getCalendarContainer={() => document.getElementById('datepicker2')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12 col-12 d-flex justify-content-between align-items-center">
											<div className="action">
												<button className="btn btn-orange" onClick={() => this.exportToCSV('Payment')}>Export to CSV</button>
											</div>
											<div className="action">
												<button className="btn btn-orange" onClick={() => this.exportToCSV('Payment')}>Send to XERO</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card card-border card-invite-official">
						<div className="card-header d-flex align-items-center justify-content-between">
							<h2>Game Payment Report</h2>
						</div>
						<div>
							<div className="card-body">
								<div>
									<div className="row">
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">Start Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker3">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.game_start_date}
															onChange={this.onChangeGameStartDate}
															getCalendarContainer={() => document.getElementById('datepicker3')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-3 col-lg-6 col-md-12 col-12">
											<div className="form-group">
												<label className="label">End Date</label>
												<div className="input-effect-1">
													<div className="datepicker" id="datepicker4">
														<DatePicker
															animation="slide-up"
															disabled={false}
															calendar={calendar}
															value={this.state.game_end_date}
															onChange={this.onChangeGameEndDate}
															getCalendarContainer={() => document.getElementById('datepicker4')}
															className="custom-calendar"
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={false}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value && value.format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12 col-12 d-flex justify-content-between   align-items-center">
											<div className="action">
												<button className="btn btn-orange" onClick={() => this.exportToCSV('Game')}>Export to CSV</button>
											</div>
											<div className="action">
												<button className="btn btn-orange" onClick={() => this.exportToCSV('Game')}>Send to XERO</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withAuth(PaymentReportsPage, ['administrator']);