import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';
import moment from 'moment';
import { getOrganisations } from '../../../../../constants';


export default class LockAvailabilityResults extends Component {
	constructor(props) {
		super(props);


		this.handleDelete = this.handleDelete.bind(this);
	}

	componentWillMount() {
		this.props.fetchLockAvailability();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.form_success === true) {

			this.props.lockavailabilitySuccess(false);
		}
	}



	handleDelete = (id) => {
		swal({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.props.removeLockAvailability(id);
			}
		})
	}

	getOrganisationName = id => {
		return getOrganisations().filter(org => org.org_id === id)[0].organisation_name;
	};

	render() {
		const data = this.props.lock_availabilities;

		const columns = [{
			Header: 'Organisation',
			Cell: row => (
				<div>
					{console.log('row', row.original)}
					{row.original.organisation_id === 0 ? 'All Organisations' : this.getOrganisationName(row.original.organisation_id)}
				</div>
			)
		}, {
			Header: 'Date',
			minWidth: 110,
			Cell: row => (
				<div className="date-time">
					{/* {moment(row.original.date).format("MMM DD, YYYY")} */}
					{row.original.lock_date_type === '1'
						? moment(row.original.date).format("MMM DD, YYYY")
						: row.original.lock_date_type === '3'
						?moment(row.original.lock_start_date).format("MMM DD, YYYY") + ' - ' + moment(row.original.lock_end_date).format("MMM DD, YYYY")
						:'-'
						}
				</div>
			)
		},
		//{
		// 	Header: 'Time',
		// 	Cell: row => (
		// 		<div className="date-time">
		// 			{/* {moment(row.original.starttime, "HH:mm").format("LT")} to {moment(row.original.endtime, "HH:mm").format("LT")} */}
		// 			{row.original.lock_date_type === 1
		// 				? 'Full Day'
		// 				: row.original.lock_date_type === 3
		// 						? '-'
		// 						: ''
		// 			}
		// 		</div>
		// 	)
		// }, {
		// 	Header: 'Hours',
		// 	Cell: row => (
		// 		<div className="date-time">
		// 			{/* {moment.duration(moment(row.original.endtime, "HH:mm").diff(moment(row.original.starttime, "HH:mm"))).asHours()} Hours */}
		// 			{row.original.lock_date_type === 1
		// 				? 'Full Day'
		// 				:row.original.lock_date_type === 3
		// 						? '-'
		// 						: ''
		// 			}
		// 		</div>
		// 	)
		// }, 
		// {
		// 	Header: 'Availability',
		// 	Cell: row => (
		// 		<div>
		// 			{row.original.available === -1 && 'Locked'}
		// 			{row.original.available === 1 && 'Unlocked'}
		// 		</div>
		// 	)
		// }, 
		{
			Header: 'Actions',
			Cell: row => (
				<div className="actions">
					<ul className="list-inline">
						{/* <li><button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button></li>
						<li><span className="seprator">|</span></li> */}
						<li><button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button></li>
					</ul>
				</div>
			)
		}];
		return (
			<div className="table-area">
				{this.props.loading && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText="No Lock Availability Found."
					defaultPageSize={10}
					resizable={true}
				/>
			</div>
		);
	}
}

LockAvailabilityResults.propTypes = {
	fetchLockAvailability: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	lock_availabilities: PropTypes.arrayOf(
		PropTypes.shape({
			date: PropTypes.string,
		}).isRequired
	),
	removeLockAvailability: PropTypes.func.isRequired,
	LockavailabilitySuccess: PropTypes.func.isRequired
}