import React, { Component } from 'react';
import './invoices.scss';
import withAuth from '../../../AuthHOC/withAuth';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Pagination from '../../../pagination/pagination';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../../loader/loader';
import { fetchInvoicesData, downloadInvoice } from '../../../../actions/billing';

class InvoicesPage extends Component {
	state = {
		open: false,
	};

	componentWillMount() {
		this.props.fetchInvoicesData();
	}

	handleDownload = (id, name, date) => {
		this.props.downloadInvoice(id, name, date);
	}

	render() {
		const columns = [
			{
				Header: 'Invoice Date',
				accessor: 'invoicedate'
			},
			{
				Header: 'Total',
				Cell: row => `$ ${row.original.total}`
			},
			{
				Header: 'Status',
				accessor: 'status'
			},
			{
				Header: 'Action',
				Cell: row => (
					<div className="actions">
						<ul className="list-inline">
							<li>
								<button className="link" onClick={() => this.handleDownload(row.original.id, row.original.total, row.original.invoicedate)}>Download</button>
							</li>
						</ul>
					</div>
				)
		
			}
		];
		return (
			<div className="invoices-page">
				{this.props.loader && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Invoices</h1>
					</div>
				</div>
				<div className="box">
					<div className="row no-gutters justify-content-between">
						<div className="actions mb_30">
							<div className="btn-group">
								<Link to="/subscription"><button type="button" className="btn btn-subscription">Subscription Detail</button></Link>
								<button type="button" className="btn btn-subscription active">Invoices</button>
								<Link to="/payment-info"><button type="button" className="btn btn-subscription">Payment Information</button></Link>
							</div>
						</div>
					</div>
					<div className="table-area">
						<ReactTable
							PaginationComponent={Pagination}
							columns={columns}
							noDataText="No Invoices Found"
							defaultPageSize={10}
							resizable={true}
							minRows={5}
							data={this.props.data}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		fetchInvoicesData: () => dispatch(fetchInvoicesData()),
		downloadInvoice: (id, name, date) => dispatch(downloadInvoice(id, name, date))
	}
}

function mapsStateToProps(state) {
	return {
		loader: state.billing.loader,
		data: state.billing.invoicesList
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(InvoicesPage, ['administrator']));