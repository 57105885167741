import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
	POST_AUTH_CODE
} from '../actions/oauth';
import { showFullSizeLoader, hideFullSizeLoader } from '../actions/loader';
import { API_BASE_URL, getHeaders } from '../constants';
import { handleError } from '../actions/handleError';
// import swal from 'sweetalert2/dist/sweetalert2.js';
// import { push } from 'connected-react-router';


function* postAuthCode({ data }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/oauth/authcode`,
			{ 
				headers: getHeaders(),
				params: data
			}
		);
		yield put(hideFullSizeLoader());
		window.location.replace(`${results.data.redirect_url}?state=${results.data.state}&authorisation_code=${results.data.authorisation_code}&organisation_id=${results.data.organisation_id}`);
	} catch (error) {
		yield put(hideFullSizeLoader());
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(POST_AUTH_CODE, postAuthCode);
}