import {connect} from 'react-redux';
import TeamListPage from './team_list';

function mapsStateToProps (state) {
    return {
        form_success: state.team.form_success,
        team: state.team.team_detail,
        loading: state.team.is_loading,
        edit_success: state.team.edit_success
    }
}

export default connect(() => (mapsStateToProps))(TeamListPage);