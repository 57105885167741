import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {FETCH_CHART_DATA, dashboardLoaderStatus, fetchChartDataSuccess, FTECH_CALENDAR_DATA, fetchCalendarDataSuccess, FTECH_OFFICIAL_CALENDAR_DATA, fetchOfficialCalendarDataSuccess} from '../actions/dashboard';
import { push } from 'connected-react-router';
import {API_BASE_URL} from '../constants'
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* getChartData({daterange}) {
    yield put(dashboardLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/games/dashboard`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                },
                params: {
                    daterange: daterange
                }
            }
        );
        yield put(dashboardLoaderStatus(false));
		yield put(fetchChartDataSuccess(results.data));
    }catch (error) {
        yield put(dashboardLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* getCalendarData() {
    yield put(dashboardLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/events`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(dashboardLoaderStatus(false));
		yield put(fetchCalendarDataSuccess(results.data.data));
    }catch (error) {
        yield put(dashboardLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* getOfficialCalendarData() {
    yield put(dashboardLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        let user_id = user.user.id;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/users/${user_id}/events`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(dashboardLoaderStatus(false));
		yield put(fetchOfficialCalendarDataSuccess(results.data.data));
    }catch (error) {
        yield put(dashboardLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

export default function* () {
    yield takeLatest(FETCH_CHART_DATA, getChartData);
    yield takeLatest(FTECH_CALENDAR_DATA, getCalendarData);
    yield takeLatest(FTECH_OFFICIAL_CALENDAR_DATA, getOfficialCalendarData);
}