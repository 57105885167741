import { connect } from 'react-redux';
import GameFeeResults from './gamefee_results';
import { getLeague } from '../../../../../actions/league';
import { fetchGameFeesPerformed, gamefeeEditPerformed } from '../../../../../actions/gamefee';
import { fetchPayScalesPerformed } from '../../../../../actions/payscale';


function mapsDispatchToProps (dispatch) {
    return {
        getLeague: (id) => dispatch(getLeague(id)),
        fetchPayScales: () => dispatch(fetchPayScalesPerformed()),
        fetchGameFees: (leagueId) => dispatch(fetchGameFeesPerformed(leagueId)),
        onGameFeeSubmitted: (formdata, id) => dispatch(gamefeeEditPerformed(formdata, id))
    }
}

function mapsStateToProps (state) {
    return {
        league: state.league.league_detail,
        payscales: state.payscale.results.data,
        gamefees: state.gamefee.results,
        loading: state.gamefee.is_loading
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(GameFeeResults);