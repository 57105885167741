import { TEAM_LOADER_SHOW, FETCH_TEAMS_SUCCESS, TEAM_CREATE_SUCCESS, TEAM_CREATE_ERROR, GET_TEAM_DETAIL_SUCCESS, TEAM_EDIT_SUCCESS, TEAM_EDIT_ERROR } from '../actions/team';

const initialState = {
    results: [],
    is_loading: false,
    form_success: false,
    create_errors: {},
    team_detail: {},
    edit_errors: {},
    edit_success: false
}

export default (state, actions) => {
    if (state === undefined) {
        return initialState;
    }

    switch (actions.type) {
        case FETCH_TEAMS_SUCCESS:
            return {
                ...state,
                results: actions.results,
                form_success: false,
                create_errors: {},
                edit_errors: {},
                edit_success: false
            };
        case TEAM_LOADER_SHOW:
            return {
                ...state,
                is_loading: actions.status
            };
        case TEAM_CREATE_SUCCESS:
            return {
                ...state,
                form_success: actions.status,
                create_errors: {}
            };
        case TEAM_CREATE_ERROR:
            return {
                ...state,
                create_errors: actions.errors
            };
        case GET_TEAM_DETAIL_SUCCESS:
            return {
                ...state,
                team_detail: actions.team
            }
        case TEAM_EDIT_ERROR:
            return {
                ...state,
                edit_errors: actions.errors
            }
        case TEAM_EDIT_SUCCESS:
            return {
                ...state,
                edit_success: actions.status
            }
        default:
            return state;
    }
}