import { connect } from 'react-redux';
import VenueResults from './venue_results';
import { fetchVenuesPerformed, deleteVenue, getRegionsForVenue } from '../../../../../actions/venue';

function mapsDispatchToProps(dispatch) {
	return {
		fetchVenues: () => dispatch(fetchVenuesPerformed()),
		removeVenue: (id) => dispatch(deleteVenue(id)),
		getRegionsForVenue: () => dispatch(getRegionsForVenue())

	}
}

function mapsStateToProps(state) {
	return {
		venues: state.venue.results,
		loading: state.venue.is_loading,
		form_success: state.venue.form_success,
		is_deleted: state.venue.is_deleted,
		edit_success: state.venue.edit_success,
		region_list: state.venue.region_list
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(VenueResults);