import {
    PAYSCALE_LOADER_SHOW,
    FETCH_PAYSCALES_SUCCESS,
    PAYSCALE_CREATE_SUCCESS,
    PAYSCALE_CREATE_ERROR,
    GET_PAYSCALE_DETAIL_SUCCESS,
    PAYSCALE_EDIT_ERROR,
    PAYSCALE_EDIT_SUCCESS
} from '../actions/payscale';

const initialState = {
    results: [],
    is_loading: false,
    form_success: false,
    create_errors: {},
    payscale_detail: {},
    edit_errors: {},
    edit_success: false
};

export default (state, actions) => {
    if (state === undefined) {
        return initialState;
    }

    switch (actions.type) {
        case FETCH_PAYSCALES_SUCCESS:
            return {
                ...state,
                results: actions.results
            };
        case PAYSCALE_LOADER_SHOW:
            return {
                ...state,
                is_loading: actions.status
            };
        case PAYSCALE_CREATE_SUCCESS:
            return {
                ...state,
                form_success: actions.status,
                create_errors: {}
            };
        case PAYSCALE_CREATE_ERROR:
            return {
                ...state,
                create_errors: actions.errors
            };
        case GET_PAYSCALE_DETAIL_SUCCESS:
            return {
                ...state,
                payscale_detail: actions.payscale
            }
        case PAYSCALE_EDIT_ERROR:
            return {
                ...state,
                edit_errors: actions.errors
            }
        case PAYSCALE_EDIT_SUCCESS:
            return {
                ...state,
                edit_success: actions.status
            }
        default:
            return state;
    }
};
