import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { HANDLE_ERROR } from '../actions/handleError';
import { showAcknowledgementModal, setAcknowledgementModalData } from '../actions/acknowledgements';
import { push } from 'connected-react-router';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

// error.response.status
// error.response.data

function* handleError({ error }) {
	if (error.response.status === 401) {
		localStorage.removeItem('user');
		swal(
			'Error',
			'Your session has expired.',
			'error'
		);
		yield put(push('/login'));
	} else if (error.response.status === 307) {
		yield put(showAcknowledgementModal(true));
		yield put(setAcknowledgementModalData(error.response.data));
	} else {
		if (error.response.data.hasOwnProperty('message')) {
			swal(
				'Error - ' + error.response.status,
				error.response.data.message,
				'error'
			);
		} else if (error.response.data.hasOwnProperty('Message')) {
			swal(
				'Error - ' + error.response.status,
				error.response.data.Message,
				'error'
			);
		} else {
			swal(
				'Error - ' + error.response.status,
				error.response.data,
				'error'
			);
		}
	}
}


export default function* () {
	yield takeLatest(HANDLE_ERROR, handleError);
}