export const FETCH_REGIONS_PERFORMED = 'FETCH_REGIONS_PERFORMED';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const REGION_LOADER_SHOW = 'REGION_LOADER_SHOW';
export const REGION_CREATE_PERFORMED = 'REGION_CREATE_PERFORMED';
export const REGION_CREATE_SUCCESS = 'REGION_CREATE_SUCCESS';
export const REGION_CREATE_ERROR = 'REGION_CREATE_ERROR';
export const GET_REGION_DETAIL = 'GET_REGION_DETAIL';
export const GET_REGION_DETAIL_SUCCESS = 'GET_REGION_DETAIL_SUCCESS';
export const REGION_EDIT_PERFORMED = 'REGION_EDIT_PERFORMED';
export const REGION_EDIT_SUCCESS = 'REGION_EDIT_SUCCESS';
export const REGION_EDIT_ERROR = 'REGION_EDIT_ERROR';
export const REGION_DELETE_PERFORMED = 'REGION_DELETE_PERFORMED';
export const REGION_DELETE_SUCCESS = 'REGION_DELETE_SUCCESS';

export const fetchRegionsPerformed = () => ({
	type: FETCH_REGIONS_PERFORMED,
});

export const fetchRegionsSuccess = (results) => ({
	type: FETCH_REGIONS_SUCCESS,
	results
});

export const regionLoaderStatus = (status) => ({
	type: REGION_LOADER_SHOW,
	status
});

export const regionCreatePerformed = (regionFormData) => ({
	type: REGION_CREATE_PERFORMED,
	regionFormData
});

export const regionCreateSuccess = (status) => ({
	type: REGION_CREATE_SUCCESS,
	status
});

export const regionCreateErrors = (errors) => ({
	type: REGION_CREATE_ERROR,
	errors
});

export const getRegion = (id) => ({
	type: GET_REGION_DETAIL,
	id
});

export const getRegionSuccess = (region) => ({
	type: GET_REGION_DETAIL_SUCCESS,
	region
});

export const regionEditPerformed = (regionFormData, id) => ({
	type: REGION_EDIT_PERFORMED,
	regionFormData,
	id
});

export const regionEditSuccess = (status) => ({
	type: REGION_EDIT_SUCCESS,
	status
});

export const regionEditErrors = (errors) => ({
	type: REGION_EDIT_ERROR,
	errors
});

export const deleteRegion = (id) => ({
	type: REGION_DELETE_PERFORMED,
	id
});

export const regionDeleteSuccess = (status) => ({
	type: REGION_DELETE_SUCCESS,
	status
});