import {connect} from 'react-redux';
import Register from './register';
import {authCheck} from '../../../actions/login';

function mapsDispatchToProps (dispatch) {
    return {
        authCheck: () => dispatch(authCheck())
    };
}

export default connect(() => ({}), mapsDispatchToProps)(Register);