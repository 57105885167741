import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../pagination/pagination';

export default class PatternResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pattern_fields: []
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillMount() {
        this.props.fetchPatterns();
    }

    handleEdit = (id) => {
        this.props.onOpenEditModal(id);
    }

    handleDelete = (id) => {
        swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if(result.value)
            {
                this.props.removePattern(id);
            }
          })
    }
    render () {
        const data = this.props.patterns;
        const columns = [{
            Header: 'Pattern Name',
            accessor: 'pattern_name' // String-based value accessors!
          }, {
            Header: 'List of positions',
            Cell: row => (
                <div className="list_position">
                    {row.original.fields.map(e => e.field_name).join(", ")}
                </div>
            )
         }, {
            Header: 'Actions',
            Cell: row => (
                <div className="actions">
                    <ul className="list-inline">
                        <li><button className="link" onClick={() => this.handleEdit(row.original.id)}>Edit</button></li>
                        <li><span className="seprator">|</span></li>
                        <li><button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button></li>
                    </ul>
                </div>
            )
         }];
        return (
            <div className="table-area">
                {this.props.loading && <Loader />}
                <ReactTable
                    PaginationComponent={Pagination}
                    data={data}
                    minRows={5}
                    columns={columns}
                    noDataText="No Patterns Found."
                    defaultPageSize= {10}
                    resizable={true}
                />
            </div>
        );
    }
}

PatternResults.propTypes = {
    patterns: PropTypes.arrayOf(
        PropTypes.shape({
            pattern_name: PropTypes.string,
        }).isRequired
    ),
    fetchPatterns: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    removePattern: PropTypes.func.isRequired
}