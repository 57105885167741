import React, {Component}  from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../loader/loader';

export default class AssignReferee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referees:[],
            assign_referees: {},
            originalProps: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlesubmit = this.handlesubmit.bind(this);
    }
    componentWillMount(){
        this.setState({
            referees:[],
            assign_referees: {},
            originalProps: {}
        });
        this.props.getReferees(this.props.game_id);
        this.props.getAssignReferee(this.props.game_id);
    }

    handleInputChange = (id, index) => event => {
        let editedData = JSON.parse(JSON.stringify(this.state.referees));
        editedData[index][id] = Number(event.target.value);

        this.setState({
            referees: editedData
        });
    }

    componentWillReceiveProps(nextProps) {
        if (Object.keys(this.state.originalProps).length === 0) {
            this.setState({
                originalProps: nextProps.assign_referees
            });
        }

        if (this.state.originalProps !== nextProps.assign_referees) {
            let newData = Object.keys(nextProps.assign_referees).map((key) => {
                let res;
                if (typeof nextProps.assign_referees[key] === 'string') {
                    res = '';
                } else {
                    res = nextProps.assign_referees[key].official.id
                }
                return {[key]: res };
            });

            this.setState({
                referees: newData,
                assign_referees: nextProps.assign_referees
            });
        }
    }

    async handlesubmit(event) {
        event.preventDefault();
        const {referees} = this.state;
        const data = {
            match_officials: referees
        }

        this.props.onAssignOfficial(data, this.props.game_id);
    }
    render () {
        let referees = this.props.referees;
        return (
            <div className="assign-edit">
                <h2>Assign Officials</h2>
                <div className="wrap mb_15" style={{position: 'relative'}}>
                    <div className="row">
                    {(this.props.loading || this.props.loading_data) && <Loader />}
                        {this.props.fields.map((field, index) => (
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12" key={index}>
                                <div className="list-item">
                                    <div className="row no-gutters align-items-center justify-content-between">
                                        <div className="post col-lg-6 col-md-12 col-12">{field.field_name}</div>
                                        <div className="name col-lg-6 col-md-12 col-12">
                                            <select className="normal-select" onChange={this.handleInputChange(field.id, index)} value={this.state.referees.length > 0 ? this.state.referees[index][field.id] : ''}>
                                                <option value="">Select One</option>
                                                {Object.keys(referees).length !== 0 && Object.keys(referees[field.field_name]).map(obj => {
                                                    return (<optgroup key={obj} label={obj}>
                                                        {referees[field.field_name][obj].map(referee => <option key={referee.id} value={referee.id}>{referee.name}</option>)}
                                                    </optgroup>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-orange" onClick={this.handlesubmit}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

AssignReferee.propTypes = {
    getReferees: PropTypes.func.isRequired,
    onAssignOfficial: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    getAssignReferee: PropTypes.func.isRequired
}
