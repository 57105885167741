import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ClassNames from 'classnames';
import Loader from '../../../../../loader/loader';
import moment from 'moment';
import withAuth from '../../../../../AuthHOC/withAuth';

class GeneralResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			monday: '',
			tuesday: '',
			wednesday: '',
			thursday: '',
			friday: '',
			saturday: '',
			sunday: ''
		}
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.availabilities).length > 0) {
			let monday = '';
			let tuesday = '';
			let wednesday = '';
			let thursday = '';
			let friday = '';
			let saturday = '';
			let sunday = '';

			if (nextProps.availabilities.monday !== '1' && nextProps.availabilities.monday !== '-1') {
				monday = JSON.parse(nextProps.availabilities.monday);
			} else {
				monday = nextProps.availabilities.monday;
			}

			if (nextProps.availabilities.tuesday !== '1' && nextProps.availabilities.tuesday !== '-1') {
				tuesday = JSON.parse(nextProps.availabilities.tuesday);
			} else {
				tuesday = nextProps.availabilities.tuesday;
			}

			if (nextProps.availabilities.wednesday !== '1' && nextProps.availabilities.wednesday !== '-1') {
				wednesday = JSON.parse(nextProps.availabilities.wednesday);
			} else {
				wednesday = nextProps.availabilities.wednesday;
			}

			if (nextProps.availabilities.thursday !== '1' && nextProps.availabilities.thursday !== '-1') {
				thursday = JSON.parse(nextProps.availabilities.thursday);
			} else {
				thursday = nextProps.availabilities.thursday;
			}

			if (nextProps.availabilities.friday !== '1' && nextProps.availabilities.friday !== '-1') {
				friday = JSON.parse(nextProps.availabilities.friday);
			} else {
				friday = nextProps.availabilities.friday;
			}

			if (nextProps.availabilities.saturday !== '1' && nextProps.availabilities.saturday !== '-1') {
				saturday = JSON.parse(nextProps.availabilities.saturday);
			} else {
				saturday = nextProps.availabilities.saturday;
			}

			if (nextProps.availabilities.sunday !== '1' && nextProps.availabilities.sunday !== '-1') {
				sunday = JSON.parse(nextProps.availabilities.sunday);
			} else {
				sunday = nextProps.availabilities.sunday;
			}

			this.setState({
				monday: monday,
				tuesday: tuesday,
				wednesday: wednesday,
				thursday: thursday,
				friday: friday,
				saturday: saturday,
				sunday: sunday
			});
		}
	}
	render() {
		let mondayTimes = '';
		let tuesdayTimes = '';
		let wednesdayTimes = '';
		let thursdayTimes = '';
		let fridayTimes = '';
		let saturdayTimes = '';
		let sundayTimes = '';
		if (typeof (this.state.monday) === 'object') {
			mondayTimes = this.state.monday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.tuesday) === 'object') {
			tuesdayTimes = this.state.tuesday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.wednesday) === 'object') {
			wednesdayTimes = this.state.wednesday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.thursday) === 'object') {
			thursdayTimes = this.state.thursday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.friday) === 'object') {
			fridayTimes = this.state.friday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.saturday) === 'object') {
			saturdayTimes = this.state.saturday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		if (typeof (this.state.sunday) === 'object') {
			sundayTimes = this.state.sunday.times.map((time, index) =>

				<span key={`time-${index}`}>{moment(time.start_time, "HH:mm").format("LT")} to {moment(time.end_time, "HH:mm").format("LT")}</span>
			);
		}
		return (
			<div className="available-days">
				{this.props.loading && <Loader />}
				<div className="row">
					<div className="col">
						<div className="boxes">
							<div className="head">Monday</div>
							{this.state.monday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.monday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.monday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{mondayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Tuesday</div>
							{this.state.tuesday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.tuesday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.tuesday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{tuesdayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Wednesday</div>
							{this.state.wednesday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.wednesday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.wednesday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{wednesdayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Thursday</div>
							{this.state.thursday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.thursday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.thursday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{thursdayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Friday</div>
							{this.state.friday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.friday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.friday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{fridayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Saturday</div>
							{this.state.saturday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.saturday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.saturday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{saturdayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col">
						<div className="boxes">
							<div className="head">Sunday</div>
							{this.state.sunday.toString() === '1' && (
								<div className="box-color green">
									<div className="wrap">
										<span>Available</span>
									</div>
								</div>
							)}
							{this.state.sunday.toString() === '-1' && (
								<div className="box-color red">
									<div className="wrap">
										<span>Not Available</span>
									</div>
								</div>
							)}
							{typeof (this.state.sunday) === 'object' && (
								<div className="box-color orange">
									<div className="wrap">
										{sundayTimes}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

GeneralResults.propTypes = {
	loading: PropTypes.bool
}

export default withAuth(GeneralResults, 'official');