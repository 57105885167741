import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from '../../loader/loader';
import FormError from '../../form-error/form-error';
import Logo from '../../logo/logo';
import LogoBackground from '../../logo/logo-background';
import emailSvg from '../../../public/svg/email.svg';
import passwordSvg from '../../../public/svg/password.svg';
import '../../../public/styles/authentication.scss';
import '../login/login.scss';
import $ from 'jquery';

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        this.state = {
            token: token,
            email: '',
            password: '',
            password_confirmation: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    getSettings() {
        $('.imgsvg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
                var $svg = $(data).find('svg');
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
                $svg = $svg.removeAttr('xmlns:a');
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                }
                $img.replaceWith($svg);
            }, 'xml');
        });
    }
    componentDidMount() {
        this.getSettings();
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        const {
            token,
            email,
            password,
            password_confirmation
        } = this.state;

        const data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        }
        this.props.doResetPassword(data);
    }
    componentWillReceiveProps (nextProps) {
        if(nextProps.form_success === true)
        {
            this.setState({
                email: '',
                password: '',
                password_confirmation: ''
            });
            this.props.history.push("/login");
        }
    }
    render () {
        let email_error = null;
        let password_error = null;
        let other_error = null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'password')
                    password_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'email')
                    email_error = <FormError error={this.props.errors[key][0]} />;
                else
                    other_error = <FormError error={this.props.errors[key][0]} />;
            }
        }
        return (
            <div>
               {this.props.loading && <Loader />} 
                <div className="authentication">
                    <LogoBackground />
                    <div className="login-container">
                        <div className="login-wrap">
                            <Logo />
                            <form>
                                {other_error}
                                <div className="signin">
                                    <label className="label">Email</label>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="icon icon-pass">
                                                        <img src={emailSvg} className="imgsvg" alt="email" />
                                                    </span>
                                                </span>
                                            </div>
                                            <input type="email" name="email" value={this.state.email} className="form-control" placeholder="Email" onChange={event => this.handleInputChange(event)} />
                                        </div>
                                    </div>
                                    {email_error}
                                </div>
                                <div className="signin">
                                    <label className="label">New Password</label>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="icon icon-pass">
                                                        <img src={passwordSvg} className="imgsvg" alt="password" />
                                                    </span>
                                                </span>
                                            </div>
                                            <input type="password" name="password" value={this.state.password} className="form-control" placeholder="Password" onChange={event => this.handleInputChange(event)} />
                                        </div>
                                    </div>
                                    {password_error}
                                </div>
                                <div className="signin">
                                    <label className="label">Confirm Password</label>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="icon icon-pass">
                                                        <img src={passwordSvg} className="imgsvg" alt="password" />
                                                    </span>
                                                </span>
                                            </div>
                                            <input type="password" name="password_confirmation" value={this.state.password_confirmation} className="form-control" placeholder="Password" onChange={event => this.handleInputChange(event)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="action">
                                    <input type="button" name="signin" value="Reset Password" className="submit" onClick={this.handleSubmit} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    doResetPassword: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    form_success: PropTypes.bool
}