import {connect} from 'react-redux';
import EditVenue from './edit_venue';
import { getVenue, venueEditPerformed, getRegionsForVenue } from '../../../../../actions/venue';

function mapsDispatchToProps (dispatch) {
    return {
        getVenue: (id) => dispatch(getVenue(id)),
        onVenueSubmitted: (venueFormData, id) => dispatch(venueEditPerformed(venueFormData, id)),
        getRegionsForVenue: () => dispatch(getRegionsForVenue())
    }
}

function mapsStateToProps (state) {
    return {
        venue: state.venue.venue_detail,
        loading: state.venue.is_loading,
        errors: state.venue.edit_errors,
        region_list: state.venue.region_list
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditVenue);