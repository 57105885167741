import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { LOGIN_PERFORMED, AUTH_CHECK, loginError, loginLoaderStatus, loginAlertError } from '../actions/login';
import { getTrialEnd } from '../actions/billing';
import { push } from 'connected-react-router';
import { API_BASE_URL, getFormatVersion, getPermission } from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import qs from 'query-string';

function* doLogin({ formData }) {
	yield put(loginLoaderStatus(true));
	try {
		const loginResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/login`,
			formData,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*'
				}
			}
		);
		yield put(loginLoaderStatus(false));
		localStorage.setItem('user', JSON.stringify({ ...loginResults.data, formatVersion: getFormatVersion()}));
		if (getPermission().includes('billing')) {
			yield put(getTrialEnd());
		}
		if (qs.parse(window.location.search)) {
			const obj = qs.parse(window.location.search);
			if (obj.client_id && obj.redirect_url && obj.scopes && obj.state) {
				yield put(push(`/oauth/connect?client_id=${obj.client_id}&redirect_url=${obj.redirect_url}&scopes=${obj.scopes}&state=${obj.state}`));
				return;
			}
		}
		yield put(push('/'));
	} catch (error) {
		if (error.response.status === 422) {
			yield put(loginLoaderStatus(false));
			yield put(loginError(error.response.data.errors));
		}
		else {
			yield put(loginLoaderStatus(false));
			yield put(loginAlertError('Invalid email or password', true));
		}
	}

}

function* doAuthCheck() {
	if (localStorage.getItem('user')) {
		swal(
			'Info',
			'You are already login',
			'info'
		);
		yield put(push('/'));
	}
}

export default function* () {
	yield takeLatest(LOGIN_PERFORMED, doLogin);
	yield takeLatest(AUTH_CHECK, doAuthCheck);
}