import {connect} from 'react-redux';
import BankForm from './bank_form';
import {bankEditPerformed, getBankDetailPerformed} from '../../../../../actions/official';

function mapsDispatchToProps (dispatch) {
    return {
        onSubmitDetail: (formData) => dispatch(bankEditPerformed(formData)),
        fetchBankDetail: () => dispatch(getBankDetailPerformed())
    }
} 

function mapsStateToProps (state) {
    return {
        errors: state.official.bank_errors,
        form_success: state.official.bank_success,
        bank_details: state.official.bank_detail,
        loading: state.official.is_loading 
    }
}

export default connect (() => (mapsStateToProps), mapsDispatchToProps)(BankForm);