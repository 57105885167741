import { 
	FETCH_TRAVEL_CLAIM_SUCCESS,
	FETCH_TRAVEL_CLAIM_LOADER,
	EDIT_TRAVEL_CLAIM_LOADER
} from '../actions/travel_claim';
const initialState = {
	data: [],
	teams: [],
	leagues: [],
	users: [],
	fetch_loader: false,
	edit_loader: false
}
export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}
	switch (actions.type) {
		case FETCH_TRAVEL_CLAIM_SUCCESS:
			return {
				...state,
				data: actions.results.data,
				teams: actions.teams,
				users: actions.users,
				leagues: actions.leagues
			};
		case FETCH_TRAVEL_CLAIM_LOADER:
			return {
				...state,
				fetch_loader: actions.status
			};
		case EDIT_TRAVEL_CLAIM_LOADER:
			return {
				...state,
				edit_loader: actions.status
			};
		default:
			return state;
	}
}