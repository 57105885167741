import React, { Component } from 'react';
import qs from 'query-string';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { getCurrentUser } from '../../../../constants';

class redirectCalendar extends Component {
	componentDidMount() {
		const state = qs.parse(this.props.location.search).state;

		if (state == null) {
			this.props.history.push('/edit/profile');
		} else {
			if (state === 'success') {
				let currentUser = getCurrentUser();
				currentUser['gcal'] = 1;
				localStorage.setItem('user', JSON.stringify(currentUser));
				swal({
					title: 'Success',
					text: "Successfully connected to Google Calendar",
					type: 'success',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ok'
				}).then((result) => {
					if (result.value) {
						this.props.history.push('/edit/profile');
					}
				})
			} else if (state === 'error') {
				swal({
					title: 'Error',
					text: "Error. Please try again later.",
					type: 'error',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ok'
				}).then((result) => {
					if (result.value) {
						this.props.history.push('/edit/profile');
					}
				})
			}
		}
	};
	render() {
		return (
			<div className="redirect-calendar">
				<div className="box">
					<div className="page-head">
						<div className="head mb-0">
							<h1 className="page-title">Google Calendar Integration</h1>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
 
export default redirectCalendar;