import {connect} from 'react-redux';
import LockAvailabilityResults from './lock_availability_result';
import {fetchLockAvailabilities, deleteLockAvailability, lockAvailabilityEditSuccess} from '../../../../../actions/lock_availability';

function mapsDispatchToProps (dispatch) {
    return {
        fetchLockAvailability: () => dispatch(fetchLockAvailabilities()),
        removeLockAvailability: (id) => dispatch(deleteLockAvailability(id)),
        LockavailabilitySuccess: (status) => dispatch(lockAvailabilityEditSuccess(status))
    }
}

function mapsStateToProps (state) {
    return {
        lock_availabilities: state.lock_availability.lock_results,
        loading: state.lock_availability.is_loading,
        form_success: state.lock_availability.edit_form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(LockAvailabilityResults);