import React from 'react';
import './edit-profile.scss';
import { Link } from 'react-router-dom';
import EditPhoto from './edit-photo/edit_photo.container';
import EditForm from './edit-form/edit_form.container';
import withAuth from '../../../AuthHOC/withAuth';

class EditProfilePage extends React.Component {
	render() {
		return (
			<div className="edit-profile">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Edit Profile</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Edit Profile</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="edit-profile-form">
						<div className="row">
							<EditPhoto />
							<EditForm />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withAuth(EditProfilePage, ['administrator', 'official', 'allocator']);