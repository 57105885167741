export const SUBMIT_CUSTOM_REPORTS = 'SUBMIT_CUSTOM_REPORTS';
export const FETCH_CUSTOM_REPORTS_LIST = 'FETCH_CUSTOM_REPORTS_LIST';
export const FETCH_CUSTOM_REPORTS_LIST_SUCCESS = 'FETCH_CUSTOM_REPORTS_LIST_SUCCESS';
export const SET_REPORTS_LOADER = 'SET_REPORTS_LOADER';
export const DELETE_CUSTOM_REPORTS = 'DELETE_CUSTOM_REPORTS';
export const FETCH_CUSTOM_REPORTS_DETAILS = 'FETCH_CUSTOM_REPORTS_DETAILS';
export const FETCH_CUSTOM_REPORTS_DETAILS_SUCCESS = 'FETCH_CUSTOM_REPORTS_DETAILS_SUCCESS';
export const EXPORT_ROSTER_REPORT = 'EXPORT_ROSTER_REPORT';
export const FETCH_DECLINED_REPORT = 'FETCH_DECLINED_REPORT';
export const FETCH_DECLINED_REPORT_SUCCESS = 'FETCH_DECLINED_REPORT_SUCCESS';
export const EXPORT_DECLINED_REPORT = 'EXPORT_DECLINED_REPORT';
export const FETCH_BANK_REPORT = 'FETCH_BANK_REPORT';
export const FETCH_BANK_REPORT_SUCCESS = 'FETCH_BANK_REPORT_SUCCESS';
export const EXPORT_BANK_REPORT="EXPORT_BANK_REPORT";
export const FETCH_ALLUSERS_REPORT = 'FETCH_ALLUSERS_REPORT';
export const FETCH_ALLUSERS_REPORT_SUCCESS = 'FETCH_ALLUSERS_REPORT_SUCCESS';
export const FETCH_CUSTOM_GAME_REPORTS_LIST = 'FETCH_CUSTOM_GAME_REPORTS_LIST';
export const FETCH_CUSTOM_GAME_REPORTS_LIST_SUCCESS = 'FETCH_CUSTOM_GAME_REPORTS_LIST_SUCCESS';
export const FETCH_CUSTOM_GAME_REPORTS_DETAILS = 'FETCH_CUSTOM_GAME_REPORTS_DETAILS';
export const FETCH_CUSTOM_GAME_REPORTS_DETAILS_SUCCESS = 'FETCH_CUSTOM_GAME_REPORTS_DETAILS_SUCCESS';
export const DELETE_CUSTOM_GAME_REPORTS = 'DELETE_CUSTOM_GAME_REPORTS';
export const SUBMIT_CUSTOM_GAME_REPORTS = 'SUBMIT_CUSTOM_GAME_REPORTS';


export const submitCustomReports = (formData, callback) => ({
	type: SUBMIT_CUSTOM_REPORTS,
	formData,
	callback
});

export const fetchCustomReportsList = () => ({
	type: FETCH_CUSTOM_REPORTS_LIST
});

export const fetchCustomReportsListSuccess = results => ({
	type: FETCH_CUSTOM_REPORTS_LIST_SUCCESS,
	results
});

export const setReportsLoader = status => ({
	type: SET_REPORTS_LOADER,
	status
});

export const deleteCustomReports = id => ({
	type: DELETE_CUSTOM_REPORTS,
	id
});

export const fetchCustomReportsDetails = id => ({
	type: FETCH_CUSTOM_REPORTS_DETAILS,
	id
});

export const fetchCustomReportsDetailsSuccess = results => ({
	type: FETCH_CUSTOM_REPORTS_DETAILS_SUCCESS,
	results
});

export const exportRosterReport = (format, startDate, endDate) => ({
	type: EXPORT_ROSTER_REPORT,
	format,
	startDate,
	endDate
});

export const fetchDeclinedReport = () => ({
	type: FETCH_DECLINED_REPORT
});

export const fetchDeclinedReportSuccess = results => ({
	type: FETCH_DECLINED_REPORT_SUCCESS,
	results
});

export const exportDeclinedReport = (report, startDate, endDate) => ({
	type: EXPORT_DECLINED_REPORT,
	report,
	startDate,
	endDate
});
export const fetchBankReport = () => ({
	type: FETCH_BANK_REPORT,
});

export const fetchBankReportSuccess = results => ({
	type: FETCH_BANK_REPORT_SUCCESS,
	results
});

export const exportBankReport = () => ({
	type: EXPORT_BANK_REPORT,
	
});
export const fetchallUsersReport = () => ({
	type: FETCH_ALLUSERS_REPORT,
});

export const fetchallUsersReportSuccess = results => ({
	type: FETCH_ALLUSERS_REPORT_SUCCESS,
	results
});
export const fetchCustomGameReportsList = () => ({
	type: FETCH_CUSTOM_GAME_REPORTS_LIST
});

export const fetchCustomGameReportsListSuccess = results => ({
	type: FETCH_CUSTOM_GAME_REPORTS_LIST_SUCCESS,
	results
});

export const fetchCustomGameReportsDetails = id => ({
	type: FETCH_CUSTOM_GAME_REPORTS_DETAILS,
	id
});

export const fetchCustomGameReportsDetailsSuccess = results => ({
	type: FETCH_CUSTOM_GAME_REPORTS_DETAILS_SUCCESS,
	results
});

export const deleteCustomGameReports = id => ({
	type: DELETE_CUSTOM_GAME_REPORTS,
	id
});

export const submitCustomGameReports = (formData, callback) => ({
	type: SUBMIT_CUSTOM_GAME_REPORTS,
	formData,
	callback
});