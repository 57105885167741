import {connect} from 'react-redux';
import PayScaleResults from './payscale_results';
import {fetchPayScalesPerformed, deletePayScale} from '../../../../../actions/payscale';


function mapsDispatchToProps (dispatch) {
    return {
        fetchPayScales: () => dispatch(fetchPayScalesPerformed()),
        removePayScale: (id) => dispatch(deletePayScale(id))
    }
}

function mapsStateToProps (state) {
    return {
        payscales: state.payscale.results.data,
        loading: state.payscale.is_loading
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(PayScaleResults);