export const FETCH_GAMES_PERFORMED = 'FETCH_GAMES_PERFORMED';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const GAME_LOADER_SHOW = 'GAME_LOADER_SHOW';
export const FETCH_GAMES_DATA_LOADING = 'FETCH_GAMES_DATA_LOADING';
export const FETCH_GAMES_DATA = 'FETCH_GAMES_DATA';
export const FETCH_GAMES_DATA_SUCCESS = 'FETCH_GAMES_DATA_SUCCESS';
export const GAME_CREATE_PERFORMED = 'GAME_CREATE_PERFORMED';
export const GAME_EDIT_PERFORMED = 'GAME_EDIT_PERFORMED';
export const GAME_CREATE_SUCCESS = 'GAME_CREATE_SUCCESS';
export const GAME_EDIT_SUCCESS = 'GAME_EDIT_SUCCESS';
export const GAME_EDIT_ERROR = 'GAME_EDIT_ERROR';
export const GAME_CREATE_ERROR = 'GAME_CREATE_ERROR';
export const GAME_DELETE_PERFORMED = 'GAME_DELETE_PERFORMED';
export const GET_GAME_DETAIL = 'GET_GAME_DETAIL';
export const GET_GAME_DETAIL_SUCCESS = 'GET_GAME_DETAIL_SUCCESS';
export const EDIT_GAME_DATA = 'EDIT_GAME_DATA';
export const GET_REFEREES_PERFORMED = 'GET_REFEREES_PERFORMED';
export const ASSIGN_REFEREE_LOADER_SHOW = 'ASSIGN_REFEREE_LOADER_SHOW';
export const ASSIGN_REFEREE_DATA_LOADER_SHOW = 'ASSIGN_REFEREE_DATA_LOADER_SHOW';
export const GET_REFEREES_SUCCESS = 'GET_REFEREES_SUCCESS';
export const ASSIGN_OFFICIAL_TO_GAME = 'ASSIGN_OFFICIAL_TO_GAME';
export const GET_ASSIGN_REFEREE_PERFORMED = 'GET_ASSIGN_REFEREE_PERFORMED';
export const GET_ASSIGN_REFEREES_SUCCESS = 'GET_ASSIGN_REFEREES_SUCCESS';
export const FETCH_OFFICIAL_GAMES_PERFORMED = 'FETCH_OFFICIAL_GAMES_PERFORMED';
export const FETCH_OFFICIAL_GAMES_SUCCESS = 'FETCH_OFFICIAL_GAMES_SUCCESS';
export const CONFIRM_GAME_TO_OFFICIAL = 'CONFIRM_GAME_TO_OFFICIAL';
export const CONFIRM_GAME_TO_OFFICIAL_SUCCESS = 'CONFIRM_GAME_TO_OFFICIAL_SUCCESS';
export const DECLINE_GAME_TO_OFFICIAL = 'DECLINE_GAME_TO_OFFICIAL';
export const RESET_DATA_REFEREES = 'RESET_DATA_REFEREES';
export const GET_TRAVEL_CLAIM = 'GET_TRAVEL_CLAIM';
export const GET_TRAVEL_CLAIM_SUCCESS = 'GET_TRAVEL_CLAIM_SUCCESS';
export const SUBMIT_TRAVEL_CLAIM = 'SUBMIT_TRAVEL_CLAIM';
export const TRAVEL_CLAIM_LOADING = 'TRAVEL_CLAIM_LOADING';
export const PUBLISH_GAMES = 'PUBLISH_GAMES';
export const DELETE_GAMES = 'DELETE_GAMES';
export const ADD_BULK_GAMES = 'ADD_BULK_GAMES';
export const CLEAR_BULK_FILES = 'CLEAR_BULK_FILES';
export const GET_PERFORMANCE_REPORT = 'GET_PERFORMANCE_REPORT';
export const PERFORMANCE_REPORT_LOADING = 'PERFORMANCE_REPORT_LOADING';
export const GET_PERFORMANCE_REPORT_SUCCESS = 'GET_PERFORMANCE_REPORT_SUCCESS';
export const SAVE_PERFORMANCE_REPORT = 'SAVE_PERFORMANCE_REPORT';
export const PUBLISH_PERFORMANCE_REPORT = 'PUBLISH_PERFORMANCE_REPORT';
export const SUBMIT_PERFORMANCE_REPORT = 'SUBMIT_PERFORMANCE_REPORT';
export const FETCH_OFFICIAL_GAMES_DATA = 'FETCH_OFFICIAL_GAMES_DATA';
export const FETCH_OFFICIAL_GAMES_DATA_SUCCESS = 'FETCH_OFFICIAL_GAMES_DATA_SUCCESS';
export const DOWNLOAD_GAME_VIDEO = 'DOWNLOAD_GAME_VIDEO';

export const fetchGamesPerformed = (filters, sortBy) => ({
	type: FETCH_GAMES_PERFORMED,
	filters,
	sortBy
});

export const fetchGamesData = () => ({
	type: FETCH_GAMES_DATA
});

export const fetchGamesSuccess = (results) => ({
	type: FETCH_GAMES_SUCCESS,
	results
});

export const fetchGamesDataSuccess = (results) => ({
	type: FETCH_GAMES_DATA_SUCCESS,
	results
});

export const gameLoaderStatus = (status) => ({
	type: GAME_LOADER_SHOW,
	status
});

export const fetchGamesDataLoading = (status) => ({
	type: FETCH_GAMES_DATA_LOADING,
	status
});

export const gameCreatePerformed = (formData) => ({
	type: GAME_CREATE_PERFORMED,
	formData
});

export const gameCreateSuccess = (status) => ({
	type: GAME_CREATE_SUCCESS,
	status
});

export const gameCreateErrors = (errors) => ({
	type: GAME_CREATE_ERROR,
	errors
});

export const gameEditPerformed = ({ id, formData }) => ({
	type: GAME_EDIT_PERFORMED,
	id,
	formData
});

export const gameEditSuccess = (status) => ({
	type: GAME_EDIT_SUCCESS,
	status
});

export const gameEditErrors = (errors) => ({
	type: GAME_EDIT_ERROR,
	errors
});

export const deleteGame = (id) => ({
	type: GAME_DELETE_PERFORMED,
	id
});

export const getGameDetail = (id) => ({
	type: GET_GAME_DETAIL,
	id
});

export const getGameDetailSuccess = (game) => ({
	type: GET_GAME_DETAIL_SUCCESS,
	game
});

export const getReferees = (id) => ({
	type: GET_REFEREES_PERFORMED,
	id
});

export const getRefereesSuccess = (results) => ({
	type: GET_REFEREES_SUCCESS,
	results
});

export const assignRefereeLoaderStatus = (status) => ({
	type: ASSIGN_REFEREE_LOADER_SHOW,
	status
});

export const assignRefereeDataLoaderStatus = (status) => ({
	type: ASSIGN_REFEREE_DATA_LOADER_SHOW,
	status
});

export const assignOfficiaToGame = (formData, id) => ({
	type: ASSIGN_OFFICIAL_TO_GAME,
	formData,
	id
});

export const getAssignReferee = (id) => ({
	type: GET_ASSIGN_REFEREE_PERFORMED,
	id
});

export const getAssignRefereesSuccess = (results) => ({
	type: GET_ASSIGN_REFEREES_SUCCESS,
	results
});

export const fetchOfficialGamesPerformed = (filters, sortBy) => ({
	type: FETCH_OFFICIAL_GAMES_PERFORMED,
	filters,
	sortBy
});

export const fetchOfficialGamesSuccess = (results) => ({
	type: FETCH_OFFICIAL_GAMES_SUCCESS,
	results
});

export const confirmGameToOfficial = (id, callback) => ({
	type: CONFIRM_GAME_TO_OFFICIAL,
	id,
	callback
});

export const confirmGameToOfficialSuccess = (results) => ({
	type: CONFIRM_GAME_TO_OFFICIAL_SUCCESS,
	results
});

export const declineGameToOfficial = (id, reason, callback) => ({
	type: DECLINE_GAME_TO_OFFICIAL,
	id,
	reason,
	callback
});

export const resetData = () => ({
	type: RESET_DATA_REFEREES
});

export const performanceReportLoading = (status) => ({
	type: PERFORMANCE_REPORT_LOADING,
	status
});

export const getTravelClaimPerformed = (id) => ({
	type: GET_TRAVEL_CLAIM,
	id
});

export const getTravelClaimSuccess = (results) => ({
	type: GET_TRAVEL_CLAIM_SUCCESS,
	results
});

export const submitTravelClaim = (id, data) => ({
	type: SUBMIT_TRAVEL_CLAIM,
	id,
	data
});

export const travelClaimLoading = (status) => ({
	type: TRAVEL_CLAIM_LOADING,
	status
});

export const publishGames = (data) => ({
	type: PUBLISH_GAMES,
	data
});
export const deleteGames = (data) => ({
	type: DELETE_GAMES,
	data
});

export const addBulkGames = (data) => ({
	type: ADD_BULK_GAMES,
	data
});

export const clearBulkFiles = (status) => ({
	type: CLEAR_BULK_FILES,
	status
});

export const getPerformanceReport = (id) => ({
	type: GET_PERFORMANCE_REPORT,
	id
});

export const getPerformanceReportSuccess = (results) => ({
	type: GET_PERFORMANCE_REPORT_SUCCESS,
	results
});

export const savePerformanceReport = (id, data) => ({
	type: SAVE_PERFORMANCE_REPORT,
	id,
	data
});

export const submitPerformanceReport = (id, data) => ({
	type: SUBMIT_PERFORMANCE_REPORT,
	id,
	data
});

export const publishPerformanceReport = (id, data) => ({
	type: PUBLISH_PERFORMANCE_REPORT,
	id,
	data
});

export const fetchOfficialGamesData = () => ({
	type: FETCH_OFFICIAL_GAMES_DATA
});

export const fetchOfficialGamesDataSuccess = (results) => ({
	type: FETCH_OFFICIAL_GAMES_DATA_SUCCESS,
	results
});

export const downloadGameVideo = (id) => ({
	type: DOWNLOAD_GAME_VIDEO,
	id
});