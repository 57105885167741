import {FETCH_CHART_DATA_SUCCESS, DASHBOARD_LOADER_SHOW, FETCH_CALENDAR_DATA_SUCCESS, FETCH_OFFICIAL_CALENDAR_DATA_SUCCESS} from '../actions/dashboard';

const initialState = {
    is_loading: false,
    chart_data: [],
    calendar_data: [],
    official_calendar_data: []
}

export default (state, actions) => {
    if (state === undefined) {
        return initialState;
    }

    switch (actions.type) {
        case DASHBOARD_LOADER_SHOW:
            return {
                ...state,
                is_loading: actions.status
            };
        case FETCH_CHART_DATA_SUCCESS:
            return {
                ...state,
                chart_data: actions.results
            };
        case FETCH_CALENDAR_DATA_SUCCESS:
            return {
                ...state,
                calendar_data: actions.results
            }
        case FETCH_OFFICIAL_CALENDAR_DATA_SUCCESS:
            return {
                ...state,
                official_calendar_data: actions.results
            }
        default:
            return state;
    }
}