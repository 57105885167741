import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import TeamResults from '../team-results/team_results.container';
import NewTeam from '../new-team/new_team.container';
import EditTeam from '../edit-team/edit_team.container';
import withAuth from '../../../../AuthHOC/withAuth';

class TeamListPage extends Component {
    constructor(props) {
		super(props);
		this.state = {
			open: false,
			editModalOpen: false,
			id: ''
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenEditModal = this.onOpenEditModal.bind(this);
		this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    onOpenModal = () => {
		this.setState({open: true});
	}

	onCloseModal = () => {
		this.setState({open: false});
	}

	onOpenEditModal = (id) => {
		this.setState({
			editModalOpen: true,
			id: id
		});
	}

	onCloseEditModal = () => {
		this.setState({editModalOpen: false});
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.form_success === true)
		{
			this.setState({open: false});
		}
		if(nextProps.edit_success === true)
		{
			this.setState({editModalOpen: false});
		}
    }

    render () {
        const { open, editModalOpen } = this.state;
        return (
            <div className="teams">
                <div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Teams</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Teams</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						<button onClick={this.onOpenModal} className="btn btn-orange">Create Team</button>
					</div>
				</div>
				<div className="box">
				<TeamResults onOpenEditModal={this.onOpenEditModal} />
				</div>
                <Modal
					open={open}
					onClose={this.onCloseModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Team</h5>
								<button type="button" className="close" onClick={this.onCloseModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<NewTeam />
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={editModalOpen}
					onClose={this.onCloseEditModal}
					showCloseIcon= {false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Team</h5>
								<button type="button" className="close" onClick={this.onCloseEditModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<EditTeam id={this.state.id} />
							</div>
						</div>
					</div>
				</Modal>
            </div>
        );
    }
}

TeamListPage.propTypes = {
	form_success: PropTypes.bool
}

export default withAuth(TeamListPage,['administrator']);