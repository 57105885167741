export const FETCH_TRAVEL_CLAIM = 'FETCH_TRAVEL_CLAIM';
export const FETCH_TRAVEL_CLAIM_SUCCESS = 'FETCH_TRAVEL_CLAIM_SUCCESS';
export const EDIT_TRAVEL_CLAIM = 'EDIT_TRAVEL_CLAIM';
export const FETCH_TRAVEL_CLAIM_LOADER = 'FETCH_TRAVEL_CLAIM_LOADER';
export const EDIT_TRAVEL_CLAIM_LOADER = 'EDIT_TRAVEL_CLAIM_LOADER';
export const APPROVE_TRAVEL_CLAIM = 'APPROVE_TRAVEL_CLAIM';
export const DECLINE_TRAVEL_CLAIM = 'DECLINE_TRAVEL_CLAIM';
//export const FETCH_TRAVEL_CLAIM_PERFORMED = 'FETCH_TRAVEL_CLAIM_PERFORMED';
//export const TRAVEL_CLAIM_LOADER_SHOW = 'TRAVEL_CLAIM_LOADER_SHOW';
//export const FETCH_TRAVEL_CLAIM_DATA_SUCCESS = 'FETCH_TRAVEL_CLAIM_DATA_SUCCESS';
//export const FETCH_TRAVEL_CLAIM_DATA ='FETCH_TRAVEL_CLAIM_DATA';

export const fetchTravelClaim = (filters) => ({
	type: FETCH_TRAVEL_CLAIM,
	filters

});

export const fetchTravelClaimSuccess = (results, teams, leagues, users ) => ({
	type: FETCH_TRAVEL_CLAIM_SUCCESS,
	results,
	teams,
	users,
	leagues
});

export const editTravelClaim = (id, data) => ({
	type: EDIT_TRAVEL_CLAIM,
	id,
	data
});

export const fetchTravelClaimLoader = (status) => ({
	type: FETCH_TRAVEL_CLAIM_LOADER,
	status
});

export const editTravelClaimLoader = (status) => ({
	type: EDIT_TRAVEL_CLAIM_LOADER,
	status
});

export const approveTravelClaim = (data) => ({
	type: APPROVE_TRAVEL_CLAIM,
	data
});

export const declineTravelClaim = (data) => ({
	type: DECLINE_TRAVEL_CLAIM,
	data
});