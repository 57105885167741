import {connect} from 'react-redux';
import NewVenue from './new_venue';
import {venueCreatePerformed, getRegionsForVenue} from '../../../../../actions/venue';

function mapsDispatchToProps (dispatch) {
    return {
        onVenueSubmitted: (formData) => dispatch(venueCreatePerformed(formData)),
        getRegionsForVenue: () => dispatch(getRegionsForVenue())
    }
}

function mapsStateToProps (state) {
    return {
        errors: state.venue.create_errors,
        loading: state.venue.is_loading,
        form_success: state.venue.form_success,
        region_list: state.venue.region_list
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewVenue);