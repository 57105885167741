import {connect} from 'react-redux';
import BulkImport from './bulk_import';
import {addBulkUsers} from '../../../../../actions/official';

function mapsDispatchToProps (dispatch) {
    return {
        addBulkUsers: (formData) => dispatch(addBulkUsers(formData))
    }
}

function mapsStateToProps (state) {
    return {
        bulk_import_success: state.official.bulk_import_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(BulkImport);
