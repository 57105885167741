import axios from 'axios';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
	LOCK_AVAILABILITY_CREATE_PERFORMED,
	FETCH_LOCK_AVAILABILITY,
	LOCK_AVAILABILITY_DELETE_PERFORMED,
	FETCH_LOCK_AVAILABILITY_DETAIL,
	LOCK_EDIT_PERFORMED,
	GO_TO_GAME_PAGE_ORG,
	SET_GAME_AVAILABILITY,
	FETCH_LOCK_GAMES,
	lockAvailabilityCreateSuccess,
	lockAvailabilityCreateError,
	fetchLockAvailabilities,
	fetchLockAvailabilitySuccess,
	lockAvailabilityEditSuccess,
	lockAvailabilityEditErrors,
	goToGamePageOrgSuccess,
	getLockAvailabilityDetailSuccess,
	resetLockGame,
	fetchLockGames,
	setSelectedOrg} from '../actions/lock_availability';
import { push } from 'connected-react-router';
import { API_BASE_URL, getOrgId,getHeaders, getUserId } from '../constants';
import { showLoader, hideLoader, showFullSizeLoader, hideFullSizeLoader } from '../actions/loader';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';



function* doCreateLockAvailability({ formData }) {
	yield put(showLoader());
	console.log("TESTPoint1");
	try {
		console.log("TESTPoint2");
		const results = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/availability/lockdate`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchLockAvailabilities());
		yield put(lockAvailabilityCreateSuccess(true));
	} catch (error) {
		if (error.response.status === 422) {
			if (error.response.data.errors) {
				yield put(lockAvailabilityCreateError(error.response.data.errors));
			}
		}
		else {
			console.log("Rejection Error",error);
		}
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getLockAvailabilities() {
	yield put(showLoader());
	try {
		console.log("Get Locks Test!");
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/availability/getlockdates`,
			{ headers: getHeaders() }
		);
		console.log("Lock Data: ",results.data);
		yield put(fetchLockAvailabilitySuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getLockAvailabilityDetail({ id }) {
	yield put(showFullSizeLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/specific/${id}`,
			{ headers: getHeaders() }
		);
		yield put(getLockAvailabilityDetailSuccess(results.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideFullSizeLoader());
}
function* doRemoveLockAvailability({ id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/availability/lockdate/${id}`,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchLockAvailabilities());
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}


function* doEditLockAvailability({ formData, id }) {
	yield put(showLoader());
	try {
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/users/${getUserId()}/availability/lock/${id}`,
			formData,
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(lockAvailabilityEditSuccess(true));
		yield put(fetchLockAvailabilities());
	} catch (error) {
		if (error.response.status === 422) {
			yield put(lockAvailabilityEditErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* goToGamePagePerformed ({ id }) {
	yield put(resetLockGame());
	yield put(setSelectedOrg(id));
	yield put(push('/availability/game'));
	yield put(showLoader());
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${id}/availability/games`,
			{ headers: getHeaders() }
		);
		yield put(goToGamePageOrgSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* getLockGames() {
	yield put(showLoader());
	try {
		const getSelectedOrg = (state) => state.availability.selected_org;
		let selectedOrg = yield select(getSelectedOrg);
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${selectedOrg}/availability/games`,
			{ headers: getHeaders() }
		);
		yield put(goToGamePageOrgSuccess(results.data.data));
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

function* setGameAvailabilityPerformed({ id, availability }) {
	yield put(showLoader());
	try {
		const getSelectedOrg = (state) => state.availability.selected_org;
		let selectedOrg = yield select(getSelectedOrg);
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${selectedOrg}/availability/game/${id}`,
			{'available': availability},
			{ headers: getHeaders() }
		);
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchLockGames());
	} catch (error) {
		yield put(handleError(error));
	}
	yield put(hideLoader());
}

export default function* () {
	// yield takeLatest(GENERAL_AVAILABILITY_CREATE_PERFORMED, doCreateGeneralAvailability);
	yield takeLatest(LOCK_AVAILABILITY_CREATE_PERFORMED, doCreateLockAvailability);
	yield takeLatest(FETCH_LOCK_AVAILABILITY, getLockAvailabilities);
	yield takeLatest(LOCK_AVAILABILITY_DELETE_PERFORMED, doRemoveLockAvailability);
	// yield takeLatest(FETCH_GENERAL_AVAILABILITY, getGeneralAvailabilities);
	yield takeLatest(FETCH_LOCK_AVAILABILITY_DETAIL, getLockAvailabilityDetail);
	yield takeLatest(LOCK_EDIT_PERFORMED, doEditLockAvailability);
	yield takeLatest(GO_TO_GAME_PAGE_ORG, goToGamePagePerformed);
	yield takeLatest(SET_GAME_AVAILABILITY, setGameAvailabilityPerformed);
	yield takeLatest(FETCH_LOCK_GAMES, getLockGames);
}