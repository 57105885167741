export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_FULL_SIZE_LOADER = 'SHOW_FULL_SIZE_LOADER';
export const HIDE_FULL_SIZE_LOADER = 'HIDE_FULL_SIZE_LOADER';


export const showLoader = () => ({
	type: SHOW_LOADER,
});

export const hideLoader = () => ({
	type: HIDE_LOADER,
});

export const showFullSizeLoader = () => ({
	type: SHOW_FULL_SIZE_LOADER,
});

export const hideFullSizeLoader = () => ({
	type: HIDE_FULL_SIZE_LOADER,
});