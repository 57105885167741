import {connect} from 'react-redux';
import PatternList from './pattern_list';

function mapsStateToProps (state) {
    return {
        form_success: state.pattern.form_success,
        edit_success: state.pattern.edit_success
    }
}

export default connect(() => (mapsStateToProps))(PatternList);