import { connect } from 'react-redux';
import ResetPassword from './form';
import { resetPasswordProfile } from '../../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		resetPasswordProfile: (formData, callback) => dispatch(resetPasswordProfile(formData, callback)),
	}
}

function mapsStateToProps(state) {
	return {
		loading: state.official.is_loading
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(ResetPassword);