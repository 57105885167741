import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import swal from 'sweetalert2/dist/sweetalert2.js';

export default class BulkImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: null
		}

		this.handleSubmit = this.handleSubmit.bind(this);
		this.downloadTemplate = this.downloadTemplate.bind(this);
	}

	handleDrop = acceptedFiles => {
		this.setState({
			files: acceptedFiles[0]
		});
	};

	handleSubmit() {
		const { files } = this.state;
		const data = {
			games: files
		}
		if (files == null) {
			swal(
				'Error',
				'Make sure you have select the file',
				'error'
			);
		} else {
			this.props.addBulkGames(data);
		}
	}

	downloadTemplate() {
		let link = document.createElement('a');
		link.href = '/assets/game_template.csv';
		link.download = 'game_template.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.clearBulkFiles) {
			this.setState({
				files: null
			})
		}
	}

	render() {
		return (
			<div className="bulk-import">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Bulk Import</h1>
					</div>
					<div className="actions">
						<button className="btn btn-orange" onClick={this.downloadTemplate}>Download Template</button>
					</div>
				</div>
				<Dropzone
					className="drop-area d-flex align-items-center"
					multiple={false}
					accept=".xls, .csv"
					onDrop={this.handleDrop}
				>
					<div className="text">
						<p>
							<span className="icon icon-upload">
								<FontAwesomeIcon icon={faCloudUploadAlt} className="fa fa-file-excel" />
							</span>
						</p>
						<p>Drop file here to click upload</p>
					</div>
				</Dropzone>
				{this.state.files && (
					<div className="file-format">
						<span className="icon icon-excel">
							<FontAwesomeIcon icon={faFileExcel} className="fa fa-file-excel" />
						</span>
						<span className="file-name">
							{this.state.files.name}
						</span>
					</div>
				)}
				<div className="file-format-message">
					<p>The file you upload that must be a valid file in <strong>.CSV format</strong></p>
				</div>
				<div className="action">
					<input type="submit" onClick={this.handleSubmit} className="btn btn-orange" value="Upload" name="upload" />
				</div>
			</div>
		);
	}
}