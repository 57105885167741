export const OFFICIAL_PERFORMED = 'OFFICIAL_PERFORMED';
export const OFFICIAL_ERROR = 'OFFICIAL_ERROR';
export const OFFICIAL_LOADER_SHOW = 'OFFICIAL_LOADER_SHOW';
export const OFFICIAL_ALERT_ERROR = 'OFFICIAL_ALERT_ERROR';
export const OFFICIAL_SUCCESS = 'OFFICIAL_SUCCESS';
export const FETCH_OFFICIAL_PERFORMED = 'FETCH_OFFICIAL_PERFORMED';
export const FETCH_OFFICIAL_SUCCESS = 'FETCH_OFFICIAL_SUCCESS';
export const FETCH_USERDATA_PERFORMED = 'FETCH_USERDATA_PERFORMED';
export const FETCH_USERDATA_SUCCESS = 'FETCH_USERDATA_SUCCESS';
export const OFFICIAL_PERFORMED_DELETE = 'OFFICIAL_PERFORMED_DELETE';
export const GET_OFFICIAL_DETAIL = 'GET_OFFICIAL_DETAIL';
export const GET_OFFICIAL_DETAIL_SUCCESS = 'GET_OFFICIAL_DETAIL_SUCCESS';
export const ADD_BULK_OFFICIAL = 'ADD_BULK_OFFICIAL';
export const BULK_IMPORT_SUCCESS = 'BULK_IMPORT_SUCCESS';
export const OFFICIAL_EDIT_PERFORMED = 'OFFICIAL_EDIT_PERFORMED';
export const OFFICIAL_EDIT_ERROR = 'OFFICIAL_EDIT_ERROR';
export const OFFICIAL_EDIT_SUCCESS = 'OFFICIAL_EDIT_SUCCESS';
export const PROFILE_PHOTO_UPLOAD = 'PROFILE_PHOTO_UPLOAD';
export const PROFILE_PHOTO_UPLOAD_SUCCESS = 'PROFILE_PHOTO_UPLOAD_SUCCESS';
export const PROFILE_PHOTO_LOADER_SHOW = 'PROFILE_PHOTO_LOADER_SHOW';
export const BANK_EDIT_PERFORMED = 'BANK_EDIT_PERFORMED';
export const BANK_EDIT_SUCCESS = 'BANK_EDIT_SUCCESS';
export const BANK_EDIT_ERROR = 'BANK_EDIT_ERROR';
export const GET_BANK_DETAIL_PERFORMED = 'GET_BANK_DETAIL_PERFORMED';
export const GET_BANK_DETAIL_SUCCESS = 'GET_BANK_DETAIL_SUCCESS';
export const GET_PROFILE_PIC_PERFORMED = 'GET_PROFILE_PIC_PERFORMED';
export const GET_PROFILE_PIC_SUCCESS = 'GET_PROFILE_PIC_SUCCESS';
export const GET_PENDING_USER = 'GET_PENDING_USER';
export const SET_PENDING_USER_LOADER = 'SET_PENDING_USER_LOADER';
export const RESEND_INVITE = 'RESEND_INVITE';
export const GET_PENDING_USER_SUCCESS = 'GET_PENDING_USER_SUCCESS';
export const REMOVE_PENDING_USER = 'REMOVE_PENDING_USER';
export const FETCH_CONFLICTS_DATA = 'FETCH_CONFLICTS_DATA';
export const SET_CONFLICTS_GENERAL_DATA = 'SET_CONFLICTS_GENERAL_DATA';
export const SET_CONFLICTS_USER_DATA = 'SET_CONFLICTS_USER_DATA';
export const SET_CONFLICTS_LOADER = 'SET_CONFLICTS_LOADER';
export const RESET_CONFLICTS_DATA = 'RESET_CONFLICTS_DATA';
export const SUBMIT_CONFLICTS = 'SUBMIT_CONFLICTS';
export const SUBMIT_EDIT_CONFLICTS = 'SUBMIT_EDIT_CONFLICTS';
export const SET_CONFLICTS_MODAL_LOADER = 'SET_CONFLICTS_MODAL_LOADER';
export const DELETE_CONFLICTS = 'DELETE_CONFLICTS';
export const CONNECT_TO_GOOGLE_CALENDAR = 'CONNECT_TO_GOOGLE_CALENDAR';
export const DISCONNECT_GOOGLE_CALENDAR = 'DISCONNECT_GOOGLE_CALENDAR';
export const GET_ALL_AVAILABLE_OFFICIAL = 'GET_ALL_AVAILABLE_OFFICIAL';
export const GET_ALL_AVAILABLE_OFFICIAL_SUCCESS = 'GET_ALL_AVAILABLE_OFFICIAL_SUCCESS';
export const RESET_PASSWORD_PROFILE = 'RESET_PASSWORD_PROFILE';


export const officialPerformed = (formData) => ({
	type: OFFICIAL_PERFORMED,
	formData
});

export const addBulkUsers = (formData) => ({
	type: ADD_BULK_OFFICIAL,
	formData
});

export const getOfficial = (id) => ({
	type: GET_OFFICIAL_DETAIL,
	id
});

export const getOfficialSuccess = (official) => ({
	type: GET_OFFICIAL_DETAIL_SUCCESS,
	official
});

export const fetchOfficialPerformed = () => ({
	type: FETCH_OFFICIAL_PERFORMED,
});

export const fetchOfficialSuccess = (results) => ({
	type: FETCH_OFFICIAL_SUCCESS,
	results
});

export const fetchUserDataPerformed = () => ({
	type: FETCH_USERDATA_PERFORMED,
})

export const fetchUserDataSuccess = (userdata) => ({
	type: FETCH_USERDATA_SUCCESS,
	userdata
})

export const officialSuccess = (msg) => ({
	type: OFFICIAL_SUCCESS,
	msg
});

export const bulkImportSuccess = (status) => ({
	type: BULK_IMPORT_SUCCESS,
	status
});

export const officialError = (errors) => ({
	type: OFFICIAL_ERROR,
	errors
});

export const officialLoaderStatus = (status) => ({
	type: OFFICIAL_LOADER_SHOW,
	status
});

export const officialAlertError = (error, status) => ({
	type: OFFICIAL_ALERT_ERROR,
	error,
	status
});

export const deleteOfficial = (id) => ({
	type: OFFICIAL_PERFORMED_DELETE,
	id
});

export const officialEditPerformed = ({ formData, id }) => ({
	type: OFFICIAL_EDIT_PERFORMED,
	formData,
	id
});

export const officialEditErrors = (errors) => ({
	type: OFFICIAL_EDIT_ERROR,
	errors
});

export const officialEditSuccess = (status) => ({
	type: OFFICIAL_EDIT_SUCCESS,
	status
});

export const profilePhotoUpload = (formData) => ({
	type: PROFILE_PHOTO_UPLOAD,
	formData
});

export const profilePhotoUploadSuccess = (status) => ({
	type: PROFILE_PHOTO_UPLOAD_SUCCESS,
	status
});

export const profilePhotoLoaderStatus = (status) => ({
	type: PROFILE_PHOTO_LOADER_SHOW,
	status
});

export const bankEditPerformed = (formData) => ({
	type: BANK_EDIT_PERFORMED,
	formData
});

export const bankEditSuccess = (status) => ({
	type: BANK_EDIT_SUCCESS,
	status
});

export const bankEditErrors = (errors) => ({
	type: BANK_EDIT_ERROR,
	errors
});

export const getBankDetailPerformed = () => ({
	type: GET_BANK_DETAIL_PERFORMED
});

export const getBankDetailSuccess = (bank_detail) => ({
	type: GET_BANK_DETAIL_SUCCESS,
	bank_detail
});

export const getProfilePicPerformed = () => ({
	type: GET_PROFILE_PIC_PERFORMED
});

export const getProfilePicSuccess = (profile_pic) => ({
	type: GET_PROFILE_PIC_SUCCESS,
	profile_pic
});

export const getPendingUser = () => ({
	type: GET_PENDING_USER
});

export const getPendingUserSuccess = (results) => ({
	type: GET_PENDING_USER_SUCCESS,
	results
});

export const setPendingUserLoader = (status) => ({
	type: SET_PENDING_USER_LOADER,
	status
});

export const resendInvite = (data) => ({
	type: RESEND_INVITE,
	data
});

export const removePendingUser = (data) => ({
	type: REMOVE_PENDING_USER,
	data
});

export const fetchConflictsData = (id) => ({
	type: FETCH_CONFLICTS_DATA,
	id
});

export const setConflictsGeneralData = (results) => ({
	type: SET_CONFLICTS_GENERAL_DATA,
	results
});

export const setConflictsUserData = (results) => ({
	type: SET_CONFLICTS_USER_DATA,
	results
});

export const setConflictsLoader = (status) => ({
	type: SET_CONFLICTS_LOADER,
	status
});

export const resetConflictsData = (status) => ({
	type: RESET_CONFLICTS_DATA,
	status
});

export const submitConflicts = (data, callback) => ({
	type: SUBMIT_CONFLICTS,
	data,
	callback
});

export const submitEditConflicts = (data, reportId ,userId, callback) => ({
	type: SUBMIT_EDIT_CONFLICTS,
	data,
	reportId,
	userId,
	callback
});

export const setConflictsModalLoader = (status) => ({
	type: SET_CONFLICTS_MODAL_LOADER,
	status
});

export const deleteConflicts = (reportId, userId) => ({
	type: DELETE_CONFLICTS,
	reportId,
	userId
});

export const connectToGoogleCalendar = () => ({
	type: CONNECT_TO_GOOGLE_CALENDAR
});

export const disconnectGoogleCalendar = () => ({
	type: DISCONNECT_GOOGLE_CALENDAR
});

export const getAllAvaialbleOfficial = (date, startTime, endTime, callback) => ({
	type: GET_ALL_AVAILABLE_OFFICIAL,
	date,
	startTime,
	endTime,
	callback
});

export const getAllAvaialbleOfficialSuccess = results => ({
	type: GET_ALL_AVAILABLE_OFFICIAL_SUCCESS,
	results
});

export const resetPasswordProfile = (formData, callback) => ({
	type: RESET_PASSWORD_PROFILE,
	formData,
	callback
});