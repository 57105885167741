import { connect } from 'react-redux';
import TravelClaimResults from './travel_claim_results';
import { 
	fetchTravelClaim,
	editTravelClaim,
	approveTravelClaim,
	declineTravelClaim
} from '../../../../../actions/travel_claim';

function mapsDispatchToProps(dispatch) {
	return {
		fetchTravelClaim:() => dispatch(fetchTravelClaim()),
		editTravelClaim: (id, data) => dispatch(editTravelClaim(id, data)),
		approveTravelClaim: (id) => dispatch(approveTravelClaim(id)),
		declineTravelClaim: (id) => dispatch(declineTravelClaim(id))
	}
}

function mapsStateToProps(state) {
	return {
		data: state.travel_claim.data,
		fetch_loader: state.travel_claim.fetch_loader,
		edit_loader: state.travel_claim.edit_loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(TravelClaimResults);