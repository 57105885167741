import React, { Component } from 'react';
import withAuth from '../../../AuthHOC/withAuth';
import { Link } from 'react-router-dom';
import ResultTable from './results/results-container';
import swal from 'sweetalert2/dist/sweetalert2.js';


class PendingUserPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listSelectedUser: []
		}

		this.selectedList = this.selectedList.bind(this);
		this.handleBulkResend = this.handleBulkResend.bind(this);
		this.handleBulkRemove = this.handleBulkRemove.bind(this);
	}

	selectedList(data) {
		let newList = data.map((res) => {
			return Number(res);
		});
		this.setState({ listSelectedUser: newList });
	}

	handleBulkResend() {
		swal({
			title: 'Are you sure?',
			text: "Do you want to bulk resend the invitation?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, bulk resend it!'
		}).then((result) => {
			if (result.value) {
				this.props.resendInvite(this.state.listSelectedUser);
			}
		})
	}

	handleBulkRemove() {
		swal({
			title: 'Are you sure?',
			text: "Do you want to bulk remove the pending user?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, bulk remove it!'
		}).then((result) => {
			if (result.value) {
				this.props.removePendingUser(this.state.listSelectedUser);
			}
		})
	}

	render() {
		return (
			<div className="pending-user-page">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Pending User</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Pending User</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="actions mb_15">
					<ul className="list-inline">
						<li className="list-inline-item mb_15">
							<button className="btn btn-orange mr-4" onClick={this.handleBulkResend} disabled={this.state.listSelectedUser.length < 1}>Bulk Resend Invitation</button>
							<button className="btn btn-orange" onClick={this.handleBulkRemove} disabled={this.state.listSelectedUser.length < 1}>Bulk Remove Pending User</button>
						</li>
					</ul>
				</div>
				<ResultTable
					selectedList={this.selectedList}
				/>
			</div>
		);
	}
}

export default withAuth(PendingUserPage, ['administrator']);