export const FETCH_GAMEFEES_PERFORMED = 'FETCH_GAMEFEES_PERFORMED';
export const FETCH_GAMEFEES_SUCCESS = 'FETCH_GAMEFEES_SUCCESS';
export const GAMEFEE_LOADER_SHOW = 'GAMEFEE_LOADER_SHOW';
export const GAMEFEE_EDIT_PERFORMED = 'GAMEFEE_EDIT_PERFORMED';
export const GAMEFEE_EDIT_ERROR = 'GAMEFEE_EDIT_ERROR';
export const GAMEFEE_EDIT_SUCCESS = 'GAMEFEE_EDIT_SUCCESS';

export const fetchGameFeesPerformed = (leagueId) => ({
    type: FETCH_GAMEFEES_PERFORMED,
    leagueId
});

export const fetchGameFeesSuccess = (results) => ({
    type: FETCH_GAMEFEES_SUCCESS,
    results
});

export const gamefeeLoaderStatus = (status) => ({
	type: GAMEFEE_LOADER_SHOW,
	status
});

export const gamefeeEditPerformed = (formData, id) => ({
    type: GAMEFEE_EDIT_PERFORMED,
    formData,
    id
});

export const gamefeeEditErrors = (errors) => ({
    type: GAMEFEE_EDIT_ERROR,
	errors
});

export const gamefeeEditSuccess = (status) => ({
    type: GAMEFEE_EDIT_SUCCESS,
    status
});
