import {connect} from 'react-redux';
import EditPayScale from './edit_payscale';
import {getPayScale, payscaleEditPerformed} from '../../../../../actions/payscale';

function mapsDispatchToProps (dispatch) {
    return {
        getPayScale: (id) => dispatch(getPayScale(id)),
        onPayScaleSubmitted: (formData, id) => dispatch(payscaleEditPerformed(formData, id))
    }
}

function mapsStateToProps (state) {
    return {
        loading: state.payscale.is_loading,
        errors: state.payscale.edit_errors,
        payscale: state.payscale.payscale_detail,
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(EditPayScale);