import {connect} from 'react-redux';
import NewPattern from './new_pattern';
import {patternCreatePerformed} from '../../../../../actions/pattern';

function mapsDispatchToProps (dispatch) {
    return {
        onPatternSubmitted: (formData) => dispatch(patternCreatePerformed(formData))
    }
}

function mapsStateToProps (state) {
    return {
        errors: state.pattern.create_errors,
        loading: state.pattern.is_loading,
        form_success: state.pattern.form_success
    }
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(NewPattern);