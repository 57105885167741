import { REGISTER_SUCCESS, REGISTER_ERROR, REGISTER_ALERT_ERROR, REGISTER_ALERT_SUCCESS, LOADER_SHOW, REGISTER_VERIFIED, ACCEPT_TOKEN_SUCCESS, ACCEPT_TOKEN_ERROR } from '../actions/register';
import { LOCATION_CHANGE } from 'react-router-redux';
const initialState = {
	results: [],
	errors: {},
	alert_error_msg: '',
	alert_success_msg: '',
	is_loading: false,
	is_alert_error: false,
	is_alert_success: false,
	is_verified: false,
	form_success: false,
	accept_errors: {}
};

export default (state, actions) => {
	if(state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case REGISTER_SUCCESS:
			return {
				...state,
				results: actions.results
            };
        case REGISTER_ERROR:
			return {
				...state,
				errors: actions.errors,
				alert_error_msg: actions.error,
				is_alert_error: actions.status,
			};
		case REGISTER_ALERT_ERROR:
			return {
				...state,
				alert_error_msg: actions.error,
				is_alert_error: actions.status,
				errors: {}
			};
		case REGISTER_ALERT_SUCCESS:
			return {
				...state,
				alert_success_msg: actions.message,
				is_alert_success: actions.status,
				errors: {},
				alert_error_msg: '',
				is_alert_error: false,
			};
		case REGISTER_VERIFIED:
			return {
				...state,
				is_verified: actions.status
			};
		case LOADER_SHOW:
			return {
				...state,
				is_loading: actions.status
			};
		case LOCATION_CHANGE:
			return {
				...state,
				results: [],
				errors: {},
				alert_error_msg: '',
				alert_success_msg: '',
				is_loading: false,
				is_alert_error: false,
				is_alert_success: false,
				is_verified: false
			}
		case ACCEPT_TOKEN_SUCCESS:
            return {
                ...state,
                form_success: actions.status,
                accept_errors: {}
			};
		case ACCEPT_TOKEN_ERROR:
			return {
				...state,
				accept_errors: actions.errors,
			};
		default: 
			return state;
	}
}