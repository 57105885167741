import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { 
	FETCH_REGIONS_PERFORMED,
	REGION_CREATE_PERFORMED,
	REGION_DELETE_PERFORMED,
	REGION_EDIT_PERFORMED,
	GET_REGION_DETAIL,
	regionLoaderStatus,
	fetchRegionsSuccess,
	fetchRegionsPerformed,
	regionCreateSuccess,
	regionCreateErrors,
	getRegionSuccess,
	regionEditSuccess,
	regionEditErrors,
	regionDeleteSuccess
} from '../actions/region';
import { API_BASE_URL, getHeaders, getOrgId } from '../constants';
import { handleError } from '../actions/handleError';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchRegions() {
	yield put(regionLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions`,
			{ headers: getHeaders() }
		);
		yield put(regionLoaderStatus(false));
		yield put(fetchRegionsSuccess(results.data));
	} catch (error) {
		yield put(regionLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* doCreateNewRegion({ regionFormData }) {
	yield put(regionLoaderStatus(true));
	try {
		const regionResults = yield call(
			axios.post,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions`,
			regionFormData,
			{ headers: getHeaders() }
		);
		yield put(regionLoaderStatus(false));
		swal(
			'Success',
			regionResults.data.message,
			'success'
		);
		yield put(regionCreateSuccess(true));
		yield put(fetchRegionsPerformed());
	} catch (error) {
		yield put(regionLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(regionCreateErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
}

function* fetchRegionDetail({ id }) {
	yield put(regionLoaderStatus(true));
	try {
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions/${id}`,
			{ headers: getHeaders() }
		);
		yield put(regionLoaderStatus(false));
		yield put(getRegionSuccess(results.data));
	} catch (error) {
		yield put(regionLoaderStatus(false));
		yield put(handleError(error));
	}
}

function* doUpdateRegion({ regionFormData, id }) {
	yield put(regionLoaderStatus(true));
	try {
		const regionResults = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions/${id}`,
			regionFormData,
			{ headers: getHeaders() }
		);
		yield put(regionLoaderStatus(false));
		swal(
			'Success',
			regionResults.data.message,
			'success'
		);
		yield put(regionEditSuccess(true));
		yield put(fetchRegionsPerformed());
	} catch (error) {
		yield put(regionLoaderStatus(false));
		if (error.response.status === 422) {
			yield put(regionEditErrors(error.response.data.errors));
		}
		yield put(handleError(error));
	}
}

function* doRemoveRegion({ id }) {
	yield put(regionLoaderStatus(true));
	try {
		const results = yield call(
			axios.delete,
			`${API_BASE_URL}/v1/auth/organisation/${getOrgId()}/regions/${id}`,
			{ headers: getHeaders() }
		);
		yield put(regionLoaderStatus(false));
		yield put(regionDeleteSuccess(true));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		yield put(fetchRegionsPerformed());
	} catch (error) {
		yield put(regionLoaderStatus(false));
		yield put(handleError(error));
	}
}

export default function* () {
	yield takeLatest(FETCH_REGIONS_PERFORMED, fetchRegions);
	yield takeLatest(REGION_CREATE_PERFORMED, doCreateNewRegion);
	yield takeLatest(GET_REGION_DETAIL, fetchRegionDetail);
	yield takeLatest(REGION_EDIT_PERFORMED, doUpdateRegion);
	yield takeLatest(REGION_DELETE_PERFORMED, doRemoveRegion);
}