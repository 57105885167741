import { connect } from 'react-redux';
import List from './list';
import {
	fetchCustomReportsList,
	deleteCustomReports
} from '../../../../../actions/reports';

function mapsDispatchToProps(dispatch) {
	return {
		fetchCustomReportsList: () => dispatch(fetchCustomReportsList()),
		deleteCustomReports: (id) => dispatch(deleteCustomReports(id))
	};
}

function mapsStateToProps(state) {
	return {
		data: state.reports.data,
		loader: state.reports.loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(List);