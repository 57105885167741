import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import Pagination from '../../../pagination/pagination';
import withAuth from '../../../AuthHOC/withAuth';
import { connect } from 'react-redux';
import { fetchDeclinedReport, exportDeclinedReport } from '../../../../actions/reports';
import Loader from '../../../loader/loader';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
const now = moment();
const before = moment().subtract(7, 'd');
const format = 'YYYY-MM-DD';

class DeclineReportPage extends Component {
	state = {
		modal: false,
		start_date: before,
		end_date: now
	};

	componentWillMount() {
		this.props.fetchDeclinedReport();
	}

	openModal = () => {
		this.setState({
			modal: true
		});
	}

	closeModal = () => {
		this.setState({
			modal: false
		});
	}

	exportToCSV = report => {
		const { start_date, end_date } = this.state
		this.props.exportDeclinedReport(report, start_date, end_date);
	};

	render() {
		console.log('props', this.props);
		const columns = [{
			Header: 'Official Name',
			accessor: 'official.name'
		}, {
			Header: 'Game Information',
			Cell: row => (
				<div className="match">
					<span className="highlight" style={{fontSize: 'inherit'}}>{row.original.game.home_team.team_name} vs {row.original.game.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game.game_time, "HH:mm").format("LT")}</span></li>
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Reason',
			accessor: 'reason'
		}, {
			Header: 'Declined At',
			Cell: row => (
				moment(row.original.created_at, '').format('MMMM Do YYYY - h:mm:ss a')
			)
		}];

		const calendar = (
			<Calendar
				style={{ zIndex: 1000, width: "100%" }}
				dateInputPlaceholder="Select Date"
				formatter={format}
				format={format}
				disabledTime={null}
				timePicker={null}
				defaultValue={now}
				showDateInput={false}
			/>
		);

		return (
			<div className="decline-report">
				{this.props.loading && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Decline Report</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Decline Report</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						<button className="btn btn-orange" onClick={this.openModal}>Export to CSV</button>
					</div>
				</div>
				<div className="box">
					<div className="table-area">
						<ReactTable
							PaginationComponent={Pagination}
							data={this.props.data}
							columns={columns}
							noDataText="No Decline Report Found."
							defaultPageSize={10}
							resizable={true}
							minRows={5}
						/>
					</div>
				</div>
				<Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						{this.props.loading && <Loader />}
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Export to CSV</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body" style={{ position: 'relative' }}>
								{this.state.loading && <Loader />}
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="form-group">
											<label className="label">Start Date</label>
											<div className="input-effect-1">
												<div className="datepicker" id="datepicker">
													<DatePicker
														animation="slide-up"
														disabled={false}
														calendar={calendar}
														value={this.state.start_date}
														onChange={this.onChangeStartDate}
														getCalendarContainer={() => document.getElementById('datepicker')}
														className="custom-calendar"
													>
														{
															({ value }) => {
																return (
																	<div className="input-group">
																		<input
																			placeholder="Select Date"
																			disabled={false}
																			readOnly
																			tabIndex="-1"
																			className="ant-calendar-picker-input ant-input form-control"
																			value={value.format(format)}
																		/>
																		<div className="input-group-append">
																			<span className="input-group-text">
																				<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																			</span>
																		</div>
																	</div>
																);
															}
														}
													</DatePicker>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
										<div className="form-group">
											<label className="label">End Date</label>
											<div className="input-effect-1">
												<div className="datepicker" id="datepicker2">
													<DatePicker
														animation="slide-up"
														disabled={false}
														calendar={calendar}
														value={this.state.end_date}
														onChange={this.onChangeEndDate}
														getCalendarContainer={() => document.getElementById('datepicker2')}
														className="custom-calendar"
													>
														{
															({ value }) => {
																return (
																	<div className="input-group">
																		<input
																			placeholder="Select Date"
																			disabled={false}
																			readOnly
																			tabIndex="-1"
																			className="ant-calendar-picker-input ant-input form-control"
																			value={value.format(format)}
																		/>
																		<div className="input-group-append">
																			<span className="input-group-text">
																				<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																			</span>
																		</div>
																	</div>
																);
															}
														}
													</DatePicker>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="action text-center">
									<button className="btn btn-orange mr-3" onClick={() => { this.exportToCSV('summary') }}>Report Summary</button>
									<button className="btn btn-orange" onClick={() => { this.exportToCSV('detail') }}>Reports Details</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapsDispatchToProps(dispatch) {
	return {
		fetchDeclinedReport: () => dispatch(fetchDeclinedReport()),
		exportDeclinedReport: (report, startDate, endDate) => dispatch(exportDeclinedReport(report, startDate, endDate))
	}
}

function mapsStateToProps(state) {
	return {
		loading: state.reports.loader,
		data: state.reports.declined_data
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(withAuth(DeclineReportPage, ['administrator']));