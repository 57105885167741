import React, { Component } from 'react';
import './specific-availability.scss';
// import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
// import FullCalendar from 'fullcalendar-reactwrapper';
// import $ from 'jquery';
// import moment from 'moment';
import Modal from 'react-responsive-modal';
import GeneralForm from './general/new/form.container';
import SpecificForm from './specific/new/form.container';
import GeneralResults from './general/results/general_results';
import SpecificResults from './specific/results/specific_results.container';
import EditSpecificAvailability from './specific/edit/form.container';
import withAuth from '../../../AuthHOC/withAuth';

class OfficialSpecificAvailability extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			openSpecific: false,
			openEditSpecific: false,
			specific_id: '',
		}
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenSpecificModal = this.onOpenSpecificModal.bind(this);
		this.onCloseSpecificModal = this.onCloseSpecificModal.bind(this);
		this.onOpenEditSpecificModal = this.onOpenEditSpecificModal.bind(this);
		this.onCloseEditSpecificModal = this.onCloseEditSpecificModal.bind(this);
		this.goToSpecificGame = this.goToSpecificGame.bind(this);
	}

	componentWillMount() {
		this.props.fetchAvailability();
	}

	onOpenModal = () => {
		this.setState({ open: true });
	}

	onCloseModal = () => {
		this.setState({ open: false });
	}

	onOpenSpecificModal = () => {
		this.setState({ openSpecific: true });
	}

	onCloseSpecificModal = () => {
		this.setState({ openSpecific: false });
	}

	onOpenEditSpecificModal = (id) => {
		this.setState({
			openEditSpecific: true,
			specific_id: id
		});
	}

	onCloseEditSpecificModal = () => {
		this.setState({ openEditSpecific: false });
	}

	goToSpecificGame(id) {
		this.props.goToGamePageOrg(id);
	}

	render() {
		const { open, openSpecific, openEditSpecific } = this.state;
		const { availabilities } = this.props;
		return (
			<div className="official-specific-availability">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">General Availability</h1>
					</div>
					<div className="actions">
						<button onClick={this.onOpenModal} className="btn btn-orange">Update</button>
					</div>
				</div>
				<div className="box mb_30">
					<GeneralResults availabilities={this.props.availabilities} />
					<Modal
						open={open}
						onClose={this.onCloseModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show general-modal"
						}}
					>
						<div className="modal-dialog modal-lg modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">General Availability</h5>
									<button type="button" className="close" onClick={this.onCloseModal}>
										<span>&times;</span>
									</button>
								</div>
								<GeneralForm />
							</div>
						</div>
					</Modal>
					<Modal
						open={openSpecific}
						onClose={this.onCloseSpecificModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-lg modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Specific Availability</h5>
									<button type="button" className="close" onClick={this.onCloseSpecificModal}>
										<span>&times;</span>
									</button>
								</div>
								<SpecificForm />
							</div>
						</div>
					</Modal>
					<Modal
						open={openEditSpecific}
						onClose={this.onCloseEditSpecificModal}
						showCloseIcon={false}
						center
						classNames={{
							modal: "modal fade show"
						}}
					>
						<div className="modal-dialog modal-lg modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Specific Availability</h5>
									<button type="button" className="close" onClick={this.onCloseEditSpecificModal}>
										<span>&times;</span>
									</button>
								</div>
								<EditSpecificAvailability id={this.state.specific_id} />
							</div>
						</div>
					</Modal>
				</div>
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Specific Availability</h1>
					</div>
					<div className="actions">
						<button onClick={this.onOpenSpecificModal} className="btn btn-orange">Add New</button>
					</div>
				</div>
				<div className="box mb-4">
					<SpecificResults onOpenEditSpecificModal={this.onOpenEditSpecificModal} onCloseEditSpecificModal={this.onCloseEditSpecificModal} />
				</div>
				<div className="actions mb_30">
					{Object.keys(availabilities).length > 0 && availabilities.game_availability.map((obj) => {
						return <button key={obj.org_id} onClick={() => this.goToSpecificGame(obj.org_id)} className="btn btn-orange mr-3">Update Specific Game Availability for {obj.org_name}</button>
					})}
				</div>
			</div>
		);
	}
}

export default withAuth(OfficialSpecificAvailability, ['official']);