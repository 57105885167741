import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
// import moment from 'moment';
import Loader from '../../../../../loader/loader';
import FormError from '../../../../../form-error/form-error';
import moment from 'moment';
import './form.scss';
import swal from 'sweetalert2';

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			day: '',
			availability: '',
			type: 'all_day',
			showTime: true,
			times: [{ start_time: '', end_time: '' }],
			times_error: false
		}
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleCloneStartTimeChange = (idx) => value => {
		const newTimeFields = this.state.times.map((time, tidx) => {
			if (idx !== tidx) return time;
			return { ...time, start_time: value.format("HH:mm") }
		});

		this.setState({ times: newTimeFields });
	}

	handleCloneEndTimeChange = (idx) => value => {
		const newTimeFields = this.state.times.map((time, tidx) => {
			if (idx !== tidx) return time;
			return { ...time, end_time: value.format("HH:mm") }
		});

		this.setState({ times: newTimeFields });
	}

	handleAddTimes = (event) => {
		event.preventDefault();
		this.setState({
			times: this.state.times.concat([{ start_time: '', end_time: '' }])
		});
	};

	handleRemoveTimeField = idx => (event) => {
		event.preventDefault();
		this.setState({
			times: this.state.times.filter((s, sidx) => idx !== sidx)
		});
	};

	onStartTimeChange(value) {
		this.setState({
			start_time: value.format("HH:mm")
		});
	}

	onEndTimeChange(value) {
		this.setState({
			end_time: value.format("HH:mm")
		});
	}

	handleInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		if (event.target.name === 'day') {
			const day = {
				0: 'monday',
				1: 'tuesday',
				2: 'wednesday',
				3: 'thursday',
				4: 'friday',
				5: 'saturday',
				6: 'sunday'
			};
			if (Object.keys(this.props.availabilities).length > 0) {
				if (Number(this.props.availabilities[day[event.target.value]]) !== 1 && Number(this.props.availabilities[day[event.target.value]]) !== -1) {
					this.setState({
						type: 'partial_day',
						availability: '1',
						times: JSON.parse(this.props.availabilities[day[event.target.value]]).times
					});
				} else {
					this.setState({
						type: 'all_day',
						availability: this.props.availabilities[day[event.target.value]]
					});
				}
			}
		}
	}

	handleTypeChange(event) {
		this.setState({
			type: event.target.value
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		if (this.state.day === '') {
			swal('Error', 'Please select the day', 'error');
			return;
		}

		let timestate = [];
		if (this.state.type === "partial_day") {
			timestate = this.state.times.map((time) => {
				if (time.start_time === '' || time.end_time === '') return true;
				return false;
			});

			this.setState({
				times_error: timestate.includes(true)
			});
		}
		else {
			this.setState({
				times_error: false
			});
		}

		if (!timestate.includes(true)) {
			if (this.state.type === "all_day") {
				const { day, availability } = this.state;
				const data = {
					day: day,
					available: availability,
					partial: '-1'
				};
				this.props.onAvailabilitySubmitted(data);
			}
			else {
				const { day, times } = this.state;
				const data = {
					day: day,
					available: '1',
					partial: '1',
					times: times
				};
				this.props.onAvailabilitySubmitted(data);
			}
		}

	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.form_success === true) {
			this.setState({
				day: '',
				availability: '',
				type: 'all_day',
				times: [{ start_time: '', end_time: '' }]
			});
		}
	}

	render() {
		let day_error = null;
		let available_error = null;
		// let times_error = null
		// let other_error = null
		if (this.props.errors !== undefined) {
			for (var key in this.props.errors) {
				if (key === 'day')
					day_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'available')
					available_error = <FormError error={this.props.errors[key][0]} />;
				// else if(key === 'times')
				// times_error = <FormError error={this.props.errors[key][0]} />;
				// else
				//     other_error = <FormError error={this.props.errors[key][0]} />;
			}
		}
		return (
			<div>
				{this.props.loading && <Loader />}
				<div className="modal-body">
					{this.state.times_error && <FormError error={'Please select values for time slots'} />}
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="form-group">
								<label className="label">Day</label>
								<div className="input-effect-1">
									<select className="normal-select" name="day" value={this.state.day} onChange={event => { this.handleInputChange(event) }}>
										<option value="">Select One</option>
										<option value="0">Monday</option>
										<option value="1">Tuesday</option>
										<option value="2">Wednesday</option>
										<option value="3">Thursday</option>
										<option value="4">Friday</option>
										<option value="5">Saturday</option>
										<option value="6">Sunday</option>
									</select>
									<div className="focus-border"></div>
								</div>
								{day_error}
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="form-group">
								<label className="label">Type</label>
								<div className="radio-group">
									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" id="inlineRadio1" value="all_day" checked={this.state.type === "all_day"} onChange={this.handleTypeChange} />
										<label className="form-check-label" htmlFor="inlineRadio1">All Day</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" id="inlineRadio2" value="partial_day" checked={this.state.type === "partial_day"} onChange={this.handleTypeChange} />
										<label className="form-check-label" htmlFor="inlineRadio2">Partial Day</label>
									</div>
								</div>
							</div>
						</div>
						{this.state.type === 'all_day' && (
							<div className="col-md-6 col-12">
								<div className="form-group">
									<label className="label">Availability</label>
									<div className="input-effect-1">
										<select className="normal-select" name="availability" value={this.state.availability} onChange={event => { this.handleInputChange(event) }}>
											<option value="">Select One</option>
											<option value="1">Available</option>
											<option value="-1">Unavailable</option>
										</select>
										<div className="focus-border"></div>
									</div>
									{available_error}
								</div>
							</div>
						)}
						{this.state.type === "partial_day" && (
							<div className="col-12">
								{this.state.times.map((time, idx) => (
									<div className="row" key={idx}>
										<div className="col-md-6 col-12">
											<div className="row">
												<div className="col-6">
													<div className="form-group">
														<label className="label">Time</label>
														<div className="timepicker">
															<div className="input-group">
																<TimePicker showSecond={false} value={time.start_time !== '' ? moment(time.start_time, 'HH:mm') : moment()} onChange={this.handleCloneStartTimeChange(idx)} />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faClock} className="fa fa-calendar-alt" />
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label className="label">
															&nbsp;{this.state.times.length > 1 && idx > 0 &&
																<button className="btn-text-link delete" onClick={this.handleRemoveTimeField(idx)}>Delete</button>
															}
														</label>
														<div className="timepicker">
															<div className="input-group">
																<TimePicker showSecond={false} value={time.end_time !== '' ? moment(time.end_time, 'HH:mm') : moment()} onChange={this.handleCloneEndTimeChange(idx)} />
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faClock} className="fa fa-faclock" />
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}

								<div className="form-group">
									<button className="btn-add" onClick={this.handleAddTimes}>Add</button>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-orange" onClick={this.handleSubmit}>Add</button>
				</div>
			</div>
		);
	}
}

Form.propTypes = {
	onAvailabilitySubmitted: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	form_success: PropTypes.bool
}