export const FETCH_VENUES_PERFORMED = 'FETCH_VENUES_PERFORMED';
export const FETCH_VENUES_SUCCESS = 'FETCH_VENUES_SUCCESS';
export const VENUE_LOADER_SHOW = 'VENUE_LOADER_SHOW';
export const VENUE_CREATE_PERFORMED = 'VENUE_CREATE_PERFORMED';
export const VENUE_CREATE_SUCCESS = 'VENUE_CREATE_SUCCESS';
export const VENUE_CREATE_ERROR = 'VENUE_CREATE_ERROR';
export const VENUE_DELETE_PERFORMED = 'VENUE_DELETE_PERFORMED';
export const VENUE_DELETE_SUCCESS = 'VENUE_DELETE_SUCCESS';
export const GET_VENUE_DETAIL = 'GET_VENUE_DETAIL';
export const GET_VENUE_DETAIL_SUCCESS = 'GET_VENUE_DETAIL_SUCCESS';
export const VENUE_EDIT_PERFORMED = 'VENUE_EDIT_PERFORMED';
export const VENUE_EDIT_ERROR = 'VENUE_EDIT_ERROR';
export const VENUE_EDIT_SUCCESS = 'VENUE_EDIT_SUCCESS';
export const GET_REGIONS_FOR_VENUE = 'GET_REGIONS_FOR_VENUE';
export const GET_REGIONS_FOR_VENUE_SUCCESS = 'GET_REGIONS_FOR_VENUE_SUCCESS';


export const fetchVenuesPerformed = () => ({
    type: FETCH_VENUES_PERFORMED,
});

export const fetchVenuesSuccess = (results) => ({
    type: FETCH_VENUES_SUCCESS,
    results
});

export const venueLoaderStatus = (status) => ({
	type: VENUE_LOADER_SHOW,
	status
});

export const venueCreatePerformed = (venueFormData) => ({
    type: VENUE_CREATE_PERFORMED,
    venueFormData
});

export const venueCreateSuccess = (status) => ({
    type: VENUE_CREATE_SUCCESS,
    status
});

export const venueCreateErrors = (errors) => ({
    type: VENUE_CREATE_ERROR,
	errors
});

export const deleteVenue = (id) => ({
    type: VENUE_DELETE_PERFORMED,
    id
});

export const venueDeleteSuccess = (status) => ({
    type: VENUE_DELETE_SUCCESS,
    status
});

export const getVenue = (id) => ({
    type: GET_VENUE_DETAIL,
    id
});

export const getVenueSuccess = (venue) => ({
    type: GET_VENUE_DETAIL_SUCCESS,
    venue
});

export const venueEditPerformed = (venueFormData, id) => ({
    type: VENUE_EDIT_PERFORMED,
    venueFormData,
    id
});

export const venueEditErrors = (errors) => ({
    type: VENUE_EDIT_ERROR,
	errors
});

export const venueEditSuccess = (status) => ({
    type: VENUE_EDIT_SUCCESS,
    status
});

export const getRegionsForVenue = () => ({
    type: GET_REGIONS_FOR_VENUE
});

export const getRegionsForVenueSuccess = (results) => ({
    type: GET_REGIONS_FOR_VENUE_SUCCESS,
    results
});