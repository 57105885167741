import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { API_BASE_URL} from '../constants'
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
//import moment from 'moment';
import { 
	FETCH_TRAVEL_CLAIM,
	fetchTravelClaim,
	fetchTravelClaimSuccess,
	EDIT_TRAVEL_CLAIM,
	fetchTravelClaimLoader,
	editTravelClaimLoader,
	APPROVE_TRAVEL_CLAIM,
	DECLINE_TRAVEL_CLAIM,
 } from '../actions/travel_claim';
 import moment from 'moment';
//import { showLoader, hideLoader } from '../actions/loader';


function* fetchTravelClaimPerformed({ filters }) {
	yield put(fetchTravelClaimLoader(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		let params = new URLSearchParams();
		console.log('filters', filters);
		if(filters != null){
			Object.keys(filters).forEach((key)=>{
				params.append(key,filters[key]);
			})
		}
		/*else{
			yield put(fetchTravelClaimPerformed(generateParams(filters)));
		}*/
		const results = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/travelclaim`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				},
				params: params,
			}
		);
		const teams = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/teams`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				},
			}
		);

		console.log('teams', teams);
	

		const users = yield call(
			axios.get,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/users`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				},
			}
		);
		
		console.log('users', users);
		
		const leagues = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/leagues`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + user.access_token,
                },
            }
        );
 
        console.log('leaguesData', leagues);
		
		yield put(fetchTravelClaimLoader(false));
		yield put(fetchTravelClaimSuccess(results.data, teams.data, leagues, users.data));
        

		
		

		
	} catch (error) {
		yield put(fetchTravelClaimLoader(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}

function* editTravelClaimPerformed({ id, data }) {
	yield put(editTravelClaimLoader(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/travelclaim/${id}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(editTravelClaimLoader(false));
		yield put(fetchTravelClaim());
		swal(
			'Success',
			results.data.Message,
			'success'
		);
	} catch (error) {
		yield put(editTravelClaimLoader(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		} else if (error.response.status === 403) {
			swal(
				'Error',
				error.response.data.Message,
				'error'
			);
		} else {
			swal(
				'Error',
				error.response.data.message,
				'error'
			);
		}
		console.log(error);
		
	}
}

function* approveTravelClaim({ data }) {
	yield put(editTravelClaimLoader(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/travelclaim`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(editTravelClaimLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		if(rememberedFilters == null){
			yield put(fetchTravelClaim());
		}else{
			yield put(fetchTravelClaim(generateParams(rememberedFilters)));
		}
		
	} catch (error) {
		yield put(editTravelClaimLoader(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);
	}
}
const generateParams = filters => {
	let date;
	if (filters.chooseDate === 'date-range') {
		date = {
			daterange: filters.date_range === 0 ? null : filters.date_range,
			game_date: null
		}
	} else {
		date = {
			daterange: null,
			game_date: filters.game_date == null ? null : moment(filters.game_date).format('YYYY-MM-DD'),
		}
	}
	let obj = {
		game_time: filters.game_time === 0 ? null : filters.game_time
	};
	let newobj = { ...obj, ...date};
	let params = new URLSearchParams();
		Object.keys(newobj).forEach(key =>{
			
			if(newobj[key]!= null){
				params.append(key,newobj[key]);
			}
		});
		return params;
};

function* declineTravelClaim({ data }) {
	yield put(editTravelClaimLoader(true));
	try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		const results = yield call(
			axios.put,
			`${API_BASE_URL}/v1/auth/organisation/${organisation_id}/travelclaim/decline`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		);
		yield put(editTravelClaimLoader(false));
		swal(
			'Success',
			results.data.message,
			'success'
		);
		const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		if(rememberedFilters == null){
			yield put(fetchTravelClaim());
		}else{
			yield put(fetchTravelClaim(generateParams(rememberedFilters)));
		}
		
	} catch (error) {
		yield put(editTravelClaimLoader(false));
		if (error.response.status === 401) {
			localStorage.removeItem('user');
			swal(
				'Error',
				'Your session has expired.',
				'error'
			);
			yield put(push('/login'));
		}
		console.log(error);	
	}
}
export default function* () {
	yield takeLatest(FETCH_TRAVEL_CLAIM, fetchTravelClaimPerformed);
	yield takeLatest(EDIT_TRAVEL_CLAIM, editTravelClaimPerformed);
	yield takeLatest(APPROVE_TRAVEL_CLAIM, approveTravelClaim);
	yield takeLatest(DECLINE_TRAVEL_CLAIM, declineTravelClaim);
}