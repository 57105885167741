import React from 'react';
import loaderSvg from '../../public/svg/loader.svg';


const Loader = ({ sidebarWidth, headerHeight}) => {
	const style = {
		width: `calc(100% - ${sidebarWidth}px)`,
		height: `calc(100% - ${headerHeight}px)`
	};
	return (
		<div className="loader" style={style}>
			<img src={loaderSvg} className="imgsvg svgloader" alt="loader" />
		</div>
	);
}

export default Loader;