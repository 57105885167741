import { connect } from 'react-redux';
import DetailPage from './detail';
import {
	fetchCustomReportsDetails
} from '../../../../../actions/reports';

function mapsDispatchToProps(dispatch) {
	return {
		fetchCustomReportsDetails: (id) => dispatch(fetchCustomReportsDetails(id))
	};
}

function mapsStateToProps(state) {
	return {
		detail: state.reports.detail,
		loader: state.reports.loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(DetailPage);