import { connect } from 'react-redux';
import BulkImport from './bulk_import';
import { addBulkGames } from '../../../../../../actions/game';

function mapsDispatchToProps(dispatch) {
	return {
		addBulkGames: (data) => dispatch(addBulkGames(data))
	}
}

function mapsStateToProps(state) {
	return {
		clearBulkFiles: state.game.clearBulkFiles
	}
}

export default connect(mapsStateToProps, mapsDispatchToProps)(BulkImport);