import { connect } from 'react-redux';
import PendingUserPage from './pending_user.js';
import { resendInvite, removePendingUser } from '../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		resendInvite: (data) => dispatch(resendInvite(data)),
		removePendingUser: (data) => dispatch(removePendingUser(data))
	};
}


export default connect(() => (null), mapsDispatchToProps)(PendingUserPage);