import TravelClaimList from './travel_claim_list';
import { connect } from 'react-redux';
import { fetchTravelClaim } from '../../../../../actions/travel_claim';
//import { fetchGamesData } from '../../../../../actions/game';
function mapsDispatchToProps(dispatch) {
	return {
		fetchTravelClaim: (filters) => dispatch(fetchTravelClaim(filters)),
		
	}
}

function mapsStateToProps(state) {
	return {
		data: state.travel_claim.data,
		teams: state.travel_claim.teams.data,
		users: state.travel_claim.users.data,
		leagues: state.travel_claim.leagues.data,
		fetch_loder: state.travel_claim.fetch_loder,
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(TravelClaimList);