import { connect } from 'react-redux';
import ResultsPage from './results';
import { getPendingUser, resendInvite } from '../../../../../actions/official';

function mapsDispatchToProps(dispatch) {
	return {
		getPendingUser: () => dispatch(getPendingUser()),
		resendInvite: (data) => dispatch(resendInvite(data)),
	};
}

function mapsStateToProps(state) {
	return {
		list: state.official.pending_user_list,
		loader: state.official.pending_user_loader,
		action_loader: state.official.resend_invite_loader
	};
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(ResultsPage);