import React, { Component } from 'react';
import DashboardPage from '../admin/dashboard/dashboard.container';
import OfficialDashboard from '../official/dashboard/official_dashboard.container';
import withAuth from '../../AuthHOC/withAuth';
import { getPermission } from '../../../constants';

class Dashboard extends Component {
	render() {
		let roles = ['administrator', 'allocator','billing'];
		let auth_data = getPermission();
		let found = auth_data.some(p => roles.includes(p));
		if (found) {
			return <DashboardPage history={this.props.history} />;
		}
		else {
			return <OfficialDashboard />;

		}

	}
}

export default withAuth(Dashboard, ['administrator', 'allocator', 'official', 'billing']);