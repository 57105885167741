import React from 'react';
import './organisation-edit.scss';
import {Link} from 'react-router-dom';
import EditForm from './edit-form/edit_form';
import EditPhoto from './edit-photo/edit_photo';
import withAuth from '../../../AuthHOC/withAuth';

class OrganisationEdit extends React.Component {
	render(){
		return(
			<div className="organisation-edit">
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Edit Organisation</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Edit Organisation</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="box">
					<div className="row">
						<EditPhoto />
						<EditForm />
					</div>
				</div>
			</div>
		);
	}
}

export default withAuth(OrganisationEdit,['administrator']);