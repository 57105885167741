export const DASHBOARD_LOADER_SHOW = 'DASHBOARD_LOADER_SHOW';
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA';
export const FETCH_CHART_DATA_SUCCESS = 'FETCH_CHART_DATA_SUCCESS';
export const FTECH_CALENDAR_DATA = 'FTECH_CALENDAR_DATA';
export const FETCH_CALENDAR_DATA_SUCCESS = 'FETCH_CALENDAR_DATA_SUCCESS';
export const FTECH_OFFICIAL_CALENDAR_DATA = 'FTECH_OFFICIAL_CALENDAR_DATA';
export const FETCH_OFFICIAL_CALENDAR_DATA_SUCCESS = 'FETCH_OFFICIAL_CALENDAR_DATA_SUCCESS';

export const dashboardLoaderStatus = (status) => ({
    type: DASHBOARD_LOADER_SHOW,
	status
});

export const fetchChartData = (daterange) => ({
    type: FETCH_CHART_DATA,
    daterange
});

export const fetchChartDataSuccess = (results) => ({
    type: FETCH_CHART_DATA_SUCCESS,
    results
});

export const fetchCalendarData = () => ({
    type: FTECH_CALENDAR_DATA
});

export const fetchCalendarDataSuccess = (results) => ({
    type: FETCH_CALENDAR_DATA_SUCCESS,
    results
})

export const fetchOfficialCalendarData = () => ({
    type: FTECH_OFFICIAL_CALENDAR_DATA
});

export const fetchOfficialCalendarDataSuccess = (results) => ({
    type: FETCH_OFFICIAL_CALENDAR_DATA_SUCCESS,
    results
})