import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import 'rc-calendar/assets/index.css';
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Loader from '../../../../loader/loader';
import FormError from '../../../../form-error/form-error';
import { getOrganisations, API_BASE_URL, getHeaders, getUserId, getOrgId } from '../../../../../constants';

const format = 'YYYY-MM-DD';
const now = moment();
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

function formatFunc(v) {
	return v ? v.format(format) : '';
}

function isValidRange(v) {
	return v && v[0] && v[1];
}

export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: now,
			lock_availability: '',
			type: 'all_day',
			start_time: '',
			end_time: '',
			showTime: true,
			showDateInput: false,
			disabled: false,
			day_mode: 'full_day',
			hoverValue: [],
			dateRange: [],
			organisation: 0,
			lock_start_date: '',
			lock_end_date: '',
			organisation_id: ''
		}
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	onChange = (value) => {
		this.setState({
			date: value,
		});
	}

	rangeOnChange = (value) => {
		this.setState({
			dateRange: value,
		});
	}

	onStartTimeChange(value) {
		if (value != null) {
			this.setState({
				start_time: value.format("HH:mm")
			});
		}
	}

	onEndTimeChange(value) {
		if (value != null) {
			this.setState({
				end_time: value.format("HH:mm")
			});
		}
	}

	handleInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	async handleSubmit(event) {
		event.preventDefault();
		let { date, lock_availability, dateRange, day_mode, organisation } = this.state;
		if (typeof date === 'object') {
			date = date.format('YYYY-MM-DD');
		}

		let data;
		if (day_mode === 'full_day') {
			data = {
				date: date,
				lock_availability: lock_availability,
				type: 'day',
				specific_org: Number(organisation)
			}

		} else if (day_mode === 'date_range') {
			data = {
				lock_availability: lock_availability,
				lock_start_date: dateRange[0].format('YYYY-MM-DD'),
				lock_end_date: dateRange[1].format('YYYY-MM-DD'),
				type: 'range',
				specific_org: Number(organisation)

			};
			//this.props.onLockAvailabilitySubmitted(data);

		}
		console.log("LockForm Data", data);
		this.props.onLockAvailabilitySubmitted(data);		


	}

	onHoverChange = (hoverValue) => {
		this.setState({ hoverValue });
	}

	disabledDate = (current) => {
		const date = moment();

		if (!current) {
			// allow empty select
			return false;
		}
		date.hour(0);
		date.minute(0);
		date.second(0);

		return current.isBefore(date);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.form_success === true) {
			this.setState({
				date: now,
				lock_availability: '',
				organisation: 0,
				lock_start_date: '',
				lock_end_date: '',
				organisation_id: ''
			});
		}
	}

	render() {
		const state = this.state;
		const calendar = (<Calendar
			style={{ zIndex: 1000, width: "100%" }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={state.showDateInput}
		/>);

		const calendarRange = (
			<RangeCalendar
				hoverValue={state.hoverValue}
				onHoverChange={this.onHoverChange}
				showWeekNumber={false}
				dateInputPlaceholder={['start', 'end']}
				defaultValue={[now, now.clone().add(1, 'months')]}
				disabledDate={this.disabledDate}
			/>
		);

		let date_error = null;
		let available_error = null;
		let other_error = null
		if (this.props.errors !== undefined) {
			for (var key in this.props.errors) {
				if (key === 'date')
					date_error = <FormError error={this.props.errors[key][0]} />;
				else if (key === 'lock_availability')
					available_error = <FormError error={this.props.errors[key][0]} />;
				else
					other_error = <FormError error={this.props.errors[key][0]} />;
			}
		}

		const orgOptions = getOrganisations().map(org => (
			<option key={org.org_id} value={org.org_id}>{org.organisation_name}</option>
		));

		return (
			<div>
				{this.props.loading && <Loader />}
				<div className="modal-body">
					<div className="row">
						{other_error}
						<div className="col-12 d-flex mb-3">
							<div className="custom-control custom-radio mr-3">
								<input type="radio" id="fullDayRadio" name="day_mode" value="full_day" checked={this.state.day_mode === 'full_day'} onChange={this.handleInputChange} className="custom-control-input" />
								<label className="custom-control-label" htmlFor="fullDayRadio">Full Day</label>
							</div>
							{/* <div className="custom-control custom-radio mr-3">
								<input type="radio" id="partialDayRadio" name="day_mode" value="partial_day" checked={this.state.day_mode === 'partial_day'} onChange={this.handleInputChange} className="custom-control-input" />
								<label className="custom-control-label" htmlFor="partialDayRadio">Partial Day</label>
							</div> */}
							<div className="custom-control custom-radio">
								<input type="radio" id="dateRangeRadio" name="day_mode" value="date_range" checked={this.state.day_mode === 'date_range'} onChange={this.handleInputChange} className="custom-control-input" />
								<label className="custom-control-label" htmlFor="dateRangeRadio">Date Range</label>
							</div>
						</div>
						{state.day_mode !== 'date_range' && (
							<div className="col-sm-6 col-12">
								<div className="form-group">
									<label className="label">Game Dates</label>
									<div className="input-effect-1">
										<div className="datepicker" id="datepicker">
											<DatePicker
												animation="slide-up"
												disabled={state.disabled}
												calendar={calendar}
												value={state.date}
												onChange={this.onChange}
												getCalendarContainer={() => document.getElementById('datepicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={state.disabled}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={value.format(format)}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
									{date_error}
								</div>
							</div>
						)}

						{state.day_mode === 'date_range' && (
							<div className="col-sm-6 col-12">
								<div className="form-group">
									<label className="label">Date Range</label>
									<div className="input-effect-1">
										<div className="datepicker" id="rangePicker">
											<DatePicker
												animation="slide-up"
												disabled={state.disabled}
												calendar={calendarRange}
												value={state.dateRange}
												onChange={this.rangeOnChange}
												getCalendarContainer={() => document.getElementById('rangePicker')}
												className="custom-calendar"
											>
												{
													({ value }) => {
														return (
															<div className="input-group">
																<input
																	placeholder="Select Date"
																	disabled={state.disabled}
																	readOnly
																	tabIndex="-1"
																	className="ant-calendar-picker-input ant-input form-control"
																	value={(isValidRange(value) && `${formatFunc(value[0])} - ${formatFunc(value[1])}`) || ''}
																/>
																<div className="input-group-append">
																	<span className="input-group-text">
																		<FontAwesomeIcon icon={faCalendarAlt} className="fa fa-calendar-alt" />
																	</span>
																</div>
															</div>
														);
													}
												}
											</DatePicker>
										</div>
										<div className="focus-border"></div>
									</div>
									{date_error}
								</div>
							</div>
						)}

						<div className="col-sm-6 col-12">
							<div className="form-group">
								<label className="label">Availability</label>
								<div className="input-effect-1">
									<select className="normal-select" name="lock_availability" value={this.state.lock_availability} onChange={event => { this.handleInputChange(event) }}>
										
										<option value="1">Lock</option>
									</select>
									<div className="focus-border"></div>
								</div>
								{available_error}
							</div>
						</div>

						{/* {state.day_mode === 'partial_day' && (
							<div className="col-md-6 col-12">
								<div className="row">
									<div className="col-6">
										<div className="form-group">
											<label className="label">Time</label>
											<div className="timepicker">
												<div className="input-group">
													<TimePicker showSecond={false} onChange={this.onStartTimeChange} />
													<div className="input-group-append">
														<span className="input-group-text">
															<FontAwesomeIcon icon={faClock} className="fa fa-calendar-alt" />
														</span>
													</div>
												</div>
											</div>
											{start_time_error}
										</div>
									</div>
									<div className="col-6">
										<div className="form-group">
											<label className="label">&nbsp;</label>
											<div className="timepicker">
												<div className="input-group">
													<TimePicker showSecond={false} onChange={this.onEndTimeChange} />
													<div className="input-group-append">
														<span className="input-group-text">
															<FontAwesomeIcon icon={faClock} className="fa fa-faclock" />
														</span>
													</div>
												</div>
											</div>
											{end_time_error}
										</div>
									</div>
								</div>
							</div>
						)} */}
						<div className="col-sm-6 col-12">
							<div className="form-group">
								<label className="label">Organisation</label>
								<div className="input-effect-1">
									<select className="normal-select" name="organisation" value={this.state.organisation} onChange={event => { this.handleInputChange(event) }}>
										{/* <option value="0">All Organisations</option> */}
										{orgOptions}
									</select>
									<div className="focus-border"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-orange" onClick={this.handleSubmit}>Add</button>
				</div>
			</div>
		);
	}
}

Form.propTypes = {
	onLockAvailabilitySubmitted: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	form_success: PropTypes.bool,
	errors: PropTypes.object,
}