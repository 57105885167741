import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_PAYSCALES_PERFORMED,
    PAYSCALE_CREATE_PERFORMED,
    PAYSCALE_EDIT_PERFORMED,
    PAYSCALE_DELETE_PERFORMED,
    GET_PAYSCALE_DETAIL,
    payscaleLoaderStatus,
    fetchPayScalesSuccess,
    payscaleCreateSuccess,
    payscaleCreateErrors,
    payscaleEditSuccess,
    payscaleEditErrors,
    fetchPayScalesPerformed,
    getPayScaleSuccess
} from '../actions/payscale';
import { push } from 'connected-react-router';
import {API_BASE_URL} from '../constants';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function* fetchPayScales() {
    yield put(payscaleLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(payscaleLoaderStatus(false));
		yield put(fetchPayScalesSuccess(results.data));
    }catch (error) {
        yield put(payscaleLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* doCreateNewPayScale({payscaleFormData}) {
	yield put(payscaleLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const payscaleResults = yield call(
            axios.post,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales`,
            payscaleFormData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(payscaleLoaderStatus(false));
        swal(
            'Success',
            payscaleResults.data.message,
            'success'
        );
        yield put(payscaleCreateSuccess(true));
        yield put(fetchPayScalesPerformed());
    }catch (error) {
		yield put(payscaleLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(payscaleCreateErrors(error.response.data.errors));
        }
        if(error.response.status === 409)
        {
            swal(
                'Error',
                error.response.data.message,
                'error'
            );
        }
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
    }
}

function* doEditPayScale({formData, id}) {
	yield put(payscaleLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const results = yield call(
            axios.put,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(payscaleLoaderStatus(false));
        swal(
            'Success',
            results.data.message,
            'success'
        );
        yield put(payscaleEditSuccess(true));
        yield put(fetchPayScalesPerformed());
    }catch (error) {
		yield put(payscaleLoaderStatus(false));
        if(error.response.status === 422)
        {
            yield put(payscaleEditErrors(error.response.data.errors));
        }
        if(error.response.status === 409)
        {
            swal(
                'Error',
                error.response.data.message,
                'error'
            );
        }
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
    }
}

function* doRemovePayScale({id}) {
    yield put(payscaleLoaderStatus(true));
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        let organisation_id = user.current_org;
        const results = yield call(
            axios.delete,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(payscaleLoaderStatus(false));
		swal(
            'Success',
            results.data.message,
            'success'
        );
        yield put(fetchPayScalesPerformed());
    }catch (error) {
        yield put(payscaleLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

function* fetchPayScaleDetail({id}) {
    yield put(payscaleLoaderStatus(true));
    try {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
        const results = yield call(
            axios.get,
            `${API_BASE_URL}/v1/auth/organisation/${organisation_id}/payscales/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
                }
            }
        );
        yield put(payscaleLoaderStatus(false));
		yield put(getPayScaleSuccess(results.data));
    }catch (error) {
        yield put(payscaleLoaderStatus(false));
        if(error.response.status === 401)
        {
            localStorage.removeItem('user');
            swal(
                'Error',
                'Your session has expired.',
                'error'
            );
            yield put(push('/login'));
        }
        console.log(error);
    }
}

export default function* () {
    yield takeLatest(FETCH_PAYSCALES_PERFORMED, fetchPayScales);
    yield takeLatest(PAYSCALE_CREATE_PERFORMED, doCreateNewPayScale);
    yield takeLatest(PAYSCALE_EDIT_PERFORMED, doEditPayScale);
    yield takeLatest(PAYSCALE_DELETE_PERFORMED, doRemovePayScale);
    yield takeLatest(GET_PAYSCALE_DETAIL, fetchPayScaleDetail);
}