import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../loader/loader';
import 'react-table/react-table.css';
import 'sweetalert2/src/sweetalert2.scss';

export default class GameFeeResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			league_name: '',
			game_length: '',
			game_fees: [{ payscale_id: '', payscale: [] }],
			payscales: [{ id: '', name: '' }]
		}
		this.feeChange = this.feeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.getLeague(this.props.leagueId);
		this.props.fetchPayScales();
		this.props.fetchGameFees(this.props.leagueId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.league !== this.props.league) {
			this.setState({
				league_name: this.props.league.league_name,
				game_length: this.props.league.game_length
			});
		}
		if (prevProps.payscales !== this.props.payscales) {
			const payscales = this.props.payscales.map(payscale => {
				return (
					{ id: payscale.id, name: payscale.name }
				);
			})
			this.setState({ payscales });
		}

		if (prevProps.gamefees !== this.props.gamefees) {
			let newData = this.props.gamefees.data.reduce((acc, { payscale_id, ...rest }) => {
				acc[payscale_id] = acc[payscale_id] || { payscale_id, payscale: [] };
				acc[payscale_id].payscale.push({ ...rest });
				return acc;
			}, {});
			this.setState({
				game_fees: Object.values(newData)
			});
		}
	}

	feeChange(id, pid, event) {
		let game_fees = this.state.game_fees;
		if (event.target.value) {
			if (event.target.value.endsWith('.')) {
				game_fees[id].payscale[pid].amount = parseFloat(event.target.value) + '.';
			} else {
				game_fees[id].payscale[pid].amount = parseFloat(event.target.value);
			}
		}
		else {
			game_fees[id].payscale[pid].amount = 0;
		}
		this.setState({ game_fees });
	}

	handleSubmit() {
		const { game_fees } = this.state;
		let fees = {};
		game_fees.forEach(fee => {
			var key = fee.payscale_id;
			let dict = {};
			fee.payscale.forEach(p => dict[p.position_name] = p.amount)
			fees[key] = dict;
		})
		JSON.stringify(fees);
		this.props.onGameFeeSubmitted({ fees }, this.props.leagueId);
	}

	render() {
		const { game_fees, payscales } = this.state;
		return (
			<div className="organisation-gamefee-form">
				<div className="box">
					<div className="row no-gutters">
						<div className="form-group mr_15">
							<label className="label">League Name</label>
							<div className="input-effect-1">
								<input type="text" className="form-control" readOnly={true} name="league_name" value={this.state.league_name} />
							</div>
						</div>
						<div className="form-group">
							<label className="label">Game Length</label>
							<div className="col-xl-8 col-lg-8 col-md-8 col-12 p-0">
								<div className="input-effect-1">
									<input type="text" className="form-control" readOnly={true} name="game_length" value={this.state.game_length} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="box">
					<div className="table-area">
						{this.props.loading && <Loader />}
						<table cellPadding="0" cellSpacing="0" className="table table-striped table-bordered">
							<thead>
								<tr>
									<th width="75">PayScale</th>
									{game_fees[0].payscale.map((position, id) => (
										<th key={id} width="10">{position.position_name}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{game_fees.map((fee, id) => (
									<tr key={id}>
										<td>
											<span className="ptext">{payscales[id].name}</span>
										</td>
										{fee.payscale.map((p, pid) => (
											<td key={pid} >
												<div className="input-effect-1">
													<input className="form-control" type="text" value={p.amount} onChange={event => this.feeChange(id, pid, event)} />
													<div className="focus-border"></div>
												</div>
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="action text-center">
					<button className="btn btn-orange float-right mr-4 mt-4" onClick={this.handleSubmit}>Save</button>
				</div>
			</div>
		)
	}
}

GameFeeResults.propTypes = {
	getLeague: PropTypes.func.isRequired,
	league: PropTypes.object.isRequired,
	fetchGameFees: PropTypes.func.isRequired,
}