import {
	FETCH_LEAGUES_POSITIONS_SUCCESS,
	FETCH_ABILITIES_SUCCESS
} from '../actions/ability';
const initialState = {
	leagues: [],
	positions: [],
	abilities: []
}

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_LEAGUES_POSITIONS_SUCCESS:
			return {
				...state,
				leagues: actions.results.Leagues,
				positions: actions.results.Fields
			};
		case FETCH_ABILITIES_SUCCESS:
			return {
				...state,
				abilities: actions.results
			}
		default:
			return state;
	}
}