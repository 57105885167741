import React from 'react';
import PropTypes from 'prop-types';

export default function Logout({ onLogoutNavigate, closeTrialBanner }) {
	const logout = (e) => {
		closeTrialBanner();
		e.preventDefault();
		localStorage.removeItem('user');
		onLogoutNavigate();
	}
	return (
		<button className="link" onClick={logout}>Logout</button>
	);
}

Logout.propTypes = {
	onLogoutNavigate: PropTypes.func.isRequired,
}