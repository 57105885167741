import React, { Component } from 'react';
import withAuth from '../../../AuthHOC/withAuth';
import { Link } from 'react-router-dom';
import './features.scss';
import worldwideSvg from '../../../../public/svg/worldwide.svg';
import lockSvg from '../../../../public/svg/lock.svg';
import basketballSvg from '../../../../public/svg/basketball.svg';
import reportSvg from '../../../../public/svg/report-2.svg';
import paperSvg from '../../../../public/svg/paper.svg';
import Switch from "react-switch";
import Loader from '../../../loader/loader';

class FeaturesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			regions: false,
			lockdates: false,
			game_availability: false,
			show_published_games: false,
			acknowledgements: false,
			xerointegration:false
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.props.fetchFeaturesData();
	}

	componentWillReceiveProps(nextProps) {
		let newData = {};
		Object.keys(nextProps.data).forEach((key) => {
			if (nextProps.data[key] === 1) {
				newData[key] = true;
			} else if (nextProps.data[key] == null || nextProps.data[key] === 0) {
				newData[key] = false;
			}
		});

		if (Object.keys(newData).length > 0) {
			this.setState(newData);
		}

		console.log('nextProps', nextProps);
	}

	handleChange(checked, event, id) {
		let data = checked ? 1 : 0;
		if (id === 'regions') {
			this.props.toggleRegions(data);
		} else if (id === 'lockdates') {
			this.props.toggleLockDates(data);
		} else if (id === 'game_availability') {
			this.props.toggleGameAvailability(data);
		} else if (id === 'show_published_games') {
			this.props.toggleGameReports(data);
		} else if (id === 'acknowledgements') {
			this.props.toggleAcknowledgements(data);
		}
		else if (id === 'xerointegration') {
			this.props.toggleXeroIntegration(data);
		}
	}

	render() {
		const isLocked = this.state.lockdates;
		return (
			<div className="features-page">
				{(this.props.loader || this.props.toggle_loader) && <Loader />}
				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title">Features</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Features</li>
							</ol>
						</nav>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-4 mb-3">
						<div className="box">
							<img src={worldwideSvg} alt="logo" className="logo-icon mb-5" />
							<div className="d-flex justify-content-between align-items-center">
								<p>Regions</p>
								<Switch
									id="regions"
									name="regions"
									onChange={this.handleChange}
									checked={this.state.regions}
									onColor="#f15a24"
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-4 mb-3">
						{isLocked
							?

							<Link to="/lock-availability">
								<div className="box">
									<img src={lockSvg} alt="logo" className="logo-icon mb-5" />
									<div className="d-flex justify-content-between align-items-center">
										<p>Lock Availability</p>
										<Switch
											id="lockdates"
											onChange={this.handleChange}
											checked={this.state.lockdates}
											onColor="#f15a24"
										/>
									</div>
								</div>
							</Link>


							:

							<div className="box">
								<img src={lockSvg} alt="logo" className="logo-icon mb-5" />
								<div className="d-flex justify-content-between align-items-center">
									<p>Lock Availability</p>
									<Switch
										id="lockdates"
										onChange={this.handleChange}
										checked={this.state.lockdates}
										onColor="#f15a24"
									/>
								</div>
							</div>}
					</div>
					<div className="col-sm-12 col-md-4 mb-3">
						<div className="box">
							<img src={basketballSvg} alt="logo" className="logo-icon mb-5" />
							<div className="d-flex justify-content-between align-items-center">
								<p>Individual Game Availability</p>
								<Switch
									id="game_availability"
									onChange={this.handleChange}
									checked={this.state.game_availability}
									onColor="#f15a24"
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-4 mb-3">
						<div className="box">
							<img src={reportSvg} alt="logo" className="logo-icon mb-5" />
							<div className="d-flex justify-content-between align-items-center">
								<p>Disciplinary Reports</p>
								<Switch
									id="show_published_games"
									onChange={this.handleChange}
									checked={this.state.show_published_games}
									onColor="#f15a24"
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-4 mb-3">
						<div className="box">
							<img src={paperSvg} alt="logo" className="logo-icon mb-5" />
							<div className="d-flex justify-content-between align-items-center">
								<p>Acknowledgements</p>
								<Switch
									id="acknowledgements"
									onChange={this.handleChange}
									checked={this.state.acknowledgements}
									onColor="#f15a24"
								/>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-4 mb-3">
						<div className="box">
							<img src={reportSvg} alt="logo" className="logo-icon mb-5" />
							<div className="d-flex justify-content-between align-items-center">
								<p>XERO</p>
								<Switch
									id="xerointegration"
									onChange={this.handleChange}
									checked={this.state.xerointegration}
									onColor="#f15a24"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withAuth(FeaturesPage, ['administrator']);