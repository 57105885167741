import {connect} from 'react-redux';
import LeaguesResults from './league_results';
import {fetchLeaguesPerformed, deleteLeague} from '../../../../../actions/league';

function mapsDispatchToProps (dispatch) {
    return {
        fetchLeagues: () => dispatch(fetchLeaguesPerformed()),
        removeLeague: (id) => dispatch(deleteLeague(id))
    };
}

function mapsStateToProps (state) {
    return {
        leagues: state.league.results,
        loading: state.league.is_loading,
        deleted: state.league.is_deleted
    };
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(LeaguesResults);