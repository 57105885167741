import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { confirmAcknowledgement, declineAcknowledgement } from '../../actions/acknowledgements';
import swal from 'sweetalert2/dist/sweetalert2.js';

class ModalAcknowledgements extends Component {
	handleConfirm = () => {
		const { acknowledgementData } = this.props;
		this.props.confirmAcknowledgement(acknowledgementData.acknowledgement_id);
	}

	handleDecline = () => {
		swal({
			title: 'Are you sure?',
			text: "Please note that you will be be de-activated from this organisation by declining this statement.",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#dd3333',
			cancelButtonColor: '#a9a9a9',
			confirmButtonText: 'Decline!'
		}).then((result) => {
			if (result.value) {
				const { acknowledgementData } = this.props;
				this.props.declineAcknowledgement(acknowledgementData.acknowledgement_id);
			}
		});
	}

	render() {
		const { acknowledgementData } = this.props;
		const editorState = !!acknowledgementData.text
			? EditorState.createWithContent(convertFromRaw(JSON.parse(acknowledgementData.text)))
			: EditorState.createEmpty();
		const orgName = !!acknowledgementData.organisation_name ? acknowledgementData.organisation_name : 'My Current Org'
		return (
			<Modal
				open={true}
				onClose={() => {}}
				showCloseIcon={false}
				center
				classNames={{ modal: "modal fade show" }}
			>
				<div className="modal-dialog modal-md modal-dialog-centered" style={{ maxWidth: 800, height: '100%' }}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Acknowledgement</h5>
						</div>
						<div className="modal-body">
							<Editor
								editorState={editorState}
								toolbarHidden={true}
								readOnly={true}
							/>
						</div>
						<div className="modal-footer justify-content-between">
							<button className="btn btn-success" onClick={this.handleConfirm}>Confirm</button>
							<button className="btn btn-danger" onClick={this.handleDecline}>Decline, please remove me from {orgName}</button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapsDispatchToProps = dispatch => ({
	confirmAcknowledgement: (id) => dispatch(confirmAcknowledgement(id)),
	declineAcknowledgement: (id) => dispatch(declineAcknowledgement(id)),
});

const mapsStateToProps = state => ({
	acknowledgementData: state.acknowledgements.modalData
});
 
export default connect(() => (mapsStateToProps), mapsDispatchToProps)(ModalAcknowledgements);