import { connect } from 'react-redux';
import CustomReportsPage from './custom_reports';
import {
	submitCustomReports,
} from '../../../../actions/reports';

function mapsDispatchToProps(dispatch) {
	return {
		submitReports: (data, callback) => dispatch(submitCustomReports(data, callback))
	};
}

function mapsStateToProps(state) {
	return {
		loader: state.reports.loader
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(CustomReportsPage);