import { connect } from 'react-redux';
import LockAvailability from './lock_availability';
import { fetchLockAvailabilities, deleteLockAvailability,lockAvailabilityEditSuccess,lockAvailabilityCreatePerformed,goToGamePageOrg } from '../../../../actions/lock_availability';

function mapsDispatchToProps(dispatch) {
	return {
		goToGamePageOrg: (id) => dispatch(goToGamePageOrg(id)),
		fetchLockAvailability: () => dispatch(fetchLockAvailabilities()),
		// onLockAvailabilitySubmitted: (formData) => dispatch(lockAvailabilityCreatePerformed(formData)),
		
        // removeLockAvailability: (id) => dispatch(deleteLockAvailability(id)),
        // LockavailabilitySuccess: (status) => dispatch(lockAvailabilityEditSuccess(status))
	}
}

function mapsStateToProps(state) {
	return {
		lock_availabilities: state.lock_availability.lock_results,
		loading: state.lock_availability.is_loading
	}
}

export default connect(() => (mapsStateToProps), mapsDispatchToProps)(LockAvailability);