import {
	FETCH_PERFORMANCE_REPORT_LIST_SUCCESS,
	FETCH_PERFORMANCE_REPORT_LOADING,
	PERFORMANCE_REPORT_MODAL_LOADING
} from '../actions/performance_report';

const initialState = {
	data: [],
	teams: [],
	leagues: [],
	venues: [],
	fetchLoading: false,
	modalLoading: false
};

export default (state, actions) => {
	if (state === undefined) {
		return initialState;
	}

	switch (actions.type) {
		case FETCH_PERFORMANCE_REPORT_LIST_SUCCESS:
			return {
				...state,
				data: actions.results,
				teams: actions.teams,
				leagues: actions.leagues,
				venues: actions.venues
				
			};

		case FETCH_PERFORMANCE_REPORT_LOADING:
			return {
				...state,
				fetchLoading: actions.status
			};

		case PERFORMANCE_REPORT_MODAL_LOADING:
			return {
				...state,
				modalLoading: actions.status
			};
		default: return state;
	}
}