export const GET_ALL_ACKNOWLEDGEMENTS = 'GET_ALL_ACKNOWLEDGEMENTS';
export const GET_ALL_ACKNOWLEDGEMENTS_SUCCESS = 'GET_ALL_ACKNOWLEDGEMENTS_SUCCESS';
export const GET_ACKNOWLEDGEMENT = 'GET_ACKNOWLEDGEMENT';
export const GET_ACKNOWLEDGEMENT_SUCCESS = 'GET_ACKNOWLEDGEMENT_SUCCESS';
export const SAVE_ACKNOWLEDGEMENT = 'SAVE_ACKNOWLEDGEMENT';
export const EDIT_ACKNOWLEDGEMENT = 'EDIT_ACKNOWLEDGEMENT';
export const DELETE_ACKNOWLEDGEMENT = 'DELETE_ACKNOWLEDGEMENT';
export const TOGGLE_ACTIVATE_ACKNOWLEDGEMENT = 'TOGGLE_ACTIVATE_ACKNOWLEDGEMENT';
export const DEACTIVATE_ACKNOWLEDGEMENT = 'DEACTIVATE_ACKNOWLEDGEMENT';
export const SHOW_ACKNOWLEDGEMENT_MODAL = 'SHOW_ACKNOWLEDGEMENT_MODAL';
export const SET_ACKNOWDLEDGEMENT_MODAL_DATA = 'SET_ACKNOWDLEDGEMENT_MODAL_DATA';
export const RESET_ACKNOWLEDGEMENT_MODAL_STATE = 'RESET_ACKNOWLEDGEMENT_MODAL_STATE';
export const CONFIRM_ACKNOWLEDGEMENT = 'CONFIRM_ACKNOWLEDGEMENT';
export const DECLINE_ACKNOWLEDGEMENT = 'DECLINE_ACKNOWLEDGEMENT';

export const getAllAcknowledgements = () => ({
	type: GET_ALL_ACKNOWLEDGEMENTS
});

export const getAllAcknowledgementsSuccess = (results) => ({
	type: GET_ALL_ACKNOWLEDGEMENTS_SUCCESS,
	results
});

export const getAcknowledgement = (id) => ({
	type: GET_ACKNOWLEDGEMENT,
	id
});

export const getAcknowledgementSuccess = (results) => ({
	type: GET_ACKNOWLEDGEMENT_SUCCESS,
	results
});

export const saveAcknowledgement = (data) => ({
	type: SAVE_ACKNOWLEDGEMENT,
	data
});

export const editAcknowledgement = (id, data) => ({
	type: EDIT_ACKNOWLEDGEMENT,
	id,
	data
});

export const deleteAcknowledgement = (id) => ({
	type: DELETE_ACKNOWLEDGEMENT,
	id
});

export const toggleActivateAcknowledgement = (id, value) => ({
	type: TOGGLE_ACTIVATE_ACKNOWLEDGEMENT,
	id,
	value
});

export const showAcknowledgementModal = value => ({
	type: SHOW_ACKNOWLEDGEMENT_MODAL,
	value
});

export const setAcknowledgementModalData = value => ({
	type: SET_ACKNOWDLEDGEMENT_MODAL_DATA,
	value
});

export const resetAcknowledgementModalState = () => ({
	type: RESET_ACKNOWLEDGEMENT_MODAL_STATE
});

export const confirmAcknowledgement = (id) => ({
	type: CONFIRM_ACKNOWLEDGEMENT,
	id
});

export const declineAcknowledgement = (id) => ({
	type: DECLINE_ACKNOWLEDGEMENT,
	id
});