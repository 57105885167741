import React, {Component} from 'react';
import './official-list.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import OfficialResults from './official-results/official-results.container';
import withAuth from '../../../AuthHOC/withAuth';

class OfficialList extends Component {
    render () {
        return(
            <div className="official-list">
                <div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title">Officials</h1>
                        <nav>
                            <ol className="breadcrumb page-title">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item current">Officials</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="actions">
                        <Link to="/official/new" className="btn btn-orange">Add Officials</Link>
                    </div>
                </div>
                <div className="official-list">
                    <OfficialResults />
                </div>
            </div>
        );
    }
}

export default withAuth(OfficialList,['administrator','allocator']);